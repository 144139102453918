import { Button } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import "./Home Stylesheets/homeHeader.css"

function Header() {
  return (
<>
    {/* <div className="start-page-container-custom-header">
      <div className="start-page-content-custom-header">
        <div className="start-page-content-wrapper-custom-header">
          <img
            src="/img/unternehmenslogos/Nifty_Logo_Glass.svg"
            height="80"
          ></img>
          <div className="start-page-content-wrapper-custom-header-text">

          </div>
        </div>
      </div>
    </div> */}
    </>
  );
}

export default Header;
