import BranchenReportsFinancialsChart4 from "./BranchenReportsFinancialsChart4";
import React from "react";


function BranchenReportsFinancialsApp4() {


    return (
<>

<span style={{fontSize:"20px", marginLeft:"5%", fontWeight:"900"}}>Gesundheit</span>

<div className="BRFundamentalsChartContainer" style={{marginTop:"5px", alignItems:"center"}}>
<BranchenReportsFinancialsChart4/>
</div>


</>
  
    );
  }
  
  export default BranchenReportsFinancialsApp4;