import { height } from "@mui/system";
import { fontFamily } from "@mui/system";
import Aktienkurs from "../Charts/Chartlib/Aktienkurs";
import "./CompanyInfo.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";




function CompanyInfoOverviewTables({quickinfoData}) {
  



const FixedLine = {
fontFamily: "Inter-bold",
fontSize: "calc(8px + 0.4vw)",
color: "white",
paddingLeft:5,
}

const FixedLineRight = {
  fontFamily: "Inter-bold",
  fontSize: "calc(8px + 0.4vw)",
  color: "white",
  paddingLeft: 10,
}

const FlexLine = {
  fontFamily: "inter-regular",
  fontSize: "calc(8px + 0.4vw)",
  color: "white",
  paddingRight: 10,
}

const FlexLineRight = {
  fontFamily: "inter-regular",
  fontSize: "calc(8px + 0.4vw)",
  color: "white",
  paddingRight: 5,
  textAlign: "right",
}

const TopLineRow = {
  fontFamily: "Inter-bold",
  fontSize: "calc(8px + 0.4vw)",
  color: "white",
  paddingRight: 5,
  paddingLeft: 5,
  textAlign: "Left",
}

const UnderLineRow = {
  fontFamily: "inter-regular",
  fontSize: "calc(8px + 0.4vw)",
  color: "white",
  paddingRight: 5,
  paddingLeft: 5,
  textAlign: "Left",
}






  return (

<div className="CompanySecondRow">
  <div className="CompanySecondRowInfoTable1">
  <div className="CompanyTableSecondRowContainer">
     <table className="CompanyTableSecondRow">
<tbody>
<tr>
<td colSpan={2} style={FixedLine}>Sektor (GICS)</td><td colSpan={2} style={FlexLineRight}>{quickinfoData.sector}</td>
</tr>
<tr>
<td colSpan={2} style={FixedLine}>Industrie (GICS)</td><td colSpan={2} style={FlexLineRight}>{quickinfoData.industry}</td>
</tr>
<tr>
<td colSpan={2} style={FixedLine}>Börsengang</td><td colSpan={2} style={FlexLineRight}>{quickinfoData.boersengang}</td>
</tr>
<tr>
<td colSpan={2} style={FixedLine}>Nächste Dividende</td><td colSpan={2} style={FlexLineRight}>{quickinfoData.nextdividendeDate} {quickinfoData.nextdividendeValue} </td>
</tr>
<tr>
<td colSpan={2} style={FixedLine}>Nächster Bericht</td><td colSpan={2} style={FlexLineRight}>{quickinfoData.naechsterBericht}</td>
</tr>
<tr>
<td colSpan={2} style={FixedLine}>Mitarbeiter</td><td colSpan={2} style={FlexLineRight}><NumberFormat value={quickinfoData.mitarbeiter} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} isNumericString={true}/></td>
</tr>
<tr>
<td colSpan={2} style={FixedLine}>Gelistet</td><td colSpan={2} style={FlexLineRight}>{quickinfoData.boerse}</td>
</tr>
</tbody>
</table>
</div>
  </div>

  <div className="CompanySecondRowInfoTable1">
  <div className="CompanyTableSecondRowContainer">
     <table className="CompanyTableSecondRow">
<tbody>
<tr>
<td colSpan={2} style={FixedLine}>KGV</td><td colSpan={2} style={FlexLineRight}><NumberFormat value={quickinfoData.KGV} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} isNumericString={true}/>
 </td>
</tr>
<tr>
<td colSpan={2} style={FixedLine}>KUV</td><td colSpan={2} style={FlexLineRight}><NumberFormat value={quickinfoData.KUV} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} isNumericString={true}/></td>
</tr>
<tr>
<td colSpan={2} style={FixedLine}>PEG</td><td colSpan={2} style={FlexLineRight}><NumberFormat value={quickinfoData.PEG} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} isNumericString={true}/></td>
</tr>
<tr>
<td colSpan={2} style={FixedLine}>K/FCF</td><td colSpan={2} style={FlexLineRight}><NumberFormat value={quickinfoData.KFCF} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} isNumericString={true}/></td>
</tr>
<tr>
<td colSpan={2} style={FixedLine}>EPS</td><td colSpan={2} style={FlexLineRight}><NumberFormat value={quickinfoData.EPS} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} isNumericString={true}/></td>
</tr>
<tr>
<td colSpan={2} style={FixedLine}>Dividende p.a.</td><td colSpan={2} style={FlexLineRight}>{<NumberFormat value={quickinfoData.dividende} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} isNumericString={true}/>} {quickinfoData.currency} ({<NumberFormat value={quickinfoData.dividendeProzent} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} isNumericString={true}/>}%)</td>
</tr>
<tr>
<td colSpan={2} style={FixedLine}>Börsenwert</td><td colSpan={2} style={FlexLineRight}><NumberFormat value={quickinfoData.boersenwert} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} isNumericString={true}/> {quickinfoData.currency}</td>
</tr>
</tbody>
</table>
</div>
  </div>

</div>


  );
}

export default CompanyInfoOverviewTables;
