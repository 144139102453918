import BranchenReportsFundamentalsChart3 from "./BranchenReportsFundamentalsChart3";
import React from "react";


function BranchenReportsFundamentalsApp3() {


    return (
<>

<span style={{fontSize:"20px", marginLeft:"5%", fontWeight:"900"}}>Gewinn historisch</span>

<div className="BRFundamentalsChartContainer" style={{marginTop:"5px", alignItems:"center"}}>
<BranchenReportsFundamentalsChart3/>
</div>


</>
  
    );
  }
  
  export default BranchenReportsFundamentalsApp3;