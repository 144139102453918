import BranchenReportsFundamentalsChart4 from "./BranchenReportsFundamentalsChart4";
import React from "react";


function BranchenReportsFundamentalsApp4() {


    return (
<>

<span style={{fontSize:"20px", marginLeft:"5%", fontWeight:"900"}}>Free Cashflow historisch</span>

<div className="BRFundamentalsChartContainer" style={{marginTop:"5px", alignItems:"center"}}>
<BranchenReportsFundamentalsChart4/>
</div>


</>
  
    );
  }
  
  export default BranchenReportsFundamentalsApp4;