import React, { Component } from "react";
import Chart from "react-apexcharts";

class Aktienkurs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          animations: {
            enabled: false
          },
          id: "basic-bar",
          foreColor: "white",
        },
        stroke: {
          width: 1,
          curve: "straight",
        },
        grid: {
          borderColor: "rgba(255,255,255,0.3)"
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          type: "numeric",
          forceNiceScale: true,
          tickPlacement: 'on',
          labels: {
          rotate: 0,
          },
        tickAmount: 10,
        axisTicks: {
            show: false,
            offsetY: 20,
        },
        forceNiceScale: true,
        series:  [
          "17/03/1980",
          "18/03/1980",
          "19/03/1980",
          "20/03/1980",
          "21/03/1980",
          "24/03/1980",
          "25/03/1980",
          "26/03/1980",
          "27/03/1980",
          "28/03/1980",
          "31/03/1980",
          "01/04/1980",
          "02/04/1980",
          "03/04/1980",
          "07/04/1980",
          "08/04/1980",
          "09/04/1980",
          "10/04/1980",
          "11/04/1980",
          "14/04/1980",
          "15/04/1980",
          "16/04/1980",
          "17/04/1980",
          "18/04/1980",
          "21/04/1980",
          "22/04/1980",
          "23/04/1980",
          "24/04/1980",
          "25/04/1980",
          "28/04/1980",
          "29/04/1980",
          "30/04/1980",
          "01/05/1980",
          "02/05/1980",
          "05/05/1980",
          "06/05/1980",
          "07/05/1980",
          "08/05/1980",
          "09/05/1980",
          "12/05/1980",
          "13/05/1980",
          "14/05/1980",
          "15/05/1980",
          "16/05/1980",
          "19/05/1980",
          "20/05/1980",
          "21/05/1980",
          "22/05/1980",
          "23/05/1980",
          "27/05/1980",
          "28/05/1980",
          "29/05/1980",
          "30/05/1980",
          "02/06/1980",
          "03/06/1980",
          "04/06/1980",
          "05/06/1980",
          "06/06/1980",
          "09/06/1980",
          "10/06/1980",
          "11/06/1980",
          "12/06/1980",
          "13/06/1980",
          "16/06/1980",
          "17/06/1980",
          "18/06/1980",
          "19/06/1980",
          "20/06/1980",
          "23/06/1980",
          "24/06/1980",
          "25/06/1980",
          "26/06/1980",
          "27/06/1980",
          "30/06/1980",
          "01/07/1980",
          "02/07/1980",
          "03/07/1980",
          "07/07/1980",
          "08/07/1980",
          "09/07/1980",
          "10/07/1980",
          "11/07/1980",
          "14/07/1980",
          "15/07/1980",
          "16/07/1980",
          "17/07/1980",
          "18/07/1980",
          "21/07/1980",
          "22/07/1980",
          "23/07/1980",
          "24/07/1980",
          "25/07/1980",
          "28/07/1980",
          "29/07/1980",
          "30/07/1980",
          "31/07/1980",
          "01/08/1980",
          "04/08/1980",
          "05/08/1980",
          "06/08/1980",
          "07/08/1980",
          "08/08/1980",
          "11/08/1980",
          "12/08/1980",
          "13/08/1980",
          "14/08/1980",
          "15/08/1980",
          "18/08/1980",
          "19/08/1980",
          "20/08/1980",
          "21/08/1980",
          "22/08/1980",
          "25/08/1980",
          "26/08/1980",
          "27/08/1980",
          "28/08/1980",
          "29/08/1980",
          "02/09/1980",
          "03/09/1980",
          "04/09/1980",
          "05/09/1980",
          "08/09/1980",
          "09/09/1980",
          "10/09/1980",
          "11/09/1980",
          "12/09/1980",
          "15/09/1980",
          "16/09/1980",
          "17/09/1980",
          "18/09/1980",
          "19/09/1980",
          "22/09/1980",
          "23/09/1980",
          "24/09/1980",
          "25/09/1980",
          "26/09/1980",
          "29/09/1980",
          "30/09/1980",
          "01/10/1980",
          "02/10/1980",
          "03/10/1980",
          "06/10/1980",
          "07/10/1980",
          "08/10/1980",
          "09/10/1980",
          "10/10/1980",
          "13/10/1980",
          "14/10/1980",
          "15/10/1980",
          "16/10/1980",
          "17/10/1980",
          "20/10/1980",
          "21/10/1980",
          "22/10/1980",
          "23/10/1980",
          "24/10/1980",
          "27/10/1980",
          "28/10/1980",
          "29/10/1980",
          "30/10/1980",
          "31/10/1980",
          "03/11/1980",
          "05/11/1980",
          "06/11/1980",
          "07/11/1980",
          "10/11/1980",
          "11/11/1980",
          "12/11/1980",
          "13/11/1980",
          "14/11/1980",
          "17/11/1980",
          "18/11/1980",
          "19/11/1980",
          "20/11/1980",
          "21/11/1980",
          "24/11/1980",
          "25/11/1980",
          "26/11/1980",
          "28/11/1980",
          "01/12/1980",
          "02/12/1980",
          "03/12/1980",
          "04/12/1980",
          "05/12/1980",
          "08/12/1980",
          "09/12/1980",
          "10/12/1980",
          "11/12/1980",
          "12/12/1980",
          "15/12/1980",
          "16/12/1980",
          "17/12/1980",
          "18/12/1980",
          "19/12/1980",
          "22/12/1980",
          "23/12/1980",
          "24/12/1980",
          "26/12/1980",
          "29/12/1980",
          "30/12/1980",
          "31/12/1980",
          "02/01/1981",
          "05/01/1981",
          "06/01/1981",
          "07/01/1981",
          "08/01/1981",
          "09/01/1981",
          "12/01/1981",
          "13/01/1981",
          "14/01/1981",
          "15/01/1981",
          "16/01/1981",
          "19/01/1981",
          "20/01/1981",
          "21/01/1981",
          "22/01/1981",
          "23/01/1981",
          "26/01/1981",
          "27/01/1981",
          "28/01/1981",
          "29/01/1981",
          "30/01/1981",
          "02/02/1981",
          "03/02/1981",
          "04/02/1981",
          "05/02/1981",
          "06/02/1981",
          "09/02/1981",
          "10/02/1981",
          "11/02/1981",
          "12/02/1981",
          "13/02/1981",
          "17/02/1981",
          "18/02/1981",
          "19/02/1981",
          "20/02/1981",
          "23/02/1981",
          "24/02/1981",
          "25/02/1981",
          "26/02/1981",
          "27/02/1981",
          "02/03/1981",
          "03/03/1981",
          "04/03/1981",
          "05/03/1981",
          "06/03/1981",
          "09/03/1981",
          "10/03/1981",
          "11/03/1981",
          "12/03/1981",
          "13/03/1981",
          "16/03/1981",
          "17/03/1981",
          "18/03/1981",
          "19/03/1981",
          "20/03/1981",
          "23/03/1981",
          "24/03/1981",
          "25/03/1981",
          "26/03/1981",
          "27/03/1981",
          "30/03/1981",
          "31/03/1981",
          "01/04/1981",
          "02/04/1981",
          "03/04/1981",
          "06/04/1981",
          "07/04/1981",
          "08/04/1981",
          "09/04/1981",
          "10/04/1981",
          "13/04/1981",
          "14/04/1981",
          "15/04/1981",
          "16/04/1981",
          "20/04/1981",
          "21/04/1981",
          "22/04/1981",
          "23/04/1981",
          "24/04/1981",
          "27/04/1981",
          "28/04/1981",
          "29/04/1981",
          "30/04/1981",
          "01/05/1981",
          "04/05/1981",
          "05/05/1981",
          "06/05/1981",
          "07/05/1981",
          "08/05/1981",
          "11/05/1981",
          "12/05/1981",
          "13/05/1981",
          "14/05/1981",
          "15/05/1981",
          "18/05/1981",
          "19/05/1981",
          "20/05/1981",
          "21/05/1981",
          "22/05/1981",
          "26/05/1981",
          "27/05/1981",
          "28/05/1981",
          "29/05/1981",
          "01/06/1981",
          "02/06/1981",
          "03/06/1981",
          "04/06/1981",
          "05/06/1981",
          "08/06/1981",
          "09/06/1981",
          "10/06/1981",
          "11/06/1981",
          "12/06/1981",
          "15/06/1981",
          "16/06/1981",
          "17/06/1981",
          "18/06/1981",
          "19/06/1981",
          "22/06/1981",
          "23/06/1981",
          "24/06/1981",
          "25/06/1981",
          "26/06/1981",
          "29/06/1981",
          "30/06/1981",
          "01/07/1981",
          "02/07/1981",
          "06/07/1981",
          "07/07/1981",
          "08/07/1981",
          "09/07/1981",
          "10/07/1981",
          "13/07/1981",
          "14/07/1981",
          "15/07/1981",
          "16/07/1981",
          "17/07/1981",
          "20/07/1981",
          "21/07/1981",
          "22/07/1981",
          "23/07/1981",
          "24/07/1981",
          "27/07/1981",
          "28/07/1981",
          "29/07/1981",
          "30/07/1981",
          "31/07/1981",
          "03/08/1981",
          "04/08/1981",
          "05/08/1981",
          "06/08/1981",
          "07/08/1981",
          "10/08/1981",
          "11/08/1981",
          "12/08/1981",
          "13/08/1981",
          "14/08/1981",
          "17/08/1981",
          "18/08/1981",
          "19/08/1981",
          "20/08/1981",
          "21/08/1981",
          "24/08/1981",
          "25/08/1981",
          "26/08/1981",
          "27/08/1981",
          "28/08/1981",
          "31/08/1981",
          "01/09/1981",
          "02/09/1981",
          "03/09/1981",
          "04/09/1981",
          "08/09/1981",
          "09/09/1981",
          "10/09/1981",
          "11/09/1981",
          "14/09/1981",
          "15/09/1981",
          "16/09/1981",
          "17/09/1981",
          "18/09/1981",
          "21/09/1981",
          "22/09/1981",
          "23/09/1981",
          "24/09/1981",
          "25/09/1981",
          "28/09/1981",
          "29/09/1981",
          "30/09/1981",
          "01/10/1981",
          "02/10/1981",
          "05/10/1981",
          "06/10/1981",
          "07/10/1981",
          "08/10/1981",
          "09/10/1981",
          "12/10/1981",
          "13/10/1981",
          "14/10/1981",
          "15/10/1981",
          "16/10/1981",
          "19/10/1981",
          "20/10/1981",
          "21/10/1981",
          "22/10/1981",
          "23/10/1981",
          "26/10/1981",
          "27/10/1981",
          "28/10/1981",
          "29/10/1981",
          "30/10/1981",
          "02/11/1981",
          "03/11/1981",
          "04/11/1981",
          "05/11/1981",
          "06/11/1981",
          "09/11/1981",
          "10/11/1981",
          "11/11/1981",
          "12/11/1981",
          "13/11/1981",
          "16/11/1981",
          "17/11/1981",
          "18/11/1981",
          "19/11/1981",
          "20/11/1981",
          "23/11/1981",
          "24/11/1981",
          "25/11/1981",
          "27/11/1981",
          "30/11/1981",
          "01/12/1981",
          "02/12/1981",
          "03/12/1981",
          "04/12/1981",
          "07/12/1981",
          "08/12/1981",
          "09/12/1981",
          "10/12/1981",
          "11/12/1981",
          "14/12/1981",
          "15/12/1981",
          "16/12/1981",
          "17/12/1981",
          "18/12/1981",
          "21/12/1981",
          "22/12/1981",
          "23/12/1981",
          "24/12/1981",
          "28/12/1981",
          "29/12/1981",
          "30/12/1981",
          "31/12/1981",
          "04/01/1982",
          "05/01/1982",
          "06/01/1982",
          "07/01/1982",
          "08/01/1982",
          "11/01/1982",
          "12/01/1982",
          "13/01/1982",
          "14/01/1982",
          "15/01/1982",
          "18/01/1982",
          "19/01/1982",
          "20/01/1982",
          "21/01/1982",
          "22/01/1982",
          "25/01/1982",
          "26/01/1982",
          "27/01/1982",
          "28/01/1982",
          "29/01/1982",
          "01/02/1982",
          "02/02/1982",
          "03/02/1982",
          "04/02/1982",
          "05/02/1982",
          "08/02/1982",
          "09/02/1982",
          "10/02/1982",
          "11/02/1982",
          "12/02/1982",
          "16/02/1982",
          "17/02/1982",
          "18/02/1982",
          "19/02/1982",
          "22/02/1982",
          "23/02/1982",
          "24/02/1982",
          "25/02/1982",
          "26/02/1982",
          "01/03/1982",
          "02/03/1982",
          "03/03/1982",
          "04/03/1982",
          "05/03/1982",
          "08/03/1982",
          "09/03/1982",
          "10/03/1982",
          "11/03/1982",
          "12/03/1982",
          "15/03/1982",
          "16/03/1982",
          "17/03/1982",
          "18/03/1982",
          "19/03/1982",
          "22/03/1982",
          "23/03/1982",
          "24/03/1982",
          "25/03/1982",
          "26/03/1982",
          "29/03/1982",
          "30/03/1982",
          "31/03/1982",
          "01/04/1982",
          "02/04/1982",
          "05/04/1982",
          "06/04/1982",
          "07/04/1982",
          "08/04/1982",
          "12/04/1982",
          "13/04/1982",
          "14/04/1982",
          "15/04/1982",
          "16/04/1982",
          "19/04/1982",
          "20/04/1982",
          "21/04/1982",
          "22/04/1982",
          "23/04/1982",
          "26/04/1982",
          "27/04/1982",
          "28/04/1982",
          "29/04/1982",
          "30/04/1982",
          "03/05/1982",
          "04/05/1982",
          "05/05/1982",
          "06/05/1982",
          "07/05/1982",
          "10/05/1982",
          "11/05/1982",
          "12/05/1982",
          "13/05/1982",
          "14/05/1982",
          "17/05/1982",
          "18/05/1982",
          "19/05/1982",
          "20/05/1982",
          "21/05/1982",
          "24/05/1982",
          "25/05/1982",
          "26/05/1982",
          "27/05/1982",
          "28/05/1982",
          "01/06/1982",
          "02/06/1982",
          "03/06/1982",
          "04/06/1982",
          "07/06/1982",
          "08/06/1982",
          "09/06/1982",
          "10/06/1982",
          "11/06/1982",
          "14/06/1982",
          "15/06/1982",
          "16/06/1982",
          "17/06/1982",
          "18/06/1982",
          "21/06/1982",
          "22/06/1982",
          "23/06/1982",
          "24/06/1982",
          "25/06/1982",
          "28/06/1982",
          "29/06/1982",
          "30/06/1982",
          "01/07/1982",
          "02/07/1982",
          "06/07/1982",
          "07/07/1982",
          "08/07/1982",
          "09/07/1982",
          "12/07/1982",
          "13/07/1982",
          "14/07/1982",
          "15/07/1982",
          "16/07/1982",
          "19/07/1982",
          "20/07/1982",
          "21/07/1982",
          "22/07/1982",
          "23/07/1982",
          "26/07/1982",
          "27/07/1982",
          "28/07/1982",
          "29/07/1982",
          "30/07/1982",
          "02/08/1982",
          "03/08/1982",
          "04/08/1982",
          "05/08/1982",
          "06/08/1982",
          "09/08/1982",
          "10/08/1982",
          "11/08/1982",
          "12/08/1982",
          "13/08/1982",
          "16/08/1982",
          "17/08/1982",
          "18/08/1982",
          "19/08/1982",
          "20/08/1982",
          "23/08/1982",
          "24/08/1982",
          "25/08/1982",
          "26/08/1982",
          "27/08/1982",
          "30/08/1982",
          "31/08/1982",
          "01/09/1982",
          "02/09/1982",
          "03/09/1982",
          "07/09/1982",
          "08/09/1982",
          "09/09/1982",
          "10/09/1982",
          "13/09/1982",
          "14/09/1982",
          "15/09/1982",
          "16/09/1982",
          "17/09/1982",
          "20/09/1982",
          "21/09/1982",
          "22/09/1982",
          "23/09/1982",
          "24/09/1982",
          "27/09/1982",
          "28/09/1982",
          "29/09/1982",
          "30/09/1982",
          "01/10/1982",
          "04/10/1982",
          "05/10/1982",
          "06/10/1982",
          "07/10/1982",
          "08/10/1982",
          "11/10/1982",
          "12/10/1982",
          "13/10/1982",
          "14/10/1982",
          "15/10/1982",
          "18/10/1982",
          "19/10/1982",
          "20/10/1982",
          "21/10/1982",
          "22/10/1982",
          "25/10/1982",
          "26/10/1982",
          "27/10/1982",
          "28/10/1982",
          "29/10/1982",
          "01/11/1982",
          "02/11/1982",
          "03/11/1982",
          "04/11/1982",
          "05/11/1982",
          "08/11/1982",
          "09/11/1982",
          "10/11/1982",
          "11/11/1982",
          "12/11/1982",
          "15/11/1982",
          "16/11/1982",
          "17/11/1982",
          "18/11/1982",
          "19/11/1982",
          "22/11/1982",
          "23/11/1982",
          "24/11/1982",
          "26/11/1982",
          "29/11/1982",
          "30/11/1982",
          "01/12/1982",
          "02/12/1982",
          "03/12/1982",
          "06/12/1982",
          "07/12/1982",
          "08/12/1982",
          "09/12/1982",
          "10/12/1982",
          "13/12/1982",
          "14/12/1982",
          "15/12/1982",
          "16/12/1982",
          "17/12/1982",
          "20/12/1982",
          "21/12/1982",
          "22/12/1982",
          "23/12/1982",
          "27/12/1982",
          "28/12/1982",
          "29/12/1982",
          "30/12/1982",
          "31/12/1982",
          "03/01/1983",
          "04/01/1983",
          "05/01/1983",
          "06/01/1983",
          "07/01/1983",
          "10/01/1983",
          "11/01/1983",
          "12/01/1983",
          "13/01/1983",
          "14/01/1983",
          "17/01/1983",
          "18/01/1983",
          "19/01/1983",
          "20/01/1983",
          "21/01/1983",
          "24/01/1983",
          "25/01/1983",
          "26/01/1983",
          "27/01/1983",
          "28/01/1983",
          "31/01/1983",
          "01/02/1983",
          "02/02/1983",
          "03/02/1983",
          "04/02/1983",
          "07/02/1983",
          "08/02/1983",
          "09/02/1983",
          "10/02/1983",
          "11/02/1983",
          "14/02/1983",
          "15/02/1983",
          "16/02/1983",
          "17/02/1983",
          "18/02/1983",
          "22/02/1983",
          "23/02/1983",
          "24/02/1983",
          "25/02/1983",
          "28/02/1983",
          "01/03/1983",
          "02/03/1983",
          "03/03/1983",
          "04/03/1983",
          "07/03/1983",
          "08/03/1983",
          "09/03/1983",
          "10/03/1983",
          "11/03/1983",
          "14/03/1983",
          "15/03/1983",
          "16/03/1983",
          "17/03/1983",
          "18/03/1983",
          "21/03/1983",
          "22/03/1983",
          "23/03/1983",
          "24/03/1983",
          "25/03/1983",
          "28/03/1983",
          "29/03/1983",
          "30/03/1983",
          "31/03/1983",
          "04/04/1983",
          "05/04/1983",
          "06/04/1983",
          "07/04/1983",
          "08/04/1983",
          "11/04/1983",
          "12/04/1983",
          "13/04/1983",
          "14/04/1983",
          "15/04/1983",
          "18/04/1983",
          "19/04/1983",
          "20/04/1983",
          "21/04/1983",
          "22/04/1983",
          "25/04/1983",
          "26/04/1983",
          "27/04/1983",
          "28/04/1983",
          "29/04/1983",
          "02/05/1983",
          "03/05/1983",
          "04/05/1983",
          "05/05/1983",
          "06/05/1983",
          "09/05/1983",
          "10/05/1983",
          "11/05/1983",
          "12/05/1983",
          "13/05/1983",
          "16/05/1983",
          "17/05/1983",
          "18/05/1983",
          "19/05/1983",
          "20/05/1983",
          "23/05/1983",
          "24/05/1983",
          "25/05/1983",
          "26/05/1983",
          "27/05/1983",
          "31/05/1983",
          "01/06/1983",
          "02/06/1983",
          "03/06/1983",
          "06/06/1983",
          "07/06/1983",
          "08/06/1983",
          "09/06/1983",
          "10/06/1983",
          "13/06/1983",
          "14/06/1983",
          "15/06/1983",
          "16/06/1983",
          "17/06/1983",
          "20/06/1983",
          "21/06/1983",
          "22/06/1983",
          "23/06/1983",
          "24/06/1983",
          "27/06/1983",
          "28/06/1983",
          "29/06/1983",
          "30/06/1983",
          "01/07/1983",
          "05/07/1983",
          "06/07/1983",
          "07/07/1983",
          "08/07/1983",
          "11/07/1983",
          "12/07/1983",
          "13/07/1983",
          "14/07/1983",
          "15/07/1983",
          "18/07/1983",
          "19/07/1983",
          "20/07/1983",
          "21/07/1983",
          "22/07/1983",
          "25/07/1983",
          "26/07/1983",
          "27/07/1983",
          "28/07/1983",
          "29/07/1983",
          "01/08/1983",
          "02/08/1983",
          "03/08/1983",
          "04/08/1983",
          "05/08/1983",
          "08/08/1983",
          "09/08/1983",
          "10/08/1983",
          "11/08/1983",
          "12/08/1983",
          "15/08/1983",
          "16/08/1983",
          "17/08/1983",
          "18/08/1983",
          "19/08/1983",
          "22/08/1983",
          "23/08/1983",
          "24/08/1983",
          "25/08/1983",
          "26/08/1983",
          "29/08/1983",
          "30/08/1983",
          "31/08/1983",
          "01/09/1983",
          "02/09/1983",
          "06/09/1983",
          "07/09/1983",
          "08/09/1983",
          "09/09/1983",
          "12/09/1983",
          "13/09/1983",
          "14/09/1983",
          "15/09/1983",
          "16/09/1983",
          "19/09/1983",
          "20/09/1983",
          "21/09/1983",
          "22/09/1983",
          "23/09/1983",
          "26/09/1983",
          "27/09/1983",
          "28/09/1983",
          "29/09/1983",
          "30/09/1983",
          "03/10/1983",
          "04/10/1983",
          "05/10/1983",
          "06/10/1983",
          "07/10/1983",
          "10/10/1983",
          "11/10/1983",
          "12/10/1983",
          "13/10/1983",
          "14/10/1983",
          "17/10/1983",
          "18/10/1983",
          "19/10/1983",
          "20/10/1983",
          "21/10/1983",
          "24/10/1983",
          "25/10/1983",
          "26/10/1983",
          "27/10/1983",
          "28/10/1983",
          "31/10/1983",
          "01/11/1983",
          "02/11/1983",
          "03/11/1983",
          "04/11/1983",
          "07/11/1983",
          "08/11/1983",
          "09/11/1983",
          "10/11/1983",
          "11/11/1983",
          "14/11/1983",
          "15/11/1983",
          "16/11/1983",
          "17/11/1983",
          "18/11/1983",
          "21/11/1983",
          "22/11/1983",
          "23/11/1983",
          "25/11/1983",
          "28/11/1983",
          "29/11/1983",
          "30/11/1983",
          "01/12/1983",
          "02/12/1983",
          "05/12/1983",
          "06/12/1983",
          "07/12/1983",
          "08/12/1983",
          "09/12/1983",
          "12/12/1983",
          "13/12/1983",
          "14/12/1983",
          "15/12/1983",
          "16/12/1983",
          "19/12/1983",
          "20/12/1983",
          "21/12/1983",
          "22/12/1983",
          "23/12/1983",
          "27/12/1983",
          "28/12/1983",
          "29/12/1983",
          "30/12/1983",
          "03/01/1984",
          "04/01/1984",
          "05/01/1984",
          "06/01/1984",
          "09/01/1984",
          "10/01/1984",
          "11/01/1984",
          "12/01/1984",
          "13/01/1984",
          "16/01/1984",
          "17/01/1984",
          "18/01/1984",
          "19/01/1984",
          "20/01/1984",
          "23/01/1984",
          "24/01/1984",
          "25/01/1984",
          "26/01/1984",
          "27/01/1984",
          "30/01/1984",
          "31/01/1984",
          "01/02/1984",
          "02/02/1984",
          "03/02/1984",
          "06/02/1984",
          "07/02/1984",
          "08/02/1984",
          "09/02/1984",
          "10/02/1984",
          "13/02/1984",
          "14/02/1984",
          "15/02/1984",
          "16/02/1984",
          "17/02/1984",
          "21/02/1984",
          "22/02/1984",
          "23/02/1984",
          "24/02/1984",
          "27/02/1984",
          "28/02/1984",
          "29/02/1984",
          "01/03/1984",
          "02/03/1984",
          "05/03/1984",
          "06/03/1984",
          "07/03/1984",
          "08/03/1984",
          "09/03/1984",
          "12/03/1984",
          "13/03/1984",
          "14/03/1984",
          "15/03/1984",
          "16/03/1984",
          "19/03/1984",
          "20/03/1984",
          "21/03/1984",
          "22/03/1984",
          "23/03/1984",
          "26/03/1984",
          "27/03/1984",
          "28/03/1984",
          "29/03/1984",
          "30/03/1984",
          "02/04/1984",
          "03/04/1984",
          "04/04/1984",
          "05/04/1984",
          "06/04/1984",
          "09/04/1984",
          "10/04/1984",
          "11/04/1984",
          "12/04/1984",
          "13/04/1984",
          "16/04/1984",
          "17/04/1984",
          "18/04/1984",
          "19/04/1984",
          "23/04/1984",
          "24/04/1984",
          "25/04/1984",
          "26/04/1984",
          "27/04/1984",
          "30/04/1984",
          "01/05/1984",
          "02/05/1984",
          "03/05/1984",
          "04/05/1984",
          "07/05/1984",
          "08/05/1984",
          "09/05/1984",
          "10/05/1984",
          "11/05/1984",
          "14/05/1984",
          "15/05/1984",
          "16/05/1984",
          "17/05/1984",
          "18/05/1984",
          "21/05/1984",
          "22/05/1984",
          "23/05/1984",
          "24/05/1984",
          "25/05/1984",
          "29/05/1984",
          "30/05/1984",
          "31/05/1984",
          "01/06/1984",
          "04/06/1984",
          "05/06/1984",
          "06/06/1984",
          "07/06/1984",
          "08/06/1984",
          "11/06/1984",
          "12/06/1984",
          "13/06/1984",
          "14/06/1984",
          "15/06/1984",
          "18/06/1984",
          "19/06/1984",
          "20/06/1984",
          "21/06/1984",
          "22/06/1984",
          "25/06/1984",
          "26/06/1984",
          "27/06/1984",
          "28/06/1984",
          "29/06/1984",
          "02/07/1984",
          "03/07/1984",
          "05/07/1984",
          "06/07/1984",
          "09/07/1984",
          "10/07/1984",
          "11/07/1984",
          "12/07/1984",
          "13/07/1984",
          "16/07/1984",
          "17/07/1984",
          "18/07/1984",
          "19/07/1984",
          "20/07/1984",
          "23/07/1984",
          "24/07/1984",
          "25/07/1984",
          "26/07/1984",
          "27/07/1984",
          "30/07/1984",
          "31/07/1984",
          "01/08/1984",
          "02/08/1984",
          "03/08/1984",
          "06/08/1984",
          "07/08/1984",
          "08/08/1984",
          "09/08/1984",
          "10/08/1984",
          "13/08/1984",
          "14/08/1984",
          "15/08/1984",
          "16/08/1984",
          "17/08/1984",
          "20/08/1984",
          "21/08/1984",
          "22/08/1984",
          "23/08/1984",
          "24/08/1984",
          "27/08/1984",
          "28/08/1984",
          "29/08/1984",
          "30/08/1984",
          "31/08/1984",
          "04/09/1984",
          "05/09/1984",
          "06/09/1984",
          "07/09/1984",
          "10/09/1984",
          "11/09/1984",
          "12/09/1984",
          "13/09/1984",
          "14/09/1984",
          "17/09/1984",
          "18/09/1984",
          "19/09/1984",
          "20/09/1984",
          "21/09/1984",
          "24/09/1984",
          "25/09/1984",
          "26/09/1984",
          "27/09/1984",
          "28/09/1984",
          "01/10/1984",
          "02/10/1984",
          "03/10/1984",
          "04/10/1984",
          "05/10/1984",
          "08/10/1984",
          "09/10/1984",
          "10/10/1984",
          "11/10/1984",
          "12/10/1984",
          "15/10/1984",
          "16/10/1984",
          "17/10/1984",
          "18/10/1984",
          "19/10/1984",
          "22/10/1984",
          "23/10/1984",
          "24/10/1984",
          "25/10/1984",
          "26/10/1984",
          "29/10/1984",
          "30/10/1984",
          "31/10/1984",
          "01/11/1984",
          "02/11/1984",
          "05/11/1984",
          "06/11/1984",
          "07/11/1984",
          "08/11/1984",
          "09/11/1984",
          "12/11/1984",
          "13/11/1984",
          "14/11/1984",
          "15/11/1984",
          "16/11/1984",
          "19/11/1984",
          "20/11/1984",
          "21/11/1984",
          "23/11/1984",
          "26/11/1984",
          "27/11/1984",
          "28/11/1984",
          "29/11/1984",
          "30/11/1984",
          "03/12/1984",
          "04/12/1984",
          "05/12/1984",
          "06/12/1984",
          "07/12/1984",
          "10/12/1984",
          "11/12/1984",
          "12/12/1984",
          "13/12/1984",
          "14/12/1984",
          "17/12/1984",
          "18/12/1984",
          "19/12/1984",
          "20/12/1984",
          "21/12/1984",
          "24/12/1984",
          "26/12/1984",
          "27/12/1984",
          "28/12/1984",
          "31/12/1984",
          "02/01/1985",
          "03/01/1985",
          "04/01/1985",
          "07/01/1985",
          "08/01/1985",
          "09/01/1985",
          "10/01/1985",
          "11/01/1985",
          "14/01/1985",
          "15/01/1985",
          "16/01/1985",
          "17/01/1985",
          "18/01/1985",
          "21/01/1985",
          "22/01/1985",
          "23/01/1985",
          "24/01/1985",
          "25/01/1985",
          "28/01/1985",
          "29/01/1985",
          "30/01/1985",
          "31/01/1985",
          "01/02/1985",
          "04/02/1985",
          "05/02/1985",
          "06/02/1985",
          "07/02/1985",
          "08/02/1985",
          "11/02/1985",
          "12/02/1985",
          "13/02/1985",
          "14/02/1985",
          "15/02/1985",
          "19/02/1985",
          "20/02/1985",
          "21/02/1985",
          "22/02/1985",
          "25/02/1985",
          "26/02/1985",
          "27/02/1985",
          "28/02/1985",
          "01/03/1985",
          "04/03/1985",
          "05/03/1985",
          "06/03/1985",
          "07/03/1985",
          "08/03/1985",
          "11/03/1985",
          "12/03/1985",
          "13/03/1985",
          "14/03/1985",
          "15/03/1985",
          "18/03/1985",
          "19/03/1985",
          "20/03/1985",
          "21/03/1985",
          "22/03/1985",
          "25/03/1985",
          "26/03/1985",
          "27/03/1985",
          "28/03/1985",
          "29/03/1985",
          "01/04/1985",
          "02/04/1985",
          "03/04/1985",
          "04/04/1985",
          "08/04/1985",
          "09/04/1985",
          "10/04/1985",
          "11/04/1985",
          "12/04/1985",
          "15/04/1985",
          "16/04/1985",
          "17/04/1985",
          "18/04/1985",
          "19/04/1985",
          "22/04/1985",
          "23/04/1985",
          "24/04/1985",
          "25/04/1985",
          "26/04/1985",
          "29/04/1985",
          "30/04/1985",
          "01/05/1985",
          "02/05/1985",
          "03/05/1985",
          "06/05/1985",
          "07/05/1985",
          "08/05/1985",
          "09/05/1985",
          "10/05/1985",
          "13/05/1985",
          "14/05/1985",
          "15/05/1985",
          "16/05/1985",
          "17/05/1985",
          "20/05/1985",
          "21/05/1985",
          "22/05/1985",
          "23/05/1985",
          "24/05/1985",
          "28/05/1985",
          "29/05/1985",
          "30/05/1985",
          "31/05/1985",
          "03/06/1985",
          "04/06/1985",
          "05/06/1985",
          "06/06/1985",
          "07/06/1985",
          "10/06/1985",
          "11/06/1985",
          "12/06/1985",
          "13/06/1985",
          "14/06/1985",
          "17/06/1985",
          "18/06/1985",
          "19/06/1985",
          "20/06/1985",
          "21/06/1985",
          "24/06/1985",
          "25/06/1985",
          "26/06/1985",
          "27/06/1985",
          "28/06/1985",
          "01/07/1985",
          "02/07/1985",
          "03/07/1985",
          "05/07/1985",
          "08/07/1985",
          "09/07/1985",
          "10/07/1985",
          "11/07/1985",
          "12/07/1985",
          "15/07/1985",
          "16/07/1985",
          "17/07/1985",
          "18/07/1985",
          "19/07/1985",
          "22/07/1985",
          "23/07/1985",
          "24/07/1985",
          "25/07/1985",
          "26/07/1985",
          "29/07/1985",
          "30/07/1985",
          "31/07/1985",
          "01/08/1985",
          "02/08/1985",
          "05/08/1985",
          "06/08/1985",
          "07/08/1985",
          "08/08/1985",
          "09/08/1985",
          "12/08/1985",
          "13/08/1985",
          "14/08/1985",
          "15/08/1985",
          "16/08/1985",
          "19/08/1985",
          "20/08/1985",
          "21/08/1985",
          "22/08/1985",
          "23/08/1985",
          "26/08/1985",
          "27/08/1985",
          "28/08/1985",
          "29/08/1985",
          "30/08/1985",
          "03/09/1985",
          "04/09/1985",
          "05/09/1985",
          "06/09/1985",
          "09/09/1985",
          "10/09/1985",
          "11/09/1985",
          "12/09/1985",
          "13/09/1985",
          "16/09/1985",
          "17/09/1985",
          "18/09/1985",
          "19/09/1985",
          "20/09/1985",
          "23/09/1985",
          "24/09/1985",
          "25/09/1985",
          "26/09/1985",
          "30/09/1985",
          "01/10/1985",
          "02/10/1985",
          "03/10/1985",
          "04/10/1985",
          "07/10/1985",
          "08/10/1985",
          "09/10/1985",
          "10/10/1985",
          "11/10/1985",
          "14/10/1985",
          "15/10/1985",
          "16/10/1985",
          "17/10/1985",
          "18/10/1985",
          "21/10/1985",
          "22/10/1985",
          "23/10/1985",
          "24/10/1985",
          "25/10/1985",
          "28/10/1985",
          "29/10/1985",
          "30/10/1985",
          "31/10/1985",
          "01/11/1985",
          "04/11/1985",
          "05/11/1985",
          "06/11/1985",
          "07/11/1985",
          "08/11/1985",
          "11/11/1985",
          "12/11/1985",
          "13/11/1985",
          "14/11/1985",
          "15/11/1985",
          "18/11/1985",
          "19/11/1985",
          "20/11/1985",
          "21/11/1985",
          "22/11/1985",
          "25/11/1985",
          "26/11/1985",
          "27/11/1985",
          "29/11/1985",
          "02/12/1985",
          "03/12/1985",
          "04/12/1985",
          "05/12/1985",
          "06/12/1985",
          "09/12/1985",
          "10/12/1985",
          "11/12/1985",
          "12/12/1985",
          "13/12/1985",
          "16/12/1985",
          "17/12/1985",
          "18/12/1985",
          "19/12/1985",
          "20/12/1985",
          "23/12/1985",
          "24/12/1985",
          "26/12/1985",
          "27/12/1985",
          "30/12/1985",
          "31/12/1985",
          "02/01/1986",
          "03/01/1986",
          "06/01/1986",
          "07/01/1986",
          "08/01/1986",
          "09/01/1986",
          "10/01/1986",
          "13/01/1986",
          "14/01/1986",
          "15/01/1986",
          "16/01/1986",
          "17/01/1986",
          "20/01/1986",
          "21/01/1986",
          "22/01/1986",
          "23/01/1986",
          "24/01/1986",
          "27/01/1986",
          "28/01/1986",
          "29/01/1986",
          "30/01/1986",
          "31/01/1986",
          "03/02/1986",
          "04/02/1986",
          "05/02/1986",
          "06/02/1986",
          "07/02/1986",
          "10/02/1986",
          "11/02/1986",
          "12/02/1986",
          "13/02/1986",
          "14/02/1986",
          "18/02/1986",
          "19/02/1986",
          "20/02/1986",
          "21/02/1986",
          "24/02/1986",
          "25/02/1986",
          "26/02/1986",
          "27/02/1986",
          "28/02/1986",
          "03/03/1986",
          "04/03/1986",
          "05/03/1986",
          "06/03/1986",
          "07/03/1986",
          "10/03/1986",
          "11/03/1986",
          "12/03/1986",
          "13/03/1986",
          "14/03/1986",
          "17/03/1986",
          "18/03/1986",
          "19/03/1986",
          "20/03/1986",
          "21/03/1986",
          "24/03/1986",
          "25/03/1986",
          "26/03/1986",
          "27/03/1986",
          "31/03/1986",
          "01/04/1986",
          "02/04/1986",
          "03/04/1986",
          "04/04/1986",
          "07/04/1986",
          "08/04/1986",
          "09/04/1986",
          "10/04/1986",
          "11/04/1986",
          "14/04/1986",
          "15/04/1986",
          "16/04/1986",
          "17/04/1986",
          "18/04/1986",
          "21/04/1986",
          "22/04/1986",
          "23/04/1986",
          "24/04/1986",
          "25/04/1986",
          "28/04/1986",
          "29/04/1986",
          "30/04/1986",
          "01/05/1986",
          "02/05/1986",
          "05/05/1986",
          "06/05/1986",
          "07/05/1986",
          "08/05/1986",
          "09/05/1986",
          "12/05/1986",
          "13/05/1986",
          "14/05/1986",
          "15/05/1986",
          "16/05/1986",
          "19/05/1986",
          "20/05/1986",
          "21/05/1986",
          "22/05/1986",
          "23/05/1986",
          "27/05/1986",
          "28/05/1986",
          "29/05/1986",
          "30/05/1986",
          "02/06/1986",
          "03/06/1986",
          "04/06/1986",
          "05/06/1986",
          "06/06/1986",
          "09/06/1986",
          "10/06/1986",
          "11/06/1986",
          "12/06/1986",
          "13/06/1986",
          "16/06/1986",
          "17/06/1986",
          "18/06/1986",
          "19/06/1986",
          "20/06/1986",
          "23/06/1986",
          "24/06/1986",
          "25/06/1986",
          "26/06/1986",
          "27/06/1986",
          "30/06/1986",
          "01/07/1986",
          "02/07/1986",
          "03/07/1986",
          "07/07/1986",
          "08/07/1986",
          "09/07/1986",
          "10/07/1986",
          "11/07/1986",
          "14/07/1986",
          "15/07/1986",
          "16/07/1986",
          "17/07/1986",
          "18/07/1986",
          "21/07/1986",
          "22/07/1986",
          "23/07/1986",
          "24/07/1986",
          "25/07/1986",
          "28/07/1986",
          "29/07/1986",
          "30/07/1986",
          "31/07/1986",
          "01/08/1986",
          "04/08/1986",
          "05/08/1986",
          "06/08/1986",
          "07/08/1986",
          "08/08/1986",
          "11/08/1986",
          "12/08/1986",
          "13/08/1986",
          "14/08/1986",
          "15/08/1986",
          "18/08/1986",
          "19/08/1986",
          "20/08/1986",
          "21/08/1986",
          "22/08/1986",
          "25/08/1986",
          "26/08/1986",
          "27/08/1986",
          "28/08/1986",
          "29/08/1986",
          "02/09/1986",
          "03/09/1986",
          "04/09/1986",
          "05/09/1986",
          "08/09/1986",
          "09/09/1986",
          "10/09/1986",
          "11/09/1986",
          "12/09/1986",
          "15/09/1986",
          "16/09/1986",
          "17/09/1986",
          "18/09/1986",
          "19/09/1986",
          "22/09/1986",
          "23/09/1986",
          "24/09/1986",
          "25/09/1986",
          "26/09/1986",
          "29/09/1986",
          "30/09/1986",
          "01/10/1986",
          "02/10/1986",
          "03/10/1986",
          "06/10/1986",
          "07/10/1986",
          "08/10/1986",
          "09/10/1986",
          "10/10/1986",
          "13/10/1986",
          "14/10/1986",
          "15/10/1986",
          "16/10/1986",
          "17/10/1986",
          "20/10/1986",
          "21/10/1986",
          "22/10/1986",
          "23/10/1986",
          "24/10/1986",
          "27/10/1986",
          "28/10/1986",
          "29/10/1986",
          "30/10/1986",
          "31/10/1986",
          "03/11/1986",
          "04/11/1986",
          "05/11/1986",
          "06/11/1986",
          "07/11/1986",
          "10/11/1986",
          "11/11/1986",
          "12/11/1986",
          "13/11/1986",
          "14/11/1986",
          "17/11/1986",
          "18/11/1986",
          "19/11/1986",
          "20/11/1986",
          "21/11/1986",
          "24/11/1986",
          "25/11/1986",
          "26/11/1986",
          "28/11/1986",
          "01/12/1986",
          "02/12/1986",
          "03/12/1986",
          "04/12/1986",
          "05/12/1986",
          "08/12/1986",
          "09/12/1986",
          "10/12/1986",
          "11/12/1986",
          "12/12/1986",
          "15/12/1986",
          "16/12/1986",
          "17/12/1986",
          "18/12/1986",
          "19/12/1986",
          "22/12/1986",
          "23/12/1986",
          "24/12/1986",
          "26/12/1986",
          "29/12/1986",
          "30/12/1986",
          "31/12/1986",
          "02/01/1987",
          "05/01/1987",
          "06/01/1987",
          "07/01/1987",
          "08/01/1987",
          "09/01/1987",
          "12/01/1987",
          "13/01/1987",
          "14/01/1987",
          "15/01/1987",
          "16/01/1987",
          "19/01/1987",
          "20/01/1987",
          "21/01/1987",
          "22/01/1987",
          "23/01/1987",
          "26/01/1987",
          "27/01/1987",
          "28/01/1987",
          "29/01/1987",
          "30/01/1987",
          "02/02/1987",
          "03/02/1987",
          "04/02/1987",
          "05/02/1987",
          "06/02/1987",
          "09/02/1987",
          "10/02/1987",
          "11/02/1987",
          "12/02/1987",
          "13/02/1987",
          "17/02/1987",
          "18/02/1987",
          "19/02/1987",
          "20/02/1987",
          "23/02/1987",
          "24/02/1987",
          "25/02/1987",
          "26/02/1987",
          "27/02/1987",
          "02/03/1987",
          "03/03/1987",
          "04/03/1987",
          "05/03/1987",
          "06/03/1987",
          "09/03/1987",
          "10/03/1987",
          "11/03/1987",
          "12/03/1987",
          "13/03/1987",
          "16/03/1987",
          "17/03/1987",
          "18/03/1987",
          "19/03/1987",
          "20/03/1987",
          "23/03/1987",
          "24/03/1987",
          "25/03/1987",
          "26/03/1987",
          "27/03/1987",
          "30/03/1987",
          "31/03/1987",
          "01/04/1987",
          "02/04/1987",
          "03/04/1987",
          "06/04/1987",
          "07/04/1987",
          "08/04/1987",
          "09/04/1987",
          "10/04/1987",
          "13/04/1987",
          "14/04/1987",
          "15/04/1987",
          "16/04/1987",
          "20/04/1987",
          "21/04/1987",
          "22/04/1987",
          "23/04/1987",
          "24/04/1987",
          "27/04/1987",
          "28/04/1987",
          "29/04/1987",
          "30/04/1987",
          "01/05/1987",
          "04/05/1987",
          "05/05/1987",
          "06/05/1987",
          "07/05/1987",
          "08/05/1987",
          "11/05/1987",
          "12/05/1987",
          "13/05/1987",
          "14/05/1987",
          "15/05/1987",
          "18/05/1987",
          "19/05/1987",
          "20/05/1987",
          "21/05/1987",
          "22/05/1987",
          "26/05/1987",
          "27/05/1987",
          "28/05/1987",
          "29/05/1987",
          "01/06/1987",
          "02/06/1987",
          "03/06/1987",
          "04/06/1987",
          "05/06/1987",
          "08/06/1987",
          "09/06/1987",
          "10/06/1987",
          "11/06/1987",
          "12/06/1987",
          "15/06/1987",
          "16/06/1987",
          "17/06/1987",
          "18/06/1987",
          "19/06/1987",
          "22/06/1987",
          "23/06/1987",
          "24/06/1987",
          "25/06/1987",
          "26/06/1987",
          "29/06/1987",
          "30/06/1987",
          "01/07/1987",
          "02/07/1987",
          "06/07/1987",
          "07/07/1987",
          "08/07/1987",
          "09/07/1987",
          "10/07/1987",
          "13/07/1987",
          "14/07/1987",
          "15/07/1987",
          "16/07/1987",
          "17/07/1987",
          "20/07/1987",
          "21/07/1987",
          "22/07/1987",
          "23/07/1987",
          "24/07/1987",
          "27/07/1987",
          "28/07/1987",
          "29/07/1987",
          "30/07/1987",
          "31/07/1987",
          "03/08/1987",
          "04/08/1987",
          "05/08/1987",
          "06/08/1987",
          "07/08/1987",
          "10/08/1987",
          "11/08/1987",
          "12/08/1987",
          "13/08/1987",
          "14/08/1987",
          "17/08/1987",
          "18/08/1987",
          "19/08/1987",
          "20/08/1987",
          "21/08/1987",
          "24/08/1987",
          "25/08/1987",
          "26/08/1987",
          "27/08/1987",
          "28/08/1987",
          "31/08/1987",
          "01/09/1987",
          "02/09/1987",
          "03/09/1987",
          "04/09/1987",
          "08/09/1987",
          "09/09/1987",
          "10/09/1987",
          "11/09/1987",
          "14/09/1987",
          "15/09/1987",
          "16/09/1987",
          "17/09/1987",
          "18/09/1987",
          "21/09/1987",
          "22/09/1987",
          "23/09/1987",
          "24/09/1987",
          "25/09/1987",
          "28/09/1987",
          "29/09/1987",
          "30/09/1987",
          "01/10/1987",
          "02/10/1987",
          "05/10/1987",
          "06/10/1987",
          "07/10/1987",
          "08/10/1987",
          "09/10/1987",
          "12/10/1987",
          "13/10/1987",
          "14/10/1987",
          "15/10/1987",
          "16/10/1987",
          "19/10/1987",
          "20/10/1987",
          "21/10/1987",
          "22/10/1987",
          "23/10/1987",
          "26/10/1987",
          "27/10/1987",
          "28/10/1987",
          "29/10/1987",
          "30/10/1987",
          "02/11/1987",
          "03/11/1987",
          "04/11/1987",
          "05/11/1987",
          "06/11/1987",
          "09/11/1987",
          "10/11/1987",
          "11/11/1987",
          "12/11/1987",
          "13/11/1987",
          "16/11/1987",
          "17/11/1987",
          "18/11/1987",
          "19/11/1987",
          "20/11/1987",
          "23/11/1987",
          "24/11/1987",
          "25/11/1987",
          "27/11/1987",
          "30/11/1987",
          "01/12/1987",
          "02/12/1987",
          "03/12/1987",
          "04/12/1987",
          "07/12/1987",
          "08/12/1987",
          "09/12/1987",
          "10/12/1987",
          "11/12/1987",
          "14/12/1987",
          "15/12/1987",
          "16/12/1987",
          "17/12/1987",
          "18/12/1987",
          "21/12/1987",
          "22/12/1987",
          "23/12/1987",
          "24/12/1987",
          "28/12/1987",
          "29/12/1987",
          "30/12/1987",
          "31/12/1987",
          "04/01/1988",
          "05/01/1988",
          "06/01/1988",
          "07/01/1988",
          "08/01/1988",
          "11/01/1988",
          "12/01/1988",
          "13/01/1988",
          "14/01/1988",
          "15/01/1988",
          "18/01/1988",
          "19/01/1988",
          "20/01/1988",
          "21/01/1988",
          "22/01/1988",
          "25/01/1988",
          "26/01/1988",
          "27/01/1988",
          "28/01/1988",
          "29/01/1988",
          "01/02/1988",
          "02/02/1988",
          "03/02/1988",
          "04/02/1988",
          "05/02/1988",
          "08/02/1988",
          "09/02/1988",
          "10/02/1988",
          "11/02/1988",
          "12/02/1988",
          "16/02/1988",
          "17/02/1988",
          "18/02/1988",
          "19/02/1988",
          "22/02/1988",
          "23/02/1988",
          "24/02/1988",
          "25/02/1988",
          "26/02/1988",
          "29/02/1988",
          "01/03/1988",
          "02/03/1988",
          "03/03/1988",
          "04/03/1988",
          "07/03/1988",
          "08/03/1988",
          "09/03/1988",
          "10/03/1988",
          "11/03/1988",
          "14/03/1988",
          "15/03/1988",
          "16/03/1988",
          "17/03/1988",
          "18/03/1988",
          "21/03/1988",
          "22/03/1988",
          "23/03/1988",
          "24/03/1988",
          "25/03/1988",
          "28/03/1988",
          "29/03/1988",
          "30/03/1988",
          "31/03/1988",
          "04/04/1988",
          "05/04/1988",
          "06/04/1988",
          "07/04/1988",
          "08/04/1988",
          "11/04/1988",
          "12/04/1988",
          "13/04/1988",
          "14/04/1988",
          "15/04/1988",
          "18/04/1988",
          "19/04/1988",
          "20/04/1988",
          "21/04/1988",
          "22/04/1988",
          "25/04/1988",
          "26/04/1988",
          "27/04/1988",
          "28/04/1988",
          "29/04/1988",
          "02/05/1988",
          "03/05/1988",
          "04/05/1988",
          "05/05/1988",
          "06/05/1988",
          "09/05/1988",
          "10/05/1988",
          "11/05/1988",
          "12/05/1988",
          "13/05/1988",
          "16/05/1988",
          "17/05/1988",
          "18/05/1988",
          "19/05/1988",
          "20/05/1988",
          "23/05/1988",
          "24/05/1988",
          "25/05/1988",
          "26/05/1988",
          "27/05/1988",
          "31/05/1988",
          "01/06/1988",
          "02/06/1988",
          "03/06/1988",
          "06/06/1988",
          "07/06/1988",
          "08/06/1988",
          "09/06/1988",
          "10/06/1988",
          "13/06/1988",
          "14/06/1988",
          "15/06/1988",
          "16/06/1988",
          "17/06/1988",
          "20/06/1988",
          "21/06/1988",
          "22/06/1988",
          "23/06/1988",
          "24/06/1988",
          "27/06/1988",
          "28/06/1988",
          "29/06/1988",
          "30/06/1988",
          "01/07/1988",
          "05/07/1988",
          "06/07/1988",
          "07/07/1988",
          "08/07/1988",
          "11/07/1988",
          "12/07/1988",
          "13/07/1988",
          "14/07/1988",
          "15/07/1988",
          "18/07/1988",
          "19/07/1988",
          "20/07/1988",
          "21/07/1988",
          "22/07/1988",
          "25/07/1988",
          "26/07/1988",
          "27/07/1988",
          "28/07/1988",
          "29/07/1988",
          "01/08/1988",
          "02/08/1988",
          "03/08/1988",
          "04/08/1988",
          "05/08/1988",
          "08/08/1988",
          "09/08/1988",
          "10/08/1988",
          "11/08/1988",
          "12/08/1988",
          "15/08/1988",
          "16/08/1988",
          "17/08/1988",
          "18/08/1988",
          "19/08/1988",
          "22/08/1988",
          "23/08/1988",
          "24/08/1988",
          "25/08/1988",
          "26/08/1988",
          "29/08/1988",
          "30/08/1988",
          "31/08/1988",
          "01/09/1988",
          "02/09/1988",
          "06/09/1988",
          "07/09/1988",
          "08/09/1988",
          "09/09/1988",
          "12/09/1988",
          "13/09/1988",
          "14/09/1988",
          "15/09/1988",
          "16/09/1988",
          "19/09/1988",
          "20/09/1988",
          "21/09/1988",
          "22/09/1988",
          "23/09/1988",
          "26/09/1988",
          "27/09/1988",
          "28/09/1988",
          "29/09/1988",
          "30/09/1988",
          "03/10/1988",
          "04/10/1988",
          "05/10/1988",
          "06/10/1988",
          "07/10/1988",
          "10/10/1988",
          "11/10/1988",
          "12/10/1988",
          "13/10/1988",
          "14/10/1988",
          "17/10/1988",
          "18/10/1988",
          "19/10/1988",
          "20/10/1988",
          "21/10/1988",
          "24/10/1988",
          "25/10/1988",
          "26/10/1988",
          "27/10/1988",
          "28/10/1988",
          "31/10/1988",
          "01/11/1988",
          "02/11/1988",
          "03/11/1988",
          "04/11/1988",
          "07/11/1988",
          "08/11/1988",
          "09/11/1988",
          "10/11/1988",
          "11/11/1988",
          "14/11/1988",
          "15/11/1988",
          "16/11/1988",
          "17/11/1988",
          "18/11/1988",
          "21/11/1988",
          "22/11/1988",
          "23/11/1988",
          "25/11/1988",
          "28/11/1988",
          "29/11/1988",
          "30/11/1988",
          "01/12/1988",
          "02/12/1988",
          "05/12/1988",
          "06/12/1988",
          "07/12/1988",
          "08/12/1988",
          "09/12/1988",
          "12/12/1988",
          "13/12/1988",
          "14/12/1988",
          "15/12/1988",
          "16/12/1988",
          "19/12/1988",
          "20/12/1988",
          "21/12/1988",
          "22/12/1988",
          "23/12/1988",
          "27/12/1988",
          "28/12/1988",
          "29/12/1988",
          "30/12/1988",
          "03/01/1989",
          "04/01/1989",
          "05/01/1989",
          "06/01/1989",
          "09/01/1989",
          "10/01/1989",
          "11/01/1989",
          "12/01/1989",
          "13/01/1989",
          "16/01/1989",
          "17/01/1989",
          "18/01/1989",
          "19/01/1989",
          "20/01/1989",
          "23/01/1989",
          "24/01/1989",
          "25/01/1989",
          "26/01/1989",
          "27/01/1989",
          "30/01/1989",
          "31/01/1989",
          "01/02/1989",
          "02/02/1989",
          "03/02/1989",
          "06/02/1989",
          "07/02/1989",
          "08/02/1989",
          "09/02/1989",
          "10/02/1989",
          "13/02/1989",
          "14/02/1989",
          "15/02/1989",
          "16/02/1989",
          "17/02/1989",
          "21/02/1989",
          "22/02/1989",
          "23/02/1989",
          "24/02/1989",
          "27/02/1989",
          "28/02/1989",
          "01/03/1989",
          "02/03/1989",
          "03/03/1989",
          "06/03/1989",
          "07/03/1989",
          "08/03/1989",
          "09/03/1989",
          "10/03/1989",
          "13/03/1989",
          "14/03/1989",
          "15/03/1989",
          "16/03/1989",
          "17/03/1989",
          "20/03/1989",
          "21/03/1989",
          "22/03/1989",
          "23/03/1989",
          "27/03/1989",
          "28/03/1989",
          "29/03/1989",
          "30/03/1989",
          "31/03/1989",
          "03/04/1989",
          "04/04/1989",
          "05/04/1989",
          "06/04/1989",
          "07/04/1989",
          "10/04/1989",
          "11/04/1989",
          "12/04/1989",
          "13/04/1989",
          "14/04/1989",
          "17/04/1989",
          "18/04/1989",
          "19/04/1989",
          "20/04/1989",
          "21/04/1989",
          "24/04/1989",
          "25/04/1989",
          "26/04/1989",
          "27/04/1989",
          "28/04/1989",
          "01/05/1989",
          "02/05/1989",
          "03/05/1989",
          "04/05/1989",
          "05/05/1989",
          "08/05/1989",
          "09/05/1989",
          "10/05/1989",
          "11/05/1989",
          "12/05/1989",
          "15/05/1989",
          "16/05/1989",
          "17/05/1989",
          "18/05/1989",
          "19/05/1989",
          "22/05/1989",
          "23/05/1989",
          "24/05/1989",
          "25/05/1989",
          "26/05/1989",
          "30/05/1989",
          "31/05/1989",
          "01/06/1989",
          "02/06/1989",
          "05/06/1989",
          "06/06/1989",
          "07/06/1989",
          "08/06/1989",
          "09/06/1989",
          "12/06/1989",
          "13/06/1989",
          "14/06/1989",
          "15/06/1989",
          "16/06/1989",
          "19/06/1989",
          "20/06/1989",
          "21/06/1989",
          "22/06/1989",
          "23/06/1989",
          "26/06/1989",
          "27/06/1989",
          "28/06/1989",
          "29/06/1989",
          "30/06/1989",
          "03/07/1989",
          "05/07/1989",
          "06/07/1989",
          "07/07/1989",
          "10/07/1989",
          "11/07/1989",
          "12/07/1989",
          "13/07/1989",
          "14/07/1989",
          "17/07/1989",
          "18/07/1989",
          "19/07/1989",
          "20/07/1989",
          "21/07/1989",
          "24/07/1989",
          "25/07/1989",
          "26/07/1989",
          "27/07/1989",
          "28/07/1989",
          "31/07/1989",
          "01/08/1989",
          "02/08/1989",
          "03/08/1989",
          "04/08/1989",
          "07/08/1989",
          "08/08/1989",
          "09/08/1989",
          "10/08/1989",
          "11/08/1989",
          "14/08/1989",
          "15/08/1989",
          "16/08/1989",
          "17/08/1989",
          "18/08/1989",
          "21/08/1989",
          "22/08/1989",
          "23/08/1989",
          "24/08/1989",
          "25/08/1989",
          "28/08/1989",
          "29/08/1989",
          "30/08/1989",
          "31/08/1989",
          "01/09/1989",
          "05/09/1989",
          "06/09/1989",
          "07/09/1989",
          "08/09/1989",
          "11/09/1989",
          "12/09/1989",
          "13/09/1989",
          "14/09/1989",
          "15/09/1989",
          "18/09/1989",
          "19/09/1989",
          "20/09/1989",
          "21/09/1989",
          "22/09/1989",
          "25/09/1989",
          "26/09/1989",
          "27/09/1989",
          "28/09/1989",
          "29/09/1989",
          "02/10/1989",
          "03/10/1989",
          "04/10/1989",
          "05/10/1989",
          "06/10/1989",
          "09/10/1989",
          "10/10/1989",
          "11/10/1989",
          "12/10/1989",
          "13/10/1989",
          "16/10/1989",
          "17/10/1989",
          "18/10/1989",
          "19/10/1989",
          "20/10/1989",
          "23/10/1989",
          "24/10/1989",
          "25/10/1989",
          "26/10/1989",
          "27/10/1989",
          "30/10/1989",
          "31/10/1989",
          "01/11/1989",
          "02/11/1989",
          "03/11/1989",
          "06/11/1989",
          "07/11/1989",
          "08/11/1989",
          "09/11/1989",
          "10/11/1989",
          "13/11/1989",
          "14/11/1989",
          "15/11/1989",
          "16/11/1989",
          "17/11/1989",
          "20/11/1989",
          "21/11/1989",
          "22/11/1989",
          "24/11/1989",
          "27/11/1989",
          "28/11/1989",
          "29/11/1989",
          "30/11/1989",
          "01/12/1989",
          "04/12/1989",
          "05/12/1989",
          "06/12/1989",
          "07/12/1989",
          "08/12/1989",
          "11/12/1989",
          "12/12/1989",
          "13/12/1989",
          "14/12/1989",
          "15/12/1989",
          "18/12/1989",
          "19/12/1989",
          "20/12/1989",
          "21/12/1989",
          "22/12/1989",
          "26/12/1989",
          "27/12/1989",
          "28/12/1989",
          "29/12/1989",
          "02/01/1990",
          "03/01/1990",
          "04/01/1990",
          "05/01/1990",
          "08/01/1990",
          "09/01/1990",
          "10/01/1990",
          "11/01/1990",
          "12/01/1990",
          "15/01/1990",
          "16/01/1990",
          "17/01/1990",
          "18/01/1990",
          "19/01/1990",
          "22/01/1990",
          "23/01/1990",
          "24/01/1990",
          "25/01/1990",
          "26/01/1990",
          "29/01/1990",
          "30/01/1990",
          "31/01/1990",
          "01/02/1990",
          "02/02/1990",
          "05/02/1990",
          "06/02/1990",
          "07/02/1990",
          "08/02/1990",
          "09/02/1990",
          "12/02/1990",
          "13/02/1990",
          "14/02/1990",
          "15/02/1990",
          "16/02/1990",
          "20/02/1990",
          "21/02/1990",
          "22/02/1990",
          "23/02/1990",
          "26/02/1990",
          "27/02/1990",
          "28/02/1990",
          "01/03/1990",
          "02/03/1990",
          "05/03/1990",
          "06/03/1990",
          "07/03/1990",
          "08/03/1990",
          "09/03/1990",
          "12/03/1990",
          "13/03/1990",
          "14/03/1990",
          "15/03/1990",
          "16/03/1990",
          "19/03/1990",
          "20/03/1990",
          "21/03/1990",
          "22/03/1990",
          "23/03/1990",
          "26/03/1990",
          "27/03/1990",
          "28/03/1990",
          "29/03/1990",
          "30/03/1990",
          "02/04/1990",
          "03/04/1990",
          "04/04/1990",
          "05/04/1990",
          "06/04/1990",
          "09/04/1990",
          "10/04/1990",
          "11/04/1990",
          "12/04/1990",
          "16/04/1990",
          "17/04/1990",
          "18/04/1990",
          "19/04/1990",
          "20/04/1990",
          "23/04/1990",
          "24/04/1990",
          "25/04/1990",
          "26/04/1990",
          "27/04/1990",
          "30/04/1990",
          "01/05/1990",
          "02/05/1990",
          "03/05/1990",
          "04/05/1990",
          "07/05/1990",
          "08/05/1990",
          "09/05/1990",
          "10/05/1990",
          "11/05/1990",
          "14/05/1990",
          "15/05/1990",
          "16/05/1990",
          "17/05/1990",
          "18/05/1990",
          "21/05/1990",
          "22/05/1990",
          "23/05/1990",
          "24/05/1990",
          "25/05/1990",
          "29/05/1990",
          "30/05/1990",
          "31/05/1990",
          "01/06/1990",
          "04/06/1990",
          "05/06/1990",
          "06/06/1990",
          "07/06/1990",
          "08/06/1990",
          "11/06/1990",
          "12/06/1990",
          "13/06/1990",
          "14/06/1990",
          "15/06/1990",
          "18/06/1990",
          "19/06/1990",
          "20/06/1990",
          "21/06/1990",
          "22/06/1990",
          "25/06/1990",
          "26/06/1990",
          "27/06/1990",
          "28/06/1990",
          "29/06/1990",
          "02/07/1990",
          "03/07/1990",
          "05/07/1990",
          "06/07/1990",
          "09/07/1990",
          "10/07/1990",
          "11/07/1990",
          "12/07/1990",
          "13/07/1990",
          "16/07/1990",
          "17/07/1990",
          "18/07/1990",
          "19/07/1990",
          "20/07/1990",
          "23/07/1990",
          "24/07/1990",
          "25/07/1990",
          "26/07/1990",
          "27/07/1990",
          "30/07/1990",
          "31/07/1990",
          "01/08/1990",
          "02/08/1990",
          "03/08/1990",
          "06/08/1990",
          "07/08/1990",
          "08/08/1990",
          "09/08/1990",
          "10/08/1990",
          "13/08/1990",
          "14/08/1990",
          "15/08/1990",
          "16/08/1990",
          "17/08/1990",
          "20/08/1990",
          "21/08/1990",
          "22/08/1990",
          "23/08/1990",
          "24/08/1990",
          "27/08/1990",
          "28/08/1990",
          "29/08/1990",
          "30/08/1990",
          "31/08/1990",
          "04/09/1990",
          "05/09/1990",
          "06/09/1990",
          "07/09/1990",
          "10/09/1990",
          "11/09/1990",
          "12/09/1990",
          "13/09/1990",
          "14/09/1990",
          "17/09/1990",
          "18/09/1990",
          "19/09/1990",
          "20/09/1990",
          "21/09/1990",
          "24/09/1990",
          "25/09/1990",
          "26/09/1990",
          "27/09/1990",
          "28/09/1990",
          "01/10/1990",
          "02/10/1990",
          "03/10/1990",
          "04/10/1990",
          "05/10/1990",
          "08/10/1990",
          "09/10/1990",
          "10/10/1990",
          "11/10/1990",
          "12/10/1990",
          "15/10/1990",
          "16/10/1990",
          "17/10/1990",
          "18/10/1990",
          "19/10/1990",
          "22/10/1990",
          "23/10/1990",
          "24/10/1990",
          "25/10/1990",
          "26/10/1990",
          "29/10/1990",
          "30/10/1990",
          "31/10/1990",
          "01/11/1990",
          "02/11/1990",
          "05/11/1990",
          "06/11/1990",
          "07/11/1990",
          "08/11/1990",
          "09/11/1990",
          "12/11/1990",
          "13/11/1990",
          "14/11/1990",
          "15/11/1990",
          "16/11/1990",
          "19/11/1990",
          "20/11/1990",
          "21/11/1990",
          "23/11/1990",
          "26/11/1990",
          "27/11/1990",
          "28/11/1990",
          "29/11/1990",
          "30/11/1990",
          "03/12/1990",
          "04/12/1990",
          "05/12/1990",
          "06/12/1990",
          "07/12/1990",
          "10/12/1990",
          "11/12/1990",
          "12/12/1990",
          "13/12/1990",
          "14/12/1990",
          "17/12/1990",
          "18/12/1990",
          "19/12/1990",
          "20/12/1990",
          "21/12/1990",
          "24/12/1990",
          "26/12/1990",
          "27/12/1990",
          "28/12/1990",
          "31/12/1990",
          "02/01/1991",
          "03/01/1991",
          "04/01/1991",
          "07/01/1991",
          "08/01/1991",
          "09/01/1991",
          "10/01/1991",
          "11/01/1991",
          "14/01/1991",
          "15/01/1991",
          "16/01/1991",
          "17/01/1991",
          "18/01/1991",
          "21/01/1991",
          "22/01/1991",
          "23/01/1991",
          "24/01/1991",
          "25/01/1991",
          "28/01/1991",
          "29/01/1991",
          "30/01/1991",
          "31/01/1991",
          "01/02/1991",
          "04/02/1991",
          "05/02/1991",
          "06/02/1991",
          "07/02/1991",
          "08/02/1991",
          "11/02/1991",
          "12/02/1991",
          "13/02/1991",
          "14/02/1991",
          "15/02/1991",
          "19/02/1991",
          "20/02/1991",
          "21/02/1991",
          "22/02/1991",
          "25/02/1991",
          "26/02/1991",
          "27/02/1991",
          "28/02/1991",
          "01/03/1991",
          "04/03/1991",
          "05/03/1991",
          "06/03/1991",
          "07/03/1991",
          "08/03/1991",
          "11/03/1991",
          "12/03/1991",
          "13/03/1991",
          "14/03/1991",
          "15/03/1991",
          "18/03/1991",
          "19/03/1991",
          "20/03/1991",
          "21/03/1991",
          "22/03/1991",
          "25/03/1991",
          "26/03/1991",
          "27/03/1991",
          "28/03/1991",
          "01/04/1991",
          "02/04/1991",
          "03/04/1991",
          "04/04/1991",
          "05/04/1991",
          "08/04/1991",
          "09/04/1991",
          "10/04/1991",
          "11/04/1991",
          "12/04/1991",
          "15/04/1991",
          "16/04/1991",
          "17/04/1991",
          "18/04/1991",
          "19/04/1991",
          "22/04/1991",
          "23/04/1991",
          "24/04/1991",
          "25/04/1991",
          "26/04/1991",
          "29/04/1991",
          "30/04/1991",
          "01/05/1991",
          "02/05/1991",
          "03/05/1991",
          "06/05/1991",
          "07/05/1991",
          "08/05/1991",
          "09/05/1991",
          "10/05/1991",
          "13/05/1991",
          "14/05/1991",
          "15/05/1991",
          "16/05/1991",
          "17/05/1991",
          "20/05/1991",
          "21/05/1991",
          "22/05/1991",
          "23/05/1991",
          "24/05/1991",
          "28/05/1991",
          "29/05/1991",
          "30/05/1991",
          "31/05/1991",
          "03/06/1991",
          "04/06/1991",
          "05/06/1991",
          "06/06/1991",
          "07/06/1991",
          "10/06/1991",
          "11/06/1991",
          "12/06/1991",
          "13/06/1991",
          "14/06/1991",
          "17/06/1991",
          "18/06/1991",
          "19/06/1991",
          "20/06/1991",
          "21/06/1991",
          "24/06/1991",
          "25/06/1991",
          "26/06/1991",
          "27/06/1991",
          "28/06/1991",
          "01/07/1991",
          "02/07/1991",
          "03/07/1991",
          "05/07/1991",
          "08/07/1991",
          "09/07/1991",
          "10/07/1991",
          "11/07/1991",
          "12/07/1991",
          "15/07/1991",
          "16/07/1991",
          "17/07/1991",
          "18/07/1991",
          "19/07/1991",
          "22/07/1991",
          "23/07/1991",
          "24/07/1991",
          "25/07/1991",
          "26/07/1991",
          "29/07/1991",
          "30/07/1991",
          "31/07/1991",
          "01/08/1991",
          "02/08/1991",
          "05/08/1991",
          "06/08/1991",
          "07/08/1991",
          "08/08/1991",
          "09/08/1991",
          "12/08/1991",
          "13/08/1991",
          "14/08/1991",
          "15/08/1991",
          "16/08/1991",
          "19/08/1991",
          "20/08/1991",
          "21/08/1991",
          "22/08/1991",
          "23/08/1991",
          "26/08/1991",
          "27/08/1991",
          "28/08/1991",
          "29/08/1991",
          "30/08/1991",
          "03/09/1991",
          "04/09/1991",
          "05/09/1991",
          "06/09/1991",
          "09/09/1991",
          "10/09/1991",
          "11/09/1991",
          "12/09/1991",
          "13/09/1991",
          "16/09/1991",
          "17/09/1991",
          "18/09/1991",
          "19/09/1991",
          "20/09/1991",
          "23/09/1991",
          "24/09/1991",
          "25/09/1991",
          "26/09/1991",
          "27/09/1991",
          "30/09/1991",
          "01/10/1991",
          "02/10/1991",
          "03/10/1991",
          "04/10/1991",
          "07/10/1991",
          "08/10/1991",
          "09/10/1991",
          "10/10/1991",
          "11/10/1991",
          "14/10/1991",
          "15/10/1991",
          "16/10/1991",
          "17/10/1991",
          "18/10/1991",
          "21/10/1991",
          "22/10/1991",
          "23/10/1991",
          "24/10/1991",
          "25/10/1991",
          "28/10/1991",
          "29/10/1991",
          "30/10/1991",
          "31/10/1991",
          "01/11/1991",
          "04/11/1991",
          "05/11/1991",
          "06/11/1991",
          "07/11/1991",
          "08/11/1991",
          "11/11/1991",
          "12/11/1991",
          "13/11/1991",
          "14/11/1991",
          "15/11/1991",
          "18/11/1991",
          "19/11/1991",
          "20/11/1991",
          "21/11/1991",
          "22/11/1991",
          "25/11/1991",
          "26/11/1991",
          "27/11/1991",
          "29/11/1991",
          "02/12/1991",
          "03/12/1991",
          "04/12/1991",
          "05/12/1991",
          "06/12/1991",
          "09/12/1991",
          "10/12/1991",
          "11/12/1991",
          "12/12/1991",
          "13/12/1991",
          "16/12/1991",
          "17/12/1991",
          "18/12/1991",
          "19/12/1991",
          "20/12/1991",
          "23/12/1991",
          "24/12/1991",
          "26/12/1991",
          "27/12/1991",
          "30/12/1991",
          "31/12/1991",
          "02/01/1992",
          "03/01/1992",
          "06/01/1992",
          "07/01/1992",
          "08/01/1992",
          "09/01/1992",
          "10/01/1992",
          "13/01/1992",
          "14/01/1992",
          "15/01/1992",
          "16/01/1992",
          "17/01/1992",
          "20/01/1992",
          "21/01/1992",
          "22/01/1992",
          "23/01/1992",
          "24/01/1992",
          "27/01/1992",
          "28/01/1992",
          "29/01/1992",
          "30/01/1992",
          "31/01/1992",
          "03/02/1992",
          "04/02/1992",
          "05/02/1992",
          "06/02/1992",
          "07/02/1992",
          "10/02/1992",
          "11/02/1992",
          "12/02/1992",
          "13/02/1992",
          "14/02/1992",
          "18/02/1992",
          "19/02/1992",
          "20/02/1992",
          "21/02/1992",
          "24/02/1992",
          "25/02/1992",
          "26/02/1992",
          "27/02/1992",
          "28/02/1992",
          "02/03/1992",
          "03/03/1992",
          "04/03/1992",
          "05/03/1992",
          "06/03/1992",
          "09/03/1992",
          "10/03/1992",
          "11/03/1992",
          "12/03/1992",
          "13/03/1992",
          "16/03/1992",
          "17/03/1992",
          "18/03/1992",
          "19/03/1992",
          "20/03/1992",
          "23/03/1992",
          "24/03/1992",
          "25/03/1992",
          "26/03/1992",
          "27/03/1992",
          "30/03/1992",
          "31/03/1992",
          "01/04/1992",
          "02/04/1992",
          "03/04/1992",
          "06/04/1992",
          "07/04/1992",
          "08/04/1992",
          "09/04/1992",
          "10/04/1992",
          "13/04/1992",
          "14/04/1992",
          "15/04/1992",
          "16/04/1992",
          "20/04/1992",
          "21/04/1992",
          "22/04/1992",
          "23/04/1992",
          "24/04/1992",
          "27/04/1992",
          "28/04/1992",
          "29/04/1992",
          "30/04/1992",
          "01/05/1992",
          "04/05/1992",
          "05/05/1992",
          "06/05/1992",
          "07/05/1992",
          "08/05/1992",
          "11/05/1992",
          "12/05/1992",
          "13/05/1992",
          "14/05/1992",
          "15/05/1992",
          "18/05/1992",
          "19/05/1992",
          "20/05/1992",
          "21/05/1992",
          "22/05/1992",
          "26/05/1992",
          "27/05/1992",
          "28/05/1992",
          "29/05/1992",
          "01/06/1992",
          "02/06/1992",
          "03/06/1992",
          "04/06/1992",
          "05/06/1992",
          "08/06/1992",
          "09/06/1992",
          "10/06/1992",
          "11/06/1992",
          "12/06/1992",
          "15/06/1992",
          "16/06/1992",
          "17/06/1992",
          "18/06/1992",
          "19/06/1992",
          "22/06/1992",
          "23/06/1992",
          "24/06/1992",
          "25/06/1992",
          "26/06/1992",
          "29/06/1992",
          "30/06/1992",
          "01/07/1992",
          "02/07/1992",
          "06/07/1992",
          "07/07/1992",
          "08/07/1992",
          "09/07/1992",
          "10/07/1992",
          "13/07/1992",
          "14/07/1992",
          "15/07/1992",
          "16/07/1992",
          "17/07/1992",
          "20/07/1992",
          "21/07/1992",
          "22/07/1992",
          "23/07/1992",
          "24/07/1992",
          "27/07/1992",
          "28/07/1992",
          "29/07/1992",
          "30/07/1992",
          "31/07/1992",
          "03/08/1992",
          "04/08/1992",
          "05/08/1992",
          "06/08/1992",
          "07/08/1992",
          "10/08/1992",
          "11/08/1992",
          "12/08/1992",
          "13/08/1992",
          "14/08/1992",
          "17/08/1992",
          "18/08/1992",
          "19/08/1992",
          "20/08/1992",
          "21/08/1992",
          "24/08/1992",
          "25/08/1992",
          "26/08/1992",
          "27/08/1992",
          "28/08/1992",
          "31/08/1992",
          "01/09/1992",
          "02/09/1992",
          "03/09/1992",
          "04/09/1992",
          "08/09/1992",
          "09/09/1992",
          "10/09/1992",
          "11/09/1992",
          "14/09/1992",
          "15/09/1992",
          "16/09/1992",
          "17/09/1992",
          "18/09/1992",
          "21/09/1992",
          "22/09/1992",
          "23/09/1992",
          "24/09/1992",
          "25/09/1992",
          "28/09/1992",
          "29/09/1992",
          "30/09/1992",
          "01/10/1992",
          "02/10/1992",
          "05/10/1992",
          "06/10/1992",
          "07/10/1992",
          "08/10/1992",
          "09/10/1992",
          "12/10/1992",
          "13/10/1992",
          "14/10/1992",
          "15/10/1992",
          "16/10/1992",
          "19/10/1992",
          "20/10/1992",
          "21/10/1992",
          "22/10/1992",
          "23/10/1992",
          "26/10/1992",
          "27/10/1992",
          "28/10/1992",
          "29/10/1992",
          "30/10/1992",
          "02/11/1992",
          "03/11/1992",
          "04/11/1992",
          "05/11/1992",
          "06/11/1992",
          "09/11/1992",
          "10/11/1992",
          "11/11/1992",
          "12/11/1992",
          "13/11/1992",
          "16/11/1992",
          "17/11/1992",
          "18/11/1992",
          "19/11/1992",
          "20/11/1992",
          "23/11/1992",
          "24/11/1992",
          "25/11/1992",
          "27/11/1992",
          "30/11/1992",
          "01/12/1992",
          "02/12/1992",
          "03/12/1992",
          "04/12/1992",
          "07/12/1992",
          "08/12/1992",
          "09/12/1992",
          "10/12/1992",
          "11/12/1992",
          "14/12/1992",
          "15/12/1992",
          "16/12/1992",
          "17/12/1992",
          "18/12/1992",
          "21/12/1992",
          "22/12/1992",
          "23/12/1992",
          "24/12/1992",
          "28/12/1992",
          "29/12/1992",
          "30/12/1992",
          "31/12/1992",
          "04/01/1993",
          "05/01/1993",
          "06/01/1993",
          "07/01/1993",
          "08/01/1993",
          "11/01/1993",
          "12/01/1993",
          "13/01/1993",
          "14/01/1993",
          "15/01/1993",
          "18/01/1993",
          "19/01/1993",
          "20/01/1993",
          "21/01/1993",
          "22/01/1993",
          "25/01/1993",
          "26/01/1993",
          "27/01/1993",
          "28/01/1993",
          "29/01/1993",
          "01/02/1993",
          "02/02/1993",
          "03/02/1993",
          "04/02/1993",
          "05/02/1993",
          "08/02/1993",
          "09/02/1993",
          "10/02/1993",
          "11/02/1993",
          "12/02/1993",
          "16/02/1993",
          "17/02/1993",
          "18/02/1993",
          "19/02/1993",
          "22/02/1993",
          "23/02/1993",
          "24/02/1993",
          "25/02/1993",
          "26/02/1993",
          "01/03/1993",
          "02/03/1993",
          "03/03/1993",
          "04/03/1993",
          "05/03/1993",
          "08/03/1993",
          "09/03/1993",
          "10/03/1993",
          "11/03/1993",
          "12/03/1993",
          "15/03/1993",
          "16/03/1993",
          "17/03/1993",
          "18/03/1993",
          "19/03/1993",
          "22/03/1993",
          "23/03/1993",
          "24/03/1993",
          "25/03/1993",
          "26/03/1993",
          "29/03/1993",
          "30/03/1993",
          "31/03/1993",
          "01/04/1993",
          "02/04/1993",
          "05/04/1993",
          "06/04/1993",
          "07/04/1993",
          "08/04/1993",
          "12/04/1993",
          "13/04/1993",
          "14/04/1993",
          "15/04/1993",
          "16/04/1993",
          "19/04/1993",
          "20/04/1993",
          "21/04/1993",
          "22/04/1993",
          "23/04/1993",
          "26/04/1993",
          "27/04/1993",
          "28/04/1993",
          "29/04/1993",
          "30/04/1993",
          "03/05/1993",
          "04/05/1993",
          "05/05/1993",
          "06/05/1993",
          "07/05/1993",
          "10/05/1993",
          "11/05/1993",
          "12/05/1993",
          "13/05/1993",
          "14/05/1993",
          "17/05/1993",
          "18/05/1993",
          "19/05/1993",
          "20/05/1993",
          "21/05/1993",
          "24/05/1993",
          "25/05/1993",
          "26/05/1993",
          "27/05/1993",
          "28/05/1993",
          "01/06/1993",
          "02/06/1993",
          "03/06/1993",
          "04/06/1993",
          "07/06/1993",
          "08/06/1993",
          "09/06/1993",
          "10/06/1993",
          "11/06/1993",
          "14/06/1993",
          "15/06/1993",
          "16/06/1993",
          "17/06/1993",
          "18/06/1993",
          "21/06/1993",
          "22/06/1993",
          "23/06/1993",
          "24/06/1993",
          "25/06/1993",
          "28/06/1993",
          "29/06/1993",
          "30/06/1993",
          "01/07/1993",
          "02/07/1993",
          "06/07/1993",
          "07/07/1993",
          "08/07/1993",
          "09/07/1993",
          "12/07/1993",
          "13/07/1993",
          "14/07/1993",
          "15/07/1993",
          "16/07/1993",
          "19/07/1993",
          "20/07/1993",
          "21/07/1993",
          "22/07/1993",
          "23/07/1993",
          "26/07/1993",
          "27/07/1993",
          "28/07/1993",
          "29/07/1993",
          "30/07/1993",
          "02/08/1993",
          "03/08/1993",
          "04/08/1993",
          "05/08/1993",
          "06/08/1993",
          "09/08/1993",
          "10/08/1993",
          "11/08/1993",
          "12/08/1993",
          "13/08/1993",
          "16/08/1993",
          "17/08/1993",
          "18/08/1993",
          "19/08/1993",
          "20/08/1993",
          "23/08/1993",
          "24/08/1993",
          "25/08/1993",
          "26/08/1993",
          "27/08/1993",
          "30/08/1993",
          "31/08/1993",
          "01/09/1993",
          "02/09/1993",
          "03/09/1993",
          "07/09/1993",
          "08/09/1993",
          "09/09/1993",
          "10/09/1993",
          "13/09/1993",
          "14/09/1993",
          "15/09/1993",
          "16/09/1993",
          "17/09/1993",
          "20/09/1993",
          "21/09/1993",
          "22/09/1993",
          "23/09/1993",
          "24/09/1993",
          "27/09/1993",
          "28/09/1993",
          "29/09/1993",
          "30/09/1993",
          "01/10/1993",
          "04/10/1993",
          "05/10/1993",
          "06/10/1993",
          "07/10/1993",
          "08/10/1993",
          "11/10/1993",
          "12/10/1993",
          "13/10/1993",
          "14/10/1993",
          "15/10/1993",
          "18/10/1993",
          "19/10/1993",
          "20/10/1993",
          "21/10/1993",
          "22/10/1993",
          "25/10/1993",
          "26/10/1993",
          "27/10/1993",
          "28/10/1993",
          "29/10/1993",
          "01/11/1993",
          "02/11/1993",
          "03/11/1993",
          "04/11/1993",
          "05/11/1993",
          "08/11/1993",
          "09/11/1993",
          "10/11/1993",
          "11/11/1993",
          "12/11/1993",
          "15/11/1993",
          "16/11/1993",
          "17/11/1993",
          "18/11/1993",
          "19/11/1993",
          "22/11/1993",
          "23/11/1993",
          "24/11/1993",
          "26/11/1993",
          "29/11/1993",
          "30/11/1993",
          "01/12/1993",
          "02/12/1993",
          "03/12/1993",
          "06/12/1993",
          "07/12/1993",
          "08/12/1993",
          "09/12/1993",
          "10/12/1993",
          "13/12/1993",
          "14/12/1993",
          "15/12/1993",
          "16/12/1993",
          "17/12/1993",
          "20/12/1993",
          "21/12/1993",
          "22/12/1993",
          "23/12/1993",
          "27/12/1993",
          "28/12/1993",
          "29/12/1993",
          "30/12/1993",
          "31/12/1993",
          "03/01/1994",
          "04/01/1994",
          "05/01/1994",
          "06/01/1994",
          "07/01/1994",
          "10/01/1994",
          "11/01/1994",
          "12/01/1994",
          "13/01/1994",
          "14/01/1994",
          "17/01/1994",
          "18/01/1994",
          "19/01/1994",
          "20/01/1994",
          "21/01/1994",
          "24/01/1994",
          "25/01/1994",
          "26/01/1994",
          "27/01/1994",
          "28/01/1994",
          "31/01/1994",
          "01/02/1994",
          "02/02/1994",
          "03/02/1994",
          "04/02/1994",
          "07/02/1994",
          "08/02/1994",
          "09/02/1994",
          "10/02/1994",
          "11/02/1994",
          "14/02/1994",
          "15/02/1994",
          "16/02/1994",
          "17/02/1994",
          "18/02/1994",
          "22/02/1994",
          "23/02/1994",
          "24/02/1994",
          "25/02/1994",
          "28/02/1994",
          "01/03/1994",
          "02/03/1994",
          "03/03/1994",
          "04/03/1994",
          "07/03/1994",
          "08/03/1994",
          "09/03/1994",
          "10/03/1994",
          "11/03/1994",
          "14/03/1994",
          "15/03/1994",
          "16/03/1994",
          "17/03/1994",
          "18/03/1994",
          "21/03/1994",
          "22/03/1994",
          "23/03/1994",
          "24/03/1994",
          "25/03/1994",
          "28/03/1994",
          "29/03/1994",
          "30/03/1994",
          "31/03/1994",
          "04/04/1994",
          "05/04/1994",
          "06/04/1994",
          "07/04/1994",
          "08/04/1994",
          "11/04/1994",
          "12/04/1994",
          "13/04/1994",
          "14/04/1994",
          "15/04/1994",
          "18/04/1994",
          "19/04/1994",
          "20/04/1994",
          "21/04/1994",
          "22/04/1994",
          "25/04/1994",
          "26/04/1994",
          "28/04/1994",
          "29/04/1994",
          "02/05/1994",
          "03/05/1994",
          "04/05/1994",
          "05/05/1994",
          "06/05/1994",
          "09/05/1994",
          "10/05/1994",
          "11/05/1994",
          "12/05/1994",
          "13/05/1994",
          "16/05/1994",
          "17/05/1994",
          "18/05/1994",
          "19/05/1994",
          "20/05/1994",
          "23/05/1994",
          "24/05/1994",
          "25/05/1994",
          "26/05/1994",
          "27/05/1994",
          "31/05/1994",
          "01/06/1994",
          "02/06/1994",
          "03/06/1994",
          "06/06/1994",
          "07/06/1994",
          "08/06/1994",
          "09/06/1994",
          "10/06/1994",
          "13/06/1994",
          "14/06/1994",
          "15/06/1994",
          "16/06/1994",
          "17/06/1994",
          "20/06/1994",
          "21/06/1994",
          "22/06/1994",
          "23/06/1994",
          "24/06/1994",
          "27/06/1994",
          "28/06/1994",
          "29/06/1994",
          "30/06/1994",
          "01/07/1994",
          "05/07/1994",
          "06/07/1994",
          "07/07/1994",
          "08/07/1994",
          "11/07/1994",
          "12/07/1994",
          "13/07/1994",
          "14/07/1994",
          "15/07/1994",
          "18/07/1994",
          "19/07/1994",
          "20/07/1994",
          "21/07/1994",
          "22/07/1994",
          "25/07/1994",
          "26/07/1994",
          "27/07/1994",
          "28/07/1994",
          "29/07/1994",
          "01/08/1994",
          "02/08/1994",
          "03/08/1994",
          "04/08/1994",
          "05/08/1994",
          "08/08/1994",
          "09/08/1994",
          "10/08/1994",
          "11/08/1994",
          "12/08/1994",
          "15/08/1994",
          "16/08/1994",
          "17/08/1994",
          "18/08/1994",
          "19/08/1994",
          "22/08/1994",
          "23/08/1994",
          "24/08/1994",
          "25/08/1994",
          "26/08/1994",
          "29/08/1994",
          "30/08/1994",
          "31/08/1994",
          "01/09/1994",
          "02/09/1994",
          "06/09/1994",
          "07/09/1994",
          "08/09/1994",
          "09/09/1994",
          "12/09/1994",
          "13/09/1994",
          "14/09/1994",
          "15/09/1994",
          "16/09/1994",
          "19/09/1994",
          "20/09/1994",
          "21/09/1994",
          "22/09/1994",
          "23/09/1994",
          "26/09/1994",
          "27/09/1994",
          "28/09/1994",
          "29/09/1994",
          "30/09/1994",
          "03/10/1994",
          "04/10/1994",
          "05/10/1994",
          "06/10/1994",
          "07/10/1994",
          "10/10/1994",
          "11/10/1994",
          "12/10/1994",
          "13/10/1994",
          "14/10/1994",
          "17/10/1994",
          "18/10/1994",
          "19/10/1994",
          "20/10/1994",
          "21/10/1994",
          "24/10/1994",
          "25/10/1994",
          "26/10/1994",
          "27/10/1994",
          "28/10/1994",
          "31/10/1994",
          "01/11/1994",
          "02/11/1994",
          "03/11/1994",
          "04/11/1994",
          "07/11/1994",
          "08/11/1994",
          "09/11/1994",
          "10/11/1994",
          "11/11/1994",
          "14/11/1994",
          "15/11/1994",
          "16/11/1994",
          "17/11/1994",
          "18/11/1994",
          "21/11/1994",
          "22/11/1994",
          "23/11/1994",
          "25/11/1994",
          "28/11/1994",
          "29/11/1994",
          "30/11/1994",
          "01/12/1994",
          "02/12/1994",
          "05/12/1994",
          "06/12/1994",
          "07/12/1994",
          "08/12/1994",
          "09/12/1994",
          "12/12/1994",
          "13/12/1994",
          "14/12/1994",
          "15/12/1994",
          "16/12/1994",
          "19/12/1994",
          "20/12/1994",
          "21/12/1994",
          "22/12/1994",
          "23/12/1994",
          "27/12/1994",
          "28/12/1994",
          "29/12/1994",
          "30/12/1994",
          "03/01/1995",
          "04/01/1995",
          "05/01/1995",
          "06/01/1995",
          "09/01/1995",
          "10/01/1995",
          "11/01/1995",
          "12/01/1995",
          "13/01/1995",
          "16/01/1995",
          "17/01/1995",
          "18/01/1995",
          "19/01/1995",
          "20/01/1995",
          "23/01/1995",
          "24/01/1995",
          "25/01/1995",
          "26/01/1995",
          "27/01/1995",
          "30/01/1995",
          "31/01/1995",
          "01/02/1995",
          "02/02/1995",
          "03/02/1995",
          "06/02/1995",
          "07/02/1995",
          "08/02/1995",
          "09/02/1995",
          "10/02/1995",
          "13/02/1995",
          "14/02/1995",
          "15/02/1995",
          "16/02/1995",
          "17/02/1995",
          "21/02/1995",
          "22/02/1995",
          "23/02/1995",
          "24/02/1995",
          "27/02/1995",
          "28/02/1995",
          "01/03/1995",
          "02/03/1995",
          "03/03/1995",
          "06/03/1995",
          "07/03/1995",
          "08/03/1995",
          "09/03/1995",
          "10/03/1995",
          "13/03/1995",
          "14/03/1995",
          "15/03/1995",
          "16/03/1995",
          "17/03/1995",
          "20/03/1995",
          "21/03/1995",
          "22/03/1995",
          "23/03/1995",
          "24/03/1995",
          "27/03/1995",
          "28/03/1995",
          "29/03/1995",
          "30/03/1995",
          "31/03/1995",
          "03/04/1995",
          "04/04/1995",
          "05/04/1995",
          "06/04/1995",
          "07/04/1995",
          "10/04/1995",
          "11/04/1995",
          "12/04/1995",
          "13/04/1995",
          "17/04/1995",
          "18/04/1995",
          "19/04/1995",
          "20/04/1995",
          "21/04/1995",
          "24/04/1995",
          "25/04/1995",
          "26/04/1995",
          "27/04/1995",
          "28/04/1995",
          "01/05/1995",
          "02/05/1995",
          "03/05/1995",
          "04/05/1995",
          "05/05/1995",
          "08/05/1995",
          "09/05/1995",
          "10/05/1995",
          "11/05/1995",
          "12/05/1995",
          "15/05/1995",
          "16/05/1995",
          "17/05/1995",
          "18/05/1995",
          "19/05/1995",
          "22/05/1995",
          "23/05/1995",
          "24/05/1995",
          "25/05/1995",
          "26/05/1995",
          "30/05/1995",
          "31/05/1995",
          "01/06/1995",
          "02/06/1995",
          "05/06/1995",
          "06/06/1995",
          "07/06/1995",
          "08/06/1995",
          "09/06/1995",
          "12/06/1995",
          "13/06/1995",
          "14/06/1995",
          "15/06/1995",
          "16/06/1995",
          "19/06/1995",
          "20/06/1995",
          "21/06/1995",
          "22/06/1995",
          "23/06/1995",
          "26/06/1995",
          "27/06/1995",
          "28/06/1995",
          "29/06/1995",
          "30/06/1995",
          "03/07/1995",
          "05/07/1995",
          "06/07/1995",
          "07/07/1995",
          "10/07/1995",
          "11/07/1995",
          "12/07/1995",
          "13/07/1995",
          "14/07/1995",
          "17/07/1995",
          "18/07/1995",
          "19/07/1995",
          "20/07/1995",
          "21/07/1995",
          "24/07/1995",
          "25/07/1995",
          "26/07/1995",
          "27/07/1995",
          "28/07/1995",
          "31/07/1995",
          "01/08/1995",
          "02/08/1995",
          "03/08/1995",
          "04/08/1995",
          "07/08/1995",
          "08/08/1995",
          "09/08/1995",
          "10/08/1995",
          "11/08/1995",
          "14/08/1995",
          "15/08/1995",
          "16/08/1995",
          "17/08/1995",
          "18/08/1995",
          "21/08/1995",
          "22/08/1995",
          "23/08/1995",
          "24/08/1995",
          "25/08/1995",
          "28/08/1995",
          "29/08/1995",
          "30/08/1995",
          "31/08/1995",
          "01/09/1995",
          "05/09/1995",
          "06/09/1995",
          "07/09/1995",
          "08/09/1995",
          "11/09/1995",
          "12/09/1995",
          "13/09/1995",
          "14/09/1995",
          "15/09/1995",
          "18/09/1995",
          "19/09/1995",
          "20/09/1995",
          "21/09/1995",
          "22/09/1995",
          "25/09/1995",
          "26/09/1995",
          "27/09/1995",
          "28/09/1995",
          "29/09/1995",
          "02/10/1995",
          "03/10/1995",
          "04/10/1995",
          "05/10/1995",
          "06/10/1995",
          "09/10/1995",
          "10/10/1995",
          "11/10/1995",
          "12/10/1995",
          "13/10/1995",
          "16/10/1995",
          "17/10/1995",
          "18/10/1995",
          "19/10/1995",
          "20/10/1995",
          "23/10/1995",
          "24/10/1995",
          "25/10/1995",
          "26/10/1995",
          "27/10/1995",
          "30/10/1995",
          "31/10/1995",
          "01/11/1995",
          "02/11/1995",
          "03/11/1995",
          "06/11/1995",
          "07/11/1995",
          "08/11/1995",
          "09/11/1995",
          "10/11/1995",
          "13/11/1995",
          "14/11/1995",
          "15/11/1995",
          "16/11/1995",
          "17/11/1995",
          "20/11/1995",
          "21/11/1995",
          "22/11/1995",
          "24/11/1995",
          "27/11/1995",
          "28/11/1995",
          "29/11/1995",
          "30/11/1995",
          "01/12/1995",
          "04/12/1995",
          "05/12/1995",
          "06/12/1995",
          "07/12/1995",
          "08/12/1995",
          "11/12/1995",
          "12/12/1995",
          "13/12/1995",
          "14/12/1995",
          "15/12/1995",
          "18/12/1995",
          "19/12/1995",
          "20/12/1995",
          "21/12/1995",
          "22/12/1995",
          "26/12/1995",
          "27/12/1995",
          "28/12/1995",
          "29/12/1995",
          "02/01/1996",
          "03/01/1996",
          "04/01/1996",
          "05/01/1996",
          "08/01/1996",
          "09/01/1996",
          "10/01/1996",
          "11/01/1996",
          "12/01/1996",
          "15/01/1996",
          "16/01/1996",
          "17/01/1996",
          "18/01/1996",
          "19/01/1996",
          "22/01/1996",
          "23/01/1996",
          "24/01/1996",
          "25/01/1996",
          "26/01/1996",
          "29/01/1996",
          "30/01/1996",
          "31/01/1996",
          "01/02/1996",
          "02/02/1996",
          "05/02/1996",
          "06/02/1996",
          "07/02/1996",
          "08/02/1996",
          "09/02/1996",
          "12/02/1996",
          "13/02/1996",
          "14/02/1996",
          "15/02/1996",
          "16/02/1996",
          "20/02/1996",
          "21/02/1996",
          "22/02/1996",
          "23/02/1996",
          "26/02/1996",
          "27/02/1996",
          "28/02/1996",
          "29/02/1996",
          "01/03/1996",
          "04/03/1996",
          "05/03/1996",
          "06/03/1996",
          "07/03/1996",
          "08/03/1996",
          "11/03/1996",
          "12/03/1996",
          "13/03/1996",
          "14/03/1996",
          "15/03/1996",
          "18/03/1996",
          "19/03/1996",
          "20/03/1996",
          "21/03/1996",
          "22/03/1996",
          "25/03/1996",
          "26/03/1996",
          "27/03/1996",
          "28/03/1996",
          "29/03/1996",
          "01/04/1996",
          "02/04/1996",
          "03/04/1996",
          "04/04/1996",
          "08/04/1996",
          "09/04/1996",
          "10/04/1996",
          "11/04/1996",
          "12/04/1996",
          "15/04/1996",
          "16/04/1996",
          "17/04/1996",
          "18/04/1996",
          "19/04/1996",
          "22/04/1996",
          "23/04/1996",
          "24/04/1996",
          "25/04/1996",
          "26/04/1996",
          "29/04/1996",
          "30/04/1996",
          "01/05/1996",
          "02/05/1996",
          "03/05/1996",
          "06/05/1996",
          "07/05/1996",
          "08/05/1996",
          "09/05/1996",
          "10/05/1996",
          "13/05/1996",
          "14/05/1996",
          "15/05/1996",
          "16/05/1996",
          "17/05/1996",
          "20/05/1996",
          "21/05/1996",
          "22/05/1996",
          "23/05/1996",
          "24/05/1996",
          "28/05/1996",
          "29/05/1996",
          "30/05/1996",
          "31/05/1996",
          "03/06/1996",
          "04/06/1996",
          "05/06/1996",
          "06/06/1996",
          "07/06/1996",
          "10/06/1996",
          "11/06/1996",
          "12/06/1996",
          "13/06/1996",
          "14/06/1996",
          "17/06/1996",
          "18/06/1996",
          "19/06/1996",
          "20/06/1996",
          "21/06/1996",
          "24/06/1996",
          "25/06/1996",
          "26/06/1996",
          "27/06/1996",
          "28/06/1996",
          "01/07/1996",
          "02/07/1996",
          "03/07/1996",
          "05/07/1996",
          "08/07/1996",
          "09/07/1996",
          "10/07/1996",
          "11/07/1996",
          "12/07/1996",
          "15/07/1996",
          "16/07/1996",
          "17/07/1996",
          "18/07/1996",
          "19/07/1996",
          "22/07/1996",
          "23/07/1996",
          "24/07/1996",
          "25/07/1996",
          "26/07/1996",
          "29/07/1996",
          "30/07/1996",
          "31/07/1996",
          "01/08/1996",
          "02/08/1996",
          "05/08/1996",
          "06/08/1996",
          "07/08/1996",
          "08/08/1996",
          "09/08/1996",
          "12/08/1996",
          "13/08/1996",
          "14/08/1996",
          "15/08/1996",
          "16/08/1996",
          "19/08/1996",
          "20/08/1996",
          "21/08/1996",
          "22/08/1996",
          "23/08/1996",
          "26/08/1996",
          "27/08/1996",
          "28/08/1996",
          "29/08/1996",
          "30/08/1996",
          "03/09/1996",
          "04/09/1996",
          "05/09/1996",
          "06/09/1996",
          "09/09/1996",
          "10/09/1996",
          "11/09/1996",
          "12/09/1996",
          "13/09/1996",
          "16/09/1996",
          "17/09/1996",
          "18/09/1996",
          "19/09/1996",
          "20/09/1996",
          "23/09/1996",
          "24/09/1996",
          "25/09/1996",
          "26/09/1996",
          "27/09/1996",
          "30/09/1996",
          "01/10/1996",
          "02/10/1996",
          "03/10/1996",
          "04/10/1996",
          "07/10/1996",
          "08/10/1996",
          "09/10/1996",
          "10/10/1996",
          "11/10/1996",
          "14/10/1996",
          "15/10/1996",
          "16/10/1996",
          "17/10/1996",
          "18/10/1996",
          "21/10/1996",
          "22/10/1996",
          "23/10/1996",
          "24/10/1996",
          "25/10/1996",
          "28/10/1996",
          "29/10/1996",
          "30/10/1996",
          "31/10/1996",
          "01/11/1996",
          "04/11/1996",
          "05/11/1996",
          "06/11/1996",
          "07/11/1996",
          "08/11/1996",
          "11/11/1996",
          "12/11/1996",
          "13/11/1996",
          "14/11/1996",
          "15/11/1996",
          "18/11/1996",
          "19/11/1996",
          "20/11/1996",
          "21/11/1996",
          "22/11/1996",
          "25/11/1996",
          "26/11/1996",
          "27/11/1996",
          "29/11/1996",
          "02/12/1996",
          "03/12/1996",
          "04/12/1996",
          "05/12/1996",
          "06/12/1996",
          "09/12/1996",
          "10/12/1996",
          "11/12/1996",
          "12/12/1996",
          "13/12/1996",
          "16/12/1996",
          "17/12/1996",
          "18/12/1996",
          "19/12/1996",
          "20/12/1996",
          "23/12/1996",
          "24/12/1996",
          "26/12/1996",
          "27/12/1996",
          "30/12/1996",
          "31/12/1996",
          "02/01/1997",
          "03/01/1997",
          "06/01/1997",
          "07/01/1997",
          "08/01/1997",
          "09/01/1997",
          "10/01/1997",
          "13/01/1997",
          "14/01/1997",
          "15/01/1997",
          "16/01/1997",
          "17/01/1997",
          "20/01/1997",
          "21/01/1997",
          "22/01/1997",
          "23/01/1997",
          "24/01/1997",
          "27/01/1997",
          "28/01/1997",
          "29/01/1997",
          "30/01/1997",
          "31/01/1997",
          "03/02/1997",
          "04/02/1997",
          "05/02/1997",
          "06/02/1997",
          "07/02/1997",
          "10/02/1997",
          "11/02/1997",
          "12/02/1997",
          "13/02/1997",
          "14/02/1997",
          "18/02/1997",
          "19/02/1997",
          "20/02/1997",
          "21/02/1997",
          "24/02/1997",
          "25/02/1997",
          "26/02/1997",
          "27/02/1997",
          "28/02/1997",
          "03/03/1997",
          "04/03/1997",
          "05/03/1997",
          "06/03/1997",
          "07/03/1997",
          "10/03/1997",
          "11/03/1997",
          "12/03/1997",
          "13/03/1997",
          "14/03/1997",
          "17/03/1997",
          "18/03/1997",
          "19/03/1997",
          "20/03/1997",
          "21/03/1997",
          "24/03/1997",
          "25/03/1997",
          "26/03/1997",
          "27/03/1997",
          "31/03/1997",
          "01/04/1997",
          "02/04/1997",
          "03/04/1997",
          "04/04/1997",
          "07/04/1997",
          "08/04/1997",
          "09/04/1997",
          "10/04/1997",
          "11/04/1997",
          "14/04/1997",
          "15/04/1997",
          "16/04/1997",
          "17/04/1997",
          "18/04/1997",
          "21/04/1997",
          "22/04/1997",
          "23/04/1997",
          "24/04/1997",
          "25/04/1997",
          "28/04/1997",
          "29/04/1997",
          "30/04/1997",
          "01/05/1997",
          "02/05/1997",
          "05/05/1997",
          "06/05/1997",
          "07/05/1997",
          "08/05/1997",
          "09/05/1997",
          "12/05/1997",
          "13/05/1997",
          "14/05/1997",
          "15/05/1997",
          "16/05/1997",
          "19/05/1997",
          "20/05/1997",
          "21/05/1997",
          "22/05/1997",
          "23/05/1997",
          "27/05/1997",
          "28/05/1997",
          "29/05/1997",
          "30/05/1997",
          "02/06/1997",
          "03/06/1997",
          "04/06/1997",
          "05/06/1997",
          "06/06/1997",
          "09/06/1997",
          "10/06/1997",
          "11/06/1997",
          "12/06/1997",
          "13/06/1997",
          "16/06/1997",
          "17/06/1997",
          "18/06/1997",
          "19/06/1997",
          "20/06/1997",
          "23/06/1997",
          "24/06/1997",
          "25/06/1997",
          "26/06/1997",
          "27/06/1997",
          "30/06/1997",
          "01/07/1997",
          "02/07/1997",
          "03/07/1997",
          "07/07/1997",
          "08/07/1997",
          "09/07/1997",
          "10/07/1997",
          "11/07/1997",
          "14/07/1997",
          "15/07/1997",
          "16/07/1997",
          "17/07/1997",
          "18/07/1997",
          "21/07/1997",
          "22/07/1997",
          "23/07/1997",
          "24/07/1997",
          "25/07/1997",
          "28/07/1997",
          "29/07/1997",
          "30/07/1997",
          "31/07/1997",
          "01/08/1997",
          "04/08/1997",
          "05/08/1997",
          "06/08/1997",
          "07/08/1997",
          "08/08/1997",
          "11/08/1997",
          "12/08/1997",
          "13/08/1997",
          "14/08/1997",
          "15/08/1997",
          "18/08/1997",
          "19/08/1997",
          "20/08/1997",
          "21/08/1997",
          "22/08/1997",
          "25/08/1997",
          "26/08/1997",
          "27/08/1997",
          "28/08/1997",
          "29/08/1997",
          "02/09/1997",
          "03/09/1997",
          "04/09/1997",
          "05/09/1997",
          "08/09/1997",
          "09/09/1997",
          "10/09/1997",
          "11/09/1997",
          "12/09/1997",
          "15/09/1997",
          "16/09/1997",
          "17/09/1997",
          "18/09/1997",
          "19/09/1997",
          "22/09/1997",
          "23/09/1997",
          "24/09/1997",
          "25/09/1997",
          "26/09/1997",
          "29/09/1997",
          "30/09/1997",
          "01/10/1997",
          "02/10/1997",
          "03/10/1997",
          "06/10/1997",
          "07/10/1997",
          "08/10/1997",
          "09/10/1997",
          "10/10/1997",
          "13/10/1997",
          "14/10/1997",
          "15/10/1997",
          "16/10/1997",
          "17/10/1997",
          "20/10/1997",
          "21/10/1997",
          "22/10/1997",
          "23/10/1997",
          "24/10/1997",
          "27/10/1997",
          "28/10/1997",
          "29/10/1997",
          "30/10/1997",
          "31/10/1997",
          "03/11/1997",
          "04/11/1997",
          "05/11/1997",
          "06/11/1997",
          "07/11/1997",
          "10/11/1997",
          "11/11/1997",
          "12/11/1997",
          "13/11/1997",
          "14/11/1997",
          "17/11/1997",
          "18/11/1997",
          "19/11/1997",
          "20/11/1997",
          "21/11/1997",
          "24/11/1997",
          "25/11/1997",
          "26/11/1997",
          "28/11/1997",
          "01/12/1997",
          "02/12/1997",
          "03/12/1997",
          "04/12/1997",
          "05/12/1997",
          "08/12/1997",
          "09/12/1997",
          "10/12/1997",
          "11/12/1997",
          "12/12/1997",
          "15/12/1997",
          "16/12/1997",
          "17/12/1997",
          "18/12/1997",
          "19/12/1997",
          "22/12/1997",
          "23/12/1997",
          "24/12/1997",
          "26/12/1997",
          "29/12/1997",
          "30/12/1997",
          "31/12/1997",
          "02/01/1998",
          "05/01/1998",
          "06/01/1998",
          "07/01/1998",
          "08/01/1998",
          "09/01/1998",
          "12/01/1998",
          "13/01/1998",
          "14/01/1998",
          "15/01/1998",
          "16/01/1998",
          "20/01/1998",
          "21/01/1998",
          "22/01/1998",
          "23/01/1998",
          "26/01/1998",
          "27/01/1998",
          "28/01/1998",
          "29/01/1998",
          "30/01/1998",
          "02/02/1998",
          "03/02/1998",
          "04/02/1998",
          "05/02/1998",
          "06/02/1998",
          "09/02/1998",
          "10/02/1998",
          "11/02/1998",
          "12/02/1998",
          "13/02/1998",
          "17/02/1998",
          "18/02/1998",
          "19/02/1998",
          "20/02/1998",
          "23/02/1998",
          "24/02/1998",
          "25/02/1998",
          "26/02/1998",
          "27/02/1998",
          "02/03/1998",
          "03/03/1998",
          "04/03/1998",
          "05/03/1998",
          "06/03/1998",
          "09/03/1998",
          "10/03/1998",
          "11/03/1998",
          "12/03/1998",
          "13/03/1998",
          "16/03/1998",
          "17/03/1998",
          "18/03/1998",
          "19/03/1998",
          "20/03/1998",
          "23/03/1998",
          "24/03/1998",
          "25/03/1998",
          "26/03/1998",
          "27/03/1998",
          "30/03/1998",
          "31/03/1998",
          "01/04/1998",
          "02/04/1998",
          "03/04/1998",
          "06/04/1998",
          "07/04/1998",
          "08/04/1998",
          "09/04/1998",
          "13/04/1998",
          "14/04/1998",
          "15/04/1998",
          "16/04/1998",
          "17/04/1998",
          "20/04/1998",
          "21/04/1998",
          "22/04/1998",
          "23/04/1998",
          "24/04/1998",
          "27/04/1998",
          "28/04/1998",
          "29/04/1998",
          "30/04/1998",
          "01/05/1998",
          "04/05/1998",
          "05/05/1998",
          "06/05/1998",
          "07/05/1998",
          "08/05/1998",
          "11/05/1998",
          "12/05/1998",
          "13/05/1998",
          "14/05/1998",
          "15/05/1998",
          "18/05/1998",
          "19/05/1998",
          "20/05/1998",
          "21/05/1998",
          "22/05/1998",
          "26/05/1998",
          "27/05/1998",
          "28/05/1998",
          "29/05/1998",
          "01/06/1998",
          "02/06/1998",
          "03/06/1998",
          "04/06/1998",
          "05/06/1998",
          "08/06/1998",
          "09/06/1998",
          "10/06/1998",
          "11/06/1998",
          "12/06/1998",
          "15/06/1998",
          "16/06/1998",
          "17/06/1998",
          "18/06/1998",
          "19/06/1998",
          "22/06/1998",
          "23/06/1998",
          "24/06/1998",
          "25/06/1998",
          "26/06/1998",
          "29/06/1998",
          "30/06/1998",
          "01/07/1998",
          "02/07/1998",
          "06/07/1998",
          "07/07/1998",
          "08/07/1998",
          "09/07/1998",
          "10/07/1998",
          "13/07/1998",
          "14/07/1998",
          "15/07/1998",
          "16/07/1998",
          "17/07/1998",
          "20/07/1998",
          "21/07/1998",
          "22/07/1998",
          "23/07/1998",
          "24/07/1998",
          "27/07/1998",
          "28/07/1998",
          "29/07/1998",
          "30/07/1998",
          "31/07/1998",
          "03/08/1998",
          "04/08/1998",
          "05/08/1998",
          "06/08/1998",
          "07/08/1998",
          "10/08/1998",
          "11/08/1998",
          "12/08/1998",
          "13/08/1998",
          "14/08/1998",
          "17/08/1998",
          "18/08/1998",
          "19/08/1998",
          "20/08/1998",
          "21/08/1998",
          "24/08/1998",
          "25/08/1998",
          "26/08/1998",
          "27/08/1998",
          "28/08/1998",
          "31/08/1998",
          "01/09/1998",
          "02/09/1998",
          "03/09/1998",
          "04/09/1998",
          "08/09/1998",
          "09/09/1998",
          "10/09/1998",
          "11/09/1998",
          "14/09/1998",
          "15/09/1998",
          "16/09/1998",
          "17/09/1998",
          "18/09/1998",
          "21/09/1998",
          "22/09/1998",
          "23/09/1998",
          "24/09/1998",
          "25/09/1998",
          "28/09/1998",
          "29/09/1998",
          "30/09/1998",
          "01/10/1998",
          "02/10/1998",
          "05/10/1998",
          "06/10/1998",
          "07/10/1998",
          "08/10/1998",
          "09/10/1998",
          "12/10/1998",
          "13/10/1998",
          "14/10/1998",
          "15/10/1998",
          "16/10/1998",
          "19/10/1998",
          "20/10/1998",
          "21/10/1998",
          "22/10/1998",
          "23/10/1998",
          "26/10/1998",
          "27/10/1998",
          "28/10/1998",
          "29/10/1998",
          "30/10/1998",
          "02/11/1998",
          "03/11/1998",
          "04/11/1998",
          "05/11/1998",
          "06/11/1998",
          "09/11/1998",
          "10/11/1998",
          "11/11/1998",
          "12/11/1998",
          "13/11/1998",
          "16/11/1998",
          "17/11/1998",
          "18/11/1998",
          "19/11/1998",
          "20/11/1998",
          "23/11/1998",
          "24/11/1998",
          "25/11/1998",
          "27/11/1998",
          "30/11/1998",
          "01/12/1998",
          "02/12/1998",
          "03/12/1998",
          "04/12/1998",
          "07/12/1998",
          "08/12/1998",
          "09/12/1998",
          "10/12/1998",
          "11/12/1998",
          "14/12/1998",
          "15/12/1998",
          "16/12/1998",
          "17/12/1998",
          "18/12/1998",
          "21/12/1998",
          "22/12/1998",
          "23/12/1998",
          "24/12/1998",
          "28/12/1998",
          "29/12/1998",
          "30/12/1998",
          "31/12/1998",
          "04/01/1999",
          "05/01/1999",
          "06/01/1999",
          "07/01/1999",
          "08/01/1999",
          "11/01/1999",
          "12/01/1999",
          "13/01/1999",
          "14/01/1999",
          "15/01/1999",
          "19/01/1999",
          "20/01/1999",
          "21/01/1999",
          "22/01/1999",
          "25/01/1999",
          "26/01/1999",
          "27/01/1999",
          "28/01/1999",
          "29/01/1999",
          "01/02/1999",
          "02/02/1999",
          "03/02/1999",
          "04/02/1999",
          "05/02/1999",
          "08/02/1999",
          "09/02/1999",
          "10/02/1999",
          "11/02/1999",
          "12/02/1999",
          "16/02/1999",
          "17/02/1999",
          "18/02/1999",
          "19/02/1999",
          "22/02/1999",
          "23/02/1999",
          "24/02/1999",
          "25/02/1999",
          "26/02/1999",
          "01/03/1999",
          "02/03/1999",
          "03/03/1999",
          "04/03/1999",
          "05/03/1999",
          "08/03/1999",
          "09/03/1999",
          "10/03/1999",
          "11/03/1999",
          "12/03/1999",
          "15/03/1999",
          "16/03/1999",
          "17/03/1999",
          "18/03/1999",
          "19/03/1999",
          "22/03/1999",
          "23/03/1999",
          "24/03/1999",
          "25/03/1999",
          "26/03/1999",
          "29/03/1999",
          "30/03/1999",
          "31/03/1999",
          "01/04/1999",
          "05/04/1999",
          "06/04/1999",
          "07/04/1999",
          "08/04/1999",
          "09/04/1999",
          "12/04/1999",
          "13/04/1999",
          "14/04/1999",
          "15/04/1999",
          "16/04/1999",
          "19/04/1999",
          "20/04/1999",
          "21/04/1999",
          "22/04/1999",
          "23/04/1999",
          "26/04/1999",
          "27/04/1999",
          "28/04/1999",
          "29/04/1999",
          "30/04/1999",
          "03/05/1999",
          "04/05/1999",
          "05/05/1999",
          "06/05/1999",
          "07/05/1999",
          "10/05/1999",
          "11/05/1999",
          "12/05/1999",
          "13/05/1999",
          "14/05/1999",
          "17/05/1999",
          "18/05/1999",
          "19/05/1999",
          "20/05/1999",
          "21/05/1999",
          "24/05/1999",
          "25/05/1999",
          "26/05/1999",
          "27/05/1999",
          "28/05/1999",
          "01/06/1999",
          "02/06/1999",
          "03/06/1999",
          "04/06/1999",
          "07/06/1999",
          "08/06/1999",
          "09/06/1999",
          "10/06/1999",
          "11/06/1999",
          "14/06/1999",
          "15/06/1999",
          "16/06/1999",
          "17/06/1999",
          "18/06/1999",
          "21/06/1999",
          "22/06/1999",
          "23/06/1999",
          "24/06/1999",
          "25/06/1999",
          "28/06/1999",
          "29/06/1999",
          "30/06/1999",
          "01/07/1999",
          "02/07/1999",
          "06/07/1999",
          "07/07/1999",
          "08/07/1999",
          "09/07/1999",
          "12/07/1999",
          "13/07/1999",
          "14/07/1999",
          "15/07/1999",
          "16/07/1999",
          "19/07/1999",
          "20/07/1999",
          "21/07/1999",
          "22/07/1999",
          "23/07/1999",
          "26/07/1999",
          "27/07/1999",
          "28/07/1999",
          "29/07/1999",
          "30/07/1999",
          "02/08/1999",
          "03/08/1999",
          "04/08/1999",
          "05/08/1999",
          "06/08/1999",
          "09/08/1999",
          "10/08/1999",
          "11/08/1999",
          "12/08/1999",
          "13/08/1999",
          "16/08/1999",
          "17/08/1999",
          "18/08/1999",
          "19/08/1999",
          "20/08/1999",
          "23/08/1999",
          "24/08/1999",
          "25/08/1999",
          "26/08/1999",
          "27/08/1999",
          "30/08/1999",
          "31/08/1999",
          "01/09/1999",
          "02/09/1999",
          "03/09/1999",
          "07/09/1999",
          "08/09/1999",
          "09/09/1999",
          "10/09/1999",
          "13/09/1999",
          "14/09/1999",
          "15/09/1999",
          "16/09/1999",
          "17/09/1999",
          "20/09/1999",
          "21/09/1999",
          "22/09/1999",
          "23/09/1999",
          "24/09/1999",
          "27/09/1999",
          "28/09/1999",
          "29/09/1999",
          "30/09/1999",
          "01/10/1999",
          "04/10/1999",
          "05/10/1999",
          "06/10/1999",
          "07/10/1999",
          "08/10/1999",
          "11/10/1999",
          "12/10/1999",
          "13/10/1999",
          "14/10/1999",
          "15/10/1999",
          "18/10/1999",
          "19/10/1999",
          "20/10/1999",
          "21/10/1999",
          "22/10/1999",
          "25/10/1999",
          "26/10/1999",
          "27/10/1999",
          "28/10/1999",
          "29/10/1999",
          "01/11/1999",
          "02/11/1999",
          "03/11/1999",
          "04/11/1999",
          "05/11/1999",
          "08/11/1999",
          "09/11/1999",
          "10/11/1999",
          "11/11/1999",
          "12/11/1999",
          "15/11/1999",
          "16/11/1999",
          "17/11/1999",
          "18/11/1999",
          "19/11/1999",
          "22/11/1999",
          "23/11/1999",
          "24/11/1999",
          "26/11/1999",
          "29/11/1999",
          "30/11/1999",
          "01/12/1999",
          "02/12/1999",
          "03/12/1999",
          "06/12/1999",
          "07/12/1999",
          "08/12/1999",
          "09/12/1999",
          "10/12/1999",
          "13/12/1999",
          "14/12/1999",
          "15/12/1999",
          "16/12/1999",
          "17/12/1999",
          "20/12/1999",
          "21/12/1999",
          "22/12/1999",
          "23/12/1999",
          "27/12/1999",
          "28/12/1999",
          "29/12/1999",
          "30/12/1999",
          "31/12/1999",
          "03/01/2000",
          "04/01/2000",
          "05/01/2000",
          "06/01/2000",
          "07/01/2000",
          "10/01/2000",
          "11/01/2000",
          "12/01/2000",
          "13/01/2000",
          "14/01/2000",
          "18/01/2000",
          "19/01/2000",
          "20/01/2000",
          "21/01/2000",
          "24/01/2000",
          "25/01/2000",
          "26/01/2000",
          "27/01/2000",
          "28/01/2000",
          "31/01/2000",
          "01/02/2000",
          "02/02/2000",
          "03/02/2000",
          "04/02/2000",
          "07/02/2000",
          "08/02/2000",
          "09/02/2000",
          "10/02/2000",
          "11/02/2000",
          "14/02/2000",
          "15/02/2000",
          "16/02/2000",
          "17/02/2000",
          "18/02/2000",
          "22/02/2000",
          "23/02/2000",
          "24/02/2000",
          "25/02/2000",
          "28/02/2000",
          "29/02/2000",
          "01/03/2000",
          "02/03/2000",
          "03/03/2000",
          "06/03/2000",
          "07/03/2000",
          "08/03/2000",
          "09/03/2000",
          "10/03/2000",
          "13/03/2000",
          "14/03/2000",
          "15/03/2000",
          "16/03/2000",
          "17/03/2000",
          "20/03/2000",
          "21/03/2000",
          "22/03/2000",
          "23/03/2000",
          "24/03/2000",
          "27/03/2000",
          "28/03/2000",
          "29/03/2000",
          "30/03/2000",
          "31/03/2000",
          "03/04/2000",
          "04/04/2000",
          "05/04/2000",
          "06/04/2000",
          "07/04/2000",
          "10/04/2000",
          "11/04/2000",
          "12/04/2000",
          "13/04/2000",
          "14/04/2000",
          "17/04/2000",
          "18/04/2000",
          "19/04/2000",
          "20/04/2000",
          "24/04/2000",
          "25/04/2000",
          "26/04/2000",
          "27/04/2000",
          "28/04/2000",
          "01/05/2000",
          "02/05/2000",
          "03/05/2000",
          "04/05/2000",
          "05/05/2000",
          "08/05/2000",
          "09/05/2000",
          "10/05/2000",
          "11/05/2000",
          "12/05/2000",
          "15/05/2000",
          "16/05/2000",
          "17/05/2000",
          "18/05/2000",
          "19/05/2000",
          "22/05/2000",
          "23/05/2000",
          "24/05/2000",
          "25/05/2000",
          "26/05/2000",
          "30/05/2000",
          "31/05/2000",
          "01/06/2000",
          "02/06/2000",
          "05/06/2000",
          "06/06/2000",
          "07/06/2000",
          "08/06/2000",
          "09/06/2000",
          "12/06/2000",
          "13/06/2000",
          "14/06/2000",
          "15/06/2000",
          "16/06/2000",
          "19/06/2000",
          "20/06/2000",
          "21/06/2000",
          "22/06/2000",
          "23/06/2000",
          "26/06/2000",
          "27/06/2000",
          "28/06/2000",
          "29/06/2000",
          "30/06/2000",
          "03/07/2000",
          "05/07/2000",
          "06/07/2000",
          "07/07/2000",
          "10/07/2000",
          "11/07/2000",
          "12/07/2000",
          "13/07/2000",
          "14/07/2000",
          "17/07/2000",
          "18/07/2000",
          "19/07/2000",
          "20/07/2000",
          "21/07/2000",
          "24/07/2000",
          "25/07/2000",
          "26/07/2000",
          "27/07/2000",
          "28/07/2000",
          "31/07/2000",
          "01/08/2000",
          "02/08/2000",
          "03/08/2000",
          "04/08/2000",
          "07/08/2000",
          "08/08/2000",
          "09/08/2000",
          "10/08/2000",
          "11/08/2000",
          "14/08/2000",
          "15/08/2000",
          "16/08/2000",
          "17/08/2000",
          "18/08/2000",
          "21/08/2000",
          "22/08/2000",
          "23/08/2000",
          "24/08/2000",
          "25/08/2000",
          "28/08/2000",
          "29/08/2000",
          "30/08/2000",
          "31/08/2000",
          "01/09/2000",
          "05/09/2000",
          "06/09/2000",
          "07/09/2000",
          "08/09/2000",
          "11/09/2000",
          "12/09/2000",
          "13/09/2000",
          "14/09/2000",
          "15/09/2000",
          "18/09/2000",
          "19/09/2000",
          "20/09/2000",
          "21/09/2000",
          "22/09/2000",
          "25/09/2000",
          "26/09/2000",
          "27/09/2000",
          "28/09/2000",
          "29/09/2000",
          "02/10/2000",
          "03/10/2000",
          "04/10/2000",
          "05/10/2000",
          "06/10/2000",
          "09/10/2000",
          "10/10/2000",
          "11/10/2000",
          "12/10/2000",
          "13/10/2000",
          "16/10/2000",
          "17/10/2000",
          "18/10/2000",
          "19/10/2000",
          "20/10/2000",
          "23/10/2000",
          "24/10/2000",
          "25/10/2000",
          "26/10/2000",
          "27/10/2000",
          "30/10/2000",
          "31/10/2000",
          "01/11/2000",
          "02/11/2000",
          "03/11/2000",
          "06/11/2000",
          "07/11/2000",
          "08/11/2000",
          "09/11/2000",
          "10/11/2000",
          "13/11/2000",
          "14/11/2000",
          "15/11/2000",
          "16/11/2000",
          "17/11/2000",
          "20/11/2000",
          "21/11/2000",
          "22/11/2000",
          "24/11/2000",
          "27/11/2000",
          "28/11/2000",
          "29/11/2000",
          "30/11/2000",
          "01/12/2000",
          "04/12/2000",
          "05/12/2000",
          "06/12/2000",
          "07/12/2000",
          "08/12/2000",
          "11/12/2000",
          "12/12/2000",
          "13/12/2000",
          "14/12/2000",
          "15/12/2000",
          "18/12/2000",
          "19/12/2000",
          "20/12/2000",
          "21/12/2000",
          "22/12/2000",
          "26/12/2000",
          "27/12/2000",
          "28/12/2000",
          "29/12/2000",
          "02/01/2001",
          "03/01/2001",
          "04/01/2001",
          "05/01/2001",
          "08/01/2001",
          "09/01/2001",
          "10/01/2001",
          "11/01/2001",
          "12/01/2001",
          "16/01/2001",
          "17/01/2001",
          "18/01/2001",
          "19/01/2001",
          "22/01/2001",
          "23/01/2001",
          "24/01/2001",
          "25/01/2001",
          "26/01/2001",
          "29/01/2001",
          "30/01/2001",
          "31/01/2001",
          "01/02/2001",
          "02/02/2001",
          "05/02/2001",
          "06/02/2001",
          "07/02/2001",
          "08/02/2001",
          "09/02/2001",
          "12/02/2001",
          "13/02/2001",
          "14/02/2001",
          "15/02/2001",
          "16/02/2001",
          "20/02/2001",
          "21/02/2001",
          "22/02/2001",
          "23/02/2001",
          "26/02/2001",
          "27/02/2001",
          "28/02/2001",
          "01/03/2001",
          "02/03/2001",
          "05/03/2001",
          "06/03/2001",
          "07/03/2001",
          "08/03/2001",
          "09/03/2001",
          "12/03/2001",
          "13/03/2001",
          "14/03/2001",
          "15/03/2001",
          "16/03/2001",
          "19/03/2001",
          "20/03/2001",
          "21/03/2001",
          "22/03/2001",
          "23/03/2001",
          "26/03/2001",
          "27/03/2001",
          "28/03/2001",
          "29/03/2001",
          "30/03/2001",
          "02/04/2001",
          "03/04/2001",
          "04/04/2001",
          "05/04/2001",
          "06/04/2001",
          "09/04/2001",
          "10/04/2001",
          "11/04/2001",
          "12/04/2001",
          "16/04/2001",
          "17/04/2001",
          "18/04/2001",
          "19/04/2001",
          "20/04/2001",
          "23/04/2001",
          "24/04/2001",
          "25/04/2001",
          "26/04/2001",
          "27/04/2001",
          "30/04/2001",
          "01/05/2001",
          "02/05/2001",
          "03/05/2001",
          "04/05/2001",
          "07/05/2001",
          "08/05/2001",
          "09/05/2001",
          "10/05/2001",
          "11/05/2001",
          "14/05/2001",
          "15/05/2001",
          "16/05/2001",
          "17/05/2001",
          "18/05/2001",
          "21/05/2001",
          "22/05/2001",
          "23/05/2001",
          "24/05/2001",
          "25/05/2001",
          "29/05/2001",
          "30/05/2001",
          "31/05/2001",
          "01/06/2001",
          "04/06/2001",
          "05/06/2001",
          "06/06/2001",
          "07/06/2001",
          "08/06/2001",
          "11/06/2001",
          "12/06/2001",
          "13/06/2001",
          "14/06/2001",
          "15/06/2001",
          "18/06/2001",
          "19/06/2001",
          "20/06/2001",
          "21/06/2001",
          "22/06/2001",
          "25/06/2001",
          "26/06/2001",
          "27/06/2001",
          "28/06/2001",
          "29/06/2001",
          "02/07/2001",
          "03/07/2001",
          "05/07/2001",
          "06/07/2001",
          "09/07/2001",
          "10/07/2001",
          "11/07/2001",
          "12/07/2001",
          "13/07/2001",
          "16/07/2001",
          "17/07/2001",
          "18/07/2001",
          "19/07/2001",
          "20/07/2001",
          "23/07/2001",
          "24/07/2001",
          "25/07/2001",
          "26/07/2001",
          "27/07/2001",
          "30/07/2001",
          "31/07/2001",
          "01/08/2001",
          "02/08/2001",
          "03/08/2001",
          "06/08/2001",
          "07/08/2001",
          "08/08/2001",
          "09/08/2001",
          "10/08/2001",
          "13/08/2001",
          "14/08/2001",
          "15/08/2001",
          "16/08/2001",
          "17/08/2001",
          "20/08/2001",
          "21/08/2001",
          "22/08/2001",
          "23/08/2001",
          "24/08/2001",
          "27/08/2001",
          "28/08/2001",
          "29/08/2001",
          "30/08/2001",
          "31/08/2001",
          "04/09/2001",
          "05/09/2001",
          "06/09/2001",
          "07/09/2001",
          "10/09/2001",
          "17/09/2001",
          "18/09/2001",
          "19/09/2001",
          "20/09/2001",
          "21/09/2001",
          "24/09/2001",
          "25/09/2001",
          "26/09/2001",
          "27/09/2001",
          "28/09/2001",
          "01/10/2001",
          "02/10/2001",
          "03/10/2001",
          "04/10/2001",
          "05/10/2001",
          "08/10/2001",
          "09/10/2001",
          "10/10/2001",
          "11/10/2001",
          "12/10/2001",
          "15/10/2001",
          "16/10/2001",
          "17/10/2001",
          "18/10/2001",
          "19/10/2001",
          "22/10/2001",
          "23/10/2001",
          "24/10/2001",
          "25/10/2001",
          "26/10/2001",
          "29/10/2001",
          "30/10/2001",
          "31/10/2001",
          "01/11/2001",
          "02/11/2001",
          "05/11/2001",
          "06/11/2001",
          "07/11/2001",
          "08/11/2001",
          "09/11/2001",
          "12/11/2001",
          "13/11/2001",
          "14/11/2001",
          "15/11/2001",
          "16/11/2001",
          "19/11/2001",
          "20/11/2001",
          "21/11/2001",
          "23/11/2001",
          "26/11/2001",
          "27/11/2001",
          "28/11/2001",
          "29/11/2001",
          "30/11/2001",
          "03/12/2001",
          "04/12/2001",
          "05/12/2001",
          "06/12/2001",
          "07/12/2001",
          "10/12/2001",
          "11/12/2001",
          "12/12/2001",
          "13/12/2001",
          "14/12/2001",
          "17/12/2001",
          "18/12/2001",
          "19/12/2001",
          "20/12/2001",
          "21/12/2001",
          "24/12/2001",
          "26/12/2001",
          "27/12/2001",
          "28/12/2001",
          "31/12/2001",
          "02/01/2002",
          "03/01/2002",
          "04/01/2002",
          "07/01/2002",
          "08/01/2002",
          "09/01/2002",
          "10/01/2002",
          "11/01/2002",
          "14/01/2002",
          "15/01/2002",
          "16/01/2002",
          "17/01/2002",
          "18/01/2002",
          "22/01/2002",
          "23/01/2002",
          "24/01/2002",
          "25/01/2002",
          "28/01/2002",
          "29/01/2002",
          "30/01/2002",
          "31/01/2002",
          "01/02/2002",
          "04/02/2002",
          "05/02/2002",
          "06/02/2002",
          "07/02/2002",
          "08/02/2002",
          "11/02/2002",
          "12/02/2002",
          "13/02/2002",
          "14/02/2002",
          "15/02/2002",
          "19/02/2002",
          "20/02/2002",
          "21/02/2002",
          "22/02/2002",
          "25/02/2002",
          "26/02/2002",
          "27/02/2002",
          "28/02/2002",
          "01/03/2002",
          "04/03/2002",
          "05/03/2002",
          "06/03/2002",
          "07/03/2002",
          "08/03/2002",
          "11/03/2002",
          "12/03/2002",
          "13/03/2002",
          "14/03/2002",
          "15/03/2002",
          "18/03/2002",
          "19/03/2002",
          "20/03/2002",
          "21/03/2002",
          "22/03/2002",
          "25/03/2002",
          "26/03/2002",
          "27/03/2002",
          "28/03/2002",
          "01/04/2002",
          "02/04/2002",
          "03/04/2002",
          "04/04/2002",
          "05/04/2002",
          "08/04/2002",
          "09/04/2002",
          "10/04/2002",
          "11/04/2002",
          "12/04/2002",
          "15/04/2002",
          "16/04/2002",
          "17/04/2002",
          "18/04/2002",
          "19/04/2002",
          "22/04/2002",
          "23/04/2002",
          "24/04/2002",
          "25/04/2002",
          "26/04/2002",
          "29/04/2002",
          "30/04/2002",
          "01/05/2002",
          "02/05/2002",
          "03/05/2002",
          "06/05/2002",
          "07/05/2002",
          "08/05/2002",
          "09/05/2002",
          "10/05/2002",
          "13/05/2002",
          "14/05/2002",
          "15/05/2002",
          "16/05/2002",
          "17/05/2002",
          "20/05/2002",
          "21/05/2002",
          "22/05/2002",
          "23/05/2002",
          "24/05/2002",
          "28/05/2002",
          "29/05/2002",
          "30/05/2002",
          "31/05/2002",
          "03/06/2002",
          "04/06/2002",
          "05/06/2002",
          "06/06/2002",
          "07/06/2002",
          "10/06/2002",
          "11/06/2002",
          "12/06/2002",
          "13/06/2002",
          "14/06/2002",
          "17/06/2002",
          "18/06/2002",
          "19/06/2002",
          "20/06/2002",
          "21/06/2002",
          "24/06/2002",
          "25/06/2002",
          "26/06/2002",
          "27/06/2002",
          "28/06/2002",
          "01/07/2002",
          "02/07/2002",
          "03/07/2002",
          "05/07/2002",
          "08/07/2002",
          "09/07/2002",
          "10/07/2002",
          "11/07/2002",
          "12/07/2002",
          "15/07/2002",
          "16/07/2002",
          "17/07/2002",
          "18/07/2002",
          "19/07/2002",
          "22/07/2002",
          "23/07/2002",
          "24/07/2002",
          "25/07/2002",
          "26/07/2002",
          "29/07/2002",
          "30/07/2002",
          "31/07/2002",
          "01/08/2002",
          "02/08/2002",
          "05/08/2002",
          "06/08/2002",
          "07/08/2002",
          "08/08/2002",
          "09/08/2002",
          "12/08/2002",
          "13/08/2002",
          "14/08/2002",
          "15/08/2002",
          "16/08/2002",
          "19/08/2002",
          "20/08/2002",
          "21/08/2002",
          "22/08/2002",
          "23/08/2002",
          "26/08/2002",
          "27/08/2002",
          "28/08/2002",
          "29/08/2002",
          "30/08/2002",
          "03/09/2002",
          "04/09/2002",
          "05/09/2002",
          "06/09/2002",
          "09/09/2002",
          "10/09/2002",
          "11/09/2002",
          "12/09/2002",
          "13/09/2002",
          "16/09/2002",
          "17/09/2002",
          "18/09/2002",
          "19/09/2002",
          "20/09/2002",
          "23/09/2002",
          "24/09/2002",
          "25/09/2002",
          "26/09/2002",
          "27/09/2002",
          "30/09/2002",
          "01/10/2002",
          "02/10/2002",
          "03/10/2002",
          "04/10/2002",
          "07/10/2002",
          "08/10/2002",
          "09/10/2002",
          "10/10/2002",
          "11/10/2002",
          "14/10/2002",
          "15/10/2002",
          "16/10/2002",
          "17/10/2002",
          "18/10/2002",
          "21/10/2002",
          "22/10/2002",
          "23/10/2002",
          "24/10/2002",
          "25/10/2002",
          "28/10/2002",
          "29/10/2002",
          "30/10/2002",
          "31/10/2002",
          "01/11/2002",
          "04/11/2002",
          "05/11/2002",
          "06/11/2002",
          "07/11/2002",
          "08/11/2002",
          "11/11/2002",
          "12/11/2002",
          "13/11/2002",
          "14/11/2002",
          "15/11/2002",
          "18/11/2002",
          "19/11/2002",
          "20/11/2002",
          "21/11/2002",
          "22/11/2002",
          "25/11/2002",
          "26/11/2002",
          "27/11/2002",
          "29/11/2002",
          "02/12/2002",
          "03/12/2002",
          "04/12/2002",
          "05/12/2002",
          "06/12/2002",
          "09/12/2002",
          "10/12/2002",
          "11/12/2002",
          "12/12/2002",
          "13/12/2002",
          "16/12/2002",
          "17/12/2002",
          "18/12/2002",
          "19/12/2002",
          "20/12/2002",
          "23/12/2002",
          "24/12/2002",
          "26/12/2002",
          "27/12/2002",
          "30/12/2002",
          "31/12/2002",
          "02/01/2003",
          "03/01/2003",
          "06/01/2003",
          "07/01/2003",
          "08/01/2003",
          "09/01/2003",
          "10/01/2003",
          "13/01/2003",
          "14/01/2003",
          "15/01/2003",
          "16/01/2003",
          "17/01/2003",
          "21/01/2003",
          "22/01/2003",
          "23/01/2003",
          "24/01/2003",
          "27/01/2003",
          "28/01/2003",
          "29/01/2003",
          "30/01/2003",
          "31/01/2003",
          "03/02/2003",
          "04/02/2003",
          "05/02/2003",
          "06/02/2003",
          "07/02/2003",
          "10/02/2003",
          "11/02/2003",
          "12/02/2003",
          "13/02/2003",
          "14/02/2003",
          "18/02/2003",
          "19/02/2003",
          "20/02/2003",
          "21/02/2003",
          "24/02/2003",
          "25/02/2003",
          "26/02/2003",
          "27/02/2003",
          "28/02/2003",
          "03/03/2003",
          "04/03/2003",
          "05/03/2003",
          "06/03/2003",
          "07/03/2003",
          "10/03/2003",
          "11/03/2003",
          "12/03/2003",
          "13/03/2003",
          "14/03/2003",
          "17/03/2003",
          "18/03/2003",
          "19/03/2003",
          "20/03/2003",
          "21/03/2003",
          "24/03/2003",
          "25/03/2003",
          "26/03/2003",
          "27/03/2003",
          "28/03/2003",
          "31/03/2003",
          "01/04/2003",
          "02/04/2003",
          "03/04/2003",
          "04/04/2003",
          "07/04/2003",
          "08/04/2003",
          "09/04/2003",
          "10/04/2003",
          "11/04/2003",
          "14/04/2003",
          "15/04/2003",
          "16/04/2003",
          "17/04/2003",
          "21/04/2003",
          "22/04/2003",
          "23/04/2003",
          "24/04/2003",
          "25/04/2003",
          "28/04/2003",
          "29/04/2003",
          "30/04/2003",
          "01/05/2003",
          "02/05/2003",
          "05/05/2003",
          "06/05/2003",
          "07/05/2003",
          "08/05/2003",
          "09/05/2003",
          "12/05/2003",
          "13/05/2003",
          "14/05/2003",
          "15/05/2003",
          "16/05/2003",
          "19/05/2003",
          "20/05/2003",
          "21/05/2003",
          "22/05/2003",
          "23/05/2003",
          "27/05/2003",
          "28/05/2003",
          "29/05/2003",
          "30/05/2003",
          "02/06/2003",
          "03/06/2003",
          "04/06/2003",
          "05/06/2003",
          "06/06/2003",
          "09/06/2003",
          "10/06/2003",
          "11/06/2003",
          "12/06/2003",
          "13/06/2003",
          "16/06/2003",
          "17/06/2003",
          "18/06/2003",
          "19/06/2003",
          "20/06/2003",
          "23/06/2003",
          "24/06/2003",
          "25/06/2003",
          "26/06/2003",
          "27/06/2003",
          "30/06/2003",
          "01/07/2003",
          "02/07/2003",
          "03/07/2003",
          "07/07/2003",
          "08/07/2003",
          "09/07/2003",
          "10/07/2003",
          "11/07/2003",
          "14/07/2003",
          "15/07/2003",
          "16/07/2003",
          "17/07/2003",
          "18/07/2003",
          "21/07/2003",
          "22/07/2003",
          "23/07/2003",
          "24/07/2003",
          "25/07/2003",
          "28/07/2003",
          "29/07/2003",
          "30/07/2003",
          "31/07/2003",
          "01/08/2003",
          "04/08/2003",
          "05/08/2003",
          "06/08/2003",
          "07/08/2003",
          "08/08/2003",
          "11/08/2003",
          "12/08/2003",
          "13/08/2003",
          "14/08/2003",
          "15/08/2003",
          "18/08/2003",
          "19/08/2003",
          "20/08/2003",
          "21/08/2003",
          "22/08/2003",
          "25/08/2003",
          "26/08/2003",
          "27/08/2003",
          "28/08/2003",
          "29/08/2003",
          "02/09/2003",
          "03/09/2003",
          "04/09/2003",
          "05/09/2003",
          "08/09/2003",
          "09/09/2003",
          "10/09/2003",
          "11/09/2003",
          "12/09/2003",
          "15/09/2003",
          "16/09/2003",
          "17/09/2003",
          "18/09/2003",
          "19/09/2003",
          "22/09/2003",
          "23/09/2003",
          "24/09/2003",
          "25/09/2003",
          "26/09/2003",
          "29/09/2003",
          "30/09/2003",
          "01/10/2003",
          "02/10/2003",
          "03/10/2003",
          "06/10/2003",
          "07/10/2003",
          "08/10/2003",
          "09/10/2003",
          "10/10/2003",
          "13/10/2003",
          "14/10/2003",
          "15/10/2003",
          "16/10/2003",
          "17/10/2003",
          "20/10/2003",
          "21/10/2003",
          "22/10/2003",
          "23/10/2003",
          "24/10/2003",
          "27/10/2003",
          "28/10/2003",
          "29/10/2003",
          "30/10/2003",
          "31/10/2003",
          "03/11/2003",
          "04/11/2003",
          "05/11/2003",
          "06/11/2003",
          "07/11/2003",
          "10/11/2003",
          "11/11/2003",
          "12/11/2003",
          "13/11/2003",
          "14/11/2003",
          "17/11/2003",
          "18/11/2003",
          "19/11/2003",
          "20/11/2003",
          "21/11/2003",
          "24/11/2003",
          "25/11/2003",
          "26/11/2003",
          "28/11/2003",
          "01/12/2003",
          "02/12/2003",
          "03/12/2003",
          "04/12/2003",
          "05/12/2003",
          "08/12/2003",
          "09/12/2003",
          "10/12/2003",
          "11/12/2003",
          "12/12/2003",
          "15/12/2003",
          "16/12/2003",
          "17/12/2003",
          "18/12/2003",
          "19/12/2003",
          "22/12/2003",
          "23/12/2003",
          "24/12/2003",
          "26/12/2003",
          "29/12/2003",
          "30/12/2003",
          "31/12/2003",
          "02/01/2004",
          "05/01/2004",
          "06/01/2004",
          "07/01/2004",
          "08/01/2004",
          "09/01/2004",
          "12/01/2004",
          "13/01/2004",
          "14/01/2004",
          "15/01/2004",
          "16/01/2004",
          "20/01/2004",
          "21/01/2004",
          "22/01/2004",
          "23/01/2004",
          "26/01/2004",
          "27/01/2004",
          "28/01/2004",
          "29/01/2004",
          "30/01/2004",
          "02/02/2004",
          "03/02/2004",
          "04/02/2004",
          "05/02/2004",
          "06/02/2004",
          "09/02/2004",
          "10/02/2004",
          "11/02/2004",
          "12/02/2004",
          "13/02/2004",
          "17/02/2004",
          "18/02/2004",
          "19/02/2004",
          "20/02/2004",
          "23/02/2004",
          "24/02/2004",
          "25/02/2004",
          "26/02/2004",
          "27/02/2004",
          "01/03/2004",
          "02/03/2004",
          "03/03/2004",
          "04/03/2004",
          "05/03/2004",
          "08/03/2004",
          "09/03/2004",
          "10/03/2004",
          "11/03/2004",
          "12/03/2004",
          "15/03/2004",
          "16/03/2004",
          "17/03/2004",
          "18/03/2004",
          "19/03/2004",
          "22/03/2004",
          "23/03/2004",
          "24/03/2004",
          "25/03/2004",
          "26/03/2004",
          "29/03/2004",
          "30/03/2004",
          "31/03/2004",
          "01/04/2004",
          "02/04/2004",
          "05/04/2004",
          "06/04/2004",
          "07/04/2004",
          "08/04/2004",
          "12/04/2004",
          "13/04/2004",
          "14/04/2004",
          "15/04/2004",
          "16/04/2004",
          "19/04/2004",
          "20/04/2004",
          "21/04/2004",
          "22/04/2004",
          "23/04/2004",
          "26/04/2004",
          "27/04/2004",
          "28/04/2004",
          "29/04/2004",
          "30/04/2004",
          "03/05/2004",
          "04/05/2004",
          "05/05/2004",
          "06/05/2004",
          "07/05/2004",
          "10/05/2004",
          "11/05/2004",
          "12/05/2004",
          "13/05/2004",
          "14/05/2004",
          "17/05/2004",
          "18/05/2004",
          "19/05/2004",
          "20/05/2004",
          "21/05/2004",
          "24/05/2004",
          "25/05/2004",
          "26/05/2004",
          "27/05/2004",
          "28/05/2004",
          "01/06/2004",
          "02/06/2004",
          "03/06/2004",
          "04/06/2004",
          "07/06/2004",
          "08/06/2004",
          "09/06/2004",
          "10/06/2004",
          "14/06/2004",
          "15/06/2004",
          "16/06/2004",
          "17/06/2004",
          "18/06/2004",
          "21/06/2004",
          "22/06/2004",
          "23/06/2004",
          "24/06/2004",
          "25/06/2004",
          "28/06/2004",
          "29/06/2004",
          "30/06/2004",
          "01/07/2004",
          "02/07/2004",
          "06/07/2004",
          "07/07/2004",
          "08/07/2004",
          "09/07/2004",
          "12/07/2004",
          "13/07/2004",
          "14/07/2004",
          "15/07/2004",
          "16/07/2004",
          "19/07/2004",
          "20/07/2004",
          "21/07/2004",
          "22/07/2004",
          "23/07/2004",
          "26/07/2004",
          "27/07/2004",
          "28/07/2004",
          "29/07/2004",
          "30/07/2004",
          "02/08/2004",
          "03/08/2004",
          "04/08/2004",
          "05/08/2004",
          "06/08/2004",
          "09/08/2004",
          "10/08/2004",
          "11/08/2004",
          "12/08/2004",
          "13/08/2004",
          "16/08/2004",
          "17/08/2004",
          "18/08/2004",
          "19/08/2004",
          "20/08/2004",
          "23/08/2004",
          "24/08/2004",
          "25/08/2004",
          "26/08/2004",
          "27/08/2004",
          "30/08/2004",
          "31/08/2004",
          "01/09/2004",
          "02/09/2004",
          "03/09/2004",
          "07/09/2004",
          "08/09/2004",
          "09/09/2004",
          "10/09/2004",
          "13/09/2004",
          "14/09/2004",
          "15/09/2004",
          "16/09/2004",
          "17/09/2004",
          "20/09/2004",
          "21/09/2004",
          "22/09/2004",
          "23/09/2004",
          "24/09/2004",
          "27/09/2004",
          "28/09/2004",
          "29/09/2004",
          "30/09/2004",
          "01/10/2004",
          "04/10/2004",
          "05/10/2004",
          "06/10/2004",
          "07/10/2004",
          "08/10/2004",
          "11/10/2004",
          "12/10/2004",
          "13/10/2004",
          "14/10/2004",
          "15/10/2004",
          "18/10/2004",
          "19/10/2004",
          "20/10/2004",
          "21/10/2004",
          "22/10/2004",
          "25/10/2004",
          "26/10/2004",
          "27/10/2004",
          "28/10/2004",
          "29/10/2004",
          "01/11/2004",
          "02/11/2004",
          "03/11/2004",
          "04/11/2004",
          "05/11/2004",
          "08/11/2004",
          "09/11/2004",
          "10/11/2004",
          "11/11/2004",
          "12/11/2004",
          "15/11/2004",
          "16/11/2004",
          "17/11/2004",
          "18/11/2004",
          "19/11/2004",
          "22/11/2004",
          "23/11/2004",
          "24/11/2004",
          "26/11/2004",
          "29/11/2004",
          "30/11/2004",
          "01/12/2004",
          "02/12/2004",
          "03/12/2004",
          "06/12/2004",
          "07/12/2004",
          "08/12/2004",
          "09/12/2004",
          "10/12/2004",
          "13/12/2004",
          "14/12/2004",
          "15/12/2004",
          "16/12/2004",
          "17/12/2004",
          "20/12/2004",
          "21/12/2004",
          "22/12/2004",
          "23/12/2004",
          "27/12/2004",
          "28/12/2004",
          "29/12/2004",
          "30/12/2004",
          "31/12/2004",
          "03/01/2005",
          "04/01/2005",
          "05/01/2005",
          "06/01/2005",
          "07/01/2005",
          "10/01/2005",
          "11/01/2005",
          "12/01/2005",
          "13/01/2005",
          "14/01/2005",
          "18/01/2005",
          "19/01/2005",
          "20/01/2005",
          "21/01/2005",
          "24/01/2005",
          "25/01/2005",
          "26/01/2005",
          "27/01/2005",
          "28/01/2005",
          "31/01/2005",
          "01/02/2005",
          "02/02/2005",
          "03/02/2005",
          "04/02/2005",
          "07/02/2005",
          "08/02/2005",
          "09/02/2005",
          "10/02/2005",
          "11/02/2005",
          "14/02/2005",
          "15/02/2005",
          "16/02/2005",
          "17/02/2005",
          "18/02/2005",
          "22/02/2005",
          "23/02/2005",
          "24/02/2005",
          "25/02/2005",
          "28/02/2005",
          "01/03/2005",
          "02/03/2005",
          "03/03/2005",
          "04/03/2005",
          "07/03/2005",
          "08/03/2005",
          "09/03/2005",
          "10/03/2005",
          "11/03/2005",
          "14/03/2005",
          "15/03/2005",
          "16/03/2005",
          "17/03/2005",
          "18/03/2005",
          "21/03/2005",
          "22/03/2005",
          "23/03/2005",
          "24/03/2005",
          "28/03/2005",
          "29/03/2005",
          "30/03/2005",
          "31/03/2005",
          "01/04/2005",
          "04/04/2005",
          "05/04/2005",
          "06/04/2005",
          "07/04/2005",
          "08/04/2005",
          "11/04/2005",
          "12/04/2005",
          "13/04/2005",
          "14/04/2005",
          "15/04/2005",
          "18/04/2005",
          "19/04/2005",
          "20/04/2005",
          "21/04/2005",
          "22/04/2005",
          "25/04/2005",
          "26/04/2005",
          "27/04/2005",
          "28/04/2005",
          "29/04/2005",
          "02/05/2005",
          "03/05/2005",
          "04/05/2005",
          "05/05/2005",
          "06/05/2005",
          "09/05/2005",
          "10/05/2005",
          "11/05/2005",
          "12/05/2005",
          "13/05/2005",
          "16/05/2005",
          "17/05/2005",
          "18/05/2005",
          "19/05/2005",
          "20/05/2005",
          "23/05/2005",
          "24/05/2005",
          "25/05/2005",
          "26/05/2005",
          "27/05/2005",
          "31/05/2005",
          "01/06/2005",
          "02/06/2005",
          "03/06/2005",
          "06/06/2005",
          "07/06/2005",
          "08/06/2005",
          "09/06/2005",
          "10/06/2005",
          "13/06/2005",
          "14/06/2005",
          "15/06/2005",
          "16/06/2005",
          "17/06/2005",
          "20/06/2005",
          "21/06/2005",
          "22/06/2005",
          "23/06/2005",
          "24/06/2005",
          "27/06/2005",
          "28/06/2005",
          "29/06/2005",
          "30/06/2005",
          "01/07/2005",
          "05/07/2005",
          "06/07/2005",
          "07/07/2005",
          "08/07/2005",
          "11/07/2005",
          "12/07/2005",
          "13/07/2005",
          "14/07/2005",
          "15/07/2005",
          "18/07/2005",
          "19/07/2005",
          "20/07/2005",
          "21/07/2005",
          "22/07/2005",
          "25/07/2005",
          "26/07/2005",
          "27/07/2005",
          "28/07/2005",
          "29/07/2005",
          "01/08/2005",
          "02/08/2005",
          "03/08/2005",
          "04/08/2005",
          "05/08/2005",
          "08/08/2005",
          "09/08/2005",
          "10/08/2005",
          "11/08/2005",
          "12/08/2005",
          "15/08/2005",
          "16/08/2005",
          "17/08/2005",
          "18/08/2005",
          "19/08/2005",
          "22/08/2005",
          "23/08/2005",
          "24/08/2005",
          "25/08/2005",
          "26/08/2005",
          "29/08/2005",
          "30/08/2005",
          "31/08/2005",
          "01/09/2005",
          "02/09/2005",
          "06/09/2005",
          "07/09/2005",
          "08/09/2005",
          "09/09/2005",
          "12/09/2005",
          "13/09/2005",
          "14/09/2005",
          "15/09/2005",
          "16/09/2005",
          "19/09/2005",
          "20/09/2005",
          "21/09/2005",
          "22/09/2005",
          "23/09/2005",
          "26/09/2005",
          "27/09/2005",
          "28/09/2005",
          "29/09/2005",
          "30/09/2005",
          "03/10/2005",
          "04/10/2005",
          "05/10/2005",
          "06/10/2005",
          "07/10/2005",
          "10/10/2005",
          "11/10/2005",
          "12/10/2005",
          "13/10/2005",
          "14/10/2005",
          "17/10/2005",
          "18/10/2005",
          "19/10/2005",
          "20/10/2005",
          "21/10/2005",
          "24/10/2005",
          "25/10/2005",
          "26/10/2005",
          "27/10/2005",
          "28/10/2005",
          "31/10/2005",
          "01/11/2005",
          "02/11/2005",
          "03/11/2005",
          "04/11/2005",
          "07/11/2005",
          "08/11/2005",
          "09/11/2005",
          "10/11/2005",
          "11/11/2005",
          "14/11/2005",
          "15/11/2005",
          "16/11/2005",
          "17/11/2005",
          "18/11/2005",
          "21/11/2005",
          "22/11/2005",
          "23/11/2005",
          "25/11/2005",
          "28/11/2005",
          "29/11/2005",
          "30/11/2005",
          "01/12/2005",
          "02/12/2005",
          "05/12/2005",
          "06/12/2005",
          "07/12/2005",
          "08/12/2005",
          "09/12/2005",
          "12/12/2005",
          "13/12/2005",
          "14/12/2005",
          "15/12/2005",
          "16/12/2005",
          "19/12/2005",
          "20/12/2005",
          "21/12/2005",
          "22/12/2005",
          "23/12/2005",
          "27/12/2005",
          "28/12/2005",
          "29/12/2005",
          "30/12/2005",
          "03/01/2006",
          "04/01/2006",
          "05/01/2006",
          "06/01/2006",
          "09/01/2006",
          "10/01/2006",
          "11/01/2006",
          "12/01/2006",
          "13/01/2006",
          "17/01/2006",
          "18/01/2006",
          "19/01/2006",
          "20/01/2006",
          "23/01/2006",
          "24/01/2006",
          "25/01/2006",
          "26/01/2006",
          "27/01/2006",
          "30/01/2006",
          "31/01/2006",
          "01/02/2006",
          "02/02/2006",
          "03/02/2006",
          "06/02/2006",
          "07/02/2006",
          "08/02/2006",
          "09/02/2006",
          "10/02/2006",
          "13/02/2006",
          "14/02/2006",
          "15/02/2006",
          "16/02/2006",
          "17/02/2006",
          "21/02/2006",
          "22/02/2006",
          "23/02/2006",
          "24/02/2006",
          "27/02/2006",
          "28/02/2006",
          "01/03/2006",
          "02/03/2006",
          "03/03/2006",
          "06/03/2006",
          "07/03/2006",
          "08/03/2006",
          "09/03/2006",
          "10/03/2006",
          "13/03/2006",
          "14/03/2006",
          "15/03/2006",
          "16/03/2006",
          "17/03/2006",
          "20/03/2006",
          "21/03/2006",
          "22/03/2006",
          "23/03/2006",
          "24/03/2006",
          "27/03/2006",
          "28/03/2006",
          "29/03/2006",
          "30/03/2006",
          "31/03/2006",
          "03/04/2006",
          "04/04/2006",
          "05/04/2006",
          "06/04/2006",
          "07/04/2006",
          "10/04/2006",
          "11/04/2006",
          "12/04/2006",
          "13/04/2006",
          "17/04/2006",
          "18/04/2006",
          "19/04/2006",
          "20/04/2006",
          "21/04/2006",
          "24/04/2006",
          "25/04/2006",
          "26/04/2006",
          "27/04/2006",
          "28/04/2006",
          "01/05/2006",
          "02/05/2006",
          "03/05/2006",
          "04/05/2006",
          "05/05/2006",
          "08/05/2006",
          "09/05/2006",
          "10/05/2006",
          "11/05/2006",
          "12/05/2006",
          "15/05/2006",
          "16/05/2006",
          "17/05/2006",
          "18/05/2006",
          "19/05/2006",
          "22/05/2006",
          "23/05/2006",
          "24/05/2006",
          "25/05/2006",
          "26/05/2006",
          "30/05/2006",
          "31/05/2006",
          "01/06/2006",
          "02/06/2006",
          "05/06/2006",
          "06/06/2006",
          "07/06/2006",
          "08/06/2006",
          "09/06/2006",
          "12/06/2006",
          "13/06/2006",
          "14/06/2006",
          "15/06/2006",
          "16/06/2006",
          "19/06/2006",
          "20/06/2006",
          "21/06/2006",
          "22/06/2006",
          "23/06/2006",
          "26/06/2006",
          "27/06/2006",
          "28/06/2006",
          "29/06/2006",
          "30/06/2006",
          "03/07/2006",
          "05/07/2006",
          "06/07/2006",
          "07/07/2006",
          "10/07/2006",
          "11/07/2006",
          "12/07/2006",
          "13/07/2006",
          "14/07/2006",
          "17/07/2006",
          "18/07/2006",
          "19/07/2006",
          "20/07/2006",
          "21/07/2006",
          "24/07/2006",
          "25/07/2006",
          "26/07/2006",
          "27/07/2006",
          "28/07/2006",
          "31/07/2006",
          "01/08/2006",
          "02/08/2006",
          "03/08/2006",
          "04/08/2006",
          "07/08/2006",
          "08/08/2006",
          "09/08/2006",
          "10/08/2006",
          "11/08/2006",
          "14/08/2006",
          "15/08/2006",
          "16/08/2006",
          "17/08/2006",
          "18/08/2006",
          "21/08/2006",
          "22/08/2006",
          "23/08/2006",
          "24/08/2006",
          "25/08/2006",
          "28/08/2006",
          "29/08/2006",
          "30/08/2006",
          "31/08/2006",
          "01/09/2006",
          "05/09/2006",
          "06/09/2006",
          "07/09/2006",
          "08/09/2006",
          "11/09/2006",
          "12/09/2006",
          "13/09/2006",
          "14/09/2006",
          "15/09/2006",
          "18/09/2006",
          "19/09/2006",
          "20/09/2006",
          "21/09/2006",
          "22/09/2006",
          "25/09/2006",
          "26/09/2006",
          "27/09/2006",
          "28/09/2006",
          "29/09/2006",
          "02/10/2006",
          "03/10/2006",
          "04/10/2006",
          "05/10/2006",
          "06/10/2006",
          "09/10/2006",
          "10/10/2006",
          "11/10/2006",
          "12/10/2006",
          "13/10/2006",
          "16/10/2006",
          "17/10/2006",
          "18/10/2006",
          "19/10/2006",
          "20/10/2006",
          "23/10/2006",
          "24/10/2006",
          "25/10/2006",
          "26/10/2006",
          "27/10/2006",
          "30/10/2006",
          "31/10/2006",
          "01/11/2006",
          "02/11/2006",
          "03/11/2006",
          "06/11/2006",
          "07/11/2006",
          "08/11/2006",
          "09/11/2006",
          "10/11/2006",
          "13/11/2006",
          "14/11/2006",
          "15/11/2006",
          "16/11/2006",
          "17/11/2006",
          "20/11/2006",
          "21/11/2006",
          "22/11/2006",
          "24/11/2006",
          "27/11/2006",
          "28/11/2006",
          "29/11/2006",
          "30/11/2006",
          "01/12/2006",
          "04/12/2006",
          "05/12/2006",
          "06/12/2006",
          "07/12/2006",
          "08/12/2006",
          "11/12/2006",
          "12/12/2006",
          "13/12/2006",
          "14/12/2006",
          "15/12/2006",
          "18/12/2006",
          "19/12/2006",
          "20/12/2006",
          "21/12/2006",
          "22/12/2006",
          "26/12/2006",
          "27/12/2006",
          "28/12/2006",
          "29/12/2006",
          "03/01/2007",
          "04/01/2007",
          "05/01/2007",
          "08/01/2007",
          "09/01/2007",
          "10/01/2007",
          "11/01/2007",
          "12/01/2007",
          "16/01/2007",
          "17/01/2007",
          "18/01/2007",
          "19/01/2007",
          "22/01/2007",
          "23/01/2007",
          "24/01/2007",
          "25/01/2007",
          "26/01/2007",
          "29/01/2007",
          "30/01/2007",
          "31/01/2007",
          "01/02/2007",
          "02/02/2007",
          "05/02/2007",
          "06/02/2007",
          "07/02/2007",
          "08/02/2007",
          "09/02/2007",
          "12/02/2007",
          "13/02/2007",
          "14/02/2007",
          "15/02/2007",
          "16/02/2007",
          "20/02/2007",
          "21/02/2007",
          "22/02/2007",
          "23/02/2007",
          "26/02/2007",
          "27/02/2007",
          "28/02/2007",
          "01/03/2007",
          "02/03/2007",
          "05/03/2007",
          "06/03/2007",
          "07/03/2007",
          "08/03/2007",
          "09/03/2007",
          "12/03/2007",
          "13/03/2007",
          "14/03/2007",
          "15/03/2007",
          "16/03/2007",
          "19/03/2007",
          "20/03/2007",
          "21/03/2007",
          "22/03/2007",
          "23/03/2007",
          "26/03/2007",
          "27/03/2007",
          "28/03/2007",
          "29/03/2007",
          "30/03/2007",
          "02/04/2007",
          "03/04/2007",
          "04/04/2007",
          "05/04/2007",
          "09/04/2007",
          "10/04/2007",
          "11/04/2007",
          "12/04/2007",
          "13/04/2007",
          "16/04/2007",
          "17/04/2007",
          "18/04/2007",
          "19/04/2007",
          "20/04/2007",
          "23/04/2007",
          "24/04/2007",
          "25/04/2007",
          "26/04/2007",
          "27/04/2007",
          "30/04/2007",
          "01/05/2007",
          "02/05/2007",
          "03/05/2007",
          "04/05/2007",
          "07/05/2007",
          "08/05/2007",
          "09/05/2007",
          "10/05/2007",
          "11/05/2007",
          "14/05/2007",
          "15/05/2007",
          "16/05/2007",
          "17/05/2007",
          "18/05/2007",
          "21/05/2007",
          "22/05/2007",
          "23/05/2007",
          "24/05/2007",
          "25/05/2007",
          "29/05/2007",
          "30/05/2007",
          "31/05/2007",
          "01/06/2007",
          "04/06/2007",
          "05/06/2007",
          "06/06/2007",
          "07/06/2007",
          "08/06/2007",
          "11/06/2007",
          "12/06/2007",
          "13/06/2007",
          "14/06/2007",
          "15/06/2007",
          "18/06/2007",
          "19/06/2007",
          "20/06/2007",
          "21/06/2007",
          "22/06/2007",
          "25/06/2007",
          "26/06/2007",
          "27/06/2007",
          "28/06/2007",
          "29/06/2007",
          "02/07/2007",
          "03/07/2007",
          "05/07/2007",
          "06/07/2007",
          "09/07/2007",
          "10/07/2007",
          "11/07/2007",
          "12/07/2007",
          "13/07/2007",
          "16/07/2007",
          "17/07/2007",
          "18/07/2007",
          "19/07/2007",
          "20/07/2007",
          "23/07/2007",
          "24/07/2007",
          "25/07/2007",
          "26/07/2007",
          "27/07/2007",
          "30/07/2007",
          "31/07/2007",
          "01/08/2007",
          "02/08/2007",
          "03/08/2007",
          "06/08/2007",
          "07/08/2007",
          "08/08/2007",
          "09/08/2007",
          "10/08/2007",
          "13/08/2007",
          "14/08/2007",
          "15/08/2007",
          "16/08/2007",
          "17/08/2007",
          "20/08/2007",
          "21/08/2007",
          "22/08/2007",
          "23/08/2007",
          "24/08/2007",
          "27/08/2007",
          "28/08/2007",
          "29/08/2007",
          "30/08/2007",
          "31/08/2007",
          "04/09/2007",
          "05/09/2007",
          "06/09/2007",
          "07/09/2007",
          "10/09/2007",
          "11/09/2007",
          "12/09/2007",
          "13/09/2007",
          "14/09/2007",
          "17/09/2007",
          "18/09/2007",
          "19/09/2007",
          "20/09/2007",
          "21/09/2007",
          "24/09/2007",
          "25/09/2007",
          "26/09/2007",
          "27/09/2007",
          "28/09/2007",
          "01/10/2007",
          "02/10/2007",
          "03/10/2007",
          "04/10/2007",
          "05/10/2007",
          "08/10/2007",
          "09/10/2007",
          "10/10/2007",
          "11/10/2007",
          "12/10/2007",
          "15/10/2007",
          "16/10/2007",
          "17/10/2007",
          "18/10/2007",
          "19/10/2007",
          "22/10/2007",
          "23/10/2007",
          "24/10/2007",
          "25/10/2007",
          "26/10/2007",
          "29/10/2007",
          "30/10/2007",
          "31/10/2007",
          "01/11/2007",
          "02/11/2007",
          "05/11/2007",
          "06/11/2007",
          "07/11/2007",
          "08/11/2007",
          "09/11/2007",
          "12/11/2007",
          "13/11/2007",
          "14/11/2007",
          "15/11/2007",
          "16/11/2007",
          "19/11/2007",
          "20/11/2007",
          "21/11/2007",
          "23/11/2007",
          "26/11/2007",
          "27/11/2007",
          "28/11/2007",
          "29/11/2007",
          "30/11/2007",
          "03/12/2007",
          "04/12/2007",
          "05/12/2007",
          "06/12/2007",
          "07/12/2007",
          "10/12/2007",
          "11/12/2007",
          "12/12/2007",
          "13/12/2007",
          "14/12/2007",
          "17/12/2007",
          "18/12/2007",
          "19/12/2007",
          "20/12/2007",
          "21/12/2007",
          "24/12/2007",
          "26/12/2007",
          "27/12/2007",
          "28/12/2007",
          "31/12/2007",
          "02/01/2008",
          "03/01/2008",
          "04/01/2008",
          "07/01/2008",
          "08/01/2008",
          "09/01/2008",
          "10/01/2008",
          "11/01/2008",
          "14/01/2008",
          "15/01/2008",
          "16/01/2008",
          "17/01/2008",
          "18/01/2008",
          "22/01/2008",
          "23/01/2008",
          "24/01/2008",
          "25/01/2008",
          "28/01/2008",
          "29/01/2008",
          "30/01/2008",
          "31/01/2008",
          "01/02/2008",
          "04/02/2008",
          "05/02/2008",
          "06/02/2008",
          "07/02/2008",
          "08/02/2008",
          "11/02/2008",
          "12/02/2008",
          "13/02/2008",
          "14/02/2008",
          "15/02/2008",
          "19/02/2008",
          "20/02/2008",
          "21/02/2008",
          "22/02/2008",
          "25/02/2008",
          "26/02/2008",
          "27/02/2008",
          "28/02/2008",
          "29/02/2008",
          "03/03/2008",
          "04/03/2008",
          "05/03/2008",
          "06/03/2008",
          "07/03/2008",
          "10/03/2008",
          "11/03/2008",
          "12/03/2008",
          "13/03/2008",
          "14/03/2008",
          "17/03/2008",
          "18/03/2008",
          "19/03/2008",
          "20/03/2008",
          "24/03/2008",
          "25/03/2008",
          "26/03/2008",
          "27/03/2008",
          "28/03/2008",
          "31/03/2008",
          "01/04/2008",
          "02/04/2008",
          "03/04/2008",
          "04/04/2008",
          "07/04/2008",
          "08/04/2008",
          "09/04/2008",
          "10/04/2008",
          "11/04/2008",
          "14/04/2008",
          "15/04/2008",
          "16/04/2008",
          "17/04/2008",
          "18/04/2008",
          "21/04/2008",
          "22/04/2008",
          "23/04/2008",
          "24/04/2008",
          "25/04/2008",
          "28/04/2008",
          "29/04/2008",
          "30/04/2008",
          "01/05/2008",
          "02/05/2008",
          "05/05/2008",
          "06/05/2008",
          "07/05/2008",
          "08/05/2008",
          "09/05/2008",
          "12/05/2008",
          "13/05/2008",
          "14/05/2008",
          "15/05/2008",
          "16/05/2008",
          "19/05/2008",
          "20/05/2008",
          "21/05/2008",
          "22/05/2008",
          "23/05/2008",
          "27/05/2008",
          "28/05/2008",
          "29/05/2008",
          "30/05/2008",
          "02/06/2008",
          "03/06/2008",
          "04/06/2008",
          "05/06/2008",
          "06/06/2008",
          "09/06/2008",
          "10/06/2008",
          "11/06/2008",
          "12/06/2008",
          "13/06/2008",
          "16/06/2008",
          "17/06/2008",
          "18/06/2008",
          "19/06/2008",
          "20/06/2008",
          "23/06/2008",
          "24/06/2008",
          "25/06/2008",
          "26/06/2008",
          "27/06/2008",
          "30/06/2008",
          "01/07/2008",
          "02/07/2008",
          "03/07/2008",
          "07/07/2008",
          "08/07/2008",
          "09/07/2008",
          "10/07/2008",
          "11/07/2008",
          "14/07/2008",
          "15/07/2008",
          "16/07/2008",
          "17/07/2008",
          "18/07/2008",
          "21/07/2008",
          "22/07/2008",
          "23/07/2008",
          "24/07/2008",
          "25/07/2008",
          "28/07/2008",
          "29/07/2008",
          "30/07/2008",
          "31/07/2008",
          "01/08/2008",
          "04/08/2008",
          "05/08/2008",
          "06/08/2008",
          "07/08/2008",
          "08/08/2008",
          "11/08/2008",
          "12/08/2008",
          "13/08/2008",
          "14/08/2008",
          "15/08/2008",
          "18/08/2008",
          "19/08/2008",
          "20/08/2008",
          "21/08/2008",
          "22/08/2008",
          "25/08/2008",
          "26/08/2008",
          "27/08/2008",
          "28/08/2008",
          "29/08/2008",
          "02/09/2008",
          "03/09/2008",
          "04/09/2008",
          "05/09/2008",
          "08/09/2008",
          "09/09/2008",
          "10/09/2008",
          "11/09/2008",
          "12/09/2008",
          "15/09/2008",
          "16/09/2008",
          "17/09/2008",
          "18/09/2008",
          "19/09/2008",
          "22/09/2008",
          "23/09/2008",
          "24/09/2008",
          "25/09/2008",
          "26/09/2008",
          "29/09/2008",
          "30/09/2008",
          "01/10/2008",
          "02/10/2008",
          "03/10/2008",
          "06/10/2008",
          "07/10/2008",
          "08/10/2008",
          "09/10/2008",
          "10/10/2008",
          "13/10/2008",
          "14/10/2008",
          "15/10/2008",
          "16/10/2008",
          "17/10/2008",
          "20/10/2008",
          "21/10/2008",
          "22/10/2008",
          "23/10/2008",
          "24/10/2008",
          "27/10/2008",
          "28/10/2008",
          "29/10/2008",
          "30/10/2008",
          "31/10/2008",
          "03/11/2008",
          "04/11/2008",
          "05/11/2008",
          "06/11/2008",
          "07/11/2008",
          "10/11/2008",
          "11/11/2008",
          "12/11/2008",
          "13/11/2008",
          "14/11/2008",
          "17/11/2008",
          "18/11/2008",
          "19/11/2008",
          "20/11/2008",
          "21/11/2008",
          "24/11/2008",
          "25/11/2008",
          "26/11/2008",
          "28/11/2008",
          "01/12/2008",
          "02/12/2008",
          "03/12/2008",
          "04/12/2008",
          "05/12/2008",
          "08/12/2008",
          "09/12/2008",
          "10/12/2008",
          "11/12/2008",
          "12/12/2008",
          "15/12/2008",
          "16/12/2008",
          "17/12/2008",
          "18/12/2008",
          "19/12/2008",
          "22/12/2008",
          "23/12/2008",
          "24/12/2008",
          "26/12/2008",
          "29/12/2008",
          "30/12/2008",
          "31/12/2008",
          "02/01/2009",
          "05/01/2009",
          "06/01/2009",
          "07/01/2009",
          "08/01/2009",
          "09/01/2009",
          "12/01/2009",
          "13/01/2009",
          "14/01/2009",
          "15/01/2009",
          "16/01/2009",
          "20/01/2009",
          "21/01/2009",
          "22/01/2009",
          "23/01/2009",
          "26/01/2009",
          "27/01/2009",
          "28/01/2009",
          "29/01/2009",
          "30/01/2009",
          "02/02/2009",
          "03/02/2009",
          "04/02/2009",
          "05/02/2009",
          "06/02/2009",
          "09/02/2009",
          "10/02/2009",
          "11/02/2009",
          "12/02/2009",
          "13/02/2009",
          "17/02/2009",
          "18/02/2009",
          "19/02/2009",
          "20/02/2009",
          "23/02/2009",
          "24/02/2009",
          "25/02/2009",
          "26/02/2009",
          "27/02/2009",
          "02/03/2009",
          "03/03/2009",
          "04/03/2009",
          "05/03/2009",
          "06/03/2009",
          "09/03/2009",
          "10/03/2009",
          "11/03/2009",
          "12/03/2009",
          "13/03/2009",
          "16/03/2009",
          "17/03/2009",
          "18/03/2009",
          "19/03/2009",
          "20/03/2009",
          "23/03/2009",
          "24/03/2009",
          "25/03/2009",
          "26/03/2009",
          "27/03/2009",
          "30/03/2009",
          "31/03/2009",
          "01/04/2009",
          "02/04/2009",
          "03/04/2009",
          "06/04/2009",
          "07/04/2009",
          "08/04/2009",
          "09/04/2009",
          "13/04/2009",
          "14/04/2009",
          "15/04/2009",
          "16/04/2009",
          "17/04/2009",
          "20/04/2009",
          "21/04/2009",
          "22/04/2009",
          "23/04/2009",
          "24/04/2009",
          "27/04/2009",
          "28/04/2009",
          "29/04/2009",
          "30/04/2009",
          "01/05/2009",
          "04/05/2009",
          "05/05/2009",
          "06/05/2009",
          "07/05/2009",
          "08/05/2009",
          "11/05/2009",
          "12/05/2009",
          "13/05/2009",
          "14/05/2009",
          "15/05/2009",
          "18/05/2009",
          "19/05/2009",
          "20/05/2009",
          "21/05/2009",
          "22/05/2009",
          "26/05/2009",
          "27/05/2009",
          "28/05/2009",
          "29/05/2009",
          "01/06/2009",
          "02/06/2009",
          "03/06/2009",
          "04/06/2009",
          "05/06/2009",
          "08/06/2009",
          "09/06/2009",
          "10/06/2009",
          "11/06/2009",
          "12/06/2009",
          "15/06/2009",
          "16/06/2009",
          "17/06/2009",
          "18/06/2009",
          "19/06/2009",
          "22/06/2009",
          "23/06/2009",
          "24/06/2009",
          "25/06/2009",
          "26/06/2009",
          "29/06/2009",
          "30/06/2009",
          "01/07/2009",
          "02/07/2009",
          "06/07/2009",
          "07/07/2009",
          "08/07/2009",
          "09/07/2009",
          "10/07/2009",
          "13/07/2009",
          "14/07/2009",
          "15/07/2009",
          "16/07/2009",
          "17/07/2009",
          "20/07/2009",
          "21/07/2009",
          "22/07/2009",
          "23/07/2009",
          "24/07/2009",
          "27/07/2009",
          "28/07/2009",
          "29/07/2009",
          "30/07/2009",
          "31/07/2009",
          "03/08/2009",
          "04/08/2009",
          "05/08/2009",
          "06/08/2009",
          "07/08/2009",
          "10/08/2009",
          "11/08/2009",
          "12/08/2009",
          "13/08/2009",
          "14/08/2009",
          "17/08/2009",
          "18/08/2009",
          "19/08/2009",
          "20/08/2009",
          "21/08/2009",
          "24/08/2009",
          "25/08/2009",
          "26/08/2009",
          "27/08/2009",
          "28/08/2009",
          "31/08/2009",
          "01/09/2009",
          "02/09/2009",
          "03/09/2009",
          "04/09/2009",
          "08/09/2009",
          "09/09/2009",
          "10/09/2009",
          "11/09/2009",
          "14/09/2009",
          "15/09/2009",
          "16/09/2009",
          "17/09/2009",
          "18/09/2009",
          "21/09/2009",
          "22/09/2009",
          "23/09/2009",
          "24/09/2009",
          "25/09/2009",
          "28/09/2009",
          "29/09/2009",
          "30/09/2009",
          "01/10/2009",
          "02/10/2009",
          "05/10/2009",
          "06/10/2009",
          "07/10/2009",
          "08/10/2009",
          "09/10/2009",
          "12/10/2009",
          "13/10/2009",
          "14/10/2009",
          "15/10/2009",
          "16/10/2009",
          "19/10/2009",
          "20/10/2009",
          "21/10/2009",
          "22/10/2009",
          "23/10/2009",
          "26/10/2009",
          "27/10/2009",
          "28/10/2009",
          "29/10/2009",
          "30/10/2009",
          "02/11/2009",
          "03/11/2009",
          "04/11/2009",
          "05/11/2009",
          "06/11/2009",
          "09/11/2009",
          "10/11/2009",
          "11/11/2009",
          "12/11/2009",
          "13/11/2009",
          "16/11/2009",
          "17/11/2009",
          "18/11/2009",
          "19/11/2009",
          "20/11/2009",
          "23/11/2009",
          "24/11/2009",
          "25/11/2009",
          "27/11/2009",
          "30/11/2009",
          "01/12/2009",
          "02/12/2009",
          "03/12/2009",
          "04/12/2009",
          "07/12/2009",
          "08/12/2009",
          "09/12/2009",
          "10/12/2009",
          "11/12/2009",
          "14/12/2009",
          "15/12/2009",
          "16/12/2009",
          "17/12/2009",
          "18/12/2009",
          "21/12/2009",
          "22/12/2009",
          "23/12/2009",
          "24/12/2009",
          "28/12/2009",
          "29/12/2009",
          "30/12/2009",
          "31/12/2009",
          "04/01/2010",
          "05/01/2010",
          "06/01/2010",
          "07/01/2010",
          "08/01/2010",
          "11/01/2010",
          "12/01/2010",
          "13/01/2010",
          "14/01/2010",
          "15/01/2010",
          "19/01/2010",
          "20/01/2010",
          "21/01/2010",
          "22/01/2010",
          "25/01/2010",
          "26/01/2010",
          "27/01/2010",
          "28/01/2010",
          "29/01/2010",
          "01/02/2010",
          "02/02/2010",
          "03/02/2010",
          "04/02/2010",
          "05/02/2010",
          "08/02/2010",
          "09/02/2010",
          "10/02/2010",
          "11/02/2010",
          "12/02/2010",
          "16/02/2010",
          "17/02/2010",
          "18/02/2010",
          "19/02/2010",
          "22/02/2010",
          "23/02/2010",
          "24/02/2010",
          "25/02/2010",
          "26/02/2010",
          "01/03/2010",
          "02/03/2010",
          "03/03/2010",
          "04/03/2010",
          "05/03/2010",
          "08/03/2010",
          "09/03/2010",
          "10/03/2010",
          "11/03/2010",
          "12/03/2010",
          "15/03/2010",
          "16/03/2010",
          "17/03/2010",
          "18/03/2010",
          "19/03/2010",
          "22/03/2010",
          "23/03/2010",
          "24/03/2010",
          "25/03/2010",
          "26/03/2010",
          "29/03/2010",
          "30/03/2010",
          "31/03/2010",
          "01/04/2010",
          "05/04/2010",
          "06/04/2010",
          "07/04/2010",
          "08/04/2010",
          "09/04/2010",
          "12/04/2010",
          "13/04/2010",
          "14/04/2010",
          "15/04/2010",
          "16/04/2010",
          "19/04/2010",
          "20/04/2010",
          "21/04/2010",
          "22/04/2010",
          "23/04/2010",
          "26/04/2010",
          "27/04/2010",
          "28/04/2010",
          "29/04/2010",
          "30/04/2010",
          "03/05/2010",
          "04/05/2010",
          "05/05/2010",
          "06/05/2010",
          "07/05/2010",
          "10/05/2010",
          "11/05/2010",
          "12/05/2010",
          "13/05/2010",
          "14/05/2010",
          "17/05/2010",
          "18/05/2010",
          "19/05/2010",
          "20/05/2010",
          "21/05/2010",
          "24/05/2010",
          "25/05/2010",
          "26/05/2010",
          "27/05/2010",
          "28/05/2010",
          "01/06/2010",
          "02/06/2010",
          "03/06/2010",
          "04/06/2010",
          "07/06/2010",
          "08/06/2010",
          "09/06/2010",
          "10/06/2010",
          "11/06/2010",
          "14/06/2010",
          "15/06/2010",
          "16/06/2010",
          "17/06/2010",
          "18/06/2010",
          "21/06/2010",
          "22/06/2010",
          "23/06/2010",
          "24/06/2010",
          "25/06/2010",
          "28/06/2010",
          "29/06/2010",
          "30/06/2010",
          "01/07/2010",
          "02/07/2010",
          "06/07/2010",
          "07/07/2010",
          "08/07/2010",
          "09/07/2010",
          "12/07/2010",
          "13/07/2010",
          "14/07/2010",
          "15/07/2010",
          "16/07/2010",
          "19/07/2010",
          "20/07/2010",
          "21/07/2010",
          "22/07/2010",
          "23/07/2010",
          "26/07/2010",
          "27/07/2010",
          "28/07/2010",
          "29/07/2010",
          "30/07/2010",
          "02/08/2010",
          "03/08/2010",
          "04/08/2010",
          "05/08/2010",
          "06/08/2010",
          "09/08/2010",
          "10/08/2010",
          "11/08/2010",
          "12/08/2010",
          "13/08/2010",
          "16/08/2010",
          "17/08/2010",
          "18/08/2010",
          "19/08/2010",
          "20/08/2010",
          "23/08/2010",
          "24/08/2010",
          "25/08/2010",
          "26/08/2010",
          "27/08/2010",
          "30/08/2010",
          "31/08/2010",
          "01/09/2010",
          "02/09/2010",
          "03/09/2010",
          "07/09/2010",
          "08/09/2010",
          "09/09/2010",
          "10/09/2010",
          "13/09/2010",
          "14/09/2010",
          "15/09/2010",
          "16/09/2010",
          "17/09/2010",
          "20/09/2010",
          "21/09/2010",
          "22/09/2010",
          "23/09/2010",
          "24/09/2010",
          "27/09/2010",
          "28/09/2010",
          "29/09/2010",
          "30/09/2010",
          "01/10/2010",
          "04/10/2010",
          "05/10/2010",
          "06/10/2010",
          "07/10/2010",
          "08/10/2010",
          "11/10/2010",
          "12/10/2010",
          "13/10/2010",
          "14/10/2010",
          "15/10/2010",
          "18/10/2010",
          "19/10/2010",
          "20/10/2010",
          "21/10/2010",
          "22/10/2010",
          "25/10/2010",
          "26/10/2010",
          "27/10/2010",
          "28/10/2010",
          "29/10/2010",
          "01/11/2010",
          "02/11/2010",
          "03/11/2010",
          "04/11/2010",
          "05/11/2010",
          "08/11/2010",
          "09/11/2010",
          "10/11/2010",
          "11/11/2010",
          "12/11/2010",
          "15/11/2010",
          "16/11/2010",
          "17/11/2010",
          "18/11/2010",
          "19/11/2010",
          "22/11/2010",
          "23/11/2010",
          "24/11/2010",
          "26/11/2010",
          "29/11/2010",
          "30/11/2010",
          "01/12/2010",
          "02/12/2010",
          "03/12/2010",
          "06/12/2010",
          "07/12/2010",
          "08/12/2010",
          "09/12/2010",
          "10/12/2010",
          "13/12/2010",
          "14/12/2010",
          "15/12/2010",
          "16/12/2010",
          "17/12/2010",
          "20/12/2010",
          "21/12/2010",
          "22/12/2010",
          "23/12/2010",
          "27/12/2010",
          "28/12/2010",
          "29/12/2010",
          "30/12/2010",
          "31/12/2010",
          "03/01/2011",
          "04/01/2011",
          "05/01/2011",
          "06/01/2011",
          "07/01/2011",
          "10/01/2011",
          "11/01/2011",
          "12/01/2011",
          "13/01/2011",
          "14/01/2011",
          "18/01/2011",
          "19/01/2011",
          "20/01/2011",
          "21/01/2011",
          "24/01/2011",
          "25/01/2011",
          "26/01/2011",
          "27/01/2011",
          "28/01/2011",
          "31/01/2011",
          "01/02/2011",
          "02/02/2011",
          "03/02/2011",
          "04/02/2011",
          "07/02/2011",
          "08/02/2011",
          "09/02/2011",
          "10/02/2011",
          "11/02/2011",
          "14/02/2011",
          "15/02/2011",
          "16/02/2011",
          "17/02/2011",
          "18/02/2011",
          "22/02/2011",
          "23/02/2011",
          "24/02/2011",
          "25/02/2011",
          "28/02/2011",
          "01/03/2011",
          "02/03/2011",
          "03/03/2011",
          "04/03/2011",
          "07/03/2011",
          "08/03/2011",
          "09/03/2011",
          "10/03/2011",
          "11/03/2011",
          "14/03/2011",
          "15/03/2011",
          "16/03/2011",
          "17/03/2011",
          "18/03/2011",
          "21/03/2011",
          "22/03/2011",
          "23/03/2011",
          "24/03/2011",
          "25/03/2011",
          "28/03/2011",
          "29/03/2011",
          "30/03/2011",
          "31/03/2011",
          "01/04/2011",
          "04/04/2011",
          "05/04/2011",
          "06/04/2011",
          "07/04/2011",
          "08/04/2011",
          "11/04/2011",
          "12/04/2011",
          "13/04/2011",
          "14/04/2011",
          "15/04/2011",
          "18/04/2011",
          "19/04/2011",
          "20/04/2011",
          "21/04/2011",
          "25/04/2011",
          "26/04/2011",
          "27/04/2011",
          "28/04/2011",
          "29/04/2011",
          "02/05/2011",
          "03/05/2011",
          "04/05/2011",
          "05/05/2011",
          "06/05/2011",
          "09/05/2011",
          "10/05/2011",
          "11/05/2011",
          "12/05/2011",
          "13/05/2011",
          "16/05/2011",
          "17/05/2011",
          "18/05/2011",
          "19/05/2011",
          "20/05/2011",
          "23/05/2011",
          "24/05/2011",
          "25/05/2011",
          "26/05/2011",
          "27/05/2011",
          "31/05/2011",
          "01/06/2011",
          "02/06/2011",
          "03/06/2011",
          "06/06/2011",
          "07/06/2011",
          "08/06/2011",
          "09/06/2011",
          "10/06/2011",
          "13/06/2011",
          "14/06/2011",
          "15/06/2011",
          "16/06/2011",
          "17/06/2011",
          "20/06/2011",
          "21/06/2011",
          "22/06/2011",
          "23/06/2011",
          "24/06/2011",
          "27/06/2011",
          "28/06/2011",
          "29/06/2011",
          "30/06/2011",
          "01/07/2011",
          "05/07/2011",
          "06/07/2011",
          "07/07/2011",
          "08/07/2011",
          "11/07/2011",
          "12/07/2011",
          "13/07/2011",
          "14/07/2011",
          "15/07/2011",
          "18/07/2011",
          "19/07/2011",
          "20/07/2011",
          "21/07/2011",
          "22/07/2011",
          "25/07/2011",
          "26/07/2011",
          "27/07/2011",
          "28/07/2011",
          "29/07/2011",
          "01/08/2011",
          "02/08/2011",
          "03/08/2011",
          "04/08/2011",
          "05/08/2011",
          "08/08/2011",
          "09/08/2011",
          "10/08/2011",
          "11/08/2011",
          "12/08/2011",
          "15/08/2011",
          "16/08/2011",
          "17/08/2011",
          "18/08/2011",
          "19/08/2011",
          "22/08/2011",
          "23/08/2011",
          "24/08/2011",
          "25/08/2011",
          "26/08/2011",
          "29/08/2011",
          "30/08/2011",
          "31/08/2011",
          "01/09/2011",
          "02/09/2011",
          "06/09/2011",
          "07/09/2011",
          "08/09/2011",
          "09/09/2011",
          "12/09/2011",
          "13/09/2011",
          "14/09/2011",
          "15/09/2011",
          "16/09/2011",
          "19/09/2011",
          "20/09/2011",
          "21/09/2011",
          "22/09/2011",
          "23/09/2011",
          "26/09/2011",
          "27/09/2011",
          "28/09/2011",
          "29/09/2011",
          "30/09/2011",
          "03/10/2011",
          "04/10/2011",
          "05/10/2011",
          "06/10/2011",
          "07/10/2011",
          "10/10/2011",
          "11/10/2011",
          "12/10/2011",
          "13/10/2011",
          "14/10/2011",
          "17/10/2011",
          "18/10/2011",
          "19/10/2011",
          "20/10/2011",
          "21/10/2011",
          "24/10/2011",
          "25/10/2011",
          "26/10/2011",
          "27/10/2011",
          "28/10/2011",
          "31/10/2011",
          "01/11/2011",
          "02/11/2011",
          "03/11/2011",
          "04/11/2011",
          "07/11/2011",
          "08/11/2011",
          "09/11/2011",
          "10/11/2011",
          "11/11/2011",
          "14/11/2011",
          "15/11/2011",
          "16/11/2011",
          "17/11/2011",
          "18/11/2011",
          "21/11/2011",
          "22/11/2011",
          "23/11/2011",
          "25/11/2011",
          "28/11/2011",
          "29/11/2011",
          "30/11/2011",
          "01/12/2011",
          "02/12/2011",
          "05/12/2011",
          "06/12/2011",
          "07/12/2011",
          "08/12/2011",
          "09/12/2011",
          "12/12/2011",
          "13/12/2011",
          "14/12/2011",
          "15/12/2011",
          "16/12/2011",
          "19/12/2011",
          "20/12/2011",
          "21/12/2011",
          "22/12/2011",
          "23/12/2011",
          "27/12/2011",
          "28/12/2011",
          "29/12/2011",
          "30/12/2011",
          "03/01/2012",
          "04/01/2012",
          "05/01/2012",
          "06/01/2012",
          "09/01/2012",
          "10/01/2012",
          "11/01/2012",
          "12/01/2012",
          "13/01/2012",
          "17/01/2012",
          "18/01/2012",
          "19/01/2012",
          "20/01/2012",
          "23/01/2012",
          "24/01/2012",
          "25/01/2012",
          "26/01/2012",
          "27/01/2012",
          "30/01/2012",
          "31/01/2012",
          "01/02/2012",
          "02/02/2012",
          "03/02/2012",
          "06/02/2012",
          "07/02/2012",
          "08/02/2012",
          "09/02/2012",
          "10/02/2012",
          "13/02/2012",
          "14/02/2012",
          "15/02/2012",
          "16/02/2012",
          "17/02/2012",
          "21/02/2012",
          "22/02/2012",
          "23/02/2012",
          "24/02/2012",
          "27/02/2012",
          "28/02/2012",
          "29/02/2012",
          "01/03/2012",
          "02/03/2012",
          "05/03/2012",
          "06/03/2012",
          "07/03/2012",
          "08/03/2012",
          "09/03/2012",
          "12/03/2012",
          "13/03/2012",
          "14/03/2012",
          "15/03/2012",
          "16/03/2012",
          "19/03/2012",
          "20/03/2012",
          "21/03/2012",
          "22/03/2012",
          "23/03/2012",
          "26/03/2012",
          "27/03/2012",
          "28/03/2012",
          "29/03/2012",
          "30/03/2012",
          "02/04/2012",
          "03/04/2012",
          "04/04/2012",
          "05/04/2012",
          "09/04/2012",
          "10/04/2012",
          "11/04/2012",
          "12/04/2012",
          "13/04/2012",
          "16/04/2012",
          "17/04/2012",
          "18/04/2012",
          "19/04/2012",
          "20/04/2012",
          "23/04/2012",
          "24/04/2012",
          "25/04/2012",
          "26/04/2012",
          "27/04/2012",
          "30/04/2012",
          "01/05/2012",
          "02/05/2012",
          "03/05/2012",
          "04/05/2012",
          "07/05/2012",
          "08/05/2012",
          "09/05/2012",
          "10/05/2012",
          "11/05/2012",
          "14/05/2012",
          "15/05/2012",
          "16/05/2012",
          "17/05/2012",
          "18/05/2012",
          "21/05/2012",
          "22/05/2012",
          "23/05/2012",
          "24/05/2012",
          "25/05/2012",
          "29/05/2012",
          "30/05/2012",
          "31/05/2012",
          "01/06/2012",
          "04/06/2012",
          "05/06/2012",
          "06/06/2012",
          "07/06/2012",
          "08/06/2012",
          "11/06/2012",
          "12/06/2012",
          "13/06/2012",
          "14/06/2012",
          "15/06/2012",
          "18/06/2012",
          "19/06/2012",
          "20/06/2012",
          "21/06/2012",
          "22/06/2012",
          "25/06/2012",
          "26/06/2012",
          "27/06/2012",
          "28/06/2012",
          "29/06/2012",
          "02/07/2012",
          "03/07/2012",
          "05/07/2012",
          "06/07/2012",
          "09/07/2012",
          "10/07/2012",
          "11/07/2012",
          "12/07/2012",
          "13/07/2012",
          "16/07/2012",
          "17/07/2012",
          "18/07/2012",
          "19/07/2012",
          "20/07/2012",
          "23/07/2012",
          "24/07/2012",
          "25/07/2012",
          "26/07/2012",
          "27/07/2012",
          "30/07/2012",
          "31/07/2012",
          "01/08/2012",
          "02/08/2012",
          "03/08/2012",
          "06/08/2012",
          "07/08/2012",
          "08/08/2012",
          "09/08/2012",
          "10/08/2012",
          "13/08/2012",
          "14/08/2012",
          "15/08/2012",
          "16/08/2012",
          "17/08/2012",
          "20/08/2012",
          "21/08/2012",
          "22/08/2012",
          "23/08/2012",
          "24/08/2012",
          "27/08/2012",
          "28/08/2012",
          "29/08/2012",
          "30/08/2012",
          "31/08/2012",
          "04/09/2012",
          "05/09/2012",
          "06/09/2012",
          "07/09/2012",
          "10/09/2012",
          "11/09/2012",
          "12/09/2012",
          "13/09/2012",
          "14/09/2012",
          "17/09/2012",
          "18/09/2012",
          "19/09/2012",
          "20/09/2012",
          "21/09/2012",
          "24/09/2012",
          "25/09/2012",
          "26/09/2012",
          "27/09/2012",
          "28/09/2012",
          "01/10/2012",
          "02/10/2012",
          "03/10/2012",
          "04/10/2012",
          "05/10/2012",
          "08/10/2012",
          "09/10/2012",
          "10/10/2012",
          "11/10/2012",
          "12/10/2012",
          "15/10/2012",
          "16/10/2012",
          "17/10/2012",
          "18/10/2012",
          "19/10/2012",
          "22/10/2012",
          "23/10/2012",
          "24/10/2012",
          "25/10/2012",
          "26/10/2012",
          "31/10/2012",
          "01/11/2012",
          "02/11/2012",
          "05/11/2012",
          "06/11/2012",
          "07/11/2012",
          "08/11/2012",
          "09/11/2012",
          "12/11/2012",
          "13/11/2012",
          "14/11/2012",
          "15/11/2012",
          "16/11/2012",
          "19/11/2012",
          "20/11/2012",
          "21/11/2012",
          "23/11/2012",
          "26/11/2012",
          "27/11/2012",
          "28/11/2012",
          "29/11/2012",
          "30/11/2012",
          "03/12/2012",
          "04/12/2012",
          "05/12/2012",
          "06/12/2012",
          "07/12/2012",
          "10/12/2012",
          "11/12/2012",
          "12/12/2012",
          "13/12/2012",
          "14/12/2012",
          "17/12/2012",
          "18/12/2012",
          "19/12/2012",
          "20/12/2012",
          "21/12/2012",
          "24/12/2012",
          "26/12/2012",
          "27/12/2012",
          "28/12/2012",
          "31/12/2012",
          "02/01/2013",
          "03/01/2013",
          "04/01/2013",
          "07/01/2013",
          "08/01/2013",
          "09/01/2013",
          "10/01/2013",
          "11/01/2013",
          "14/01/2013",
          "15/01/2013",
          "16/01/2013",
          "17/01/2013",
          "18/01/2013",
          "22/01/2013",
          "23/01/2013",
          "24/01/2013",
          "25/01/2013",
          "28/01/2013",
          "29/01/2013",
          "30/01/2013",
          "31/01/2013",
          "01/02/2013",
          "04/02/2013",
          "05/02/2013",
          "06/02/2013",
          "07/02/2013",
          "08/02/2013",
          "11/02/2013",
          "12/02/2013",
          "13/02/2013",
          "14/02/2013",
          "15/02/2013",
          "19/02/2013",
          "20/02/2013",
          "21/02/2013",
          "22/02/2013",
          "25/02/2013",
          "26/02/2013",
          "27/02/2013",
          "28/02/2013",
          "01/03/2013",
          "04/03/2013",
          "05/03/2013",
          "06/03/2013",
          "07/03/2013",
          "08/03/2013",
          "11/03/2013",
          "12/03/2013",
          "13/03/2013",
          "14/03/2013",
          "15/03/2013",
          "18/03/2013",
          "19/03/2013",
          "20/03/2013",
          "21/03/2013",
          "22/03/2013",
          "25/03/2013",
          "26/03/2013",
          "27/03/2013",
          "28/03/2013",
          "01/04/2013",
          "02/04/2013",
          "03/04/2013",
          "04/04/2013",
          "05/04/2013",
          "08/04/2013",
          "09/04/2013",
          "10/04/2013",
          "11/04/2013",
          "12/04/2013",
          "15/04/2013",
          "16/04/2013",
          "17/04/2013",
          "18/04/2013",
          "19/04/2013",
          "22/04/2013",
          "23/04/2013",
          "24/04/2013",
          "25/04/2013",
          "26/04/2013",
          "29/04/2013",
          "30/04/2013",
          "01/05/2013",
          "02/05/2013",
          "03/05/2013",
          "06/05/2013",
          "07/05/2013",
          "08/05/2013",
          "09/05/2013",
          "10/05/2013",
          "13/05/2013",
          "14/05/2013",
          "15/05/2013",
          "16/05/2013",
          "17/05/2013",
          "20/05/2013",
          "21/05/2013",
          "22/05/2013",
          "23/05/2013",
          "24/05/2013",
          "28/05/2013",
          "29/05/2013",
          "30/05/2013",
          "31/05/2013",
          "03/06/2013",
          "04/06/2013",
          "05/06/2013",
          "06/06/2013",
          "07/06/2013",
          "10/06/2013",
          "11/06/2013",
          "12/06/2013",
          "13/06/2013",
          "14/06/2013",
          "17/06/2013",
          "18/06/2013",
          "19/06/2013",
          "20/06/2013",
          "21/06/2013",
          "24/06/2013",
          "25/06/2013",
          "26/06/2013",
          "27/06/2013",
          "28/06/2013",
          "01/07/2013",
          "02/07/2013",
          "03/07/2013",
          "05/07/2013",
          "08/07/2013",
          "09/07/2013",
          "10/07/2013",
          "11/07/2013",
          "12/07/2013",
          "15/07/2013",
          "16/07/2013",
          "17/07/2013",
          "18/07/2013",
          "19/07/2013",
          "22/07/2013",
          "23/07/2013",
          "24/07/2013",
          "25/07/2013",
          "26/07/2013",
          "29/07/2013",
          "30/07/2013",
          "31/07/2013",
          "01/08/2013",
          "02/08/2013",
          "05/08/2013",
          "06/08/2013",
          "07/08/2013",
          "08/08/2013",
          "09/08/2013",
          "12/08/2013",
          "13/08/2013",
          "14/08/2013",
          "15/08/2013",
          "16/08/2013",
          "19/08/2013",
          "20/08/2013",
          "21/08/2013",
          "22/08/2013",
          "23/08/2013",
          "26/08/2013",
          "27/08/2013",
          "28/08/2013",
          "29/08/2013",
          "30/08/2013",
          "03/09/2013",
          "04/09/2013",
          "05/09/2013",
          "06/09/2013",
          "09/09/2013",
          "10/09/2013",
          "11/09/2013",
          "12/09/2013",
          "13/09/2013",
          "16/09/2013",
          "17/09/2013",
          "18/09/2013",
          "19/09/2013",
          "20/09/2013",
          "23/09/2013",
          "24/09/2013",
          "25/09/2013",
          "26/09/2013",
          "27/09/2013",
          "30/09/2013",
          "01/10/2013",
          "02/10/2013",
          "03/10/2013",
          "04/10/2013",
          "07/10/2013",
          "08/10/2013",
          "09/10/2013",
          "10/10/2013",
          "11/10/2013",
          "14/10/2013",
          "15/10/2013",
          "16/10/2013",
          "17/10/2013",
          "18/10/2013",
          "21/10/2013",
          "22/10/2013",
          "23/10/2013",
          "24/10/2013",
          "25/10/2013",
          "28/10/2013",
          "29/10/2013",
          "30/10/2013",
          "31/10/2013",
          "01/11/2013",
          "04/11/2013",
          "05/11/2013",
          "06/11/2013",
          "07/11/2013",
          "08/11/2013",
          "11/11/2013",
          "12/11/2013",
          "13/11/2013",
          "14/11/2013",
          "15/11/2013",
          "18/11/2013",
          "19/11/2013",
          "20/11/2013",
          "21/11/2013",
          "22/11/2013",
          "25/11/2013",
          "26/11/2013",
          "27/11/2013",
          "29/11/2013",
          "02/12/2013",
          "03/12/2013",
          "04/12/2013",
          "05/12/2013",
          "06/12/2013",
          "09/12/2013",
          "10/12/2013",
          "11/12/2013",
          "12/12/2013",
          "13/12/2013",
          "16/12/2013",
          "17/12/2013",
          "18/12/2013",
          "19/12/2013",
          "20/12/2013",
          "23/12/2013",
          "24/12/2013",
          "26/12/2013",
          "27/12/2013",
          "30/12/2013",
          "31/12/2013",
          "02/01/2014",
          "03/01/2014",
          "06/01/2014",
          "07/01/2014",
          "08/01/2014",
          "09/01/2014",
          "10/01/2014",
          "13/01/2014",
          "14/01/2014",
          "15/01/2014",
          "16/01/2014",
          "17/01/2014",
          "21/01/2014",
          "22/01/2014",
          "23/01/2014",
          "24/01/2014",
          "27/01/2014",
          "28/01/2014",
          "29/01/2014",
          "30/01/2014",
          "31/01/2014",
          "03/02/2014",
          "04/02/2014",
          "06/02/2014",
          "07/02/2014",
          "10/02/2014",
          "11/02/2014",
          "12/02/2014",
          "13/02/2014",
          "14/02/2014",
          "18/02/2014",
          "19/02/2014",
          "20/02/2014",
          "21/02/2014",
          "24/02/2014",
          "25/02/2014",
          "26/02/2014",
          "27/02/2014",
          "28/02/2014",
          "03/03/2014",
          "04/03/2014",
          "05/03/2014",
          "06/03/2014",
          "07/03/2014",
          "10/03/2014",
          "11/03/2014",
          "12/03/2014",
          "13/03/2014",
          "14/03/2014",
          "17/03/2014",
          "18/03/2014",
          "19/03/2014",
          "20/03/2014",
          "21/03/2014",
          "24/03/2014",
          "25/03/2014",
          "26/03/2014",
          "27/03/2014",
          "28/03/2014",
          "31/03/2014",
          "01/04/2014",
          "02/04/2014",
          "03/04/2014",
          "04/04/2014",
          "07/04/2014",
          "08/04/2014",
          "09/04/2014",
          "10/04/2014",
          "11/04/2014",
          "14/04/2014",
          "15/04/2014",
          "16/04/2014",
          "17/04/2014",
          "21/04/2014",
          "22/04/2014",
          "23/04/2014",
          "24/04/2014",
          "25/04/2014",
          "28/04/2014",
          "29/04/2014",
          "30/04/2014",
          "01/05/2014",
          "02/05/2014",
          "05/05/2014",
          "06/05/2014",
          "07/05/2014",
          "08/05/2014",
          "09/05/2014",
          "12/05/2014",
          "13/05/2014",
          "14/05/2014",
          "15/05/2014",
          "16/05/2014",
          "19/05/2014",
          "20/05/2014",
          "21/05/2014",
          "22/05/2014",
          "23/05/2014",
          "27/05/2014",
          "28/05/2014",
          "29/05/2014",
          "30/05/2014",
          "02/06/2014",
          "03/06/2014",
          "04/06/2014",
          "05/06/2014",
          "06/06/2014",
          "09/06/2014",
          "10/06/2014",
          "11/06/2014",
          "12/06/2014",
          "13/06/2014",
          "16/06/2014",
          "17/06/2014",
          "18/06/2014",
          "19/06/2014",
          "20/06/2014",
          "23/06/2014",
          "24/06/2014",
          "25/06/2014",
          "26/06/2014",
          "27/06/2014",
          "30/06/2014",
          "01/07/2014",
          "02/07/2014",
          "03/07/2014",
          "07/07/2014",
          "08/07/2014",
          "09/07/2014",
          "10/07/2014",
          "11/07/2014",
          "14/07/2014",
          "15/07/2014",
          "16/07/2014",
          "17/07/2014",
          "18/07/2014",
          "21/07/2014",
          "22/07/2014",
          "23/07/2014",
          "24/07/2014",
          "25/07/2014",
          "28/07/2014",
          "29/07/2014",
          "30/07/2014",
          "31/07/2014",
          "01/08/2014",
          "04/08/2014",
          "05/08/2014",
          "06/08/2014",
          "07/08/2014",
          "08/08/2014",
          "11/08/2014",
          "12/08/2014",
          "13/08/2014",
          "14/08/2014",
          "15/08/2014",
          "18/08/2014",
          "19/08/2014",
          "20/08/2014",
          "21/08/2014",
          "22/08/2014",
          "25/08/2014",
          "26/08/2014",
          "27/08/2014",
          "28/08/2014",
          "29/08/2014",
          "02/09/2014",
          "03/09/2014",
          "04/09/2014",
          "05/09/2014",
          "08/09/2014",
          "09/09/2014",
          "10/09/2014",
          "11/09/2014",
          "12/09/2014",
          "15/09/2014",
          "16/09/2014",
          "17/09/2014",
          "18/09/2014",
          "19/09/2014",
          "22/09/2014",
          "23/09/2014",
          "24/09/2014",
          "25/09/2014",
          "26/09/2014",
          "29/09/2014",
          "30/09/2014",
          "01/10/2014",
          "02/10/2014",
          "03/10/2014",
          "06/10/2014",
          "07/10/2014",
          "08/10/2014",
          "09/10/2014",
          "10/10/2014",
          "13/10/2014",
          "14/10/2014",
          "15/10/2014",
          "16/10/2014",
          "17/10/2014",
          "20/10/2014",
          "21/10/2014",
          "22/10/2014",
          "23/10/2014",
          "24/10/2014",
          "27/10/2014",
          "28/10/2014",
          "29/10/2014",
          "30/10/2014",
          "31/10/2014",
          "03/11/2014",
          "04/11/2014",
          "05/11/2014",
          "06/11/2014",
          "07/11/2014",
          "10/11/2014",
          "11/11/2014",
          "12/11/2014",
          "13/11/2014",
          "14/11/2014",
          "17/11/2014",
          "18/11/2014",
          "19/11/2014",
          "20/11/2014",
          "21/11/2014",
          "24/11/2014",
          "25/11/2014",
          "26/11/2014",
          "28/11/2014",
          "01/12/2014",
          "02/12/2014",
          "03/12/2014",
          "04/12/2014",
          "05/12/2014",
          "08/12/2014",
          "09/12/2014",
          "10/12/2014",
          "11/12/2014",
          "12/12/2014",
          "15/12/2014",
          "16/12/2014",
          "17/12/2014",
          "18/12/2014",
          "19/12/2014",
          "22/12/2014",
          "23/12/2014",
          "24/12/2014",
          "26/12/2014",
          "29/12/2014",
          "30/12/2014",
          "31/12/2014",
          "02/01/2015",
          "05/01/2015",
          "06/01/2015",
          "07/01/2015",
          "08/01/2015",
          "09/01/2015",
          "12/01/2015",
          "13/01/2015",
          "14/01/2015",
          "15/01/2015",
          "16/01/2015",
          "20/01/2015",
          "21/01/2015",
          "22/01/2015",
          "23/01/2015",
          "26/01/2015",
          "27/01/2015",
          "28/01/2015",
          "29/01/2015",
          "30/01/2015",
          "02/02/2015",
          "03/02/2015",
          "04/02/2015",
          "05/02/2015",
          "06/02/2015",
          "09/02/2015",
          "10/02/2015",
          "11/02/2015",
          "12/02/2015",
          "13/02/2015",
          "17/02/2015",
          "18/02/2015",
          "19/02/2015",
          "20/02/2015",
          "23/02/2015",
          "24/02/2015",
          "25/02/2015",
          "26/02/2015",
          "27/02/2015",
          "02/03/2015",
          "03/03/2015",
          "04/03/2015",
          "05/03/2015",
          "06/03/2015",
          "09/03/2015",
          "10/03/2015",
          "11/03/2015",
          "12/03/2015",
          "13/03/2015",
          "16/03/2015",
          "17/03/2015",
          "18/03/2015",
          "19/03/2015",
          "20/03/2015",
          "23/03/2015",
          "24/03/2015",
          "25/03/2015",
          "26/03/2015",
          "27/03/2015",
          "30/03/2015",
          "31/03/2015",
          "01/04/2015",
          "02/04/2015",
          "06/04/2015",
          "07/04/2015",
          "08/04/2015",
          "09/04/2015",
          "10/04/2015",
          "13/04/2015",
          "14/04/2015",
          "15/04/2015",
          "16/04/2015",
          "17/04/2015",
          "20/04/2015",
          "21/04/2015",
          "22/04/2015",
          "23/04/2015",
          "24/04/2015",
          "27/04/2015",
          "28/04/2015",
          "29/04/2015",
          "30/04/2015",
          "01/05/2015",
          "04/05/2015",
          "05/05/2015",
          "06/05/2015",
          "07/05/2015",
          "08/05/2015",
          "11/05/2015",
          "12/05/2015",
          "13/05/2015",
          "14/05/2015",
          "15/05/2015",
          "18/05/2015",
          "19/05/2015",
          "20/05/2015",
          "21/05/2015",
          "22/05/2015",
          "26/05/2015",
          "27/05/2015",
          "28/05/2015",
          "29/05/2015",
          "01/06/2015",
          "02/06/2015",
          "03/06/2015",
          "04/06/2015",
          "05/06/2015",
          "08/06/2015",
          "09/06/2015",
          "10/06/2015",
          "11/06/2015",
          "12/06/2015",
          "15/06/2015",
          "16/06/2015",
          "17/06/2015",
          "18/06/2015",
          "19/06/2015",
          "22/06/2015",
          "23/06/2015",
          "24/06/2015",
          "25/06/2015",
          "26/06/2015",
          "29/06/2015",
          "30/06/2015",
          "01/07/2015",
          "02/07/2015",
          "06/07/2015",
          "07/07/2015",
          "08/07/2015",
          "09/07/2015",
          "10/07/2015",
          "13/07/2015",
          "14/07/2015",
          "15/07/2015",
          "16/07/2015",
          "17/07/2015",
          "20/07/2015",
          "21/07/2015",
          "22/07/2015",
          "23/07/2015",
          "24/07/2015",
          "27/07/2015",
          "28/07/2015",
          "29/07/2015",
          "30/07/2015",
          "31/07/2015",
          "03/08/2015",
          "04/08/2015",
          "05/08/2015",
          "06/08/2015",
          "07/08/2015",
          "10/08/2015",
          "11/08/2015",
          "12/08/2015",
          "13/08/2015",
          "14/08/2015",
          "17/08/2015",
          "18/08/2015",
          "19/08/2015",
          "20/08/2015",
          "21/08/2015",
          "24/08/2015",
          "25/08/2015",
          "26/08/2015",
          "27/08/2015",
          "28/08/2015",
          "31/08/2015",
          "01/09/2015",
          "02/09/2015",
          "03/09/2015",
          "04/09/2015",
          "08/09/2015",
          "09/09/2015",
          "10/09/2015",
          "11/09/2015",
          "14/09/2015",
          "15/09/2015",
          "16/09/2015",
          "17/09/2015",
          "18/09/2015",
          "21/09/2015",
          "22/09/2015",
          "23/09/2015",
          "24/09/2015",
          "25/09/2015",
          "28/09/2015",
          "29/09/2015",
          "30/09/2015",
          "01/10/2015",
          "02/10/2015",
          "05/10/2015",
          "06/10/2015",
          "07/10/2015",
          "08/10/2015",
          "09/10/2015",
          "12/10/2015",
          "13/10/2015",
          "14/10/2015",
          "15/10/2015",
          "16/10/2015",
          "19/10/2015",
          "20/10/2015",
          "21/10/2015",
          "22/10/2015",
          "23/10/2015",
          "26/10/2015",
          "27/10/2015",
          "28/10/2015",
          "29/10/2015",
          "30/10/2015",
          "02/11/2015",
          "03/11/2015",
          "04/11/2015",
          "05/11/2015",
          "06/11/2015",
          "09/11/2015",
          "10/11/2015",
          "11/11/2015",
          "12/11/2015",
          "13/11/2015",
          "16/11/2015",
          "17/11/2015",
          "18/11/2015",
          "19/11/2015",
          "20/11/2015",
          "23/11/2015",
          "24/11/2015",
          "25/11/2015",
          "27/11/2015",
          "30/11/2015",
          "01/12/2015",
          "02/12/2015",
          "03/12/2015",
          "04/12/2015",
          "07/12/2015",
          "08/12/2015",
          "09/12/2015",
          "10/12/2015",
          "11/12/2015",
          "14/12/2015",
          "15/12/2015",
          "16/12/2015",
          "17/12/2015",
          "18/12/2015",
          "21/12/2015",
          "22/12/2015",
          "23/12/2015",
          "24/12/2015",
          "28/12/2015",
          "29/12/2015",
          "30/12/2015",
          "31/12/2015",
          "04/01/2016",
          "05/01/2016",
          "06/01/2016",
          "07/01/2016",
          "08/01/2016",
          "11/01/2016",
          "12/01/2016",
          "13/01/2016",
          "14/01/2016",
          "15/01/2016",
          "19/01/2016",
          "20/01/2016",
          "21/01/2016",
          "22/01/2016",
          "25/01/2016",
          "26/01/2016",
          "27/01/2016",
          "28/01/2016",
          "29/01/2016",
          "01/02/2016",
          "02/02/2016",
          "03/02/2016",
          "04/02/2016",
          "05/02/2016",
          "08/02/2016",
          "09/02/2016",
          "10/02/2016",
          "11/02/2016",
          "12/02/2016",
          "16/02/2016",
          "17/02/2016",
          "18/02/2016",
          "19/02/2016",
          "22/02/2016",
          "23/02/2016",
          "24/02/2016",
          "25/02/2016",
          "26/02/2016",
          "29/02/2016",
          "01/03/2016",
          "02/03/2016",
          "03/03/2016",
          "04/03/2016",
          "07/03/2016",
          "08/03/2016",
          "09/03/2016",
          "10/03/2016",
          "11/03/2016",
          "14/03/2016",
          "15/03/2016",
          "16/03/2016",
          "17/03/2016",
          "18/03/2016",
          "21/03/2016",
          "22/03/2016",
          "23/03/2016",
          "24/03/2016",
          "28/03/2016",
          "29/03/2016",
          "30/03/2016",
          "31/03/2016",
          "01/04/2016",
          "04/04/2016",
          "05/04/2016",
          "06/04/2016",
          "07/04/2016",
          "08/04/2016",
          "11/04/2016",
          "12/04/2016",
          "13/04/2016",
          "14/04/2016",
          "15/04/2016",
          "18/04/2016",
          "19/04/2016",
          "20/04/2016",
          "21/04/2016",
          "22/04/2016",
          "25/04/2016",
          "26/04/2016",
          "27/04/2016",
          "28/04/2016",
          "29/04/2016",
          "02/05/2016",
          "03/05/2016",
          "04/05/2016",
          "05/05/2016",
          "06/05/2016",
          "09/05/2016",
          "10/05/2016",
          "11/05/2016",
          "12/05/2016",
          "13/05/2016",
          "16/05/2016",
          "17/05/2016",
          "18/05/2016",
          "19/05/2016",
          "20/05/2016",
          "23/05/2016",
          "24/05/2016",
          "25/05/2016",
          "26/05/2016",
          "27/05/2016",
          "31/05/2016",
          "01/06/2016",
          "02/06/2016",
          "03/06/2016",
          "06/06/2016",
          "07/06/2016",
          "08/06/2016",
          "09/06/2016",
          "10/06/2016",
          "13/06/2016",
          "14/06/2016",
          "15/06/2016",
          "16/06/2016",
          "17/06/2016",
          "20/06/2016",
          "21/06/2016",
          "22/06/2016",
          "23/06/2016",
          "24/06/2016",
          "27/06/2016",
          "28/06/2016",
          "29/06/2016",
          "30/06/2016",
          "01/07/2016",
          "05/07/2016",
          "06/07/2016",
          "07/07/2016",
          "08/07/2016",
          "11/07/2016",
          "12/07/2016",
          "13/07/2016",
          "14/07/2016",
          "15/07/2016",
          "18/07/2016",
          "19/07/2016",
          "20/07/2016",
          "21/07/2016",
          "22/07/2016",
          "25/07/2016",
          "26/07/2016",
          "27/07/2016",
          "28/07/2016",
          "29/07/2016",
          "01/08/2016",
          "02/08/2016",
          "03/08/2016",
          "04/08/2016",
          "05/08/2016",
          "08/08/2016",
          "09/08/2016",
          "10/08/2016",
          "11/08/2016",
          "12/08/2016",
          "15/08/2016",
          "16/08/2016",
          "17/08/2016",
          "18/08/2016",
          "19/08/2016",
          "22/08/2016",
          "23/08/2016",
          "24/08/2016",
          "25/08/2016",
          "26/08/2016",
          "29/08/2016",
          "30/08/2016",
          "31/08/2016",
          "01/09/2016",
          "02/09/2016",
          "06/09/2016",
          "07/09/2016",
          "08/09/2016",
          "09/09/2016",
          "12/09/2016",
          "13/09/2016",
          "14/09/2016",
          "15/09/2016",
          "16/09/2016",
          "19/09/2016",
          "20/09/2016",
          "21/09/2016",
          "22/09/2016",
          "23/09/2016",
          "26/09/2016",
          "27/09/2016",
          "28/09/2016",
          "29/09/2016",
          "30/09/2016",
          "03/10/2016",
          "04/10/2016",
          "05/10/2016",
          "06/10/2016",
          "07/10/2016",
          "10/10/2016",
          "11/10/2016",
          "12/10/2016",
          "13/10/2016",
          "14/10/2016",
          "17/10/2016",
          "18/10/2016",
          "19/10/2016",
          "20/10/2016",
          "21/10/2016",
          "24/10/2016",
          "25/10/2016",
          "26/10/2016",
          "27/10/2016",
          "28/10/2016",
          "31/10/2016",
          "01/11/2016",
          "02/11/2016",
          "03/11/2016",
          "04/11/2016",
          "07/11/2016",
          "08/11/2016",
          "09/11/2016",
          "10/11/2016",
          "11/11/2016",
          "14/11/2016",
          "15/11/2016",
          "16/11/2016",
          "17/11/2016",
          "18/11/2016",
          "21/11/2016",
          "22/11/2016",
          "23/11/2016",
          "25/11/2016",
          "28/11/2016",
          "29/11/2016",
          "30/11/2016",
          "01/12/2016",
          "02/12/2016",
          "05/12/2016",
          "06/12/2016",
          "07/12/2016",
          "08/12/2016",
          "09/12/2016",
          "12/12/2016",
          "13/12/2016",
          "14/12/2016",
          "15/12/2016",
          "16/12/2016",
          "19/12/2016",
          "20/12/2016",
          "21/12/2016",
          "22/12/2016",
          "23/12/2016",
          "27/12/2016",
          "28/12/2016",
          "29/12/2016",
          "30/12/2016",
          "03/01/2017",
          "04/01/2017",
          "05/01/2017",
          "06/01/2017",
          "09/01/2017",
          "10/01/2017",
          "11/01/2017",
          "12/01/2017",
          "13/01/2017",
          "17/01/2017",
          "18/01/2017",
          "19/01/2017",
          "20/01/2017",
          "23/01/2017",
          "24/01/2017",
          "25/01/2017",
          "26/01/2017",
          "27/01/2017",
          "30/01/2017",
          "31/01/2017",
          "01/02/2017",
          "02/02/2017",
          "03/02/2017",
          "06/02/2017",
          "07/02/2017",
          "08/02/2017",
          "09/02/2017",
          "10/02/2017",
          "13/02/2017",
          "14/02/2017",
          "15/02/2017",
          "16/02/2017",
          "17/02/2017",
          "21/02/2017",
          "22/02/2017",
          "23/02/2017",
          "24/02/2017",
          "27/02/2017",
          "28/02/2017",
          "01/03/2017",
          "02/03/2017",
          "03/03/2017",
          "06/03/2017",
          "07/03/2017",
          "08/03/2017",
          "09/03/2017",
          "10/03/2017",
          "13/03/2017",
          "14/03/2017",
          "15/03/2017",
          "16/03/2017",
          "17/03/2017",
          "20/03/2017",
          "21/03/2017",
          "22/03/2017",
          "23/03/2017",
          "24/03/2017",
          "27/03/2017",
          "28/03/2017",
          "29/03/2017",
          "30/03/2017",
          "31/03/2017",
          "03/04/2017",
          "04/04/2017",
          "05/04/2017",
          "06/04/2017",
          "07/04/2017",
          "10/04/2017",
          "11/04/2017",
          "12/04/2017",
          "13/04/2017",
          "17/04/2017",
          "18/04/2017",
          "19/04/2017",
          "20/04/2017",
          "21/04/2017",
          "24/04/2017",
          "25/04/2017",
          "26/04/2017",
          "27/04/2017",
          "28/04/2017",
          "01/05/2017",
          "02/05/2017",
          "03/05/2017",
          "04/05/2017",
          "05/05/2017",
          "08/05/2017",
          "09/05/2017",
          "10/05/2017",
          "11/05/2017",
          "12/05/2017",
          "15/05/2017",
          "16/05/2017",
          "17/05/2017",
          "18/05/2017",
          "19/05/2017",
          "22/05/2017",
          "23/05/2017",
          "24/05/2017",
          "25/05/2017",
          "26/05/2017",
          "30/05/2017",
          "31/05/2017",
          "01/06/2017",
          "02/06/2017",
          "05/06/2017",
          "06/06/2017",
          "07/06/2017",
          "08/06/2017",
          "09/06/2017",
          "12/06/2017",
          "13/06/2017",
          "14/06/2017",
          "15/06/2017",
          "16/06/2017",
          "19/06/2017",
          "20/06/2017",
          "21/06/2017",
          "22/06/2017",
          "23/06/2017",
          "26/06/2017",
          "27/06/2017",
          "28/06/2017",
          "29/06/2017",
          "30/06/2017",
          "03/07/2017",
          "05/07/2017",
          "06/07/2017",
          "07/07/2017",
          "10/07/2017",
          "11/07/2017",
          "12/07/2017",
          "13/07/2017",
          "14/07/2017",
          "17/07/2017",
          "18/07/2017",
          "19/07/2017",
          "20/07/2017",
          "21/07/2017",
          "24/07/2017",
          "25/07/2017",
          "26/07/2017",
          "27/07/2017",
          "28/07/2017",
          "31/07/2017",
          "01/08/2017",
          "02/08/2017",
          "03/08/2017",
          "04/08/2017",
          "07/08/2017",
          "08/08/2017",
          "09/08/2017",
          "10/08/2017",
          "11/08/2017",
          "14/08/2017",
          "15/08/2017",
          "16/08/2017",
          "17/08/2017",
          "18/08/2017",
          "21/08/2017",
          "22/08/2017",
          "23/08/2017",
          "24/08/2017",
          "25/08/2017",
          "28/08/2017",
          "29/08/2017",
          "30/08/2017",
          "31/08/2017",
          "01/09/2017",
          "05/09/2017",
          "06/09/2017",
          "07/09/2017",
          "08/09/2017",
          "11/09/2017",
          "12/09/2017",
          "13/09/2017",
          "14/09/2017",
          "15/09/2017",
          "18/09/2017",
          "19/09/2017",
          "20/09/2017",
          "21/09/2017",
          "22/09/2017",
          "25/09/2017",
          "26/09/2017",
          "27/09/2017",
          "28/09/2017",
          "29/09/2017",
          "02/10/2017",
          "03/10/2017",
          "04/10/2017",
          "05/10/2017",
          "06/10/2017",
          "09/10/2017",
          "10/10/2017",
          "11/10/2017",
          "12/10/2017",
          "13/10/2017",
          "16/10/2017",
          "17/10/2017",
          "18/10/2017",
          "19/10/2017",
          "20/10/2017",
          "23/10/2017",
          "24/10/2017",
          "25/10/2017",
          "26/10/2017",
          "27/10/2017",
          "30/10/2017",
          "31/10/2017",
          "01/11/2017",
          "02/11/2017",
          "03/11/2017",
          "06/11/2017",
          "07/11/2017",
          "08/11/2017",
          "09/11/2017",
          "10/11/2017",
          "13/11/2017",
          "14/11/2017",
          "15/11/2017",
          "16/11/2017",
          "17/11/2017",
          "20/11/2017",
          "21/11/2017",
          "22/11/2017",
          "24/11/2017",
          "27/11/2017",
          "28/11/2017",
          "29/11/2017",
          "30/11/2017",
          "01/12/2017",
          "04/12/2017",
          "05/12/2017",
          "06/12/2017",
          "07/12/2017",
          "08/12/2017",
          "11/12/2017",
          "12/12/2017",
          "13/12/2017",
          "14/12/2017",
          "15/12/2017",
          "18/12/2017",
          "19/12/2017",
          "20/12/2017",
          "21/12/2017",
          "22/12/2017",
          "26/12/2017",
          "27/12/2017",
          "28/12/2017",
          "29/12/2017",
          "02/01/2018",
          "03/01/2018",
          "04/01/2018",
          "05/01/2018",
          "08/01/2018",
          "09/01/2018",
          "10/01/2018",
          "11/01/2018",
          "12/01/2018",
          "16/01/2018",
          "17/01/2018",
          "18/01/2018",
          "19/01/2018",
          "22/01/2018",
          "23/01/2018",
          "24/01/2018",
          "25/01/2018",
          "26/01/2018",
          "29/01/2018",
          "30/01/2018",
          "31/01/2018",
          "01/02/2018",
          "02/02/2018",
          "05/02/2018",
          "06/02/2018",
          "07/02/2018",
          "08/02/2018",
          "09/02/2018",
          "12/02/2018",
          "13/02/2018",
          "14/02/2018",
          "15/02/2018",
          "16/02/2018",
          "20/02/2018",
          "21/02/2018",
          "22/02/2018",
          "23/02/2018",
          "26/02/2018",
          "27/02/2018",
          "28/02/2018",
          "01/03/2018",
          "02/03/2018",
          "05/03/2018",
          "06/03/2018",
          "07/03/2018",
          "08/03/2018",
          "09/03/2018",
          "12/03/2018",
          "13/03/2018",
          "14/03/2018",
          "15/03/2018",
          "16/03/2018",
          "19/03/2018",
          "20/03/2018",
          "21/03/2018",
          "22/03/2018",
          "23/03/2018",
          "26/03/2018",
          "27/03/2018",
          "28/03/2018",
          "29/03/2018",
          "02/04/2018",
          "03/04/2018",
          "04/04/2018",
          "05/04/2018",
          "06/04/2018",
          "09/04/2018",
          "10/04/2018",
          "11/04/2018",
          "12/04/2018",
          "13/04/2018",
          "16/04/2018",
          "17/04/2018",
          "18/04/2018",
          "19/04/2018",
          "20/04/2018",
          "23/04/2018",
          "24/04/2018",
          "25/04/2018",
          "26/04/2018",
          "27/04/2018",
          "30/04/2018",
          "01/05/2018",
          "02/05/2018",
          "03/05/2018",
          "04/05/2018",
          "07/05/2018",
          "08/05/2018",
          "09/05/2018",
          "10/05/2018",
          "11/05/2018",
          "14/05/2018",
          "15/05/2018",
          "16/05/2018",
          "17/05/2018",
          "18/05/2018",
          "21/05/2018",
          "22/05/2018",
          "23/05/2018",
          "24/05/2018",
          "25/05/2018",
          "29/05/2018",
          "30/05/2018",
          "31/05/2018",
          "01/06/2018",
          "04/06/2018",
          "05/06/2018",
          "06/06/2018",
          "07/06/2018",
          "08/06/2018",
          "11/06/2018",
          "12/06/2018",
          "13/06/2018",
          "14/06/2018",
          "15/06/2018",
          "18/06/2018",
          "19/06/2018",
          "20/06/2018",
          "21/06/2018",
          "22/06/2018",
          "25/06/2018",
          "26/06/2018",
          "27/06/2018",
          "28/06/2018",
          "29/06/2018",
          "02/07/2018",
          "03/07/2018",
          "05/07/2018",
          "06/07/2018",
          "09/07/2018",
          "10/07/2018",
          "11/07/2018",
          "12/07/2018",
          "13/07/2018",
          "16/07/2018",
          "17/07/2018",
          "18/07/2018",
          "19/07/2018",
          "20/07/2018",
          "23/07/2018",
          "24/07/2018",
          "25/07/2018",
          "26/07/2018",
          "27/07/2018",
          "30/07/2018",
          "31/07/2018",
          "01/08/2018",
          "02/08/2018",
          "03/08/2018",
          "06/08/2018",
          "07/08/2018",
          "08/08/2018",
          "09/08/2018",
          "10/08/2018",
          "13/08/2018",
          "14/08/2018",
          "15/08/2018",
          "16/08/2018",
          "17/08/2018",
          "20/08/2018",
          "21/08/2018",
          "22/08/2018",
          "23/08/2018",
          "24/08/2018",
          "27/08/2018",
          "28/08/2018",
          "29/08/2018",
          "30/08/2018",
          "31/08/2018",
          "04/09/2018",
          "05/09/2018",
          "06/09/2018",
          "07/09/2018",
          "10/09/2018",
          "11/09/2018",
          "12/09/2018",
          "13/09/2018",
          "14/09/2018",
          "17/09/2018",
          "18/09/2018",
          "19/09/2018",
          "20/09/2018",
          "21/09/2018",
          "24/09/2018",
          "25/09/2018",
          "26/09/2018",
          "27/09/2018",
          "28/09/2018",
          "01/10/2018",
          "02/10/2018",
          "03/10/2018",
          "04/10/2018",
          "05/10/2018",
          "08/10/2018",
          "09/10/2018",
          "10/10/2018",
          "11/10/2018",
          "12/10/2018",
          "15/10/2018",
          "16/10/2018",
          "17/10/2018",
          "18/10/2018",
          "19/10/2018",
          "22/10/2018",
          "23/10/2018",
          "24/10/2018",
          "25/10/2018",
          "26/10/2018",
          "29/10/2018",
          "30/10/2018",
          "31/10/2018",
          "01/11/2018",
          "02/11/2018",
          "05/11/2018",
          "06/11/2018",
          "07/11/2018",
          "08/11/2018",
          "09/11/2018",
          "12/11/2018",
          "13/11/2018",
          "14/11/2018",
          "15/11/2018",
          "16/11/2018",
          "19/11/2018",
          "20/11/2018",
          "21/11/2018",
          "23/11/2018",
          "26/11/2018",
          "27/11/2018",
          "28/11/2018",
          "29/11/2018",
          "30/11/2018",
          "03/12/2018",
          "04/12/2018",
          "06/12/2018",
          "07/12/2018",
          "10/12/2018",
          "11/12/2018",
          "12/12/2018",
          "13/12/2018",
          "14/12/2018",
          "17/12/2018",
          "18/12/2018",
          "19/12/2018",
          "20/12/2018",
          "21/12/2018",
          "24/12/2018",
          "26/12/2018",
          "27/12/2018",
          "28/12/2018",
          "31/12/2018",
          "02/01/2019",
          "03/01/2019",
          "04/01/2019",
          "07/01/2019",
          "08/01/2019",
          "09/01/2019",
          "10/01/2019",
          "11/01/2019",
          "14/01/2019",
          "15/01/2019",
          "16/01/2019",
          "17/01/2019",
          "18/01/2019",
          "22/01/2019",
          "23/01/2019",
          "24/01/2019",
          "25/01/2019",
          "28/01/2019",
          "29/01/2019",
          "30/01/2019",
          "31/01/2019",
          "01/02/2019",
          "04/02/2019",
          "05/02/2019",
          "06/02/2019",
          "07/02/2019",
          "08/02/2019",
          "11/02/2019",
          "12/02/2019",
          "13/02/2019",
          "14/02/2019",
          "15/02/2019",
          "19/02/2019",
          "20/02/2019",
          "21/02/2019",
          "22/02/2019",
          "25/02/2019",
          "26/02/2019",
          "27/02/2019",
          "28/02/2019",
          "01/03/2019",
          "04/03/2019",
          "05/03/2019",
          "06/03/2019",
          "07/03/2019",
          "08/03/2019",
          "11/03/2019",
          "12/03/2019",
          "13/03/2019",
          "14/03/2019",
          "15/03/2019",
          "18/03/2019",
          "19/03/2019",
          "20/03/2019",
          "21/03/2019",
          "22/03/2019",
          "25/03/2019",
          "26/03/2019",
          "27/03/2019",
          "28/03/2019",
          "29/03/2019",
          "01/04/2019",
          "02/04/2019",
          "03/04/2019",
          "04/04/2019",
          "05/04/2019",
          "08/04/2019",
          "09/04/2019",
          "10/04/2019",
          "11/04/2019",
          "12/04/2019",
          "15/04/2019",
          "16/04/2019",
          "17/04/2019",
          "18/04/2019",
          "22/04/2019",
          "23/04/2019",
          "24/04/2019",
          "25/04/2019",
          "26/04/2019",
          "29/04/2019",
          "30/04/2019",
          "01/05/2019",
          "02/05/2019",
          "03/05/2019",
          "06/05/2019",
          "07/05/2019",
          "08/05/2019",
          "09/05/2019",
          "10/05/2019",
          "13/05/2019",
          "14/05/2019",
          "15/05/2019",
          "16/05/2019",
          "17/05/2019",
          "20/05/2019",
          "21/05/2019",
          "22/05/2019",
          "23/05/2019",
          "24/05/2019",
          "28/05/2019",
          "29/05/2019",
          "30/05/2019",
          "31/05/2019",
          "03/06/2019",
          "04/06/2019",
          "05/06/2019",
          "06/06/2019",
          "07/06/2019",
          "10/06/2019",
          "11/06/2019",
          "12/06/2019",
          "13/06/2019",
          "14/06/2019",
          "17/06/2019",
          "18/06/2019",
          "19/06/2019",
          "20/06/2019",
          "21/06/2019",
          "24/06/2019",
          "25/06/2019",
          "26/06/2019",
          "27/06/2019",
          "28/06/2019",
          "01/07/2019",
          "02/07/2019",
          "03/07/2019",
          "05/07/2019",
          "08/07/2019",
          "09/07/2019",
          "10/07/2019",
          "11/07/2019",
          "12/07/2019",
          "15/07/2019",
          "16/07/2019",
          "17/07/2019",
          "18/07/2019",
          "19/07/2019",
          "22/07/2019",
          "23/07/2019",
          "24/07/2019",
          "25/07/2019",
          "26/07/2019",
          "29/07/2019",
          "30/07/2019",
          "31/07/2019",
          "01/08/2019",
          "02/08/2019",
          "05/08/2019",
          "06/08/2019",
          "07/08/2019",
          "08/08/2019",
          "09/08/2019",
          "12/08/2019",
          "13/08/2019",
          "14/08/2019",
          "15/08/2019",
          "16/08/2019",
          "19/08/2019",
          "20/08/2019",
          "21/08/2019",
          "22/08/2019",
          "23/08/2019",
          "26/08/2019",
          "27/08/2019",
          "28/08/2019",
          "29/08/2019",
          "30/08/2019",
          "03/09/2019",
          "04/09/2019",
          "05/09/2019",
          "06/09/2019",
          "09/09/2019",
          "10/09/2019",
          "11/09/2019",
          "12/09/2019",
          "13/09/2019",
          "16/09/2019",
          "17/09/2019",
          "18/09/2019",
          "19/09/2019",
          "20/09/2019",
          "23/09/2019",
          "24/09/2019",
          "25/09/2019",
          "26/09/2019",
          "27/09/2019",
          "30/09/2019",
          "01/10/2019",
          "02/10/2019",
          "03/10/2019",
          "04/10/2019",
          "07/10/2019",
          "08/10/2019",
          "09/10/2019",
          "10/10/2019",
          "11/10/2019",
          "14/10/2019",
          "15/10/2019",
          "16/10/2019",
          "17/10/2019",
          "18/10/2019",
          "21/10/2019",
          "22/10/2019",
          "23/10/2019",
          "24/10/2019",
          "25/10/2019",
          "28/10/2019",
          "29/10/2019",
          "30/10/2019",
          "31/10/2019",
          "01/11/2019",
          "04/11/2019",
          "05/11/2019",
          "06/11/2019",
          "07/11/2019",
          "08/11/2019",
          "11/11/2019",
          "12/11/2019",
          "13/11/2019",
          "14/11/2019",
          "15/11/2019",
          "18/11/2019",
          "19/11/2019",
          "20/11/2019",
          "21/11/2019",
          "22/11/2019",
          "25/11/2019",
          "26/11/2019",
          "27/11/2019",
          "29/11/2019",
          "02/12/2019",
          "03/12/2019",
          "04/12/2019",
          "05/12/2019",
          "06/12/2019",
          "09/12/2019",
          "10/12/2019",
          "11/12/2019",
          "12/12/2019",
          "13/12/2019",
          "16/12/2019",
          "17/12/2019",
          "18/12/2019",
          "19/12/2019",
          "20/12/2019",
          "23/12/2019",
          "24/12/2019",
          "26/12/2019",
          "27/12/2019",
          "30/12/2019",
          "31/12/2019",
          "02/01/2020",
          "03/01/2020",
          "06/01/2020",
          "07/01/2020",
          "08/01/2020",
          "09/01/2020",
          "10/01/2020",
          "13/01/2020",
          "14/01/2020",
          "15/01/2020",
          "16/01/2020",
          "17/01/2020",
          "21/01/2020",
          "22/01/2020",
          "23/01/2020",
          "24/01/2020",
          "27/01/2020",
          "28/01/2020",
          "29/01/2020",
          "30/01/2020",
          "31/01/2020",
          "03/02/2020",
          "04/02/2020",
          "05/02/2020",
          "06/02/2020",
          "07/02/2020",
          "10/02/2020",
          "11/02/2020",
          "12/02/2020",
          "13/02/2020",
          "14/02/2020",
          "18/02/2020",
          "19/02/2020",
          "20/02/2020",
          "21/02/2020",
          "24/02/2020",
          "25/02/2020",
          "26/02/2020",
          "27/02/2020",
          "28/02/2020",
          "02/03/2020",
          "03/03/2020",
          "04/03/2020",
          "05/03/2020",
          "06/03/2020",
          "09/03/2020",
          "10/03/2020",
          "11/03/2020",
          "12/03/2020",
          "13/03/2020",
          "16/03/2020",
          "17/03/2020",
          "18/03/2020",
          "19/03/2020",
          "20/03/2020",
          "23/03/2020",
          "24/03/2020",
          "25/03/2020",
          "26/03/2020",
          "27/03/2020",
          "30/03/2020",
          "31/03/2020",
          "01/04/2020",
          "02/04/2020",
          "03/04/2020",
          "06/04/2020",
          "07/04/2020",
          "08/04/2020",
          "09/04/2020",
          "13/04/2020",
          "14/04/2020",
          "15/04/2020",
          "16/04/2020",
          "17/04/2020",
          "20/04/2020",
          "21/04/2020",
          "22/04/2020",
          "23/04/2020",
          "24/04/2020",
          "27/04/2020",
          "28/04/2020",
          "29/04/2020",
          "30/04/2020",
          "01/05/2020",
          "04/05/2020",
          "05/05/2020",
          "06/05/2020",
          "07/05/2020",
          "08/05/2020",
          "11/05/2020",
          "12/05/2020",
          "13/05/2020",
          "14/05/2020",
          "15/05/2020",
          "18/05/2020",
          "19/05/2020",
          "20/05/2020",
          "21/05/2020",
          "22/05/2020",
          "26/05/2020",
          "27/05/2020",
          "28/05/2020",
          "29/05/2020",
          "01/06/2020",
          "02/06/2020",
          "03/06/2020",
          "04/06/2020",
          "05/06/2020",
          "08/06/2020",
          "09/06/2020",
          "10/06/2020",
          "11/06/2020",
          "12/06/2020",
          "15/06/2020",
          "16/06/2020",
          "17/06/2020",
          "18/06/2020",
          "19/06/2020",
          "22/06/2020",
          "23/06/2020",
          "24/06/2020",
          "25/06/2020",
          "26/06/2020",
          "29/06/2020",
          "30/06/2020",
          "01/07/2020",
          "02/07/2020",
          "06/07/2020",
          "07/07/2020",
          "08/07/2020",
          "09/07/2020",
          "10/07/2020",
          "13/07/2020",
          "14/07/2020",
          "15/07/2020",
          "16/07/2020",
          "17/07/2020",
          "20/07/2020",
          "21/07/2020",
          "22/07/2020",
          "23/07/2020",
          "24/07/2020",
          "27/07/2020",
          "28/07/2020",
          "29/07/2020",
          "30/07/2020",
          "31/07/2020",
          "03/08/2020",
          "04/08/2020",
          "05/08/2020",
          "06/08/2020",
          "07/08/2020",
          "10/08/2020",
          "11/08/2020",
          "12/08/2020",
          "13/08/2020",
          "14/08/2020",
          "17/08/2020",
          "18/08/2020",
          "19/08/2020",
          "20/08/2020",
          "21/08/2020",
          "24/08/2020",
          "25/08/2020",
          "26/08/2020",
          "27/08/2020",
          "28/08/2020",
          "31/08/2020",
          "01/09/2020",
          "02/09/2020",
          "03/09/2020",
          "04/09/2020",
          "08/09/2020",
          "09/09/2020",
          "10/09/2020",
          "11/09/2020",
          "14/09/2020",
          "15/09/2020",
          "16/09/2020",
          "17/09/2020",
          "18/09/2020",
          "21/09/2020",
          "22/09/2020",
          "23/09/2020",
          "24/09/2020",
          "25/09/2020",
          "28/09/2020",
          "29/09/2020",
          "30/09/2020",
          "01/10/2020",
          "02/10/2020",
          "05/10/2020",
          "06/10/2020",
          "07/10/2020",
          "08/10/2020",
          "09/10/2020",
          "12/10/2020",
          "13/10/2020",
          "14/10/2020",
          "15/10/2020",
          "16/10/2020",
          "19/10/2020",
          "20/10/2020",
          "21/10/2020",
          "22/10/2020",
          "23/10/2020",
          "26/10/2020",
          "27/10/2020",
          "28/10/2020",
          "29/10/2020",
          "30/10/2020",
          "02/11/2020",
          "03/11/2020",
          "04/11/2020",
          "05/11/2020",
          "06/11/2020",
          "09/11/2020",
          "10/11/2020",
          "11/11/2020",
          "12/11/2020",
          "13/11/2020",
          "16/11/2020",
          "17/11/2020",
          "18/11/2020",
          "19/11/2020",
          "20/11/2020",
          "23/11/2020",
          "24/11/2020",
          "25/11/2020",
          "27/11/2020",
          "30/11/2020",
          "01/12/2020",
          "02/12/2020",
          "03/12/2020",
          "04/12/2020",
          "07/12/2020",
          "08/12/2020",
          "09/12/2020",
          "10/12/2020",
          "11/12/2020",
          "14/12/2020",
          "15/12/2020",
          "16/12/2020",
          "17/12/2020",
          "18/12/2020",
          "21/12/2020",
          "22/12/2020",
          "23/12/2020",
          "24/12/2020",
          "28/12/2020",
          "29/12/2020",
          "30/12/2020",
          "31/12/2020",
          "04/01/2021",
          "05/01/2021",
          "06/01/2021",
          "07/01/2021",
          "08/01/2021",
          "11/01/2021",
          "12/01/2021",
          "13/01/2021",
          "14/01/2021",
          "15/01/2021",
          "19/01/2021",
          "20/01/2021",
          "21/01/2021",
          "22/01/2021",
          "25/01/2021",
          "26/01/2021",
          "27/01/2021",
          "28/01/2021",
          "29/01/2021",
          "01/02/2021",
          "02/02/2021",
          "03/02/2021",
          "04/02/2021",
          "05/02/2021",
          "08/02/2021",
          "09/02/2021",
          "10/02/2021",
          "11/02/2021",
          "12/02/2021",
          "16/02/2021",
          "17/02/2021",
          "18/02/2021",
          "19/02/2021",
          "22/02/2021",
          "23/02/2021",
          "24/02/2021",
          "25/02/2021",
          "26/02/2021",
          "01/03/2021",
          "02/03/2021",
          "03/03/2021",
          "04/03/2021",
          "05/03/2021",
          "08/03/2021",
          "09/03/2021",
          "10/03/2021",
          "11/03/2021",
          "12/03/2021",
          "15/03/2021",
          "16/03/2021",
          "17/03/2021",
          "18/03/2021",
          "19/03/2021",
          "22/03/2021",
          "23/03/2021",
          "24/03/2021",
          "25/03/2021",
          "26/03/2021",
          "29/03/2021",
          "30/03/2021",
          "31/03/2021",
          "01/04/2021",
          "05/04/2021",
          "06/04/2021",
          "07/04/2021",
          "08/04/2021",
          "09/04/2021",
          "12/04/2021",
          "13/04/2021",
          "14/04/2021",
          "15/04/2021",
          "16/04/2021",
          "19/04/2021",
          "20/04/2021",
          "21/04/2021",
          "22/04/2021",
          "23/04/2021",
          "26/04/2021",
          "27/04/2021",
          "28/04/2021",
          "29/04/2021",
          "30/04/2021",
          "03/05/2021",
          "04/05/2021",
          "05/05/2021",
          "06/05/2021",
          "07/05/2021",
          "10/05/2021",
          "11/05/2021",
          "12/05/2021",
          "13/05/2021",
          "14/05/2021",
          "17/05/2021",
          "18/05/2021",
          "19/05/2021",
          "20/05/2021",
          "21/05/2021",
          "24/05/2021",
          "25/05/2021",
          "26/05/2021",
          "27/05/2021",
          "28/05/2021",
          "01/06/2021",
          "02/06/2021",
          "03/06/2021",
          "04/06/2021",
          "07/06/2021",
          "08/06/2021",
          "09/06/2021",
          "10/06/2021",
          "11/06/2021",
          "14/06/2021",
          "15/06/2021",
          "16/06/2021",
          "17/06/2021",
          "18/06/2021",
          "21/06/2021",
          "22/06/2021",
          "23/06/2021",
          "24/06/2021",
          "25/06/2021",
          "28/06/2021",
          "29/06/2021",
          "30/06/2021",
          "01/07/2021",
          "02/07/2021",
          "06/07/2021",
          "07/07/2021",
          "08/07/2021",
          "09/07/2021",
          "12/07/2021",
          "13/07/2021",
          "14/07/2021",
          "15/07/2021",
          "16/07/2021",
          "19/07/2021",
          "20/07/2021",
          "21/07/2021",
          "22/07/2021",
          "23/07/2021",
          "26/07/2021",
          "27/07/2021",
          "28/07/2021",
          "29/07/2021",
          "30/07/2021",
          "02/08/2021",
          "03/08/2021",
          "04/08/2021",
          "05/08/2021",
          "06/08/2021",
          "09/08/2021",
          "10/08/2021",
          "11/08/2021",
          "12/08/2021",
          "13/08/2021",
          "16/08/2021",
          "17/08/2021",
          "18/08/2021",
          "19/08/2021",
          "20/08/2021",
          "23/08/2021",
          "24/08/2021",
          "25/08/2021",
          "26/08/2021",
          "27/08/2021",
          "30/08/2021",
          "31/08/2021",
          "01/09/2021",
          "02/09/2021",
          "03/09/2021",
          "07/09/2021",
          "08/09/2021",
          "09/09/2021",
          "10/09/2021",
          "13/09/2021",
          "14/09/2021",
          "15/09/2021",
          "16/09/2021",
          "17/09/2021",
          "20/09/2021",
          "21/09/2021",
          "22/09/2021",
          "23/09/2021",
          "24/09/2021",
          "27/09/2021",
          "28/09/2021",
          "29/09/2021",
          "30/09/2021",
          "01/10/2021",
          "04/10/2021",
          "05/10/2021",
          "06/10/2021",
          "07/10/2021",
          "08/10/2021",
          "11/10/2021",
          "12/10/2021",
          "13/10/2021",
          "14/10/2021",
          "15/10/2021",
          "18/10/2021",
          "19/10/2021",
          "20/10/2021",
          "21/10/2021",
          "22/10/2021",
          "25/10/2021",
          "26/10/2021",
          "27/10/2021",
          "28/10/2021",
          "29/10/2021",
          "01/11/2021",
          "02/11/2021",
          "03/11/2021",
          "04/11/2021",
          "05/11/2021",
          "08/11/2021",
          "09/11/2021",
          "10/11/2021",
          "11/11/2021",
          "12/11/2021",
          "15/11/2021",
          "16/11/2021",
          "17/11/2021",
          "18/11/2021",
          "19/11/2021",
          "22/11/2021",
          "23/11/2021",
          "24/11/2021",
          "26/11/2021",
          "29/11/2021",
          "30/11/2021",
          "01/12/2021",
          "02/12/2021",
          "03/12/2021",
          "06/12/2021",
          "07/12/2021",
          "08/12/2021",
          "09/12/2021",
          "10/12/2021",
          "13/12/2021",
          "14/12/2021",
          "15/12/2021",
          "16/12/2021",
          "17/12/2021",
          "20/12/2021",
          "21/12/2021",
          "22/12/2021",
          "23/12/2021",
          "27/12/2021",
          "28/12/2021",
          "29/12/2021",
          "30/12/2021",
          "31/12/2021",
          "03/01/2022",
          "04/01/2022",
          "05/01/2022",
          "06/01/2022",
          "07/01/2022",
          "10/01/2022",
          "11/01/2022",
          "12/01/2022",
          "13/01/2022",
          "14/01/2022",
          "18/01/2022",
          "19/01/2022",
          "20/01/2022",
          "21/01/2022",
          "24/01/2022",
          "25/01/2022",
          "26/01/2022",
          "27/01/2022",
          "28/01/2022",
          "31/01/2022",
          "01/02/2022",
          "02/02/2022",
          "03/02/2022",
          "04/02/2022",
          "07/02/2022",
          "08/02/2022",
          "09/02/2022",
          "10/02/2022",
          "11/02/2022",
          "14/02/2022",
          "15/02/2022",
          "16/02/2022",
          "17/02/2022",
          "18/02/2022",
          "22/02/2022",
          "23/02/2022",
          "24/02/2022",
          "25/02/2022",
          "28/02/2022",
          "01/03/2022",
          "02/03/2022",
          "03/03/2022",
          "04/03/2022",
          "07/03/2022",
          "08/03/2022",
          "09/03/2022",
          "10/03/2022",
          "11/03/2022",
          "14/03/2022",
          "15/03/2022",
          "16/03/2022",
          "17/03/2022",
          "18/03/2022",
          "21/03/2022",
          "22/03/2022",
          "23/03/2022",
          "24/03/2022",
          "25/03/2022",
          "28/03/2022",
          "29/03/2022",
          "30/03/2022",
          "31/03/2022",
          "01/04/2022",
          "04/04/2022",
          "05/04/2022",
          "06/04/2022",
          "07/04/2022",]
      },
        yaxis: {
          labels: {
            formatter: (value) => { return value },
          },
          opposite: true,
          forceNiceScale: true,
          min: 0,
        tickAmount: 6,
        axisTicks: {
            show: false,
        },
        },

        tooltip: {
          enabled: true,
          theme: "dark" }
      },
      series: [
        {
          name: "Kurs",
          data: ["0.87",
          "0.89",
          "0.88",
          "0.87",
          "0.87",
          "0.84",
          "0.86",
          "0.87",
          "0.86",
          "0.90",
          "0.91",
          "0.92",
          "0.92",
          "0.90",
          "0.86",
          "0.88",
          "0.92",
          "0.93",
          "0.91",
          "0.92",
          "0.91",
          "0.89",
          "0.88",
          "0.87",
          "0.87",
          "0.92",
          "0.92",
          "0.92",
          "0.94",
          "0.97",
          "0.97",
          "0.97",
          "0.94",
          "0.93",
          "0.94",
          "0.94",
          "0.95",
          "0.94",
          "0.94",
          "0.98",
          "0.99",
          "1.02",
          "1.02",
          "1.03",
          "1.02",
          "1.02",
          "1.03",
          "1.05",
          "1.07",
          "1.06",
          "1.06",
          "1.03",
          "1.03",
          "1.04",
          "1.00",
          "1.03",
          "1.01",
          "1.02",
          "1.03",
          "1.04",
          "1.05",
          "1.04",
          "1.03",
          "1.03",
          "1.02",
          "1.01",
          "0.99",
          "0.99",
          "0.98",
          "1.00",
          "1.03",
          "1.01",
          "1.00",
          "0.99",
          "1.00",
          "0.99",
          "0.99",
          "1.01",
          "1.02",
          "1.02",
          "1.00",
          "1.01",
          "1.05",
          "1.04",
          "1.02",
          "1.03",
          "1.03",
          "1.04",
          "1.03",
          "1.03",
          "1.03",
          "1.03",
          "1.02",
          "1.04",
          "1.07",
          "1.09",
          "1.08",
          "1.08",
          "1.08",
          "1.09",
          "1.09",
          "1.08",
          "1.09",
          "1.07",
          "1.07",
          "1.08",
          "1.09",
          "1.08",
          "1.05",
          "1.06",
          "1.05",
          "1.06",
          "1.06",
          "1.06",
          "1.05",
          "1.04",
          "1.02",
          "1.02",
          "1.02",
          "0.99",
          "0.96",
          "0.97",
          "0.98",
          "0.98",
          "0.98",
          "0.97",
          "0.98",
          "1.00",
          "1.02",
          "1.02",
          "1.03",
          "1.03",
          "1.02",
          "1.01",
          "1.01",
          "0.99",
          "0.97",
          "0.97",
          "0.98",
          "0.97",
          "0.98",
          "0.98",
          "0.97",
          "0.97",
          "0.95",
          "0.93",
          "0.93",
          "0.94",
          "0.94",
          "0.93",
          "0.93",
          "0.92",
          "0.90",
          "0.89",
          "0.88",
          "0.89",
          "0.88",
          "0.89",
          "0.87",
          "0.87",
          "0.87",
          "0.89",
          "0.90",
          "0.93",
          "0.93",
          "0.94",
          "0.96",
          "0.98",
          "1.01",
          "1.00",
          "0.98",
          "1.00",
          "0.99",
          "1.02",
          "1.03",
          "1.07",
          "1.06",
          "1.05",
          "1.04",
          "0.97",
          "0.98",
          "0.98",
          "0.97",
          "0.98",
          "0.96",
          "0.97",
          "0.97",
          "0.97",
          "0.97",
          "0.96",
          "0.97",
          "0.97",
          "0.98",
          "0.98",
          "1.03",
          "1.05",
          "1.05",
          "1.05",
          "1.05",
          "1.05",
          "1.05",
          "1.07",
          "1.08",
          "1.07",
          "1.04",
          "1.04",
          "1.06",
          "1.08",
          "1.08",
          "1.08",
          "1.06",
          "1.05",
          "1.05",
          "1.03",
          "1.02",
          "1.02",
          "1.02",
          "1.02",
          "1.05",
          "1.06",
          "1.07",
          "1.07",
          "1.06",
          "1.09",
          "1.13",
          "1.16",
          "1.18",
          "1.17",
          "1.18",
          "1.19",
          "1.16",
          "1.14",
          "1.15",
          "1.19",
          "1.18",
          "1.18",
          "1.21",
          "1.20",
          "1.23",
          "1.24",
          "1.22",
          "1.24",
          "1.22",
          "1.22",
          "1.21",
          "1.21",
          "1.20",
          "1.20",
          "1.20",
          "1.21",
          "1.21",
          "1.21",
          "1.20",
          "1.20",
          "1.20",
          "1.22",
          "1.25",
          "1.26",
          "1.30",
          "1.24",
          "1.21",
          "1.23",
          "1.26",
          "1.25",
          "1.25",
          "1.23",
          "1.22",
          "1.24",
          "1.23",
          "1.25",
          "1.26",
          "1.24",
          "1.24",
          "1.24",
          "1.24",
          "1.23",
          "1.20",
          "1.19",
          "1.20",
          "1.21",
          "1.21",
          "1.20",
          "1.20",
          "1.20",
          "1.19",
          "1.16",
          "1.16",
          "1.16",
          "1.14",
          "1.13",
          "1.12",
          "1.13",
          "1.13",
          "1.13",
          "1.14",
          "1.16",
          "1.16",
          "1.16",
          "1.17",
          "1.16",
          "1.18",
          "1.19",
          "1.24",
          "1.24",
          "1.29",
          "1.31",
          "1.35",
          "1.37",
          "1.35",
          "1.33",
          "1.32",
          "1.34",
          "1.36",
          "1.37",
          "1.33",
          "1.34",
          "1.35",
          "1.32",
          "1.31",
          "1.31",
          "1.31",
          "1.31",
          "1.30",
          "1.31",
          "1.30",
          "1.30",
          "1.30",
          "1.27",
          "1.25",
          "1.22",
          "1.20",
          "1.21",
          "1.23",
          "1.23",
          "1.22",
          "1.19",
          "1.16",
          "1.14",
          "1.11",
          "1.13",
          "1.13",
          "1.11",
          "1.11",
          "1.11",
          "1.08",
          "1.07",
          "1.09",
          "1.10",
          "1.11",
          "1.14",
          "1.13",
          "1.09",
          "1.08",
          "1.07",
          "1.08",
          "1.09",
          "1.08",
          "1.08",
          "1.07",
          "1.07",
          "1.07",
          "1.07",
          "1.06",
          "1.05",
          "1.07",
          "1.09",
          "1.07",
          "1.09",
          "1.06",
          "1.05",
          "1.05",
          "1.01",
          "1.02",
          "1.01",
          "1.02",
          "1.01",
          "1.02",
          "1.01",
          "1.01",
          "1.00",
          "0.98",
          "0.96",
          "0.94",
          "0.94",
          "0.95",
          "0.94",
          "0.91",
          "0.93",
          "0.96",
          "0.96",
          "0.96",
          "0.99",
          "0.99",
          "0.98",
          "1.02",
          "1.07",
          "1.06",
          "1.05",
          "1.05",
          "1.04",
          "1.03",
          "1.03",
          "1.04",
          "1.05",
          "1.05",
          "1.03",
          "1.03",
          "1.03",
          "1.03",
          "1.06",
          "1.06",
          "1.08",
          "1.09",
          "1.09",
          "1.06",
          "1.07",
          "1.09",
          "1.09",
          "1.10",
          "1.10",
          "1.11",
          "1.09",
          "1.08",
          "1.09",
          "1.09",
          "1.10",
          "1.12",
          "1.12",
          "1.12",
          "1.11",
          "1.12",
          "1.12",
          "1.12",
          "1.10",
          "1.05",
          "1.01",
          "1.01",
          "1.03",
          "1.06",
          "1.06",
          "1.06",
          "1.04",
          "1.04",
          "1.04",
          "1.06",
          "1.07",
          "1.08",
          "1.07",
          "1.09",
          "1.09",
          "1.09",
          "1.07",
          "1.07",
          "1.07",
          "1.10",
          "1.09",
          "1.08",
          "1.07",
          "1.06",
          "1.02",
          "1.02",
          "1.02",
          "1.03",
          "1.03",
          "1.03",
          "0.98",
          "0.98",
          "0.98",
          "0.98",
          "0.98",
          "0.99",
          "1.00",
          "1.03",
          "1.03",
          "1.00",
          "1.02",
          "1.01",
          "1.01",
          "1.02",
          "1.01",
          "1.01",
          "1.02",
          "1.01",
          "1.01",
          "1.00",
          "1.02",
          "1.02",
          "1.03",
          "1.02",
          "1.04",
          "1.05",
          "1.05",
          "1.06",
          "1.08",
          "1.09",
          "1.09",
          "1.06",
          "1.06",
          "1.02",
          "1.03",
          "1.03",
          "0.99",
          "0.99",
          "1.00",
          "1.00",
          "1.03",
          "1.06",
          "1.07",
          "1.08",
          "1.07",
          "1.07",
          "1.06",
          "1.05",
          "1.06",
          "1.08",
          "1.09",
          "1.13",
          "1.13",
          "1.13",
          "1.13",
          "1.15",
          "1.17",
          "1.17",
          "1.18",
          "1.17",
          "1.18",
          "1.18",
          "1.18",
          "1.16",
          "1.16",
          "1.16",
          "1.18",
          "1.20",
          "1.18",
          "1.17",
          "1.17",
          "1.17",
          "1.18",
          "1.18",
          "1.17",
          "1.17",
          "1.16",
          "1.13",
          "1.13",
          "1.15",
          "1.15",
          "1.15",
          "1.15",
          "1.16",
          "1.16",
          "1.15",
          "1.16",
          "1.16",
          "1.17",
          "1.17",
          "1.19",
          "1.18",
          "1.15",
          "1.13",
          "1.12",
          "1.11",
          "1.12",
          "1.11",
          "1.09",
          "1.10",
          "1.11",
          "1.11",
          "1.12",
          "1.11",
          "1.11",
          "1.11",
          "1.11",
          "1.13",
          "1.18",
          "1.17",
          "1.18",
          "1.19",
          "1.19",
          "1.21",
          "1.21",
          "1.19",
          "1.21",
          "1.16",
          "1.16",
          "1.17",
          "1.15",
          "1.13",
          "1.12",
          "1.13",
          "1.12",
          "1.12",
          "1.06",
          "1.06",
          "1.08",
          "1.10",
          "1.08",
          "1.06",
          "1.05",
          "1.05",
          "1.04",
          "1.07",
          "1.06",
          "1.05",
          "1.06",
          "1.03",
          "1.03",
          "1.04",
          "1.04",
          "1.04",
          "1.05",
          "1.06",
          "1.10",
          "1.09",
          "1.10",
          "1.13",
          "1.16",
          "1.15",
          "1.13",
          "1.09",
          "1.07",
          "1.09",
          "1.11",
          "1.10",
          "1.13",
          "1.17",
          "1.16",
          "1.13",
          "1.10",
          "1.08",
          "1.09",
          "1.10",
          "1.12",
          "1.15",
          "1.14",
          "1.14",
          "1.17",
          "1.16",
          "1.17",
          "1.18",
          "1.19",
          "1.18",
          "1.14",
          "1.16",
          "1.17",
          "1.14",
          "1.15",
          "1.18",
          "1.22",
          "1.28",
          "1.32",
          "1.31",
          "1.35",
          "1.31",
          "1.31",
          "1.35",
          "1.38",
          "1.44",
          "1.42",
          "1.39",
          "1.34",
          "1.35",
          "1.35",
          "1.35",
          "1.36",
          "1.37",
          "1.39",
          "1.46",
          "1.44",
          "1.41",
          "1.38",
          "1.38",
          "1.38",
          "1.39",
          "1.40",
          "1.39",
          "1.36",
          "1.32",
          "1.35",
          "1.36",
          "1.34",
          "1.28",
          "1.28",
          "1.31",
          "1.31",
          "1.35",
          "1.39",
          "1.38",
          "1.37",
          "1.40",
          "1.42",
          "1.44",
          "1.40",
          "1.34",
          "1.31",
          "1.27",
          "1.27",
          "1.28",
          "1.30",
          "1.28",
          "1.29",
          "1.29",
          "1.31",
          "1.33",
          "1.31",
          "1.29",
          "1.29",
          "1.30",
          "1.28",
          "1.30",
          "1.26",
          "1.26",
          "1.27",
          "1.29",
          "1.29",
          "1.32",
          "1.33",
          "1.31",
          "1.27",
          "1.27",
          "1.35",
          "1.36",
          "1.39",
          "1.36",
          "1.38",
          "1.37",
          "1.39",
          "1.39",
          "1.38",
          "1.37",
          "1.39",
          "1.39",
          "1.38",
          "1.38",
          "1.39",
          "1.37",
          "1.40",
          "1.40",
          "1.36",
          "1.37",
          "1.39",
          "1.41",
          "1.47",
          "1.49",
          "1.55",
          "1.58",
          "1.57",
          "1.54",
          "1.58",
          "1.60",
          "1.60",
          "1.60",
          "1.60",
          "1.58",
          "1.59",
          "1.59",
          "1.58",
          "1.58",
          "1.56",
          "1.54",
          "1.53",
          "1.53",
          "1.52",
          "1.54",
          "1.55",
          "1.56",
          "1.57",
          "1.59",
          "1.59",
          "1.59",
          "1.57",
          "1.57",
          "1.59",
          "1.61",
          "1.64",
          "1.65",
          "1.69",
          "1.73",
          "1.72",
          "1.72",
          "1.72",
          "1.73",
          "1.70",
          "1.70",
          "1.65",
          "1.65",
          "1.65",
          "1.66",
          "1.63",
          "1.60",
          "1.60",
          "1.65",
          "1.63",
          "1.64",
          "1.67",
          "1.67",
          "1.64",
          "1.56",
          "1.51",
          "1.54",
          "1.56",
          "1.54",
          "1.54",
          "1.53",
          "1.53",
          "1.53",
          "1.53",
          "1.53",
          "1.55",
          "1.58",
          "1.55",
          "1.54",
          "1.54",
          "1.55",
          "1.50",
          "1.48",
          "1.44",
          "1.43",
          "1.41",
          "1.40",
          "1.40",
          "1.40",
          "1.38",
          "1.40",
          "1.38",
          "1.38",
          "1.36",
          "1.35",
          "1.36",
          "1.37",
          "1.36",
          "1.37",
          "1.38",
          "1.39",
          "1.38",
          "1.36",
          "1.38",
          "1.36",
          "1.33",
          "1.34",
          "1.31",
          "1.31",
          "1.34",
          "1.33",
          "1.32",
          "1.28",
          "1.31",
          "1.34",
          "1.34",
          "1.35",
          "1.39",
          "1.36",
          "1.33",
          "1.29",
          "1.30",
          "1.32",
          "1.37",
          "1.34",
          "1.31",
          "1.27",
          "1.31",
          "1.27",
          "1.29",
          "1.27",
          "1.28",
          "1.25",
          "1.28",
          "1.26",
          "1.24",
          "1.21",
          "1.20",
          "1.17",
          "1.16",
          "1.16",
          "1.15",
          "1.17",
          "1.21",
          "1.21",
          "1.21",
          "1.22",
          "1.24",
          "1.23",
          "1.23",
          "1.22",
          "1.21",
          "1.20",
          "1.22",
          "1.26",
          "1.27",
          "1.27",
          "1.27",
          "1.25",
          "1.23",
          "1.28",
          "1.27",
          "1.30",
          "1.30",
          "1.27",
          "1.27",
          "1.28",
          "1.29",
          "1.29",
          "1.29",
          "1.28",
          "1.25",
          "1.26",
          "1.26",
          "1.29",
          "1.30",
          "1.29",
          "1.27",
          "1.27",
          "1.25",
          "1.25",
          "1.25",
          "1.25",
          "1.25",
          "1.24",
          "1.26",
          "1.25",
          "1.26",
          "1.27",
          "1.27",
          "1.28",
          "1.24",
          "1.21",
          "0.97",
          "1.01",
          "1.03",
          "1.03",
          "1.03",
          "1.02",
          "1.00",
          "1.01",
          "1.01",
          "1.00",
          "0.99",
          "0.98",
          "1.00",
          "0.99",
          "1.00",
          "1.06",
          "1.07",
          "1.06",
          "1.07",
          "1.06",
          "1.05",
          "1.05",
          "1.05",
          "1.03",
          "1.01",
          "1.01",
          "1.03",
          "1.05",
          "1.06",
          "1.05",
          "1.06",
          "1.05",
          "1.07",
          "1.08",
          "1.08",
          "1.08",
          "1.09",
          "1.09",
          "1.08",
          "1.08",
          "1.05",
          "1.05",
          "1.06",
          "1.08",
          "1.10",
          "1.08",
          "1.12",
          "1.12",
          "1.09",
          "1.09",
          "1.08",
          "1.08",
          "1.09",
          "1.11",
          "1.13",
          "1.16",
          "1.14",
          "1.13",
          "1.13",
          "1.11",
          "1.10",
          "1.08",
          "1.07",
          "1.08",
          "1.07",
          "1.05",
          "1.00",
          "1.02",
          "1.00",
          "1.01",
          "1.00",
          "1.02",
          "1.05",
          "1.05",
          "1.03",
          "1.03",
          "1.07",
          "1.07",
          "1.04",
          "1.04",
          "1.03",
          "1.04",
          "1.07",
          "1.10",
          "1.17",
          "1.18",
          "1.17",
          "1.16",
          "1.21",
          "1.25",
          "1.30",
          "1.26",
          "1.31",
          "1.31",
          "1.29",
          "1.31",
          "1.34",
          "1.37",
          "1.33",
          "1.37",
          "1.36",
          "1.37",
          "1.38",
          "1.36",
          "1.37",
          "1.32",
          "1.33",
          "1.31",
          "1.31",
          "1.27",
          "1.28",
          "1.25",
          "1.22",
          "1.23",
          "1.32",
          "1.33",
          "1.31",
          "1.29",
          "1.36",
          "1.33",
          "1.32",
          "1.31",
          "1.32",
          "1.31",
          "1.32",
          "1.29",
          "1.29",
          "1.31",
          "1.31",
          "1.30",
          "1.30",
          "1.29",
          "1.28",
          "1.29",
          "1.36",
          "1.34",
          "1.39",
          "1.31",
          "1.29",
          "1.29",
          "1.32",
          "1.33",
          "1.32",
          "1.34",
          "1.34",
          "1.34",
          "1.11",
          "1.04",
          "1.01",
          "1.00",
          "1.02",
          "1.02",
          "1.01",
          "1.01",
          "1.00",
          "1.02",
          "0.95",
          "0.96",
          "0.97",
          "0.96",
          "0.97",
          "0.95",
          "0.94",
          "0.94",
          "0.94",
          "0.97",
          "0.98",
          "0.98",
          "0.99",
          "1.07",
          "1.04",
          "1.09",
          "1.12",
          "1.10",
          "1.08",
          "1.11",
          "1.09",
          "1.12",
          "1.12",
          "1.11",
          "1.10",
          "1.08",
          "1.13",
          "1.14",
          "1.16",
          "1.15",
          "1.15",
          "1.14",
          "1.15",
          "1.15",
          "1.15",
          "1.13",
          "1.16",
          "1.18",
          "1.18",
          "1.18",
          "1.18",
          "1.16",
          "1.17",
          "1.16",
          "1.15",
          "1.14",
          "1.15",
          "1.16",
          "1.16",
          "1.16",
          "1.16",
          "1.17",
          "1.18",
          "1.18",
          "1.23",
          "1.21",
          "1.21",
          "1.29",
          "1.30",
          "1.27",
          "1.29",
          "1.29",
          "1.31",
          "1.24",
          "1.23",
          "1.20",
          "1.21",
          "1.21",
          "1.17",
          "1.22",
          "1.22",
          "1.13",
          "1.13",
          "1.14",
          "1.18",
          "1.19",
          "1.19",
          "1.19",
          "1.21",
          "1.21",
          "1.20",
          "1.21",
          "1.19",
          "1.18",
          "1.20",
          "1.21",
          "1.19",
          "1.19",
          "1.18",
          "1.18",
          "1.18",
          "1.17",
          "1.16",
          "1.16",
          "1.17",
          "1.17",
          "1.19",
          "1.20",
          "1.20",
          "1.19",
          "1.20",
          "1.20",
          "1.20",
          "1.18",
          "1.17",
          "1.17",
          "1.17",
          "1.19",
          "1.19",
          "1.21",
          "1.20",
          "1.20",
          "1.20",
          "1.18",
          "1.18",
          "1.18",
          "1.18",
          "1.18",
          "1.18",
          "1.18",
          "1.19",
          "1.20",
          "1.21",
          "1.24",
          "1.24",
          "1.24",
          "1.24",
          "1.25",
          "1.25",
          "1.25",
          "1.24",
          "1.23",
          "1.24",
          "1.22",
          "1.22",
          "1.24",
          "1.24",
          "1.26",
          "1.29",
          "1.30",
          "1.34",
          "1.31",
          "1.33",
          "1.35",
          "1.36",
          "1.40",
          "1.41",
          "1.46",
          "1.43",
          "1.43",
          "1.43",
          "1.46",
          "1.51",
          "1.53",
          "1.51",
          "1.52",
          "1.54",
          "1.53",
          "1.55",
          "1.56",
          "1.56",
          "1.55",
          "1.57",
          "1.64",
          "1.61",
          "1.61",
          "1.59",
          "1.57",
          "1.57",
          "1.57",
          "1.59",
          "1.59",
          "1.60",
          "1.57",
          "1.58",
          "1.58",
          "1.57",
          "1.55",
          "1.54",
          "1.53",
          "1.56",
          "1.57",
          "1.57",
          "1.58",
          "1.58",
          "1.64",
          "1.63",
          "1.63",
          "1.64",
          "1.64",
          "1.64",
          "1.63",
          "1.60",
          "1.62",
          "1.59",
          "1.58",
          "1.57",
          "1.58",
          "1.63",
          "1.63",
          "1.62",
          "1.62",
          "1.64",
          "1.64",
          "1.64",
          "1.65",
          "1.64",
          "1.64",
          "1.64",
          "1.63",
          "1.62",
          "1.62",
          "1.60",
          "1.59",
          "1.57",
          "1.54",
          "1.57",
          "1.58",
          "1.58",
          "1.58",
          "1.59",
          "1.62",
          "1.66",
          "1.64",
          "1.61",
          "1.63",
          "1.64",
          "1.68",
          "1.72",
          "1.72",
          "1.72",
          "1.70",
          "1.70",
          "1.71",
          "1.74",
          "1.84",
          "1.79",
          "1.78",
          "1.79",
          "1.78",
          "1.78",
          "1.78",
          "1.78",
          "1.79",
          "1.85",
          "1.80",
          "1.80",
          "1.83",
          "1.84",
          "1.82",
          "1.83",
          "1.85",
          "1.89",
          "1.91",
          "1.92",
          "1.95",
          "1.93",
          "1.91",
          "1.90",
          "1.89",
          "1.88",
          "1.86",
          "1.82",
          "1.79",
          "1.82",
          "1.80",
          "1.80",
          "1.84",
          "1.85",
          "1.84",
          "1.85",
          "1.85",
          "1.81",
          "1.78",
          "1.80",
          "1.81",
          "1.79",
          "1.79",
          "1.79",
          "1.85",
          "1.86",
          "1.85",
          "1.85",
          "1.84",
          "1.83",
          "1.83",
          "1.82",
          "1.80",
          "1.82",
          "1.81",
          "1.81",
          "1.82",
          "1.84",
          "1.83",
          "1.82",
          "1.82",
          "1.81",
          "1.80",
          "1.83",
          "1.84",
          "1.84",
          "1.81",
          "1.80",
          "1.80",
          "1.81",
          "1.81",
          "1.81",
          "1.79",
          "1.73",
          "1.73",
          "1.69",
          "1.71",
          "1.73",
          "1.75",
          "1.76",
          "1.78",
          "1.76",
          "1.76",
          "1.73",
          "1.72",
          "1.71",
          "1.74",
          "1.78",
          "1.76",
          "1.77",
          "1.76",
          "1.77",
          "1.79",
          "1.82",
          "1.84",
          "1.83",
          "1.84",
          "1.85",
          "1.85",
          "1.84",
          "1.83",
          "1.84",
          "1.83",
          "1.82",
          "1.81",
          "1.83",
          "1.83",
          "1.89",
          "1.89",
          "1.88",
          "1.90",
          "1.91",
          "1.91",
          "1.91",
          "1.97",
          "2.01",
          "1.96",
          "2.01",
          "2.00",
          "1.97",
          "1.96",
          "1.96",
          "1.98",
          "2.01",
          "1.98",
          "1.98",
          "2.02",
          "2.03",
          "2.00",
          "2.00",
          "2.08",
          "2.10",
          "2.10",
          "2.13",
          "2.21",
          "2.21",
          "2.18",
          "2.21",
          "2.29",
          "2.25",
          "2.23",
          "2.26",
          "2.33",
          "2.31",
          "2.27",
          "2.26",
          "2.28",
          "2.28",
          "2.32",
          "2.31",
          "2.33",
          "2.36",
          "2.44",
          "2.42",
          "2.35",
          "2.35",
          "2.46",
          "2.52",
          "2.50",
          "2.46",
          "2.50",
          "2.47",
          "2.41",
          "2.39",
          "2.37",
          "2.41",
          "2.43",
          "2.43",
          "2.43",
          "2.40",
          "2.43",
          "2.45",
          "2.45",
          "2.42",
          "2.42",
          "2.45",
          "2.53",
          "2.57",
          "2.60",
          "2.66",
          "2.65",
          "2.65",
          "2.60",
          "2.58",
          "2.62",
          "2.65",
          "2.69",
          "2.71",
          "2.79",
          "2.86",
          "2.86",
          "2.95",
          "2.93",
          "2.94",
          "2.91",
          "2.88",
          "2.75",
          "2.86",
          "2.91",
          "2.93",
          "2.84",
          "2.82",
          "2.88",
          "2.91",
          "2.81",
          "2.88",
          "2.90",
          "3.04",
          "3.08",
          "3.08",
          "3.02",
          "3.00",
          "3.04",
          "2.98",
          "2.96",
          "3.12",
          "3.27",
          "3.35",
          "3.35",
          "3.36",
          "3.55",
          "3.84",
          "3.89",
          "3.84",
          "4.06",
          "4.03",
          "3.93",
          "4.01",
          "3.96",
          "3.86",
          "3.75",
          "3.69",
          "3.70",
          "3.81",
          "3.76",
          "3.78",
          "3.71",
          "3.75",
          "3.80",
          "3.84",
          "3.80",
          "3.72",
          "3.69",
          "3.58",
          "3.62",
          "3.73",
          "3.74",
          "3.77",
          "3.81",
          "3.86",
          "3.91",
          "3.87",
          "3.86",
          "3.86",
          "3.95",
          "4.08",
          "4.09",
          "4.21",
          "4.11",
          "4.10",
          "4.12",
          "4.19",
          "4.21",
          "4.17",
          "4.12",
          "4.11",
          "4.13",
          "4.17",
          "4.14",
          "4.15",
          "4.38",
          "4.47",
          "4.48",
          "4.50",
          "4.47",
          "4.41",
          "4.32",
          "4.10",
          "4.01",
          "4.12",
          "4.10",
          "4.09",
          "3.87",
          "3.77",
          "3.89",
          "4.00",
          "4.09",
          "4.10",
          "4.10",
          "4.07",
          "4.11",
          "4.10",
          "3.91",
          "3.97",
          "4.03",
          "3.97",
          "3.87",
          "3.86",
          "3.77",
          "3.71",
          "3.73",
          "3.69",
          "3.81",
          "3.94",
          "3.92",
          "3.83",
          "3.80",
          "3.76",
          "3.70",
          "3.78",
          "3.74",
          "3.73",
          "3.68",
          "3.64",
          "3.62",
          "3.69",
          "3.75",
          "3.59",
          "3.50",
          "3.44",
          "3.36",
          "3.37",
          "3.38",
          "3.29",
          "3.07",
          "3.13",
          "3.20",
          "3.16",
          "3.06",
          "3.01",
          "3.01",
          "2.98",
          "3.09",
          "3.31",
          "3.19",
          "3.24",
          "3.14",
          "3.25",
          "3.37",
          "3.35",
          "3.34",
          "3.38",
          "3.41",
          "3.44",
          "3.37",
          "3.36",
          "3.39",
          "3.40",
          "3.47",
          "3.50",
          "3.49",
          "3.45",
          "3.49",
          "3.51",
          "3.61",
          "3.53",
          "3.55",
          "3.56",
          "3.57",
          "3.62",
          "3.61",
          "3.61",
          "3.57",
          "3.59",
          "3.61",
          "3.66",
          "3.68",
          "3.68",
          "3.65",
          "3.53",
          "3.48",
          "3.46",
          "3.32",
          "3.34",
          "3.51",
          "3.64",
          "3.59",
          "3.61",
          "3.58",
          "3.54",
          "3.61",
          "3.70",
          "3.70",
          "3.63",
          "3.67",
          "3.67",
          "3.64",
          "3.73",
          "3.71",
          "3.77",
          "3.73",
          "3.72",
          "3.71",
          "3.74",
          "3.71",
          "3.66",
          "3.63",
          "3.57",
          "3.57",
          "3.55",
          "3.48",
          "3.54",
          "3.69",
          "3.91",
          "3.94",
          "4.03",
          "4.01",
          "3.99",
          "4.02",
          "4.11",
          "4.16",
          "4.15",
          "4.11",
          "4.19",
          "4.28",
          "4.40",
          "4.60",
          "4.32",
          "4.50",
          "4.52",
          "4.52",
          "4.54",
          "4.64",
          "4.63",
          "4.68",
          "4.78",
          "4.76",
          "4.60",
          "4.52",
          "4.50",
          "4.57",
          "4.66",
          "4.81",
          "4.90",
          "4.84",
          "4.86",
          "4.85",
          "4.83",
          "4.81",
          "4.83",
          "4.73",
          "4.88",
          "4.82",
          "4.84",
          "4.93",
          "4.95",
          "4.91",
          "5.05",
          "4.97",
          "4.98",
          "4.96",
          "4.91",
          "4.81",
          "4.98",
          "4.98",
          "4.94",
          "4.98",
          "5.09",
          "5.22",
          "5.24",
          "5.32",
          "5.26",
          "5.11",
          "5.13",
          "5.16",
          "5.29",
          "5.43",
          "5.51",
          "5.41",
          "5.42",
          "5.26",
          "5.28",
          "5.13",
          "5.07",
          "5.16",
          "5.26",
          "5.20",
          "5.30",
          "5.22",
          "5.25",
          "5.08",
          "5.02",
          "5.20",
          "5.27",
          "5.28",
          "5.24",
          "5.24",
          "5.42",
          "5.44",
          "5.41",
          "5.36",
          "5.31",
          "5.35",
          "5.33",
          "5.22",
          "5.14",
          "5.09",
          "5.00",
          "4.93",
          "5.01",
          "5.02",
          "5.22",
          "5.26",
          "5.31",
          "5.26",
          "5.24",
          "5.24",
          "5.32",
          "5.28",
          "5.30",
          "5.44",
          "5.51",
          "5.60",
          "5.59",
          "5.55",
          "5.57",
          "5.56",
          "5.63",
          "5.71",
          "5.73",
          "5.89",
          "5.89",
          "5.94",
          "5.92",
          "6.02",
          "5.96",
          "5.94",
          "5.85",
          "5.92",
          "5.95",
          "5.97",
          "5.89",
          "5.80",
          "5.83",
          "5.83",
          "5.90",
          "5.83",
          "5.86",
          "5.79",
          "5.69",
          "5.67",
          "5.72",
          "5.82",
          "5.80",
          "5.87",
          "5.93",
          "5.98",
          "5.95",
          "5.93",
          "6.01",
          "5.93",
          "6.07",
          "6.17",
          "6.24",
          "6.41",
          "6.49",
          "6.47",
          "6.41",
          "6.48",
          "6.57",
          "6.47",
          "6.40",
          "6.53",
          "6.71",
          "6.62",
          "6.74",
          "6.67",
          "6.61",
          "6.47",
          "6.57",
          "6.46",
          "6.28",
          "6.14",
          "6.13",
          "5.96",
          "5.96",
          "6.21",
          "6.42",
          "6.39",
          "6.33",
          "6.14",
          "6.04",
          "6.07",
          "5.98",
          "6.12",
          "6.24",
          "6.33",
          "6.32",
          "6.43",
          "6.38",
          "6.37",
          "6.49",
          "6.55",
          "6.42",
          "6.21",
          "6.29",
          "6.15",
          "6.06",
          "5.97",
          "6.11",
          "5.83",
          "5.71",
          "5.33",
          "3.78",
          "4.09",
          "4.87",
          "4.34",
          "4.19",
          "3.78",
          "3.94",
          "3.99",
          "4.22",
          "4.54",
          "4.70",
          "4.35",
          "4.27",
          "4.48",
          "4.44",
          "4.26",
          "4.18",
          "4.21",
          "4.35",
          "4.34",
          "4.45",
          "4.33",
          "4.52",
          "4.37",
          "4.35",
          "4.38",
          "4.56",
          "4.62",
          "4.50",
          "4.30",
          "4.32",
          "4.33",
          "4.13",
          "4.07",
          "4.27",
          "4.49",
          "4.56",
          "4.55",
          "4.50",
          "4.72",
          "4.81",
          "4.86",
          "4.65",
          "4.88",
          "4.95",
          "4.96",
          "5.08",
          "5.17",
          "4.97",
          "4.95",
          "4.94",
          "4.87",
          "5.11",
          "5.20",
          "5.22",
          "5.24",
          "4.80",
          "4.97",
          "4.80",
          "4.86",
          "4.85",
          "4.97",
          "5.00",
          "4.94",
          "4.69",
          "4.77",
          "4.75",
          "4.88",
          "4.83",
          "4.77",
          "4.73",
          "4.78",
          "4.76",
          "4.73",
          "4.60",
          "4.54",
          "4.55",
          "4.46",
          "4.58",
          "4.69",
          "4.79",
          "4.84",
          "4.85",
          "4.85",
          "4.73",
          "4.79",
          "4.89",
          "4.98",
          "5.04",
          "4.96",
          "4.93",
          "5.04",
          "5.07",
          "5.11",
          "5.11",
          "4.99",
          "5.06",
          "5.11",
          "5.20",
          "5.05",
          "5.07",
          "5.18",
          "5.15",
          "5.20",
          "5.26",
          "5.25",
          "5.18",
          "5.15",
          "5.16",
          "5.01",
          "4.96",
          "4.91",
          "4.98",
          "4.89",
          "4.86",
          "4.72",
          "4.67",
          "4.81",
          "4.80",
          "4.73",
          "4.68",
          "4.64",
          "4.65",
          "4.54",
          "4.60",
          "4.57",
          "4.57",
          "4.56",
          "4.51",
          "4.52",
          "4.62",
          "4.75",
          "4.76",
          "4.76",
          "4.72",
          "4.79",
          "4.78",
          "4.71",
          "4.70",
          "4.69",
          "4.70",
          "4.67",
          "4.60",
          "4.57",
          "4.59",
          "4.67",
          "4.68",
          "4.64",
          "4.62",
          "4.56",
          "4.51",
          "4.55",
          "4.60",
          "4.69",
          "4.67",
          "4.77",
          "4.90",
          "4.97",
          "5.03",
          "5.03",
          "5.01",
          "5.11",
          "5.16",
          "5.19",
          "5.18",
          "5.17",
          "5.10",
          "5.04",
          "5.05",
          "5.10",
          "5.14",
          "5.27",
          "5.33",
          "5.34",
          "5.23",
          "5.18",
          "5.17",
          "5.18",
          "5.22",
          "5.23",
          "5.25",
          "5.21",
          "5.11",
          "5.14",
          "5.05",
          "5.05",
          "5.06",
          "5.08",
          "5.17",
          "5.22",
          "5.17",
          "5.09",
          "5.02",
          "4.96",
          "4.99",
          "5.00",
          "5.07",
          "5.19",
          "5.21",
          "5.19",
          "5.25",
          "5.21",
          "5.19",
          "5.21",
          "5.14",
          "5.04",
          "5.09",
          "5.15",
          "5.09",
          "5.07",
          "5.13",
          "5.08",
          "5.05",
          "5.02",
          "4.94",
          "5.05",
          "5.01",
          "5.06",
          "5.11",
          "5.15",
          "5.15",
          "5.04",
          "5.16",
          "5.20",
          "5.17",
          "5.22",
          "5.35",
          "5.33",
          "5.25",
          "5.20",
          "5.17",
          "5.23",
          "5.18",
          "5.19",
          "5.20",
          "5.20",
          "5.19",
          "5.18",
          "5.20",
          "5.20",
          "5.27",
          "5.33",
          "5.31",
          "5.29",
          "5.31",
          "5.28",
          "5.38",
          "5.45",
          "5.43",
          "5.38",
          "5.38",
          "5.43",
          "5.42",
          "5.45",
          "5.39",
          "5.51",
          "5.54",
          "5.59",
          "5.58",
          "5.55",
          "5.51",
          "5.44",
          "5.44",
          "5.38",
          "5.33",
          "5.32",
          "5.25",
          "5.18",
          "5.19",
          "5.20",
          "5.19",
          "5.08",
          "5.03",
          "4.98",
          "4.98",
          "5.03",
          "5.10",
          "5.17",
          "5.18",
          "5.16",
          "5.08",
          "5.09",
          "5.11",
          "5.21",
          "5.17",
          "5.18",
          "5.24",
          "5.32",
          "5.36",
          "5.35",
          "5.39",
          "5.35",
          "5.40",
          "5.38",
          "5.37",
          "5.36",
          "5.40",
          "5.38",
          "5.32",
          "5.34",
          "5.35",
          "5.35",
          "5.37",
          "5.47",
          "5.40",
          "5.40",
          "5.54",
          "5.53",
          "5.56",
          "5.59",
          "5.63",
          "5.64",
          "5.66",
          "5.64",
          "5.65",
          "5.63",
          "5.67",
          "5.59",
          "5.64",
          "5.73",
          "5.87",
          "5.92",
          "5.98",
          "6.10",
          "6.14",
          "6.16",
          "6.14",
          "6.11",
          "6.09",
          "6.02",
          "6.20",
          "6.16",
          "6.15",
          "6.03",
          "6.09",
          "6.11",
          "6.09",
          "6.14",
          "6.17",
          "6.18",
          "6.08",
          "6.08",
          "5.98",
          "6.02",
          "6.08",
          "6.11",
          "6.16",
          "6.28",
          "6.38",
          "6.33",
          "6.25",
          "6.22",
          "6.28",
          "6.31",
          "6.28",
          "6.35",
          "6.45",
          "6.34",
          "6.20",
          "6.25",
          "6.30",
          "6.23",
          "6.24",
          "6.24",
          "6.34",
          "6.39",
          "6.45",
          "6.57",
          "6.57",
          "6.50",
          "6.44",
          "6.53",
          "6.56",
          "6.56",
          "6.56",
          "6.47",
          "6.61",
          "6.71",
          "6.77",
          "6.93",
          "6.94",
          "6.98",
          "6.94",
          "6.88",
          "6.93",
          "7.01",
          "6.99",
          "7.05",
          "7.01",
          "7.04",
          "7.00",
          "6.99",
          "6.93",
          "6.98",
          "7.07",
          "7.17",
          "7.57",
          "7.73",
          "7.61",
          "7.64",
          "7.65",
          "7.69",
          "7.83",
          "7.73",
          "7.64",
          "7.70",
          "7.72",
          "7.59",
          "7.64",
          "7.65",
          "7.75",
          "7.72",
          "7.66",
          "7.74",
          "7.74",
          "7.72",
          "7.67",
          "7.55",
          "7.56",
          "7.51",
          "7.43",
          "7.47",
          "7.54",
          "7.64",
          "7.71",
          "7.82",
          "7.85",
          "8.07",
          "7.99",
          "7.79",
          "7.77",
          "7.71",
          "7.81",
          "7.96",
          "8.01",
          "8.04",
          "8.10",
          "8.12",
          "8.20",
          "8.22",
          "8.42",
          "8.37",
          "8.38",
          "8.32",
          "8.35",
          "8.35",
          "8.58",
          "9.02",
          "8.94",
          "8.79",
          "8.83",
          "9.02",
          "9.42",
          "9.94",
          "9.54",
          "9.43",
          "9.81",
          "9.60",
          "9.69",
          "9.53",
          "9.51",
          "9.48",
          "9.48",
          "9.34",
          "9.16",
          "9.45",
          "9.39",
          "9.37",
          "9.63",
          "9.57",
          "9.59",
          "9.60",
          "9.66",
          "9.65",
          "9.75",
          "10.04",
          "9.99",
          "9.97",
          "9.86",
          "9.70",
          "9.66",
          "9.43",
          "9.28",
          "9.35",
          "9.58",
          "9.71",
          "9.63",
          "9.70",
          "9.69",
          "9.73",
          "9.88",
          "9.89",
          "9.88",
          "9.93",
          "10.26",
          "10.46",
          "10.39",
          "10.33",
          "10.51",
          "11.06",
          "10.78",
          "10.75",
          "10.70",
          "10.11",
          "10.14",
          "9.96",
          "9.87",
          "10.20",
          "10.22",
          "10.48",
          "10.17",
          "10.29",
          "10.02",
          "9.92",
          "10.12",
          "10.18",
          "10.12",
          "10.10",
          "10.09",
          "10.01",
          "9.93",
          "10.10",
          "10.23",
          "10.37",
          "10.41",
          "10.27",
          "10.51",
          "10.58",
          "10.68",
          "10.64",
          "10.52",
          "10.58",
          "10.66",
          "10.75",
          "10.68",
          "10.58",
          "10.60",
          "10.68",
          "10.59",
          "10.65",
          "10.90",
          "10.75",
          "10.65",
          "10.78",
          "10.65",
          "10.74",
          "10.74",
          "10.68",
          "10.35",
          "10.14",
          "9.20",
          "9.39",
          "9.41",
          "9.35",
          "8.98",
          "9.22",
          "9.20",
          "9.49",
          "9.51",
          "9.50",
          "9.53",
          "9.60",
          "9.59",
          "9.28",
          "9.28",
          "9.02",
          "9.03",
          "9.19",
          "9.05",
          "9.02",
          "9.00",
          "8.71",
          "8.62",
          "8.67",
          "8.59",
          "8.57",
          "8.70",
          "8.63",
          "8.56",
          "8.38",
          "8.61",
          "8.86",
          "8.84",
          "8.96",
          "8.96",
          "8.91",
          "8.80",
          "8.84",
          "8.97",
          "9.06",
          "9.02",
          "8.85",
          "8.78",
          "8.74",
          "8.61",
          "8.82",
          "8.93",
          "9.00",
          "8.88",
          "8.97",
          "9.09",
          "9.21",
          "9.19",
          "9.24",
          "9.11",
          "9.20",
          "9.32",
          "9.48",
          "9.45",
          "9.53",
          "9.50",
          "9.52",
          "9.44",
          "9.19",
          "9.39",
          "9.27",
          "9.37",
          "9.32",
          "9.20",
          "9.13",
          "9.13",
          "9.35",
          "9.20",
          "9.39",
          "9.36",
          "9.35",
          "9.37",
          "9.45",
          "9.42",
          "9.39",
          "9.39",
          "9.25",
          "9.18",
          "9.20",
          "9.13",
          "9.06",
          "9.14",
          "9.10",
          "9.01",
          "9.08",
          "9.09",
          "9.14",
          "9.19",
          "9.26",
          "9.31",
          "9.23",
          "9.12",
          "8.99",
          "9.21",
          "9.25",
          "9.27",
          "9.25",
          "9.24",
          "9.45",
          "9.55",
          "9.82",
          "9.76",
          "9.78",
          "9.66",
          "9.84",
          "10.33",
          "10.60",
          "10.56",
          "10.54",
          "10.78",
          "10.69",
          "10.54",
          "10.14",
          "10.40",
          "10.68",
          "10.66",
          "10.60",
          "10.83",
          "10.45",
          "10.65",
          "10.60",
          "10.80",
          "10.50",
          "10.37",
          "10.25",
          "10.43",
          "10.63",
          "10.54",
          "10.59",
          "10.73",
          "10.64",
          "10.76",
          "10.96",
          "10.78",
          "11.05",
          "11.05",
          "11.04",
          "10.86",
          "10.78",
          "10.68",
          "10.68",
          "10.56",
          "10.19",
          "9.94",
          "9.77",
          "9.94",
          "9.53",
          "9.69",
          "9.66",
          "9.56",
          "9.18",
          "9.00",
          "8.41",
          "8.49",
          "8.87",
          "9.02",
          "8.77",
          "8.96",
          "8.84",
          "8.93",
          "8.61",
          "8.43",
          "8.38",
          "8.23",
          "8.06",
          "7.82",
          "8.04",
          "8.47",
          "8.49",
          "8.53",
          "8.40",
          "8.41",
          "8.36",
          "8.41",
          "8.30",
          "8.37",
          "8.20",
          "8.19",
          "8.14",
          "7.91",
          "7.77",
          "7.79",
          "7.73",
          "7.43",
          "7.30",
          "7.36",
          "7.27",
          "7.41",
          "7.44",
          "7.33",
          "7.45",
          "7.91",
          "7.85",
          "7.73",
          "7.70",
          "7.68",
          "7.73",
          "7.51",
          "7.51",
          "7.45",
          "7.42",
          "7.35",
          "7.23",
          "7.18",
          "7.56",
          "7.67",
          "7.88",
          "7.93",
          "7.87",
          "7.86",
          "7.64",
          "7.47",
          "7.51",
          "7.48",
          "7.56",
          "7.82",
          "7.85",
          "7.59",
          "7.48",
          "7.49",
          "7.59",
          "7.72",
          "7.80",
          "7.80",
          "7.75",
          "7.82",
          "8.15",
          "8.03",
          "7.84",
          "7.87",
          "8.03",
          "8.15",
          "8.00",
          "7.95",
          "8.19",
          "8.31",
          "8.40",
          "8.71",
          "8.67",
          "8.58",
          "8.47",
          "8.30",
          "8.50",
          "8.62",
          "8.46",
          "8.35",
          "8.41",
          "8.28",
          "8.25",
          "8.30",
          "8.26",
          "8.34",
          "8.33",
          "8.39",
          "8.34",
          "8.30",
          "8.26",
          "8.23",
          "8.00",
          "7.83",
          "7.76",
          "7.87",
          "7.85",
          "7.79",
          "7.72",
          "7.90",
          "8.36",
          "8.47",
          "8.38",
          "8.18",
          "8.31",
          "8.18",
          "8.36",
          "8.39",
          "8.44",
          "8.88",
          "8.89",
          "9.12",
          "9.56",
          "9.82",
          "9.74",
          "9.62",
          "9.50",
          "9.70",
          "9.84",
          "9.99",
          "9.70",
          "9.69",
          "9.75",
          "9.63",
          "9.76",
          "9.98",
          "10.15",
          "9.97",
          "10.19",
          "10.14",
          "10.18",
          "10.02",
          "10.27",
          "10.40",
          "10.43",
          "10.47",
          "10.35",
          "10.17",
          "10.28",
          "10.17",
          "10.13",
          "10.13",
          "9.95",
          "10.01",
          "9.71",
          "9.78",
          "9.49",
          "9.80",
          "9.80",
          "9.78",
          "9.64",
          "9.87",
          "9.88",
          "9.92",
          "9.83",
          "9.76",
          "9.63",
          "9.53",
          "9.45",
          "9.49",
          "9.56",
          "9.91",
          "9.94",
          "9.84",
          "9.63",
          "9.61",
          "9.78",
          "9.70",
          "9.46",
          "9.45",
          "9.44",
          "9.46",
          "9.66",
          "9.69",
          "9.91",
          "10.04",
          "9.85",
          "9.84",
          "10.13",
          "9.93",
          "9.98",
          "9.89",
          "9.73",
          "9.84",
          "9.85",
          "9.91",
          "9.91",
          "9.88",
          "9.90",
          "9.91",
          "9.93",
          "9.76",
          "9.59",
          "9.53",
          "9.70",
          "9.65",
          "9.67",
          "9.45",
          "9.50",
          "9.58",
          "9.57",
          "9.52",
          "9.52",
          "9.58",
          "9.51",
          "9.47",
          "9.33",
          "9.31",
          "9.31",
          "9.22",
          "9.27",
          "9.19",
          "9.10",
          "9.16",
          "9.35",
          "9.77",
          "9.73",
          "9.74",
          "9.81",
          "9.83",
          "9.85",
          "9.96",
          "10.04",
          "10.03",
          "9.97",
          "9.87",
          "10.00",
          "10.00",
          "10.02",
          "9.97",
          "9.81",
          "9.69",
          "9.63",
          "9.65",
          "9.85",
          "9.92",
          "9.95",
          "9.92",
          "9.84",
          "9.95",
          "9.83",
          "9.85",
          "9.83",
          "9.90",
          "9.93",
          "10.04",
          "9.84",
          "9.60",
          "9.33",
          "9.27",
          "9.61",
          "9.55",
          "9.69",
          "9.78",
          "9.73",
          "9.79",
          "9.73",
          "9.76",
          "9.59",
          "9.45",
          "9.48",
          "9.46",
          "9.49",
          "9.36",
          "9.29",
          "9.28",
          "9.22",
          "9.34",
          "9.37",
          "9.24",
          "9.11",
          "9.10",
          "9.22",
          "9.39",
          "9.33",
          "9.40",
          "9.37",
          "9.37",
          "9.24",
          "9.25",
          "9.24",
          "9.29",
          "9.25",
          "9.34",
          "9.23",
          "9.34",
          "9.47",
          "9.49",
          "9.45",
          "9.65",
          "9.59",
          "9.79",
          "9.72",
          "9.80",
          "9.85",
          "9.74",
          "9.66",
          "9.75",
          "9.92",
          "9.84",
          "9.76",
          "9.89",
          "9.73",
          "9.35",
          "9.28",
          "9.42",
          "9.44",
          "9.42",
          "9.27",
          "9.07",
          "9.19",
          "8.96",
          "8.96",
          "8.76",
          "8.82",
          "8.78",
          "8.70",
          "8.68",
          "8.63",
          "8.61",
          "8.61",
          "8.80",
          "8.79",
          "8.74",
          "8.69",
          "8.67",
          "8.64",
          "8.60",
          "8.58",
          "8.74",
          "8.89",
          "8.97",
          "8.87",
          "9.00",
          "8.93",
          "8.71",
          "9.02",
          "9.13",
          "9.08",
          "9.31",
          "9.44",
          "9.41",
          "9.52",
          "9.78",
          "9.85",
          "9.86",
          "10.07",
          "10.52",
          "10.48",
          "10.41",
          "10.74",
          "10.95",
          "10.84",
          "10.88",
          "10.80",
          "10.60",
          "10.83",
          "10.73",
          "10.94",
          "10.89",
          "11.63",
          "11.40",
          "11.40",
          "11.22",
          "11.49",
          "11.65",
          "11.70",
          "11.54",
          "11.47",
          "11.81",
          "11.94",
          "11.86",
          "11.63",
          "11.75",
          "12.04",
          "11.97",
          "12.47",
          "12.56",
          "12.68",
          "12.58",
          "12.80",
          "12.66",
          "12.63",
          "12.69",
          "12.73",
          "12.76",
          "12.77",
          "12.76",
          "12.46",
          "12.46",
          "12.21",
          "12.10",
          "12.24",
          "12.32",
          "12.39",
          "12.44",
          "12.45",
          "12.31",
          "12.31",
          "12.15",
          "12.28",
          "12.16",
          "12.14",
          "12.38",
          "12.46",
          "12.66",
          "12.86",
          "13.01",
          "13.06",
          "12.68",
          "12.29",
          "12.43",
          "12.55",
          "12.52",
          "12.28",
          "12.32",
          "12.49",
          "12.42",
          "12.46",
          "12.60",
          "12.54",
          "12.43",
          "12.26",
          "12.10",
          "12.20",
          "11.98",
          "12.00",
          "12.15",
          "12.17",
          "12.35",
          "12.32",
          "12.35",
          "12.32",
          "12.19",
          "12.49",
          "12.36",
          "12.56",
          "13.02",
          "13.43",
          "12.94",
          "12.65",
          "12.45",
          "12.28",
          "12.28",
          "12.57",
          "12.49",
          "12.69",
          "12.82",
          "12.86",
          "12.24",
          "12.00",
          "12.20",
          "12.04",
          "11.87",
          "12.08",
          "12.04",
          "12.12",
          "12.00",
          "11.87",
          "11.83",
          "12.00",
          "11.91",
          "11.54",
          "11.50",
          "11.83",
          "11.67",
          "11.75",
          "11.87",
          "12.08",
          "11.87",
          "11.50",
          "11.38",
          "11.34",
          "11.26",
          "11.34",
          "11.22",
          "11.79",
          "12.04",
          "11.95",
          "11.79",
          "11.75",
          "11.83",
          "11.75",
          "11.67",
          "11.71",
          "11.75",
          "11.67",
          "11.87",
          "11.95",
          "11.83",
          "11.83",
          "11.67",
          "11.59",
          "11.59",
          "11.75",
          "11.67",
          "11.75",
          "11.83",
          "11.95",
          "11.91",
          "11.91",
          "11.79",
          "11.34",
          "11.30",
          "11.09",
          "10.80",
          "10.80",
          "11.13",
          "11.30",
          "11.50",
          "11.34",
          "11.34",
          "11.42",
          "11.54",
          "11.42",
          "11.42",
          "11.59",
          "12.08",
          "12.20",
          "12.04",
          "11.95",
          "12.08",
          "12.08",
          "12.08",
          "12.16",
          "12.08",
          "12.04",
          "11.91",
          "11.95",
          "12.00",
          "12.00",
          "11.91",
          "11.67",
          "11.46",
          "11.46",
          "11.34",
          "11.26",
          "11.54",
          "11.26",
          "11.63",
          "11.87",
          "11.67",
          "11.71",
          "11.83",
          "11.91",
          "11.83",
          "11.91",
          "12.08",
          "12.65",
          "12.82",
          "13.06",
          "13.23",
          "13.02",
          "12.94",
          "13.15",
          "13.02",
          "13.15",
          "13.15",
          "13.19",
          "13.43",
          "13.72",
          "13.68",
          "13.52",
          "13.39",
          "13.23",
          "13.39",
          "13.23",
          "13.43",
          "13.64",
          "13.48",
          "13.72",
          "13.48",
          "13.52",
          "13.87",
          "13.27",
          "13.27",
          "13.19",
          "13.39",
          "13.52",
          "13.60",
          "13.43",
          "13.31",
          "13.15",
          "13.31",
          "13.27",
          "13.23",
          "13.56",
          "13.89",
          "13.89",
          "13.93",
          "13.89",
          "14.13",
          "14.54",
          "14.50",
          "14.30",
          "14.13",
          "14.05",
          "14.13",
          "14.46",
          "14.17",
          "14.17",
          "13.97",
          "13.93",
          "14.21",
          "14.63",
          "14.75",
          "14.67",
          "14.58",
          "14.63",
          "14.79",
          "14.54",
          "14.26",
          "15.16",
          "14.95",
          "15.12",
          "15.00",
          "15.00",
          "15.32",
          "15.65",
          "15.61",
          "15.57",
          "15.57",
          "15.24",
          "15.36",
          "15.45",
          "15.45",
          "14.75",
          "14.79",
          "14.91",
          "14.79",
          "15.00",
          "14.21",
          "14.63",
          "14.75",
          "14.58",
          "14.38",
          "14.50",
          "14.67",
          "14.75",
          "14.95",
          "15.04",
          "14.95",
          "14.79",
          "14.75",
          "14.75",
          "14.87",
          "14.87",
          "14.67",
          "14.87",
          "14.71",
          "14.58",
          "14.46",
          "14.34",
          "14.42",
          "14.50",
          "14.34",
          "14.50",
          "14.63",
          "14.63",
          "14.42",
          "13.93",
          "13.97",
          "13.64",
          "13.39",
          "13.76",
          "13.93",
          "13.64",
          "13.68",
          "13.60",
          "13.76",
          "13.64",
          "13.35",
          "13.23",
          "13.31",
          "13.48",
          "13.43",
          "13.52",
          "13.60",
          "13.39",
          "13.23",
          "13.23",
          "12.94",
          "12.65",
          "12.74",
          "12.98",
          "13.15",
          "13.02",
          "12.74",
          "12.94",
          "13.02",
          "13.15",
          "13.64",
          "14.46",
          "14.13",
          "14.54",
          "14.42",
          "14.58",
          "14.58",
          "14.46",
          "14.46",
          "14.34",
          "14.46",
          "14.54",
          "14.50",
          "14.38",
          "14.21",
          "14.17",
          "14.34",
          "14.26",
          "14.38",
          "14.21",
          "14.21",
          "14.01",
          "14.01",
          "14.01",
          "13.31",
          "13.76",
          "13.68",
          "13.80",
          "13.52",
          "13.39",
          "13.39",
          "13.39",
          "13.15",
          "12.74",
          "12.74",
          "12.90",
          "12.37",
          "12.28",
          "12.49",
          "12.45",
          "12.41",
          "12.04",
          "12.04",
          "11.95",
          "12.20",
          "12.90",
          "13.06",
          "13.15",
          "13.06",
          "12.69",
          "12.32",
          "12.28",
          "12.28",
          "12.00",
          "12.00",
          "12.08",
          "12.28",
          "12.20",
          "12.12",
          "12.37",
          "12.37",
          "12.86",
          "12.82",
          "12.78",
          "12.69",
          "13.02",
          "12.74",
          "12.78",
          "12.86",
          "12.94",
          "13.23",
          "13.06",
          "12.90",
          "12.82",
          "12.78",
          "12.90",
          "12.78",
          "12.82",
          "12.61",
          "13.15",
          "13.39",
          "13.35",
          "12.86",
          "12.90",
          "12.94",
          "12.69",
          "12.61",
          "12.61",
          "12.65",
          "12.61",
          "12.69",
          "12.57",
          "12.49",
          "12.41",
          "12.45",
          "12.49",
          "12.41",
          "12.69",
          "12.90",
          "12.98",
          "13.02",
          "13.06",
          "14.63",
          "14.42",
          "14.38",
          "14.26",
          "13.93",
          "13.80",
          "13.68",
          "13.64",
          "13.76",
          "13.80",
          "13.76",
          "13.89",
          "14.13",
          "14.34",
          "14.13",
          "13.93",
          "13.97",
          "13.68",
          "13.60",
          "13.56",
          "13.52",
          "13.27",
          "13.19",
          "13.15",
          "13.15",
          "12.90",
          "12.94",
          "12.82",
          "12.53",
          "12.78",
          "12.82",
          "12.78",
          "12.94",
          "13.11",
          "13.39",
          "13.60",
          "13.56",
          "13.48",
          "13.64",
          "13.68",
          "13.89",
          "13.80",
          "13.97",
          "13.80",
          "13.84",
          "13.97",
          "14.26",
          "14.13",
          "14.21",
          "14.63",
          "14.71",
          "14.58",
          "14.46",
          "14.42",
          "14.38",
          "14.01",
          "14.17",
          "14.67",
          "14.75",
          "14.91",
          "15.28",
          "15.69",
          "15.49",
          "15.32",
          "15.12",
          "15.65",
          "15.32",
          "15.41",
          "15.49",
          "15.57",
          "15.61",
          "15.41",
          "15.45",
          "15.49",
          "15.32",
          "15.49",
          "15.53",
          "15.49",
          "15.45",
          "15.41",
          "14.79",
          "15.32",
          "15.00",
          "14.87",
          "15.00",
          "15.08",
          "15.24",
          "15.36",
          "15.41",
          "15.36",
          "14.91",
          "15.08",
          "15.32",
          "15.57",
          "15.73",
          "15.78",
          "15.65",
          "15.36",
          "15.04",
          "15.16",
          "15.24",
          "15.24",
          "15.69",
          "15.41",
          "15.45",
          "15.08",
          "15.12",
          "15.04",
          "15.04",
          "15.45",
          "15.16",
          "15.36",
          "15.20",
          "14.87",
          "14.79",
          "14.46",
          "14.17",
          "13.89",
          "13.80",
          "13.68",
          "14.09",
          "13.93",
          "13.93",
          "13.72",
          "13.89",
          "13.89",
          "13.72",
          "13.60",
          "13.43",
          "13.15",
          "13.68",
          "13.43",
          "13.84",
          "14.01",
          "13.93",
          "14.30",
          "13.93",
          "13.97",
          "13.89",
          "14.09",
          "13.93",
          "13.93",
          "13.68",
          "13.39",
          "13.56",
          "13.76",
          "13.68",
          "13.68",
          "13.72",
          "13.97",
          "14.17",
          "14.54",
          "14.17",
          "14.09",
          "14.09",
          "14.50",
          "14.30",
          "14.54",
          "14.26",
          "14.34",
          "14.42",
          "14.67",
          "14.46",
          "14.54",
          "14.34",
          "14.46",
          "14.46",
          "14.50",
          "14.67",
          "14.67",
          "14.83",
          "14.67",
          "14.67",
          "14.13",
          "13.97",
          "14.01",
          "13.80",
          "14.17",
          "14.17",
          "14.01",
          "13.68",
          "13.97",
          "14.09",
          "13.89",
          "13.93",
          "13.93",
          "13.84",
          "13.80",
          "13.72",
          "13.72",
          "13.72",
          "13.80",
          "13.68",
          "13.43",
          "13.60",
          "13.72",
          "13.56",
          "13.68",
          "13.84",
          "13.84",
          "13.93",
          "14.01",
          "14.05",
          "13.97",
          "13.89",
          "13.93",
          "14.01",
          "14.09",
          "14.30",
          "14.38",
          "14.34",
          "14.26",
          "14.05",
          "14.26",
          "13.97",
          "13.89",
          "13.80",
          "13.97",
          "14.21",
          "14.05",
          "14.01",
          "13.93",
          "13.72",
          "13.56",
          "13.52",
          "13.52",
          "13.64",
          "13.89",
          "14.05",
          "13.89",
          "13.76",
          "13.93",
          "13.60",
          "13.52",
          "13.64",
          "13.68",
          "13.35",
          "13.39",
          "13.23",
          "12.98",
          "13.23",
          "13.02",
          "13.11",
          "13.11",
          "12.74",
          "13.11",
          "12.82",
          "12.65",
          "12.65",
          "12.45",
          "12.82",
          "13.02",
          "12.78",
          "12.69",
          "12.74",
          "12.78",
          "12.61",
          "12.74",
          "12.61",
          "12.69",
          "12.74",
          "12.74",
          "13.11",
          "12.86",
          "13.15",
          "12.98",
          "12.82",
          "12.78",
          "13.15",
          "12.94",
          "13.06",
          "13.48",
          "13.89",
          "14.09",
          "13.80",
          "14.13",
          "14.21",
          "14.42",
          "14.05",
          "13.93",
          "13.93",
          "14.13",
          "14.13",
          "14.13",
          "14.63",
          "14.50",
          "14.30",
          "14.17",
          "14.30",
          "14.30",
          "14.26",
          "14.34",
          "14.17",
          "14.01",
          "13.97",
          "14.38",
          "14.42",
          "14.42",
          "14.58",
          "14.71",
          "14.67",
          "14.75",
          "15.12",
          "15.24",
          "15.24",
          "15.32",
          "15.00",
          "15.12",
          "15.32",
          "15.20",
          "15.16",
          "15.12",
          "15.08",
          "14.95",
          "15.12",
          "15.24",
          "15.32",
          "15.65",
          "15.65",
          "15.53",
          "15.32",
          "15.53",
          "15.41",
          "15.69",
          "15.36",
          "15.53",
          "16.52",
          "16.80",
          "16.72",
          "16.76",
          "16.72",
          "17.09",
          "17.25",
          "17.13",
          "17.50",
          "17.25",
          "17.34",
          "17.30",
          "17.21",
          "17.62",
          "17.54",
          "17.48",
          "17.21",
          "17.42",
          "17.54",
          "17.62",
          "17.50",
          "17.54",
          "17.75",
          "17.75",
          "17.67",
          "18.40",
          "17.99",
          "17.99",
          "17.95",
          "18.45",
          "17.91",
          "18.04",
          "17.83",
          "17.99",
          "18.20",
          "18.08",
          "18.20",
          "18.24",
          "18.08",
          "18.24",
          "18.24",
          "18.16",
          "18.04",
          "17.99",
          "17.58",
          "17.95",
          "18.12",
          "17.95",
          "17.87",
          "18.04",
          "17.95",
          "18.08",
          "18.08",
          "17.95",
          "17.91",
          "17.91",
          "17.75",
          "17.91",
          "17.99",
          "18.32",
          "18.32",
          "18.36",
          "18.61",
          "18.20",
          "17.75",
          "17.71",
          "17.83",
          "17.75",
          "17.71",
          "17.87",
          "17.83",
          "17.42",
          "17.75",
          "17.87",
          "18.12",
          "18.16",
          "18.12",
          "17.83",
          "17.75",
          "17.99",
          "18.12",
          "18.24",
          "18.12",
          "18.20",
          "18.16",
          "18.24",
          "18.36",
          "18.36",
          "18.40",
          "18.90",
          "18.82",
          "18.73",
          "18.86",
          "19.39",
          "19.56",
          "19.14",
          "19.02",
          "18.82",
          "19.19",
          "19.14",
          "19.31",
          "19.56",
          "19.56",
          "18.73",
          "18.45",
          "18.49",
          "18.32",
          "18.24",
          "18.20",
          "18.08",
          "18.28",
          "18.20",
          "17.95",
          "17.79",
          "17.62",
          "17.99",
          "17.99",
          "17.91",
          "17.62",
          "17.58",
          "17.75",
          "17.95",
          "18.08",
          "18.32",
          "18.73",
          "18.82",
          "18.86",
          "19.27",
          "20.21",
          "19.92",
          "19.51",
          "19.06",
          "18.69",
          "19.06",
          "18.98",
          "19.02",
          "18.57",
          "19.39",
          "19.64",
          "19.43",
          "19.43",
          "19.47",
          "19.39",
          "19.31",
          "18.86",
          "18.45",
          "18.65",
          "18.57",
          "18.53",
          "18.61",
          "18.49",
          "18.40",
          "18.12",
          "18.24",
          "18.16",
          "17.99",
          "18.16",
          "18.53",
          "18.86",
          "18.94",
          "18.90",
          "18.90",
          "19.10",
          "19.02",
          "18.94",
          "18.73",
          "19.10",
          "19.23",
          "19.06",
          "18.94",
          "18.86",
          "18.73",
          "18.69",
          "18.90",
          "18.82",
          "18.82",
          "18.45",
          "18.36",
          "18.32",
          "18.20",
          "18.90",
          "18.69",
          "18.49",
          "18.53",
          "18.69",
          "18.82",
          "18.73",
          "18.98",
          "19.02",
          "18.86",
          "18.73",
          "18.94",
          "18.94",
          "18.98",
          "18.94",
          "19.14",
          "19.06",
          "19.06",
          "19.64",
          "19.43",
          "19.51",
          "19.10",
          "18.65",
          "19.02",
          "18.98",
          "19.47",
          "19.80",
          "20.01",
          "20.38",
          "20.54",
          "20.66",
          "20.21",
          "20.25",
          "19.76",
          "20.13",
          "20.38",
          "20.54",
          "20.58",
          "20.09",
          "20.03",
          "20.25",
          "20.21",
          "20.01",
          "19.97",
          "20.09",
          "19.56",
          "19.60",
          "19.60",
          "19.76",
          "19.97",
          "19.84",
          "19.80",
          "19.51",
          "19.35",
          "20.01",
          "20.13",
          "19.88",
          "20.25",
          "20.38",
          "20.17",
          "19.60",
          "19.84",
          "19.72",
          "19.72",
          "19.72",
          "19.56",
          "19.64",
          "19.72",
          "19.88",
          "19.88",
          "19.97",
          "19.80",
          "19.97",
          "20.29",
          "20.91",
          "21.12",
          "20.50",
          "20.38",
          "20.25",
          "20.58",
          "20.95",
          "20.91",
          "20.62",
          "20.79",
          "20.91",
          "21.03",
          "20.99",
          "21.08",
          "20.83",
          "20.95",
          "21.20",
          "21.36",
          "21.36",
          "21.36",
          "21.65",
          "21.53",
          "21.69",
          "21.94",
          "21.94",
          "21.86",
          "21.98",
          "21.57",
          "21.81",
          "22.27",
          "22.55",
          "22.92",
          "22.76",
          "21.98",
          "21.36",
          "21.45",
          "21.20",
          "21.32",
          "21.08",
          "21.20",
          "21.20",
          "21.20",
          "20.99",
          "21.32",
          "21.53",
          "21.57",
          "21.36",
          "21.16",
          "20.91",
          "20.01",
          "19.84",
          "20.17",
          "20.34",
          "20.46",
          "20.50",
          "20.66",
          "20.75",
          "20.58",
          "20.29",
          "19.92",
          "20.13",
          "20.34",
          "20.34",
          "20.38",
          "19.88",
          "19.72",
          "19.39",
          "19.76",
          "19.51",
          "19.39",
          "19.14",
          "19.47",
          "19.97",
          "20.01",
          "19.84",
          "19.97",
          "20.13",
          "20.34",
          "20.50",
          "20.62",
          "20.38",
          "20.54",
          "20.42",
          "19.88",
          "20.13",
          "19.97",
          "19.84",
          "19.76",
          "19.88",
          "20.54",
          "20.79",
          "20.50",
          "20.50",
          "20.50",
          "20.29",
          "20.21",
          "20.38",
          "20.05",
          "20.09",
          "20.29",
          "20.71",
          "20.46",
          "20.79",
          "20.50",
          "20.75",
          "20.66",
          "20.46",
          "20.13",
          "20.05",
          "19.51",
          "19.39",
          "18.90",
          "19.10",
          "18.45",
          "18.69",
          "18.04",
          "18.40",
          "18.40",
          "18.82",
          "18.86",
          "18.40",
          "18.24",
          "17.87",
          "17.87",
          "17.79",
          "17.54",
          "18.16",
          "18.28",
          "18.86",
          "19.06",
          "19.19",
          "19.39",
          "19.19",
          "18.90",
          "18.77",
          "19.23",
          "19.06",
          "19.10",
          "19.02",
          "19.10",
          "19.06",
          "18.82",
          "19.02",
          "19.10",
          "19.19",
          "19.51",
          "19.39",
          "19.14",
          "18.98",
          "18.73",
          "18.73",
          "18.65",
          "18.28",
          "18.73",
          "19.14",
          "19.02",
          "19.27",
          "19.88",
          "19.97",
          "19.80",
          "20.09",
          "20.13",
          "20.87",
          "20.75",
          "20.91",
          "20.62",
          "20.58",
          "20.38",
          "20.71",
          "20.79",
          "20.54",
          "20.66",
          "20.75",
          "21.08",
          "21.08",
          "20.91",
          "20.87",
          "20.91",
          "21.03",
          "21.08",
          "21.16",
          "21.53",
          "21.32",
          "21.36",
          "21.57",
          "21.90",
          "21.73",
          "21.49",
          "21.77",
          "21.81",
          "21.61",
          "21.57",
          "21.65",
          "21.53",
          "21.98",
          "22.23",
          "22.27",
          "22.68",
          "22.51",
          "23.13",
          "23.29",
          "23.21",
          "23.21",
          "23.75",
          "23.46",
          "23.33",
          "23.58",
          "23.75",
          "23.70",
          "24.16",
          "24.98",
          "24.28",
          "24.28",
          "24.28",
          "23.62",
          "23.66",
          "23.62",
          "23.50",
          "24.03",
          "24.07",
          "23.70",
          "23.09",
          "23.09",
          "22.80",
          "23.29",
          "23.50",
          "23.79",
          "23.79",
          "23.70",
          "23.50",
          "23.46",
          "23.42",
          "23.01",
          "22.92",
          "22.14",
          "22.76",
          "22.68",
          "22.64",
          "22.31",
          "22.18",
          "22.31",
          "22.35",
          "22.76",
          "22.72",
          "22.64",
          "23.01",
          "23.17",
          "22.92",
          "23.25",
          "23.13",
          "23.42",
          "23.54",
          "23.87",
          "24.24",
          "23.79",
          "23.95",
          "23.95",
          "23.87",
          "23.91",
          "24.32",
          "24.65",
          "24.85",
          "24.65",
          "25.18",
          "25.43",
          "25.68",
          "25.47",
          "25.02",
          "24.57",
          "24.77",
          "24.85",
          "25.02",
          "24.81",
          "24.94",
          "24.40",
          "24.12",
          "23.91",
          "24.16",
          "24.69",
          "24.98",
          "25.22",
          "25.35",
          "24.77",
          "24.07",
          "24.57",
          "24.98",
          "24.81",
          "24.65",
          "24.49",
          "24.32",
          "24.65",
          "24.57",
          "24.73",
          "24.65",
          "23.95",
          "23.79",
          "23.79",
          "23.87",
          "24.24",
          "24.24",
          "24.28",
          "24.24",
          "24.32",
          "23.79",
          "23.38",
          "24.28",
          "24.85",
          "24.90",
          "25.22",
          "25.27",
          "25.72",
          "26.01",
          "25.72",
          "25.43",
          "25.51",
          "26.54",
          "26.87",
          "26.62",
          "26.42",
          "26.99",
          "27.03",
          "26.46",
          "26.70",
          "27.03",
          "27.77",
          "27.48",
          "27.48",
          "27.24",
          "26.58",
          "27.36",
          "27.44",
          "26.99",
          "26.99",
          "27.36",
          "27.53",
          "27.57",
          "27.24",
          "26.91",
          "26.58",
          "26.50",
          "26.25",
          "26.54",
          "26.74",
          "26.62",
          "26.91",
          "26.74",
          "26.83",
          "26.95",
          "27.57",
          "27.61",
          "27.11",
          "27.40",
          "27.03",
          "26.50",
          "27.11",
          "26.95",
          "26.81",
          "26.27",
          "26.37",
          "25.82",
          "25.27",
          "25.39",
          "24.98",
          "25.66",
          "25.33",
          "25.27",
          "25.12",
          "25.43",
          "25.61",
          "25.92",
          "25.55",
          "24.94",
          "24.71",
          "25.51",
          "25.61",
          "26.07",
          "25.96",
          "26.17",
          "26.33",
          "26.60",
          "26.56",
          "26.48",
          "26.60",
          "26.27",
          "26.56",
          "26.01",
          "26.03",
          "26.17",
          "25.76",
          "25.96",
          "25.92",
          "25.18",
          "25.84",
          "26.19",
          "26.42",
          "25.92",
          "25.68",
          "25.74",
          "25.47",
          "25.80",
          "25.55",
          "25.25",
          "25.96",
          "26.15",
          "25.53",
          "25.70",
          "25.59",
          "25.80",
          "25.55",
          "24.90",
          "25.35",
          "25.37",
          "25.98",
          "25.92",
          "26.07",
          "26.27",
          "26.15",
          "25.96",
          "25.86",
          "26.17",
          "26.13",
          "26.29",
          "26.50",
          "26.95",
          "27.55",
          "27.73",
          "27.28",
          "28.00",
          "27.61",
          "27.67",
          "27.50",
          "27.81",
          "28.41",
          "28.55",
          "27.92",
          "27.16",
          "27.46",
          "27.50",
          "27.30",
          "27.05",
          "27.07",
          "25.55",
          "27.05",
          "27.26",
          "26.50",
          "27.07",
          "28.10",
          "28.35",
          "27.98",
          "28.39",
          "28.37",
          "28.08",
          "28.26",
          "27.30",
          "27.83",
          "28.49",
          "29.25",
          "29.33",
          "30.03",
          "29.81",
          "31.14",
          "30.77",
          "30.77",
          "31.06",
          "31.20",
          "32.09",
          "31.74",
          "31.28",
          "30.75",
          "30.89",
          "31.02",
          "31.00",
          "30.83",
          "30.50",
          "30.77",
          "31.92",
          "31.55",
          "31.82",
          "31.98",
          "31.49",
          "32.00",
          "32.04",
          "31.22",
          "31.24",
          "31.88",
          "32.54",
          "32.54",
          "32.74",
          "32.48",
          "32.35",
          "32.37",
          "32.11",
          "31.55",
          "31.04",
          "31.22",
          "31.72",
          "31.45",
          "31.59",
          "32.66",
          "32.50",
          "31.72",
          "32.41",
          "32.00",
          "33.77",
          "34.51",
          "34.92",
          "35.13",
          "35.76",
          "35.50",
          "35.60",
          "35.60",
          "35.68",
          "35.39",
          "35.66",
          "36.42",
          "36.19",
          "36.62",
          "37.14",
          "37.75",
          "37.53",
          "37.59",
          "37.49",
          "36.79",
          "36.56",
          "36.15",
          "36.79",
          "35.95",
          "36.15",
          "35.13",
          "34.57",
          "34.71",
          "34.78",
          "34.80",
          "34.45",
          "34.82",
          "34.84",
          "36.03",
          "35.39",
          "35.27",
          "34.94",
          "35.17",
          "33.85",
          "35.15",
          "35.13",
          "35.39",
          "35.25",
          "34.69",
          "35.08",
          "34.84",
          "35.78",
          "36.46",
          "35.62",
          "35.45",
          "35.17",
          "35.84",
          "36.23",
          "37.20",
          "37.32",
          "36.81",
          "37.26",
          "37.92",
          "38.37",
          "40.10",
          "40.65",
          "40.14",
          "39.75",
          "40.59",
          "40.71",
          "40.94",
          "41.31",
          "41.78",
          "41.64",
          "40.82",
          "39.17",
          "39.27",
          "39.73",
          "39.05",
          "38.95",
          "38.14",
          "36.36",
          "36.05",
          "36.46",
          "37.67",
          "38.10",
          "38.23",
          "36.73",
          "37.57",
          "37.26",
          "37.22",
          "36.42",
          "36.19",
          "36.01",
          "36.01",
          "37.69",
          "38.08",
          "38.29",
          "38.88",
          "38.19",
          "37.82",
          "36.87",
          "37.10",
          "37.57",
          "36.81",
          "35.35",
          "35.74",
          "36.07",
          "37.04",
          "36.97",
          "36.32",
          "37.20",
          "34.53",
          "34.92",
          "34.98",
          "34.78",
          "35.02",
          "35.60",
          "36.48",
          "37.59",
          "38.82",
          "39.32",
          "38.82",
          "38.76",
          "38.82",
          "38.02",
          "37.22",
          "36.67",
          "35.74",
          "36.30",
          "36.11",
          "34.88",
          "34.88",
          "34.82",
          "33.95",
          "34.20",
          "32.35",
          "33.09",
          "32.85",
          "32.91",
          "32.41",
          "31.67",
          "31.98",
          "31.37",
          "31.31",
          "31.74",
          "33.03",
          "32.66",
          "33.15",
          "32.78",
          "32.91",
          "33.59",
          "32.54",
          "31.31",
          "30.20",
          "27.05",
          "29.95",
          "29.46",
          "27.92",
          "27.85",
          "28.72",
          "27.42",
          "26.68",
          "25.45",
          "24.10",
          "25.14",
          "25.39",
          "25.33",
          "25.20",
          "24.77",
          "24.83",
          "26.44",
          "25.51",
          "25.27",
          "25.88",
          "25.64",
          "25.02",
          "24.59",
          "24.90",
          "24.71",
          "25.02",
          "24.59",
          "23.17",
          "23.36",
          "24.28",
          "25.14",
          "24.03",
          "25.51",
          "25.14",
          "25.70",
          "26.68",
          "27.18",
          "27.61",
          "27.36",
          "27.55",
          "26.44",
          "26.07",
          "26.25",
          "26.56",
          "28.41",
          "29.15",
          "28.22",
          "29.76",
          "29.83",
          "29.15",
          "28.47",
          "28.47",
          "28.16",
          "28.72",
          "28.66",
          "28.66",
          "28.59",
          "27.92",
          "28.10",
          "29.09",
          "29.39",
          "30.50",
          "31.43",
          "31.74",
          "31.06",
          "30.75",
          "30.44",
          "30.94",
          "30.69",
          "31.55",
          "33.09",
          "31.98",
          "31.55",
          "30.20",
          "30.32",
          "30.32",
          "30.44",
          "30.38",
          "30.57",
          "29.52",
          "30.50",
          "30.13",
          "30.01",
          "30.01",
          "29.46",
          "29.58",
          "29.15",
          "30.13",
          "30.50",
          "31.31",
          "32.11",
          "34.76",
          "37.47",
          "35.62",
          "35.56",
          "35.50",
          "35.99",
          "35.50",
          "34.08",
          "33.65",
          "34.45",
          "34.08",
          "32.48",
          "33.03",
          "32.54",
          "33.22",
          "32.91",
          "33.15",
          "32.66",
          "33.77",
          "33.52",
          "33.03",
          "33.59",
          "34.69",
          "34.94",
          "34.57",
          "34.39",
          "34.02",
          "33.65",
          "34.39",
          "34.39",
          "34.57",
          "34.02",
          "34.69",
          "34.32",
          "33.71",
          "34.02",
          "34.69",
          "35.31",
          "35.93",
          "34.20",
          "34.02",
          "34.20",
          "35.56",
          "35.37",
          "34.57",
          "34.02",
          "34.94",
          "34.57",
          "33.89",
          "32.72",
          "32.72",
          "32.91",
          "32.48",
          "32.60",
          "32.35",
          "30.69",
          "31.06",
          "31.06",
          "30.20",
          "32.41",
          "33.59",
          "34.02",
          "34.94",
          "34.26",
          "34.51",
          "33.59",
          "32.91",
          "31.98",
          "32.60",
          "32.97",
          "32.97",
          "34.02",
          "34.51",
          "32.04",
          "32.04",
          "29.95",
          "31.31",
          "30.81",
          "30.32",
          "29.64",
          "29.46",
          "29.58",
          "29.33",
          "29.02",
          "28.78",
          "28.96",
          "28.96",
          "28.59",
          "29.15",
          "29.52",
          "29.39",
          "29.09",
          "29.46",
          "29.46",
          "29.58",
          "29.21",
          "28.72",
          "28.41",
          "28.72",
          "28.59",
          "30.20",
          "30.44",
          "29.21",
          "29.02",
          "28.96",
          "28.47",
          "28.59",
          "28.84",
          "28.96",
          "29.58",
          "30.07",
          "30.32",
          "29.70",
          "29.15",
          "29.15",
          "29.27",
          "28.90",
          "29.70",
          "30.38",
          "28.59",
          "27.92",
          "27.55",
          "27.67",
          "27.42",
          "27.24",
          "27.42",
          "27.18",
          "27.11",
          "27.73",
          "27.61",
          "27.42",
          "26.99",
          "26.81",
          "26.62",
          "26.44",
          "27.11",
          "27.85",
          "27.98",
          "27.36",
          "27.18",
          "26.81",
          "26.44",
          "25.64",
          "25.51",
          "25.14",
          "25.39",
          "25.27",
          "25.51",
          "25.76",
          "26.81",
          "27.55",
          "28.10",
          "28.78",
          "28.96",
          "29.46",
          "29.58",
          "29.39",
          "29.46",
          "29.09",
          "28.72",
          "28.10",
          "27.36",
          "27.67",
          "27.36",
          "27.55",
          "28.29",
          "28.66",
          "28.35",
          "27.48",
          "27.67",
          "27.55",
          "27.11",
          "27.11",
          "27.85",
          "27.79",
          "27.30",
          "26.99",
          "26.25",
          "25.94",
          "25.76",
          "26.07",
          "25.82",
          "25.64",
          "25.02",
          "25.27",
          "24.59",
          "25.27",
          "25.27",
          "24.96",
          "24.90",
          "24.65",
          "24.28",
          "24.59",
          "23.73",
          "23.29",
          "23.66",
          "24.53",
          "24.28",
          "25.70",
          "25.27",
          "24.90",
          "25.14",
          "25.88",
          "26.13",
          "25.33",
          "26.07",
          "26.50",
          "26.13",
          "23.97",
          "23.17",
          "23.29",
          "23.54",
          "23.97",
          "24.71",
          "25.51",
          "25.76",
          "25.64",
          "26.74",
          "26.38",
          "26.87",
          "26.62",
          "27.85",
          "27.18",
          "28.04",
          "27.48",
          "27.61",
          "27.47",
          "27.85",
          "27.42",
          "27.11",
          "26.87",
          "27.24",
          "27.67",
          "27.24",
          "28.10",
          "28.47",
          "27.61",
          "27.85",
          "27.74",
          "28.04",
          "28.59",
          "28.72",
          "28.90",
          "28.59",
          "28.22",
          "28.72",
          "28.84",
          "29.46",
          "31.18",
          "32.48",
          "31.18",
          "30.69",
          "35.37",
          "35.74",
          "33.22",
          "34.32",
          "33.09",
          "34.02",
          "34.26",
          "33.15",
          "32.29",
          "32.85",
          "36.97",
          "36.11",
          "36.60",
          "36.23",
          "35.80",
          "35.50",
          "36.73",
          "37.34",
          "37.59",
          "37.47",
          "37.04",
          "37.16",
          "36.42",
          "37.34",
          "36.48",
          "36.36",
          "36.42",
          "35.99",
          "35.00",
          "32.91",
          "33.65",
          "32.72",
          "30.57",
          "32.29",
          "33.52",
          "34.20",
          "34.02",
          "35.99",
          "35.50",
          "35.50",
          "34.45",
          "35.74",
          "34.63",
          "34.45",
          "33.65",
          "34.32",
          "34.82",
          "36.30",
          "38.88",
          "38.64",
          "39.93",
          "40.98",
          "40.86",
          "41.16",
          "40.36",
          "40.24",
          "39.69",
          "40.67",
          "41.23",
          "40.55",
          "39.44",
          "39.87",
          "40.43",
          "40.73",
          "41.90",
          "39.50",
          "38.70",
          "38.33",
          "38.21",
          "38.95",
          "40.36",
          "41.66",
          "39.69",
          "39.93",
          "41.78",
          "41.04",
          "43.01",
          "41.41",
          "42.40",
          "40.67",
          "39.13",
          "39.01",
          "39.99",
          "40.18",
          "40.49",
          "40.55",
          "40.24",
          "40.24",
          "41.60",
          "41.66",
          "40.61",
          "40.49",
          "40.30",
          "39.50",
          "38.76",
          "39.25",
          "39.32",
          "41.29",
          "41.60",
          "41.16",
          "39.87",
          "40.06",
          "40.30",
          "39.62",
          "39.87",
          "40.98",
          "40.14",
          "39.62",
          "40.18",
          "41.41",
          "40.61",
          "41.35",
          "41.16",
          "41.16",
          "40.92",
          "39.07",
          "39.93",
          "39.44",
          "38.61",
          "38.95",
          "38.27",
          "38.02",
          "37.53",
          "36.97",
          "37.47",
          "37.28",
          "35.50",
          "35.56",
          "36.60",
          "36.91",
          "36.05",
          "35.25",
          "36.48",
          "38.08",
          "36.91",
          "36.11",
          "36.97",
          "37.53",
          "37.47",
          "37.34",
          "38.02",
          "38.95",
          "39.56",
          "41.90",
          "41.84",
          "40.49",
          "40.80",
          "40.12",
          "39.93",
          "40.06",
          "40.36",
          "39.93",
          "39.32",
          "39.62",
          "38.58",
          "37.90",
          "37.41",
          "36.91",
          "37.34",
          "37.59",
          "37.53",
          "37.90",
          "37.84",
          "38.41",
          "38.45",
          "38.08",
          "39.44",
          "40.92",
          "39.69",
          "39.38",
          "38.08",
          "39.44",
          "38.82",
          "38.64",
          "37.90",
          "37.78",
          "36.67",
          "36.60",
          "36.91",
          "37.04",
          "37.16",
          "37.04",
          "38.27",
          "37.71",
          "38.95",
          "38.76",
          "39.32",
          "40.67",
          "40.12",
          "40.36",
          "40.80",
          "39.75",
          "38.45",
          "39.19",
          "37.71",
          "34.94",
          "35.00",
          "34.45",
          "35.87",
          "35.68",
          "35.68",
          "34.32",
          "34.63",
          "33.83",
          "34.39",
          "35.31",
          "36.11",
          "36.79",
          "37.53",
          "36.79",
          "36.60",
          "36.36",
          "30.69",
          "31.24",
          "30.01",
          "30.50",
          "31.37",
          "30.44",
          "29.76",
          "29.46",
          "28.96",
          "28.66",
          "29.33",
          "29.52",
          "29.46",
          "28.41",
          "28.53",
          "29.58",
          "29.02",
          "30.26",
          "31.00",
          "30.20",
          "30.50",
          "29.95",
          "29.89",
          "29.33",
          "28.59",
          "28.96",
          "28.90",
          "28.72",
          "26.68",
          "27.30",
          "26.07",
          "26.56",
          "27.92",
          "28.35",
          "28.53",
          "27.55",
          "30.63",
          "31.49",
          "31.06",
          "29.70",
          "29.70",
          "29.95",
          "29.09",
          "31.12",
          "32.60",
          "32.91",
          "32.66",
          "32.23",
          "30.69",
          "29.58",
          "30.07",
          "29.64",
          "29.39",
          "30.39",
          "30.47",
          "30.02",
          "31.06",
          "30.32",
          "30.00",
          "31.17",
          "30.57",
          "31.68",
          "31.50",
          "32.05",
          "31.06",
          "30.71",
          "31.96",
          "31.56",
          "30.71",
          "31.07",
          "30.57",
          "29.88",
          "30.96",
          "30.32",
          "30.52",
          "29.91",
          "29.57",
          "29.12",
          "29.31",
          "29.56",
          "29.43",
          "29.30",
          "27.12",
          "27.63",
          "26.71",
          "27.61",
          "27.13",
          "27.62",
          "26.53",
          "27.00",
          "26.59",
          "27.43",
          "27.53",
          "28.79",
          "27.96",
          "27.71",
          "28.20",
          "27.58",
          "26.72",
          "26.72",
          "27.69",
          "28.09",
          "28.20",
          "28.84",
          "28.23",
          "28.68",
          "28.75",
          "29.58",
          "31.45",
          "31.43",
          "30.92",
          "29.61",
          "28.18",
          "30.38",
          "31.08",
          "30.50",
          "29.83",
          "30.12",
          "30.81",
          "30.53",
          "30.48",
          "30.56",
          "30.13",
          "29.43",
          "30.64",
          "30.83",
          "30.52",
          "30.66",
          "31.61",
          "32.05",
          "32.14",
          "33.80",
          "34.02",
          "32.65",
          "32.83",
          "32.18",
          "32.16",
          "31.33",
          "31.18",
          "31.28",
          "31.18",
          "30.91",
          "31.16",
          "31.70",
          "31.40",
          "31.07",
          "31.05",
          "30.37",
          "29.44",
          "29.28",
          "28.62",
          "28.45",
          "28.84",
          "29.68",
          "28.78",
          "28.58",
          "27.85",
          "27.76",
          "27.94",
          "28.49",
          "28.34",
          "27.95",
          "27.79",
          "27.32",
          "27.30",
          "26.72",
          "27.61",
          "27.23",
          "27.79",
          "27.21",
          "27.90",
          "26.63",
          "26.77",
          "26.62",
          "26.50",
          "25.78",
          "26.10",
          "26.52",
          "26.65",
          "26.23",
          "25.98",
          "26.30",
          "26.13",
          "26.23",
          "26.45",
          "26.92",
          "26.64",
          "26.86",
          "27.02",
          "26.86",
          "26.70",
          "26.25",
          "26.08",
          "25.29",
          "25.54",
          "25.44",
          "25.74",
          "25.78",
          "26.28",
          "26.03",
          "25.40",
          "25.05",
          "24.28",
          "25.07",
          "25.35",
          "25.00",
          "24.15",
          "23.77",
          "23.25",
          "18.98",
          "18.14",
          "18.24",
          "16.74",
          "17.62",
          "17.65",
          "17.22",
          "17.20",
          "17.30",
          "18.36",
          "17.77",
          "18.73",
          "19.63",
          "19.03",
          "18.93",
          "18.45",
          "18.83",
          "19.03",
          "19.57",
          "19.34",
          "18.88",
          "18.89",
          "18.24",
          "17.65",
          "18.23",
          "18.29",
          "18.26",
          "18.18",
          "18.75",
          "18.45",
          "17.99",
          "17.91",
          "18.33",
          "18.57",
          "18.61",
          "18.89",
          "18.49",
          "18.20",
          "18.58",
          "18.68",
          "18.45",
          "18.83",
          "19.05",
          "20.02",
          "20.47",
          "21.20",
          "20.97",
          "20.56",
          "20.71",
          "21.09",
          "20.77",
          "19.93",
          "19.98",
          "20.18",
          "19.69",
          "19.72",
          "21.35",
          "22.22",
          "22.04",
          "21.33",
          "22.09",
          "21.93",
          "21.30",
          "20.68",
          "20.50",
          "20.68",
          "20.41",
          "20.26",
          "20.39",
          "20.36",
          "20.31",
          "20.71",
          "20.66",
          "20.43",
          "21.15",
          "21.81",
          "22.38",
          "22.92",
          "22.47",
          "21.49",
          "21.98",
          "21.56",
          "21.07",
          "21.18",
          "20.21",
          "20.94",
          "20.90",
          "20.81",
          "21.11",
          "20.90",
          "21.47",
          "21.83",
          "21.45",
          "21.10",
          "20.76",
          "22.14",
          "21.38",
          "21.49",
          "21.12",
          "22.23",
          "23.09",
          "23.61",
          "23.07",
          "23.54",
          "23.90",
          "23.57",
          "22.54",
          "23.99",
          "23.40",
          "23.34",
          "23.88",
          "23.56",
          "23.91",
          "22.68",
          "23.65",
          "23.23",
          "23.70",
          "23.81",
          "23.43",
          "23.42",
          "23.58",
          "23.38",
          "23.14",
          "23.17",
          "23.98",
          "24.17",
          "23.96",
          "23.66",
          "23.30",
          "23.12",
          "22.87",
          "22.54",
          "22.57",
          "22.76",
          "22.58",
          "22.44",
          "22.13",
          "22.24",
          "22.91",
          "22.80",
          "22.75",
          "23.71",
          "23.14",
          "23.66",
          "23.31",
          "23.57",
          "23.77",
          "24.06",
          "24.60",
          "24.29",
          "24.16",
          "24.18",
          "24.65",
          "23.76",
          "22.70",
          "22.86",
          "23.83",
          "23.69",
          "23.27",
          "22.57",
          "22.57",
          "23.50",
          "23.22",
          "22.83",
          "23.12",
          "24.06",
          "24.16",
          "24.19",
          "24.50",
          "24.09",
          "23.51",
          "23.12",
          "23.50",
          "23.23",
          "23.21",
          "22.73",
          "22.90",
          "22.59",
          "22.18",
          "21.93",
          "21.81",
          "21.40",
          "21.35",
          "21.67",
          "21.28",
          "20.85",
          "20.75",
          "20.74",
          "21.23",
          "21.64",
          "20.57",
          "19.29",
          "18.98",
          "18.73",
          "18.96",
          "18.75",
          "18.78",
          "18.64",
          "18.10",
          "18.24",
          "18.68",
          "19.51",
          "18.73",
          "18.50",
          "18.03",
          "18.44",
          "18.19",
          "17.73",
          "17.40",
          "17.89",
          "17.41",
          "16.41",
          "15.68",
          "15.41",
          "16.19",
          "16.30",
          "15.78",
          "17.11",
          "17.20",
          "17.48",
          "16.59",
          "15.10",
          "14.07",
          "14.18",
          "13.71",
          "14.30",
          "14.44",
          "14.03",
          "13.58",
          "14.30",
          "14.94",
          "15.64",
          "16.42",
          "16.22",
          "16.66",
          "17.13",
          "16.59",
          "16.44",
          "15.78",
          "15.18",
          "15.55",
          "15.46",
          "14.52",
          "14.83",
          "14.55",
          "15.14",
          "15.68",
          "16.02",
          "15.95",
          "15.28",
          "15.28",
          "15.60",
          "15.76",
          "15.52",
          "14.60",
          "15.03",
          "14.86",
          "14.54",
          "15.21",
          "15.68",
          "15.03",
          "14.93",
          "15.48",
          "16.02",
          "15.78",
          "14.84",
          "13.94",
          "14.44",
          "14.00",
          "15.01",
          "15.78",
          "15.85",
          "16.52",
          "16.17",
          "16.52",
          "16.52",
          "17.14",
          "16.87",
          "17.55",
          "17.16",
          "17.73",
          "17.14",
          "16.52",
          "16.59",
          "16.47",
          "16.79",
          "17.80",
          "18.37",
          "18.60",
          "18.00",
          "17.43",
          "17.13",
          "17.30",
          "17.65",
          "17.87",
          "18.27",
          "18.05",
          "17.93",
          "18.29",
          "19.28",
          "19.26",
          "19.36",
          "18.74",
          "19.61",
          "19.54",
          "19.12",
          "18.28",
          "17.43",
          "17.39",
          "16.93",
          "16.61",
          "16.80",
          "16.62",
          "16.63",
          "16.29",
          "16.64",
          "16.79",
          "16.30",
          "15.97",
          "16.23",
          "16.40",
          "16.18",
          "16.33",
          "15.79",
          "15.82",
          "16.08",
          "17.02",
          "17.12",
          "17.99",
          "17.80",
          "17.43",
          "17.83",
          "17.90",
          "18.04",
          "18.21",
          "17.74",
          "17.80",
          "17.55",
          "16.91",
          "17.08",
          "17.43",
          "16.86",
          "16.52",
          "16.61",
          "16.82",
          "16.12",
          "17.25",
          "17.21",
          "16.66",
          "16.66",
          "16.76",
          "16.12",
          "16.23",
          "16.25",
          "16.07",
          "15.90",
          "16.50",
          "17.06",
          "16.61",
          "16.61",
          "16.78",
          "16.38",
          "16.53",
          "16.44",
          "16.27",
          "16.82",
          "16.56",
          "15.83",
          "15.72",
          "15.44",
          "15.50",
          "14.97",
          "14.81",
          "14.97",
          "15.97",
          "16.19",
          "16.77",
          "16.35",
          "16.73",
          "16.90",
          "18.48",
          "17.16",
          "17.44",
          "17.38",
          "17.33",
          "17.44",
          "16.78",
          "16.87",
          "17.73",
          "17.40",
          "17.20",
          "17.49",
          "16.89",
          "16.86",
          "17.10",
          "17.59",
          "17.94",
          "18.05",
          "17.74",
          "18.14",
          "18.19",
          "18.61",
          "18.97",
          "18.39",
          "17.91",
          "18.19",
          "18.07",
          "18.40",
          "18.46",
          "18.63",
          "18.23",
          "18.20",
          "18.10",
          "17.92",
          "18.40",
          "18.77",
          "18.24",
          "18.04",
          "18.20",
          "18.18",
          "17.57",
          "17.60",
          "17.63",
          "17.87",
          "17.98",
          "18.61",
          "19.17",
          "19.01",
          "19.37",
          "19.79",
          "19.48",
          "20.44",
          "20.65",
          "20.98",
          "20.04",
          "20.33",
          "20.51",
          "20.51",
          "20.06",
          "20.38",
          "20.84",
          "20.32",
          "19.81",
          "20.05",
          "19.58",
          "19.35",
          "18.84",
          "19.13",
          "19.07",
          "19.47",
          "19.61",
          "19.93",
          "19.79",
          "20.29",
          "20.31",
          "20.63",
          "20.27",
          "20.55",
          "21.01",
          "21.05",
          "20.76",
          "20.53",
          "20.72",
          "20.12",
          "20.53",
          "20.32",
          "20.56",
          "20.99",
          "21.60",
          "21.74",
          "21.39",
          "21.61",
          "22.20",
          "21.80",
          "21.25",
          "21.11",
          "21.35",
          "21.53",
          "21.74",
          "22.14",
          "22.18",
          "22.24",
          "22.14",
          "22.09",
          "21.92",
          "21.67",
          "21.30",
          "21.11",
          "20.95",
          "20.56",
          "20.43",
          "20.02",
          "20.21",
          "20.66",
          "21.02",
          "21.16",
          "20.89",
          "21.06",
          "20.88",
          "20.31",
          "20.39",
          "19.96",
          "19.82",
          "20.15",
          "19.99",
          "20.16",
          "20.19",
          "19.73",
          "19.92",
          "19.53",
          "20.03",
          "19.80",
          "20.00",
          "19.89",
          "20.54",
          "20.65",
          "20.98",
          "20.89",
          "21.26",
          "21.16",
          "21.57",
          "21.36",
          "21.20",
          "21.64",
          "21.45",
          "21.59",
          "21.28",
          "21.25",
          "21.48",
          "21.32",
          "21.54",
          "21.49",
          "21.83",
          "22.22",
          "22.45",
          "22.56",
          "22.32",
          "22.60",
          "22.43",
          "22.70",
          "23.07",
          "23.07",
          "22.83",
          "22.80",
          "23.01",
          "22.99",
          "22.75",
          "22.71",
          "22.20",
          "22.47",
          "22.36",
          "22.26",
          "22.51",
          "22.67",
          "22.76",
          "22.77",
          "22.85",
          "22.26",
          "21.54",
          "21.89",
          "21.37",
          "21.46",
          "21.99",
          "22.10",
          "22.33",
          "22.50",
          "22.51",
          "22.63",
          "22.62",
          "22.82",
          "22.90",
          "23.12",
          "23.13",
          "23.04",
          "22.88",
          "23.15",
          "22.97",
          "23.00",
          "23.34",
          "23.80",
          "23.86",
          "24.26",
          "24.61",
          "24.52",
          "24.53",
          "24.33",
          "24.55",
          "24.47",
          "24.50",
          "24.13",
          "24.24",
          "24.35",
          "23.71",
          "24.06",
          "23.82",
          "23.34",
          "24.11",
          "23.66",
          "23.47",
          "22.93",
          "22.87",
          "22.87",
          "23.02",
          "23.44",
          "23.74",
          "27.21",
          "27.61",
          "26.54",
          "26.52",
          "26.34",
          "26.62",
          "26.18",
          "26.38",
          "25.60",
          "25.93",
          "26.36",
          "26.16",
          "26.49",
          "26.39",
          "26.28",
          "26.42",
          "26.11",
          "25.87",
          "25.86",
          "25.09",
          "24.60",
          "25.73",
          "24.98",
          "25.12",
          "25.44",
          "25.37",
          "25.03",
          "24.55",
          "24.75",
          "24.42",
          "24.70",
          "24.75",
          "24.85",
          "24.83",
          "24.64",
          "24.99",
          "25.59",
          "26.13",
          "26.22",
          "25.65",
          "25.88",
          "25.34",
          "24.65",
          "24.65",
          "24.35",
          "24.55",
          "24.61",
          "24.45",
          "24.65",
          "24.53",
          "24.30",
          "24.04",
          "23.84",
          "23.61",
          "23.37",
          "22.71",
          "22.81",
          "22.70",
          "22.68",
          "22.43",
          "22.14",
          "21.79",
          "22.66",
          "22.68",
          "22.97",
          "22.91",
          "22.58",
          "22.52",
          "22.68",
          "22.73",
          "22.36",
          "22.87",
          "23.42",
          "23.33",
          "23.38",
          "23.14",
          "23.51",
          "24.08",
          "23.63",
          "23.72",
          "24.06",
          "24.26",
          "24.24",
          "24.37",
          "24.37",
          "24.35",
          "24.52",
          "24.51",
          "24.67",
          "24.60",
          "24.57",
          "24.30",
          "24.98",
          "24.83",
          "24.89",
          "25.13",
          "25.13",
          "24.93",
          "24.92",
          "24.35",
          "24.38",
          "24.01",
          "24.26",
          "24.01",
          "23.89",
          "23.85",
          "23.54",
          "23.12",
          "23.16",
          "23.57",
          "23.31",
          "22.98",
          "22.64",
          "22.53",
          "22.63",
          "22.52",
          "22.68",
          "22.77",
          "22.78",
          "22.45",
          "22.17",
          "21.97",
          "21.68",
          "21.63",
          "22.13",
          "21.47",
          "20.91",
          "20.60",
          "21.25",
          "21.67",
          "21.95",
          "22.09",
          "22.13",
          "21.86",
          "22.00",
          "22.18",
          "22.36",
          "22.34",
          "22.09",
          "22.14",
          "22.16",
          "22.19",
          "22.06",
          "22.37",
          "22.57",
          "22.54",
          "22.84",
          "22.99",
          "22.92",
          "22.69",
          "23.07",
          "23.09",
          "22.81",
          "23.08",
          "22.97",
          "22.93",
          "23.13",
          "22.85",
          "22.28",
          "22.48",
          "22.23",
          "22.76",
          "23.21",
          "23.55",
          "24.00",
          "24.40",
          "24.50",
          "24.55",
          "24.49",
          "24.66",
          "24.50",
          "24.56",
          "24.73",
          "24.54",
          "24.39",
          "24.95",
          "24.28",
          "24.25",
          "24.52",
          "24.62",
          "24.77",
          "24.87",
          "24.88",
          "25.10",
          "25.28",
          "25.95",
          "26.06",
          "25.68",
          "25.90",
          "26.29",
          "26.22",
          "26.42",
          "26.36",
          "26.16",
          "26.19",
          "26.00",
          "26.29",
          "26.81",
          "26.94",
          "26.96",
          "26.74",
          "26.68",
          "26.50",
          "27.29",
          "27.23",
          "26.99",
          "26.94",
          "26.72",
          "26.82",
          "27.23",
          "27.24",
          "27.34",
          "27.27",
          "27.21",
          "27.16",
          "26.99",
          "27.04",
          "27.09",
          "27.24",
          "27.20",
          "27.36",
          "27.60",
          "27.56",
          "27.49",
          "27.41",
          "27.46",
          "27.16",
          "27.02",
          "27.06",
          "26.79",
          "26.90",
          "26.83",
          "27.33",
          "27.36",
          "27.90",
          "28.34",
          "28.10",
          "28.12",
          "27.76",
          "27.56",
          "28.40",
          "28.18",
          "27.90",
          "27.83",
          "28.23",
          "28.40",
          "28.43",
          "28.40",
          "28.90",
          "29.46",
          "29.42",
          "28.94",
          "28.94",
          "28.93",
          "28.98",
          "29.17",
          "28.92",
          "28.94",
          "28.65",
          "28.03",
          "28.25",
          "27.76",
          "27.50",
          "27.55",
          "28.08",
          "28.29",
          "28.17",
          "28.48",
          "28.55",
          "28.13",
          "27.59",
          "27.61",
          "27.20",
          "27.63",
          "28.20",
          "28.25",
          "27.97",
          "27.70",
          "27.79",
          "27.39",
          "27.39",
          "27.36",
          "27.78",
          "27.51",
          "27.95",
          "28.33",
          "28.18",
          "28.03",
          "28.14",
          "28.01",
          "28.30",
          "28.00",
          "27.74",
          "27.75",
          "27.31",
          "27.24",
          "26.99",
          "26.56",
          "26.77",
          "26.25",
          "27.28",
          "26.92",
          "26.82",
          "26.42",
          "26.46",
          "25.54",
          "26.03",
          "25.96",
          "25.88",
          "26.41",
          "26.34",
          "26.51",
          "26.68",
          "26.57",
          "26.30",
          "26.38",
          "26.62",
          "26.69",
          "26.90",
          "27.11",
          "27.55",
          "27.44",
          "27.16",
          "27.27",
          "27.26",
          "27.41",
          "27.49",
          "27.06",
          "27.19",
          "27.06",
          "26.94",
          "26.70",
          "26.91",
          "27.03",
          "27.16",
          "27.13",
          "27.37",
          "27.25",
          "26.66",
          "26.40",
          "26.33",
          "26.22",
          "26.36",
          "26.32",
          "25.90",
          "25.68",
          "25.27",
          "25.50",
          "25.07",
          "24.83",
          "24.74",
          "24.69",
          "24.44",
          "24.11",
          "24.65",
          "24.83",
          "25.05",
          "25.35",
          "25.96",
          "26.01",
          "25.68",
          "25.40",
          "25.47",
          "25.20",
          "25.47",
          "25.28",
          "25.52",
          "25.47",
          "25.59",
          "25.28",
          "25.25",
          "25.20",
          "25.41",
          "25.26",
          "25.12",
          "25.05",
          "25.77",
          "25.11",
          "25.52",
          "25.40",
          "25.80",
          "25.89",
          "25.92",
          "25.71",
          "25.61",
          "25.37",
          "25.44",
          "25.04",
          "24.85",
          "24.98",
          "24.99",
          "24.94",
          "24.84",
          "24.34",
          "24.41",
          "24.82",
          "24.87",
          "24.60",
          "24.69",
          "24.77",
          "24.46",
          "23.77",
          "23.66",
          "23.77",
          "23.57",
          "23.42",
          "23.00",
          "22.90",
          "22.89",
          "22.93",
          "23.03",
          "23.04",
          "23.62",
          "23.79",
          "23.67",
          "24.22",
          "23.89",
          "23.75",
          "23.76",
          "23.55",
          "23.44",
          "23.01",
          "23.10",
          "23.06",
          "23.16",
          "23.06",
          "23.11",
          "22.64",
          "22.66",
          "22.97",
          "23.19",
          "23.25",
          "23.09",
          "23.49",
          "24.03",
          "24.31",
          "24.47",
          "24.55",
          "24.46",
          "24.81",
          "24.81",
          "25.06",
          "25.35",
          "25.50",
          "25.65",
          "25.69",
          "25.49",
          "25.63",
          "24.85",
          "24.70",
          "24.59",
          "24.61",
          "24.70",
          "24.67",
          "24.73",
          "24.58",
          "24.61",
          "24.53",
          "24.66",
          "25.16",
          "25.31",
          "24.70",
          "24.84",
          "24.80",
          "24.78",
          "24.65",
          "24.39",
          "24.35",
          "24.20",
          "23.93",
          "23.76",
          "23.85",
          "23.93",
          "23.80",
          "23.79",
          "23.83",
          "23.63",
          "24.06",
          "23.65",
          "24.07",
          "24.39",
          "24.65",
          "24.97",
          "25.20",
          "25.28",
          "25.34",
          "25.02",
          "24.85",
          "25.87",
          "25.36",
          "25.16",
          "25.63",
          "25.08",
          "24.73",
          "24.73",
          "25.10",
          "24.96",
          "24.92",
          "24.75",
          "24.66",
          "24.61",
          "26.33",
          "26.52",
          "26.35",
          "26.31",
          "26.04",
          "26.38",
          "26.50",
          "26.67",
          "26.53",
          "26.78",
          "27.08",
          "27.32",
          "27.59",
          "27.98",
          "27.60",
          "27.70",
          "27.64",
          "27.82",
          "27.49",
          "27.62",
          "27.66",
          "27.70",
          "27.77",
          "28.16",
          "28.26",
          "28.35",
          "28.28",
          "28.24",
          "28.24",
          "27.71",
          "27.40",
          "27.13",
          "27.07",
          "26.96",
          "26.71",
          "27.47",
          "27.48",
          "27.50",
          "27.55",
          "27.52",
          "27.40",
          "27.29",
          "27.14",
          "27.40",
          "27.38",
          "27.53",
          "27.49",
          "27.26",
          "27.70",
          "27.09",
          "26.96",
          "26.64",
          "26.89",
          "26.62",
          "26.82",
          "27.37",
          "27.57",
          "27.28",
          "27.50",
          "27.72",
          "28.00",
          "28.68",
          "28.37",
          "29.17",
          "29.69",
          "29.38",
          "29.48",
          "29.57",
          "29.92",
          "29.34",
          "29.19",
          "29.73",
          "29.58",
          "29.81",
          "29.72",
          "29.73",
          "30.08",
          "29.78",
          "30.07",
          "30.19",
          "30.19",
          "30.31",
          "30.10",
          "30.22",
          "29.45",
          "28.92",
          "28.50",
          "27.88",
          "28.29",
          "28.78",
          "29.04",
          "28.66",
          "28.82",
          "28.72",
          "28.87",
          "28.71",
          "29.25",
          "29.00",
          "28.97",
          "29.46",
          "29.58",
          "29.60",
          "29.65",
          "29.62",
          "29.41",
          "29.50",
          "29.83",
          "29.49",
          "28.30",
          "28.09",
          "28.20",
          "28.69",
          "28.85",
          "28.71",
          "28.07",
          "28.96",
          "29.07",
          "28.93",
          "28.80",
          "29.28",
          "29.27",
          "28.94",
          "29.38",
          "29.62",
          "29.48",
          "28.98",
          "28.57",
          "28.43",
          "29.17",
          "29.02",
          "29.11",
          "29.22",
          "29.29",
          "29.48",
          "29.49",
          "29.55",
          "29.22",
          "28.78",
          "28.54",
          "28.54",
          "28.96",
          "28.70",
          "29.16",
          "29.23",
          "29.47",
          "29.43",
          "29.20",
          "29.08",
          "29.17",
          "29.35",
          "29.79",
          "30.02",
          "29.96",
          "29.89",
          "29.64",
          "30.04",
          "30.60",
          "30.28",
          "29.66",
          "29.74",
          "30.63",
          "30.61",
          "30.50",
          "30.48",
          "30.10",
          "30.57",
          "30.75",
          "30.63",
          "30.90",
          "30.95",
          "30.81",
          "30.87",
          "30.86",
          "30.67",
          "30.59",
          "30.66",
          "31.14",
          "31.15",
          "31.01",
          "30.84",
          "30.94",
          "30.76",
          "31.53",
          "31.29",
          "31.41",
          "31.02",
          "31.29",
          "31.26",
          "31.44",
          "32.01",
          "32.39",
          "32.64",
          "33.11",
          "31.95",
          "31.81",
          "32.07",
          "32.23",
          "32.60",
          "32.48",
          "32.66",
          "32.76",
          "32.53",
          "32.45",
          "32.13",
          "32.43",
          "32.42",
          "32.59",
          "32.63",
          "32.97",
          "33.72",
          "33.62",
          "33.66",
          "33.91",
          "34.04",
          "34.06",
          "33.97",
          "34.23",
          "33.82",
          "34.01",
          "34.36",
          "34.12",
          "34.06",
          "33.68",
          "34.05",
          "34.06",
          "34.04",
          "33.79",
          "33.72",
          "33.99",
          "33.71",
          "34.02",
          "33.97",
          "34.01",
          "34.50",
          "34.72",
          "34.54",
          "34.76",
          "35.35",
          "35.00",
          "34.66",
          "35.08",
          "34.85",
          "34.45",
          "34.07",
          "34.19",
          "34.55",
          "34.68",
          "34.52",
          "34.69",
          "34.77",
          "34.70",
          "34.98",
          "34.80",
          "33.88",
          "33.44",
          "34.11",
          "34.26",
          "34.18",
          "34.40",
          "34.56",
          "34.65",
          "34.67",
          "34.65",
          "34.62",
          "32.64",
          "33.77",
          "33.91",
          "33.47",
          "33.25",
          "33.89",
          "33.95",
          "34.20",
          "33.89",
          "33.72",
          "33.15",
          "33.26",
          "33.32",
          "33.14",
          "33.61",
          "33.90",
          "34.68",
          "34.83",
          "34.50",
          "34.73",
          "34.17",
          "33.75",
          "33.91",
          "33.95",
          "33.99",
          "34.43",
          "34.15",
          "34.42",
          "34.42",
          "34.50",
          "34.11",
          "34.18",
          "34.23",
          "34.53",
          "34.66",
          "34.43",
          "34.43",
          "34.79",
          "34.59",
          "34.42",
          "34.83",
          "34.68",
          "34.49",
          "34.49",
          "34.97",
          "35.20",
          "35.19",
          "35.35",
          "35.55",
          "36.04",
          "35.61",
          "35.19",
          "35.56",
          "35.48",
          "35.44",
          "35.51",
          "35.49",
          "35.52",
          "35.93",
          "35.76",
          "35.97",
          "35.34",
          "35.53",
          "35.09",
          "35.41",
          "34.94",
          "34.75",
          "35.20",
          "34.78",
          "34.42",
          "33.78",
          "33.72",
          "33.70",
          "33.09",
          "33.40",
          "33.79",
          "34.40",
          "34.54",
          "34.76",
          "34.19",
          "34.19",
          "34.14",
          "34.06",
          "34.06",
          "34.27",
          "33.85",
          "34.14",
          "34.52",
          "34.54",
          "34.63",
          "34.49",
          "34.46",
          "33.89",
          "33.98",
          "34.25",
          "34.37",
          "34.47",
          "34.68",
          "34.60",
          "34.47",
          "34.34",
          "35.03",
          "34.75",
          "34.93",
          "34.08",
          "33.74",
          "34.01",
          "33.00",
          "33.83",
          "34.36",
          "33.90",
          "34.30",
          "34.55",
          "34.29",
          "33.00",
          "33.16",
          "33.24",
          "32.36",
          "31.71",
          "32.58",
          "32.68",
          "32.91",
          "33.29",
          "33.57",
          "33.16",
          "33.87",
          "33.84",
          "33.20",
          "33.89",
          "33.47",
          "33.60",
          "34.13",
          "34.04",
          "34.41",
          "33.59",
          "33.56",
          "33.49",
          "33.72",
          "33.52",
          "33.56",
          "33.38",
          "34.58",
          "34.56",
          "34.04",
          "34.60",
          "34.32",
          "34.07",
          "34.52",
          "34.21",
          "34.39",
          "34.65",
          "34.77",
          "34.72",
          "34.90",
          "35.47",
          "35.27",
          "35.45",
          "35.28",
          "35.01",
          "35.47",
          "35.14",
          "35.01",
          "35.38",
          "34.78",
          "33.81",
          "34.68",
          "34.89",
          "35.06",
          "34.45",
          "34.38",
          "34.68",
          "34.29",
          "34.63",
          "33.80",
          "33.92",
          "33.88",
          "34.08",
          "33.50",
          "33.63",
          "32.74",
          "32.02",
          "33.01",
          "31.86",
          "32.40",
          "32.53",
          "31.25",
          "31.55",
          "31.50",
          "31.84",
          "31.24",
          "31.72",
          "32.69",
          "32.81",
          "33.15",
          "33.04",
          "32.75",
          "32.75",
          "32.72",
          "32.79",
          "32.35",
          "31.76",
          "32.28",
          "32.76",
          "33.01",
          "32.99",
          "33.02",
          "32.26",
          "32.33",
          "32.94",
          "33.24",
          "32.82",
          "32.43",
          "32.42",
          "32.28",
          "31.84",
          "31.77",
          "31.13",
          "31.16",
          "30.54",
          "30.16",
          "30.67",
          "30.32",
          "30.35",
          "29.85",
          "29.83",
          "28.77",
          "28.51",
          "28.12",
          "28.52",
          "29.23",
          "28.68",
          "29.37",
          "28.80",
          "29.41",
          "29.84",
          "30.66",
          "30.90",
          "30.07",
          "31.50",
          "31.72",
          "32.12",
          "31.93",
          "32.03",
          "32.78",
          "32.32",
          "32.49",
          "32.19",
          "32.58",
          "32.27",
          "32.57",
          "32.89",
          "32.89",
          "33.10",
          "32.54",
          "32.41",
          "32.41",
          "31.87",
          "31.64",
          "31.25",
          "30.76",
          "30.46",
          "31.19",
          "31.41",
          "31.33",
          "30.78",
          "30.46",
          "31.72",
          "31.24",
          "31.90",
          "32.04",
          "32.08",
          "31.76",
          "31.38",
          "31.00",
          "31.38",
          "31.91",
          "31.56",
          "31.53",
          "31.24",
          "31.52",
          "31.07",
          "30.85",
          "31.35",
          "30.18",
          "30.04",
          "29.93",
          "30.32",
          "30.78",
          "31.33",
          "31.46",
          "31.29",
          "31.64",
          "31.94",
          "32.36",
          "32.43",
          "32.76",
          "32.43",
          "33.30",
          "33.49",
          "33.29",
          "33.73",
          "34.70",
          "34.56",
          "34.30",
          "34.77",
          "34.33",
          "34.80",
          "34.99",
          "34.91",
          "34.72",
          "34.09",
          "33.66",
          "33.61",
          "33.23",
          "33.64",
          "33.86",
          "33.81",
          "33.60",
          "33.30",
          "33.18",
          "34.35",
          "34.49",
          "33.01",
          "33.18",
          "33.83",
          "33.27",
          "33.24",
          "33.93",
          "33.65",
          "33.11",
          "32.95",
          "32.89",
          "31.94",
          "32.39",
          "32.22",
          "32.49",
          "31.53",
          "31.57",
          "31.20",
          "31.05",
          "30.71",
          "30.90",
          "30.08",
          "29.95",
          "29.54",
          "29.60",
          "29.20",
          "29.10",
          "29.43",
          "30.43",
          "31.20",
          "30.90",
          "30.49",
          "30.41",
          "31.15",
          "31.02",
          "31.10",
          "30.50",
          "30.92",
          "31.67",
          "30.35",
          "30.08",
          "30.37",
          "31.31",
          "31.42",
          "30.92",
          "32.03",
          "32.80",
          "32.16",
          "31.74",
          "32.33",
          "32.50",
          "32.08",
          "31.75",
          "31.82",
          "31.83",
          "32.20",
          "31.60",
          "31.71",
          "31.76",
          "32.59",
          "32.35",
          "32.44",
          "32.51",
          "31.54",
          "31.36",
          "32.92",
          "32.43",
          "32.77",
          "32.94",
          "33.26",
          "32.36",
          "32.51",
          "32.19",
          "33.44",
          "34.39",
          "32.91",
          "32.53",
          "31.77",
          "32.47",
          "32.75",
          "29.73",
          "30.69",
          "30.68",
          "29.96",
          "29.54",
          "28.26",
          "26.57",
          "25.68",
          "23.80",
          "23.04",
          "26.72",
          "25.68",
          "23.37",
          "24.27",
          "24.75",
          "26.55",
          "25.31",
          "23.05",
          "23.40",
          "22.61",
          "21.37",
          "23.91",
          "23.97",
          "25.33",
          "25.91",
          "25.04",
          "26.02",
          "24.23",
          "22.81",
          "23.36",
          "22.08",
          "21.78",
          "20.16",
          "21.65",
          "21.08",
          "19.74",
          "20.67",
          "19.94",
          "18.73",
          "21.12",
          "22.20",
          "22.03",
          "22.50",
          "22.52",
          "20.33",
          "21.46",
          "21.94",
          "21.79",
          "22.77",
          "24.92",
          "23.53",
          "23.70",
          "22.82",
          "22.61",
          "22.77",
          "23.78",
          "23.58",
          "22.83",
          "22.43",
          "21.93",
          "21.85",
          "21.99",
          "22.18",
          "21.47",
          "22.48",
          "22.69",
          "23.92",
          "23.50",
          "24.31",
          "23.18",
          "22.90",
          "22.31",
          "21.86",
          "21.20",
          "20.80",
          "21.36",
          "21.46",
          "20.24",
          "21.23",
          "20.97",
          "20.61",
          "20.86",
          "21.25",
          "22.28",
          "21.25",
          "20.68",
          "20.20",
          "20.62",
          "19.00",
          "18.71",
          "19.45",
          "19.44",
          "18.76",
          "18.50",
          "18.83",
          "18.52",
          "17.84",
          "17.63",
          "17.69",
          "17.53",
          "16.97",
          "17.92",
          "17.36",
          "16.86",
          "16.77",
          "16.05",
          "16.36",
          "16.94",
          "15.99",
          "15.83",
          "15.59",
          "16.61",
          "16.59",
          "17.43",
          "17.13",
          "17.21",
          "17.82",
          "18.11",
          "17.70",
          "17.45",
          "18.92",
          "18.32",
          "18.53",
          "19.06",
          "18.59",
          "17.85",
          "18.16",
          "18.79",
          "20.21",
          "20.00",
          "19.62",
          "19.12",
          "19.36",
          "19.88",
          "19.53",
          "19.02",
          "19.73",
          "20.51",
          "20.38",
          "19.41",
          "19.47",
          "19.40",
          "19.63",
          "20.26",
          "19.74",
          "19.51",
          "21.01",
          "21.90",
          "21.94",
          "22.86",
          "23.15",
          "25.87",
          "25.33",
          "25.46",
          "24.71",
          "24.32",
          "23.60",
          "23.48",
          "23.41",
          "24.21",
          "23.82",
          "23.55",
          "23.23",
          "23.70",
          "24.54",
          "23.87",
          "23.99",
          "24.22",
          "25.03",
          "25.13",
          "25.08",
          "25.14",
          "24.95",
          "25.33",
          "25.33",
          "25.10",
          "25.23",
          "25.06",
          "24.25",
          "23.49",
          "23.68",
          "23.72",
          "23.53",
          "22.66",
          "22.88",
          "22.90",
          "23.55",
          "23.43",
          "23.66",
          "23.33",
          "23.45",
          "22.84",
          "23.09",
          "22.53",
          "22.24",
          "22.53",
          "22.41",
          "22.70",
          "23.11",
          "24.08",
          "24.79",
          "24.51",
          "25.37",
          "25.20",
          "25.38",
          "26.80",
          "26.58",
          "26.45",
          "26.37",
          "25.89",
          "26.22",
          "25.12",
          "25.52",
          "25.64",
          "25.27",
          "25.37",
          "26.69",
          "26.44",
          "25.90",
          "26.22",
          "26.26",
          "25.86",
          "25.09",
          "25.20",
          "25.48",
          "25.89",
          "26.79",
          "26.80",
          "26.87",
          "27.00",
          "27.01",
          "26.84",
          "26.04",
          "25.68",
          "25.40",
          "25.44",
          "25.90",
          "26.28",
          "26.95",
          "28.36",
          "28.42",
          "28.08",
          "28.29",
          "28.37",
          "28.46",
          "28.44",
          "28.00",
          "28.38",
          "28.08",
          "27.97",
          "27.62",
          "28.23",
          "27.94",
          "27.46",
          "27.36",
          "27.21",
          "27.67",
          "28.18",
          "28.17",
          "28.67",
          "28.64",
          "28.64",
          "28.41",
          "28.91",
          "29.23",
          "29.40",
          "29.87",
          "29.35",
          "29.23",
          "29.44",
          "28.89",
          "28.65",
          "28.17",
          "27.24",
          "28.14",
          "27.37",
          "27.41",
          "27.62",
          "28.03",
          "29.00",
          "28.56",
          "29.00",
          "29.12",
          "29.29",
          "29.05",
          "30.44",
          "30.70",
          "30.87",
          "30.67",
          "30.21",
          "30.01",
          "30.48",
          "30.23",
          "30.61",
          "30.35",
          "30.22",
          "30.73",
          "30.79",
          "30.33",
          "30.84",
          "31.00",
          "30.68",
          "30.70",
          "31.30",
          "31.70",
          "31.83",
          "32.18",
          "32.43",
          "31.93",
          "31.95",
          "32.40",
          "32.31",
          "32.43",
          "32.29",
          "31.91",
          "32.38",
          "32.28",
          "32.25",
          "32.07",
          "31.99",
          "31.82",
          "31.83",
          "31.88",
          "31.36",
          "30.82",
          "31.29",
          "31.02",
          "30.60",
          "31.01",
          "31.19",
          "30.61",
          "29.98",
          "29.92",
          "29.66",
          "29.32",
          "29.35",
          "29.55",
          "29.52",
          "29.98",
          "30.75",
          "29.67",
          "29.54",
          "29.48",
          "29.84",
          "30.03",
          "30.16",
          "30.07",
          "30.47",
          "30.86",
          "31.02",
          "31.23",
          "31.12",
          "30.92",
          "31.39",
          "31.36",
          "31.24",
          "31.54",
          "31.88",
          "31.64",
          "32.57",
          "33.22",
          "33.19",
          "33.31",
          "33.33",
          "33.81",
          "33.69",
          "33.72",
          "33.75",
          "33.75",
          "33.78",
          "33.64",
          "33.95",
          "34.01",
          "34.39",
          "35.09",
          "35.31",
          "35.16",
          "35.10",
          "34.91",
          "35.55",
          "35.22",
          "35.47",
          "35.32",
          "35.67",
          "36.22",
          "35.89",
          "35.84",
          "36.20",
          "36.40",
          "35.83",
          "36.17",
          "36.54",
          "36.56",
          "36.78",
          "36.79",
          "37.04",
          "36.32",
          "36.29",
          "37.22",
          "36.84",
          "37.56",
          "36.59",
          "35.34",
          "34.01",
          "33.41",
          "35.29",
          "35.76",
          "35.13",
          "34.75",
          "34.06",
          "34.20",
          "33.73",
          "33.39",
          "31.99",
          "32.87",
          "32.48",
          "32.32",
          "33.07",
          "34.37",
          "33.42",
          "33.33",
          "34.74",
          "34.71",
          "33.69",
          "33.04",
          "33.15",
          "32.94",
          "34.11",
          "34.24",
          "33.93",
          "34.99",
          "34.79",
          "35.07",
          "35.15",
          "35.06",
          "34.35",
          "34.34",
          "33.60",
          "33.48",
          "33.22",
          "32.30",
          "31.50",
          "31.49",
          "31.38",
          "31.72",
          "33.14",
          "33.34",
          "33.75",
          "33.77",
          "34.45",
          "34.24",
          "34.05",
          "33.03",
          "33.31",
          "33.40",
          "32.81",
          "33.59",
          "34.13",
          "34.40",
          "34.28",
          "34.04",
          "33.71",
          "33.69",
          "34.48",
          "34.21",
          "34.83",
          "34.98",
          "35.00",
          "35.16",
          "35.29",
          "34.22",
          "33.99",
          "33.68",
          "33.66",
          "33.76",
          "33.91",
          "33.19",
          "33.05",
          "32.93",
          "32.14",
          "32.11",
          "31.94",
          "32.78",
          "32.35",
          "32.54",
          "33.51",
          "33.91",
          "34.67",
          "33.84",
          "33.84",
          "34.08",
          "34.15",
          "34.27",
          "34.05",
          "34.21",
          "34.17",
          "34.56",
          "34.90",
          "34.50",
          "33.99",
          "33.12",
          "33.58",
          "33.29",
          "33.22",
          "33.01",
          "33.10",
          "33.34",
          "33.14",
          "33.83",
          "33.72",
          "33.91",
          "34.51",
          "34.57",
          "34.48",
          "34.91",
          "34.85",
          "34.88",
          "34.75",
          "34.24",
          "34.62",
          "34.68",
          "34.97",
          "35.46",
          "35.96",
          "35.92",
          "36.51",
          "36.13",
          "36.03",
          "36.11",
          "36.29",
          "37.03",
          "37.20",
          "37.06",
          "36.86",
          "36.99",
          "35.93",
          "37.75",
          "37.25",
          "36.92",
          "37.22",
          "37.58",
          "37.01",
          "36.95",
          "36.12",
          "36.87",
          "36.70",
          "36.42",
          "36.51",
          "37.12",
          "37.34",
          "37.59",
          "37.56",
          "37.33",
          "36.97",
          "36.74",
          "36.66",
          "37.13",
          "37.24",
          "36.95",
          "37.01",
          "37.05",
          "37.06",
          "37.33",
          "37.95",
          "37.70",
          "37.48",
          "37.36",
          "37.60",
          "37.48",
          "37.51",
          "37.82",
          "38.99",
          "39.96",
          "39.65",
          "39.45",
          "39.50",
          "39.40",
          "39.17",
          "39.26",
          "39.29",
          "39.39",
          "39.09",
          "39.17",
          "39.74",
          "39.94",
          "39.86",
          "39.44",
          "39.46",
          "38.85",
          "38.87",
          "39.88",
          "40.49",
          "40.50",
          "40.71",
          "40.94",
          "41.18",
          "43.36",
          "43.31",
          "43.41",
          "43.24",
          "43.09",
          "43.70",
          "43.70",
          "43.56",
          "42.65",
          "42.13",
          "42.42",
          "42.95",
          "43.74",
          "42.99",
          "43.29",
          "44.07",
          "43.55",
          "43.02",
          "43.20",
          "43.12",
          "42.47",
          "42.93",
          "42.24",
          "41.62",
          "40.60",
          "40.76",
          "41.23",
          "41.82",
          "41.44",
          "42.24",
          "42.86",
          "42.97",
          "42.52",
          "42.94",
          "43.35",
          "43.09",
          "42.85",
          "42.63",
          "42.43",
          "42.27",
          "42.04",
          "41.76",
          "41.89",
          "41.63",
          "41.70",
          "41.02",
          "41.52",
          "41.20",
          "41.35",
          "42.04",
          "42.27",
          "41.93",
          "42.33",
          "42.58",
          "43.02",
          "43.10",
          "43.27",
          "43.07",
          "42.62",
          "42.69",
          "43.06",
          "43.10",
          "43.91",
          "41.52",
          "41.58",
          "41.52",
          "40.93",
          "41.01",
          "41.42",
          "41.38",
          "41.50",
          "41.16",
          "41.09",
          "41.13",
          "40.99",
          "41.52",
          "41.63",
          "40.40",
          "40.06",
          "39.38",
          "39.40",
          "39.35",
          "39.23",
          "39.37",
          "38.50",
          "38.37",
          "38.59",
          "38.39",
          "38.01",
          "38.04",
          "38.23",
          "38.78",
          "38.31",
          "37.82",
          "37.58",
          "38.07",
          "37.92",
          "38.35",
          "39.04",
          "39.72",
          "39.97",
          "39.57",
          "39.74",
          "39.91",
          "39.22",
          "39.15",
          "39.56",
          "39.58",
          "39.27",
          "38.75",
          "39.54",
          "39.36",
          "40.74",
          "40.65",
          "40.56",
          "40.51",
          "39.52",
          "39.40",
          "38.62",
          "38.44",
          "36.97",
          "37.43",
          "35.35",
          "35.18",
          "33.03",
          "34.70",
          "31.54",
          "32.12",
          "33.09",
          "33.65",
          "33.42",
          "33.37",
          "32.55",
          "31.85",
          "32.02",
          "32.11",
          "32.51",
          "32.01",
          "32.40",
          "33.16",
          "33.58",
          "34.06",
          "33.38",
          "32.46",
          "31.76",
          "32.63",
          "31.95",
          "31.04",
          "31.29",
          "31.45",
          "32.29",
          "32.94",
          "32.91",
          "32.51",
          "32.31",
          "31.28",
          "29.55",
          "29.83",
          "30.30",
          "31.16",
          "30.48",
          "30.65",
          "30.16",
          "29.00",
          "29.86",
          "31.51",
          "32.03",
          "31.70",
          "33.00",
          "32.61",
          "33.76",
          "33.55",
          "34.47",
          "33.39",
          "33.94",
          "33.61",
          "33.84",
          "35.16",
          "35.37",
          "34.51",
          "35.05",
          "36.28",
          "36.21",
          "34.88",
          "33.60",
          "34.07",
          "35.03",
          "34.76",
          "35.15",
          "35.29",
          "33.79",
          "34.64",
          "36.70",
          "36.12",
          "36.45",
          "35.53",
          "35.15",
          "35.63",
          "34.33",
          "34.02",
          "33.40",
          "33.51",
          "34.07",
          "34.00",
          "35.85",
          "35.99",
          "36.61",
          "36.94",
          "36.83",
          "37.10",
          "35.92",
          "36.56",
          "36.65",
          "36.33",
          "35.16",
          "35.19",
          "35.32",
          "34.75",
          "36.17",
          "36.27",
          "36.95",
          "37.70",
          "37.61",
          "37.24",
          "37.71",
          "37.50",
          "38.31",
          "38.85",
          "39.50",
          "39.91",
          "39.75",
          "39.63",
          "38.70",
          "38.73",
          "38.40",
          "38.48",
          "39.02",
          "39.44",
          "39.31",
          "39.25",
          "39.25",
          "39.56",
          "39.35",
          "39.25",
          "38.99",
          "38.90",
          "39.33",
          "38.91",
          "40.00",
          "40.46",
          "40.98",
          "41.27",
          "41.53",
          "41.45",
          "41.79",
          "41.60",
          "41.25",
          "41.54",
          "41.75",
          "41.57",
          "41.27",
          "41.48",
          "41.31",
          "41.64",
          "41.93",
          "41.99",
          "42.39",
          "42.36",
          "42.70",
          "42.00",
          "41.75",
          "42.02",
          "42.24",
          "42.34",
          "44.01",
          "43.48",
          "43.47",
          "43.19",
          "43.44",
          "43.24",
          "43.27",
          "43.29",
          "43.65",
          "44.38",
          "44.15",
          "43.51",
          "43.01",
          "43.78",
          "43.84",
          "43.29",
          "42.93",
          "43.08",
          "42.11",
          "40.99",
          "41.37",
          "42.15",
          "41.85",
          "41.66",
          "42.68",
          "42.49",
          "42.08",
          "42.35",
          "42.01",
          "42.18",
          "42.70",
          "43.36",
          "43.35",
          "43.11",
          "43.79",
          "43.54",
          "43.81",
          "42.93",
          "43.82",
          "44.30",
          "45.02",
          "45.28",
          "45.56",
          "45.17",
          "45.01",
          "45.08",
          "44.33",
          "43.81",
          "44.39",
          "44.39",
          "44.21",
          "44.44",
          "44.50",
          "45.48",
          "45.20",
          "45.71",
          "44.40",
          "44.41",
          "44.84",
          "45.53",
          "45.63",
          "46.24",
          "45.80",
          "46.38",
          "46.23",
          "47.18",
          "47.09",
          "47.10",
          "47.51",
          "47.73",
          "47.40",
          "47.47",
          "46.70",
          "47.30",
          "47.87",
          "47.82",
          "48.50",
          "48.72",
          "48.59",
          "48.11",
          "48.04",
          "48.00",
          "47.36",
          "47.27",
          "47.41",
          "48.19",
          "47.86",
          "49.35",
          "49.35",
          "48.99",
          "48.59",
          "47.98",
          "48.16",
          "48.30",
          "49.71",
          "49.94",
          "49.80",
          "49.14",
          "48.82",
          "48.98",
          "49.77",
          "49.65",
          "49.81",
          "50.49",
          "49.96",
          "49.65",
          "49.86",
          "49.69",
          "49.89",
          "50.25",
          "50.46",
          "50.45",
          "49.64",
          "49.66",
          "49.17",
          "49.56",
          "49.63",
          "49.63",
          "50.04",
          "49.42",
          "49.47",
          "49.66",
          "50.79",
          "51.86",
          "51.74",
          "51.52",
          "51.56",
          "51.70",
          "52.60",
          "52.35",
          "52.15",
          "51.90",
          "52.70",
          "52.66",
          "52.74",
          "52.92",
          "52.54",
          "51.94",
          "52.58",
          "52.28",
          "52.07",
          "51.64",
          "52.47",
          "52.63",
          "52.97",
          "52.33",
          "51.51",
          "51.21",
          "50.34",
          "50.59",
          "50.79",
          "51.24",
          "52.29",
          "52.42",
          "51.90",
          "51.79",
          "50.76",
          "50.65",
          "50.26",
          "50.08",
          "49.12",
          "49.78",
          "49.86",
          "50.32",
          "50.47",
          "50.08",
          "50.04",
          "47.06",
          "47.45",
          "47.96",
          "47.17",
          "47.47",
          "47.42",
          "47.91",
          "48.44",
          "48.68",
          "49.26",
          "49.03",
          "48.60",
          "49.20",
          "49.72",
          "49.66",
          "49.29",
          "49.30",
          "49.59",
          "49.06",
          "49.24",
          "49.30",
          "49.48",
          "49.64",
          "49.11",
          "48.67",
          "49.28",
          "50.22",
          "49.94",
          "50.93",
          "50.00",
          "49.88",
          "49.85",
          "49.53",
          "49.15",
          "49.79",
          "51.10",
          "51.21",
          "52.19",
          "50.97",
          "50.76",
          "50.78",
          "50.79",
          "50.58",
          "50.59",
          "51.09",
          "51.53",
          "52.41",
          "52.34",
          "52.73",
          "53.95",
          "53.95",
          "54.38",
          "54.36",
          "53.99",
          "53.79",
          "53.88",
          "54.59",
          "53.90",
          "54.29",
          "54.52",
          "54.36",
          "54.66",
          "54.75",
          "54.95",
          "54.96",
          "54.88",
          "55.61",
          "55.73",
          "54.60",
          "54.17",
          "54.25",
          "53.59",
          "53.90",
          "54.48",
          "54.59",
          "55.33",
          "55.80",
          "56.48",
          "56.36",
          "56.32",
          "57.39",
          "57.66",
          "57.11",
          "57.34",
          "57.75",
          "57.58",
          "56.83",
          "56.31",
          "56.94",
          "56.31",
          "56.78",
          "56.21",
          "56.63",
          "56.47",
          "56.80",
          "56.69",
          "57.46",
          "57.25",
          "57.59",
          "57.70",
          "58.82",
          "59.14",
          "60.11",
          "60.55",
          "60.55",
          "58.88",
          "60.75",
          "60.68",
          "59.99",
          "61.56",
          "62.01",
          "62.59",
          "61.94",
          "62.00",
          "61.87",
          "63.00",
          "62.84",
          "63.21",
          "63.88",
          "64.80",
          "65.06",
          "66.07",
          "65.99",
          "66.67",
          "67.20",
          "67.32",
          "67.47",
          "67.67",
          "66.47",
          "66.58",
          "66.12",
          "65.83",
          "65.57",
          "65.23",
          "65.49",
          "66.69",
          "66.26",
          "64.65",
          "63.08",
          "63.80",
          "64.35",
          "63.12",
          "63.14",
          "64.85",
          "63.83",
          "63.80",
          "62.99",
          "64.30",
          "63.80",
          "64.49",
          "65.29",
          "64.33",
          "61.98",
          "62.73",
          "62.44",
          "62.57",
          "63.21",
          "63.72",
          "63.15",
          "63.93",
          "63.26",
          "63.61",
          "63.82",
          "64.71",
          "64.94",
          "64.91",
          "66.58",
          "66.98",
          "65.93",
          "65.03",
          "65.36",
          "65.82",
          "65.16",
          "64.40",
          "64.45",
          "64.64",
          "64.55",
          "64.98",
          "64.61",
          "64.18",
          "64.65",
          "65.36",
          "66.51",
          "66.02",
          "67.05",
          "65.91",
          "65.75",
          "64.73",
          "63.93",
          "63.98",
          "63.96",
          "62.38",
          "62.17",
          "61.83",
          "61.88",
          "61.14",
          "61.64",
          "61.73",
          "61.35",
          "60.69",
          "60.81",
          "61.08",
          "60.83",
          "60.93",
          "61.11",
          "61.30",
          "61.39",
          "61.59",
          "62.83",
          "63.94",
          "65.49",
          "66.69",
          "66.94",
          "66.98",
          "67.11",
          "65.72",
          "65.01",
          "64.75",
          "64.32",
          "64.45",
          "65.24",
          "65.19",
          "64.49",
          "64.83",
          "64.88",
          "64.02",
          "65.30",
          "64.59",
          "64.00",
          "63.59",
          "65.58",
          "66.21",
          "66.83",
          "66.44",
          "66.35",
          "66.42",
          "67.15",
          "67.61",
          "69.00",
          "68.12",
          "69.05",
          "69.26",
          "69.00",
          "68.92",
          "68.47",
          "68.59",
          "69.01",
          "68.81",
          "68.85",
          "69.00",
          "67.15",
          "68.58",
          "68.34",
          "67.77",
          "68.97",
          "70.02",
          "70.00",
          "69.50",
          "69.12",
          "69.23",
          "69.94",
          "70.20",
          "69.74",
          "71.18",
          "70.77",
          "70.54",
          "70.91",
          "69.90",
          "69.97",
          "70.23",
          "71.46",
          "71.11",
          "71.57",
          "70.52",
          "69.63",
          "69.62",
          "70.51",
          "70.66",
          "72.20",
          "72.97",
          "72.40",
          "73.28",
          "73.85",
          "74.62",
          "74.35",
          "76.23",
          "76.40",
          "76.27",
          "76.11",
          "75.82",
          "76.34",
          "75.22",
          "74.90",
          "75.39",
          "73.27",
          "74.45",
          "74.28",
          "74.21",
          "73.98",
          "74.20",
          "75.31",
          "74.79",
          "72.72",
          "72.25",
          "72.88",
          "71.33",
          "73.22",
          "72.61",
          "69.99",
          "71.05",
          "75.56",
          "75.67",
          "77.06",
          "77.79",
          "77.91",
          "77.90",
          "79.23",
          "79.58",
          "78.87",
          "79.19",
          "80.13",
          "80.73",
          "80.21",
          "80.08",
          "80.48",
          "80.81",
          "79.46",
          "81.71",
          "82.68",
          "83.34",
          "82.21",
          "81.97",
          "81.05",
          "81.38",
          "79.93",
          "80.07",
          "81.39",
          "81.99",
          "80.52",
          "80.81",
          "80.35",
          "79.49",
          "79.55",
          "78.62",
          "78.48",
          "78.99",
          "80.07",
          "81.57",
          "81.67",
          "81.69",
          "80.43",
          "79.13",
          "79.57",
          "80.47",
          "77.51",
          "77.01",
          "77.62",
          "77.66",
          "78.95",
          "79.99",
          "79.11",
          "79.45",
          "79.52",
          "79.59",
          "78.23",
          "77.78",
          "78.64",
          "79.34",
          "79.56",
          "80.31",
          "81.22",
          "81.03",
          "80.29",
          "81.60",
          "81.95",
          "82.42",
          "82.08",
          "80.92",
          "80.15",
          "80.39",
          "81.05",
          "81.09",
          "82.19",
          "82.35",
          "83.32",
          "83.74",
          "83.60",
          "84.03",
          "84.01",
          "84.27",
          "83.88",
          "84.24",
          "84.78",
          "84.61",
          "85.48",
          "84.75",
          "84.31",
          "82.80",
          "82.80",
          "83.30",
          "83.16",
          "83.58",
          "83.77",
          "82.82",
          "82.76",
          "82.68",
          "83.90",
          "84.45",
          "85.30",
          "85.74",
          "86.47",
          "86.44",
          "86.84",
          "86.59",
          "85.86",
          "87.22",
          "86.86",
          "86.89",
          "86.76",
          "86.15",
          "85.35",
          "85.03",
          "85.81",
          "85.74",
          "86.21",
          "86.04",
          "86.80",
          "86.23",
          "87.15",
          "86.20",
          "87.21",
          "85.88",
          "85.38",
          "87.24",
          "86.75",
          "86.59",
          "85.51",
          "86.85",
          "87.50",
          "87.21",
          "87.60",
          "88.61",
          "89.28",
          "89.97",
          "90.09",
          "89.84",
          "90.38",
          "90.49",
          "90.38",
          "90.02",
          "90.37",
          "90.23",
          "89.88",
          "90.80",
          "90.94",
          "90.14",
          "90.94",
          "90.56",
          "89.60",
          "89.52",
          "89.97",
          "89.67",
          "90.08",
          "90.27",
          "90.33",
          "90.34",
          "90.49",
          "89.29",
          "88.31",
          "89.45",
          "88.07",
          "88.74",
          "88.83",
          "89.03",
          "87.49",
          "86.79",
          "88.45",
          "88.56",
          "87.11",
          "88.11",
          "85.71",
          "86.27",
          "83.91",
          "84.14",
          "82.08",
          "81.74",
          "83.83",
          "85.52",
          "87.54",
          "87.10",
          "87.99",
          "88.61",
          "88.45",
          "89.93",
          "89.53",
          "90.22",
          "91.38",
          "91.71",
          "90.35",
          "91.00",
          "92.00",
          "90.00",
          "89.78",
          "89.98",
          "89.90",
          "90.49",
          "90.80",
          "90.41",
          "90.28",
          "89.82",
          "88.90",
          "88.96",
          "90.58",
          "91.65",
          "91.92",
          "92.51",
          "92.70",
          "93.47",
          "93.11",
          "93.23",
          "93.76",
          "93.80",
          "92.94",
          "91.63",
          "91.75",
          "91.49",
          "90.90",
          "90.17",
          "91.38",
          "92.61",
          "92.89",
          "94.20",
          "94.69",
          "94.42",
          "95.03",
          "95.50",
          "94.70",
          "94.19",
          "93.75",
          "92.38",
          "91.89",
          "92.83",
          "93.79",
          "94.25",
          "94.46",
          "95.19",
          "94.23",
          "94.35",
          "95.18",
          "94.74",
          "94.17",
          "95.15",
          "94.72",
          "94.97",
          "93.97",
          "92.67",
          "93.22",
          "90.96",
          "91.93",
          "94.10",
          "101.28",
          "102.64",
          "102.02",
          "101.73",
          "101.92",
          "101.87",
          "103.58",
          "104.17",
          "104.12",
          "103.86",
          "103.89",
          "104.55",
          "104.99",
          "104.67",
          "105.57",
          "104.56",
          "104.08",
          "105.89",
          "106.35",
          "105.57",
          "105.03",
          "103.82",
          "105.26",
          "103.09",
          "102.89",
          "107.17",
          "106.44",
          "107.37",
          "106.96",
          "107.97",
          "107.37",
          "108.43",
          "108.22",
          "107.11",
          "105.00",
          "105.24",
          "105.48",
          "106.12",
          "104.89",
          "105.44",
          "106.00",
          "105.63",
          "105.43",
          "106.36",
          "106.77",
          "106.95",
          "106.51",
          "106.66",
          "106.98",
          "108.10",
          "106.69",
          "108.22",
          "107.68",
          "107.94",
          "109.00",
          "109.53",
          "110.16",
          "109.92",
          "109.81",
          "108.72",
          "110.52",
          "111.03",
          "110.81",
          "109.72",
          "109.26",
          "110.11",
          "108.60",
          "109.24",
          "109.19",
          "109.93",
          "110.30",
          "110.33",
          "110.56",
          "110.20",
          "110.39",
          "110.26",
          "109.44",
          "110.37",
          "110.53",
          "110.37",
          "110.96",
          "110.75",
          "111.17",
          "110.30",
          "110.30",
          "109.29",
          "108.52",
          "110.00",
          "110.62",
          "109.95",
          "110.18",
          "111.06",
          "111.49",
          "113.22",
          "112.62",
          "113.53",
          "114.41",
          "113.77",
          "114.45",
          "114.99",
          "113.05",
          "114.14",
          "115.13",
          "114.97",
          "115.70",
          "117.10",
          "115.19",
          "115.60",
          "116.44",
          "118.05",
          "117.85",
          "118.30",
          "119.07",
          "118.86",
          "119.58",
          "119.31",
          "119.33",
          "118.80",
          "118.91",
          "118.25",
          "118.46",
          "119.84",
          "120.03",
          "120.00",
          "121.12",
          "121.69",
          "110.53",
          "108.55",
          "109.35",
          "111.00",
          "108.00",
          "106.99",
          "107.52",
          "107.16",
          "109.05",
          "106.94",
          "106.45",
          "100.02",
          "98.84",
          "95.36",
          "95.89",
          "99.23",
          "102.17",
          "102.48",
          "101.88",
          "99.51",
          "101.89",
          "101.99",
          "100.97",
          "104.01",
          "101.91",
          "102.60",
          "104.48",
          "103.82",
          "103.43",
          "103.96",
          "104.20",
          "102.84",
          "103.41",
          "102.49",
          "101.57",
          "100.62",
          "100.30",
          "98.49",
          "99.42",
          "102.20",
          "102.67",
          "103.00",
          "103.85",
          "103.77",
          "103.39",
          "104.61",
          "105.56",
          "106.35",
          "106.59",
          "105.73",
          "107.89",
          "108.24",
          "109.47",
          "109.84",
          "110.09",
          "113.25",
          "113.09",
          "113.52",
          "113.77",
          "114.34",
          "115.04",
          "113.74",
          "115.04",
          "115.54",
          "113.25",
          "113.00",
          "115.67",
          "116.42",
          "117.42",
          "116.52",
          "116.21",
          "114.84",
          "115.92",
          "116.13",
          "118.14",
          "118.71",
          "120.07",
          "119.42",
          "117.95",
          "118.67",
          "115.13",
          "113.47",
          "115.39",
          "114.00",
          "111.89",
          "114.24",
          "113.83",
          "112.48",
          "111.47",
          "110.76",
          "108.04",
          "109.35",
          "112.16",
          "113.79",
          "112.01",
          "107.72",
          "106.59",
          "106.74",
          "105.56",
          "105.86",
          "107.25",
          "107.08",
          "106.34",
          "105.08",
          "102.98",
          "100.90",
          "100.36",
          "99.50",
          "99.25",
          "99.92",
          "101.46",
          "98.48",
          "99.11",
          "93.90",
          "93.97",
          "92.54",
          "94.02",
          "96.90",
          "95.29",
          "96.27",
          "94.32",
          "93.53",
          "95.82",
          "95.15",
          "93.12",
          "95.14",
          "95.43",
          "93.90",
          "92.12",
          "92.32",
          "88.85",
          "90.31",
          "91.15",
          "92.91",
          "95.50",
          "95.17",
          "95.01",
          "96.37",
          "95.38",
          "95.43",
          "95.65",
          "95.31",
          "95.52",
          "97.65",
          "97.00",
          "98.82",
          "98.48",
          "99.39",
          "97.82",
          "97.66",
          "97.04",
          "97.94",
          "98.81",
          "98.24",
          "98.44",
          "99.60",
          "99.20",
          "98.46",
          "97.58",
          "96.83",
          "97.22",
          "98.09",
          "98.16",
          "98.91",
          "99.31",
          "99.07",
          "98.68",
          "97.00",
          "97.48",
          "96.16",
          "96.42",
          "96.27",
          "97.35",
          "99.48",
          "98.63",
          "98.59",
          "101.48",
          "102.64",
          "103.27",
          "102.91",
          "103.77",
          "104.57",
          "104.89",
          "105.28",
          "104.03",
          "103.26",
          "104.36",
          "103.78",
          "103.67",
          "104.93",
          "105.54",
          "105.34",
          "106.60",
          "102.29",
          "101.71",
          "100.52",
          "100.36",
          "99.94",
          "99.00",
          "98.41",
          "99.78",
          "99.18",
          "99.51",
          "99.86",
          "99.81",
          "100.29",
          "99.22",
          "98.52",
          "98.72",
          "98.75",
          "98.78",
          "98.35",
          "98.04",
          "97.84",
          "97.34",
          "97.57",
          "98.40",
          "98.27",
          "98.38",
          "99.00",
          "99.57",
          "98.82",
          "98.79",
          "99.02",
          "95.72",
          "94.38",
          "96.05",
          "96.98",
          "97.82",
          "98.03",
          "97.66",
          "98.45",
          "98.41",
          "99.62",
          "99.98",
          "100.20",
          "99.88",
          "99.97",
          "99.80",
          "100.15",
          "99.47",
          "98.22",
          "98.01",
          "97.71",
          "97.39",
          "96.69",
          "96.33",
          "95.91",
          "95.95",
          "95.54",
          "95.01",
          "96.09",
          "95.16",
          "95.83",
          "95.75",
          "96.67",
          "97.86",
          "97.77",
          "96.84",
          "97.10",
          "96.88",
          "96.87",
          "96.65",
          "96.39",
          "95.87",
          "95.97",
          "95.82",
          "95.55",
          "95.21",
          "94.87",
          "94.86",
          "94.46",
          "94.26",
          "94.42",
          "93.80",
          "93.71",
          "94.09",
          "92.42",
          "93.64",
          "92.70",
          "92.26",
          "92.50",
          "92.56",
          "92.63",
          "92.95",
          "92.39",
          "93.41",
          "93.27",
          "91.96",
          "91.72",
          "92.20",
          "91.80",
          "92.86",
          "92.49",
          "92.59",
          "92.45",
          "92.83",
          "92.49",
          "92.49",
          "91.86",
          "91.41",
          "91.12",
          "91.30",
          "90.83",
          "91.17",
          "91.93",
          "92.03",
          "93.03",
          "93.37",
          "92.75",
          "93.49",
          "94.02",
          "93.85",
          "92.69",
          "92.39",
          "91.91",
          "93.37",
          "92.45",
          "94.43",
          "94.38",
          "94.64",
          "94.96",
          "97.68",
          "97.92",
          "97.70",
          "99.12",
          "99.37",
          "98.24",
          "97.63",
          "97.71",
          "98.26",
          "98.82",
          "98.97",
          "99.67",
          "99.12",
          "98.94",
          "98.50",
          "99.96",
          "100.66",
          "101.99",
          "103.38",
          "104.86",
          "104.06",
          "103.85",
          "104.05",
          "104.39",
          "103.91",
          "105.30",
          "105.46",
          "105.56",
          "105.42",
          "105.15",
          "105.17",
          "104.30",
          "104.56",
          "104.22",
          "106.08",
          "107.44",
          "107.38",
          "108.98",
          "108.36",
          "108.38",
          "109.44",
          "107.53",
          "108.06",
          "107.97",
          "108.16",
          "107.28",
          "107.66",
          "107.12",
          "107.90",
          "108.04",
          "108.06",
          "109.30",
          "110.94",
          "110.65",
          "111.30",
          "110.61",
          "110.30",
          "109.57",
          "109.00",
          "109.00",
          "109.49",
          "109.26",
          "109.65",
          "110.33",
          "110.18",
          "110.71",
          "110.06",
          "110.01",
          "110.12",
          "109.73",
          "110.32",
          "110.23",
          "110.09",
          "111.04",
          "110.59",
          "111.24",
          "110.67",
          "110.86",
          "110.84",
          "111.03",
          "110.92",
          "111.52",
          "112.31",
          "111.87",
          "111.71",
          "111.76",
          "112.71",
          "111.75",
          "112.08",
          "112.24",
          "112.14",
          "112.38",
          "113.05",
          "112.53",
          "113.18",
          "113.39",
          "113.20",
          "113.04",
          "113.00",
          "113.05",
          "112.58",
          "112.43",
          "113.07",
          "113.04",
          "113.20",
          "113.78",
          "114.19",
          "113.73",
          "114.79",
          "114.44",
          "113.70",
          "115.18",
          "115.58",
          "115.84",
          "115.60",
          "114.73",
          "114.37",
          "111.62",
          "111.17",
          "111.99",
          "111.42",
          "112.07",
          "109.66",
          "109.58",
          "109.69",
          "109.13",
          "107.98",
          "106.31",
          "106.68",
          "107.52",
          "107.63",
          "107.02",
          "107.71",
          "107.27",
          "108.41",
          "108.34",
          "107.94",
          "108.21",
          "107.18",
          "106.52",
          "105.50",
          "105.92",
          "104.32",
          "105.62",
          "107.04",
          "106.56",
          "106.14",
          "105.98",
          "105.51",
          "105.37",
          "103.94",
          "104.80",
          "104.22",
          "104.36",
          "105.57",
          "105.41",
          "106.86",
          "105.71",
          "106.25",
          "107.46",
          "105.63",
          "103.35",
          "103.32",
          "103.59",
          "103.84",
          "104.38",
          "104.29",
          "105.09",
          "104.79",
          "105.75",
          "106.82",
          "107.36",
          "107.09",
          "107.00",
          "106.42",
          "106.94",
          "110.00",
          "109.96",
          "109.93",
          "110.61",
          "108.67",
          "109.12",
          "107.69",
          "106.35",
          "106.98",
          "102.83",
          "101.35",
          "101.99",
          "101.40",
          "101.51",
          "102.20",
          "101.38",
          "100.70",
          "101.17",
          "102.77",
          "101.50",
          "101.52",
          "102.41",
          "102.56",
          "102.57",
          "102.87",
          "101.20",
          "101.50",
          "101.60",
          "101.50",
          "97.06",
          "97.07",
          "97.09",
          "97.89",
          "98.82",
          "97.90",
          "98.52",
          "98.10",
          "98.43",
          "99.21",
          "98.89",
          "98.60",
          "99.57",
          "98.63",
          "99.24",
          "98.05",
          "98.57",
          "99.86",
          "100.79",
          "100.55",
          "100.11",
          "100.07",
          "99.57",
          "99.58",
          "98.55",
          "96.93",
          "97.38",
          "98.13",
          "98.36",
          "98.25",
          "99.01",
          "99.40",
          "98.70",
          "98.29",
          "97.80",
          "98.56",
          "98.31",
          "98.04",
          "97.81",
          "99.03",
          "98.35",
          "98.64",
          "100.64",
          "101.61",
          "101.18",
          "102.68",
          "104.78",
          "104.74",
          "103.17",
          "103.69",
          "103.60",
          "103.44",
          "102.75",
          "103.00",
          "102.74",
          "102.64",
          "102.79",
          "103.41",
          "105.24",
          "104.82",
          "105.25",
          "110.22",
          "107.22",
          "105.46",
          "105.26",
          "104.23",
          "106.83",
          "107.43",
          "107.61",
          "110.57",
          "111.27",
          "111.03",
          "111.81",
          "109.69",
          "109.57",
          "108.67",
          "108.12",
          "107.64",
          "107.77",
          "107.51",
          "111.80",
          "112.28",
          "112.23",
          "111.62",
          "110.02",
          "109.94",
          "109.47",
          "110.99",
          "112.47",
          "110.69",
          "111.97",
          "110.42",
          "110.59",
          "111.10",
          "110.41",
          "110.50",
          "110.55",
          "112.19",
          "111.54",
          "110.11",
          "108.67",
          "110.49",
          "108.70",
          "104.70",
          "106.17",
          "104.76",
          "101.35",
          "103.09",
          "103.39",
          "104.12",
          "104.60",
          "105.18",
          "106.53",
          "105.98",
          "105.05",
          "105.24",
          "107.25",
          "109.81",
          "104.87",
          "103.16",
          "102.57",
          "102.99",
          "103.41",
          "104.94",
          "103.59",
          "104.03",
          "104.73",
          "105.17",
          "103.73",
          "103.90",
          "103.24",
          "102.87",
          "101.48",
          "101.35",
          "101.82",
          "100.60",
          "98.54",
          "100.65",
          "99.36",
          "98.54",
          "100.44",
          "98.66",
          "99.42",
          "100.95",
          "102.11",
          "100.35",
          "99.70",
          "101.37",
          "100.80",
          "100.39",
          "100.35",
          "100.24",
          "102.17",
          "101.21",
          "100.89",
          "100.24",
          "100.15",
          "99.46",
          "101.15",
          "99.84",
          "99.23",
          "100.33",
          "100.06",
          "99.62",
          "98.76",
          "101.15",
          "102.48",
          "101.79",
          "99.97",
          "101.68",
          "102.07",
          "102.44",
          "102.92",
          "105.04",
          "104.34",
          "103.93",
          "104.06",
          "104.07",
          "102.89",
          "102.11",
          "102.20",
          "99.69",
          "99.98",
          "99.47",
          "99.36",
          "100.24",
          "99.94",
          "101.91",
          "102.47",
          "103.98",
          "104.35",
          "104.33",
          "106.31",
          "108.75",
          "108.85",
          "107.06",
          "106.10",
          "107.15",
          "105.89",
          "106.34",
          "104.45",
          "104.26",
          "103.96",
          "104.77",
          "104.81",
          "105.33",
          "104.04",
          "105.34",
          "104.78",
          "106.02",
          "106.03",
          "108.04",
          "108.25",
          "110.00",
          "110.20",
          "110.30",
          "110.69",
          "112.13",
          "111.48",
          "111.09",
          "110.70",
          "111.18",
          "113.51",
          "112.62",
          "112.63",
          "113.56",
          "112.97",
          "112.75",
          "114.09",
          "115.94",
          "116.56",
          "113.98",
          "114.16",
          "112.68",
          "112.12",
          "112.75",
          "112.85",
          "112.48",
          "112.48",
          "111.99",
          "112.39",
          "111.94",
          "112.00",
          "111.93",
          "112.33",
          "112.58",
          "112.45",
          "111.92",
          "112.02",
          "110.85",
          "109.87",
          "110.26",
          "110.97",
          "110.68",
          "109.60",
          "109.46",
          "110.67",
          "109.26",
          "109.36",
          "109.53",
          "109.79",
          "111.62",
          "110.40",
          "112.77",
          "113.63",
          "115.21",
          "116.04",
          "116.94",
          "116.24",
          "117.66",
          "116.91",
          "116.13",
          "114.78",
          "116.02",
          "116.89",
          "112.86",
          "111.15",
          "112.61",
          "113.44",
          "116.19",
          "117.13",
          "116.18",
          "118.90",
          "118.27",
          "117.85",
          "111.61",
          "114.16",
          "113.19",
          "113.04",
          "114.76",
          "114.83",
          "116.10",
          "115.18",
          "115.45",
          "116.71",
          "117.05",
          "116.00",
          "118.00",
          "116.70",
          "116.85",
          "117.12",
          "117.11",
          "116.19",
          "115.42",
          "111.87",
          "113.03",
          "112.08",
          "112.55",
          "113.90",
          "116.10",
          "116.61",
          "115.49",
          "115.74",
          "112.87",
          "114.33",
          "111.98",
          "111.86",
          "111.97",
          "112.21",
          "113.39",
          "112.20",
          "110.62",
          "109.45",
          "109.22",
          "107.00",
          "104.22",
          "100.35",
          "105.83",
          "106.52",
          "107.30",
          "109.65",
          "108.97",
          "106.33",
          "109.61",
          "110.56",
          "111.42",
          "112.67",
          "112.80",
          "112.65",
          "112.42",
          "111.76",
          "110.91",
          "111.01",
          "111.04",
          "110.60",
          "111.12",
          "110.55",
          "111.09",
          "110.81",
          "110.90",
          "110.13",
          "111.52",
          "111.30",
          "111.80",
          "112.66",
          "111.41",
          "110.95",
          "111.51",
          "109.44",
          "109.20",
          "110.20",
          "110.66",
          "112.59",
          "113.51",
          "113.68",
          "114.29",
          "115.25",
          "113.59",
          "113.50",
          "112.78",
          "112.84",
          "114.01",
          "114.33",
          "114.00",
          "114.85",
          "114.01",
          "113.81",
          "114.75",
          "114.73",
          "114.09",
          "114.48",
          "114.96",
          "113.12",
          "110.00",
          "109.99",
          "108.66",
          "108.23",
          "107.79",
          "110.14",
          "110.28",
          "110.71",
          "111.03",
          "112.51",
          "111.96",
          "112.52",
          "114.75",
          "115.00",
          "114.96",
          "116.86",
          "117.16",
          "116.60",
          "130.06",
          "132.04",
          "129.90",
          "131.75",
          "132.45",
          "131.68",
          "133.36",
          "135.10",
          "137.24",
          "139.92",
          "139.30",
          "136.97",
          "136.38",
          "134.14",
          "134.33",
          "135.00",
          "133.44",
          "134.99",
          "133.59",
          "134.04",
          "131.34",
          "133.20",
          "134.68",
          "135.50",
          "135.04",
          "133.91",
          "134.09",
          "133.85",
          "132.73",
          "132.79",
          "132.62",
          "131.57",
          "132.20",
          "132.04",
          "132.47",
          "134.82",
          "135.94",
          "137.21",
          "138.04",
          "137.07",
          "135.08",
          "135.72",
          "141.74",
          "141.65",
          "140.97",
          "139.24",
          "140.92",
          "142.02",
          "140.23",
          "139.22",
          "139.94",
          "140.40",
          "139.30",
          "139.64",
          "141.65",
          "142.53",
          "142.98",
          "142.45",
          "141.02",
          "141.61",
          "143.54",
          "143.56",
          "144.88",
          "145.06",
          "144.30",
          "142.57",
          "141.63",
          "139.85",
          "140.84",
          "141.26",
          "141.29",
          "143.21",
          "144.65",
          "146.39",
          "144.93",
          "143.01",
          "141.85",
          "141.71",
          "138.30",
          "141.87",
          "134.86",
          "137.89",
          "138.52",
          "135.75",
          "137.01",
          "132.85",
          "133.41",
          "135.20",
          "135.29",
          "135.13",
          "135.76",
          "136.08",
          "131.67",
          "134.61",
          "134.49",
          "136.55",
          "137.84",
          "137.26",
          "136.31",
          "137.89",
          "138.84",
          "139.55",
          "138.83",
          "135.79",
          "136.19",
          "137.50",
          "138.02",
          "135.80",
          "136.31",
          "136.80",
          "133.30",
          "132.27",
          "132.46",
          "131.97",
          "133.09",
          "131.27",
          "129.96",
          "130.32",
          "129.55",
          "129.14",
          "128.15",
          "130.27",
          "130.90",
          "128.47",
          "129.33",
          "129.34",
          "130.02",
          "129.70",
          "129.76",
          "130.86",
          "132.37",
          "130.89",
          "130.26",
          "132.40",
          "131.13",
          "130.26",
          "130.90",
          "130.53",
          "129.48",
          "129.60",
          "129.92",
          "132.75",
          "132.92",
          "131.45",
          "131.27",
          "132.96",
          "137.96",
          "136.74",
          "138.58",
          "148.72",
          "147.15",
          "144.67",
          "147.65",
          "148.38",
          "146.93",
          "146.90",
          "148.29",
          "149.69",
          "151.64",
          "151.48",
          "151.58",
          "150.62",
          "148.58",
          "148.28",
          "147.44",
          "147.66",
          "146.21",
          "146.10",
          "147.59",
          "147.76",
          "146.38",
          "148.46",
          "147.73",
          "146.26",
          "146.15",
          "146.88",
          "144.68",
          "145.29",
          "145.70",
          "145.75",
          "143.77",
          "144.63",
          "148.20",
          "146.50",
          "145.65",
          "145.70",
          "145.40",
          "144.83",
          "144.62",
          "143.88",
          "145.20",
          "144.32",
          "145.12",
          "144.33",
          "143.56",
          "144.01",
          "142.20",
          "140.08",
          "135.90",
          "138.37",
          "136.06",
          "137.81",
          "138.31",
          "141.32",
          "144.73",
          "141.37",
          "142.70",
          "141.02",
          "142.59",
          "141.01",
          "141.85",
          "140.90",
          "139.54",
          "139.14",
          "141.30",
          "140.37",
          "138.97",
          "133.01",
          "128.19",
          "123.36",
          "118.04",
          "117.65",
          "119.98",
          "116.45",
          "119.18",
          "113.98",
          "115.27",
          "104.35",
          "111.46",
          "105.51",
          "91.81",
          "102.52",
          "95.01",
          "93.53",
          "88.80",
          "94.93",
          "85.98",
          "85.76",
          "98.12",
          "100.73",
          "105.36",
          "96.40",
          "99.80",
          "96.60",
          "94.92",
          "96.97",
          "93.88",
          "99.58",
          "101.24",
          "101.07",
          "104.50",
          "103.50",
          "106.03",
          "103.37",
          "102.02",
          "106.63",
          "102.26",
          "100.54",
          "100.99",
          "101.00",
          "101.19",
          "106.06",
          "106.21",
          "112.25",
          "108.15",
          "105.50",
          "103.18",
          "101.06",
          "100.88",
          "105.57",
          "109.16",
          "107.77",
          "104.56",
          "102.92",
          "105.91",
          "109.05",
          "116.85",
          "114.37",
          "119.92",
          "117.83",
          "118.02",
          "120.95",
          "121.53",
          "116.75",
          "117.30",
          "118.77",
          "118.75",
          "122.18",
          "123.69",
          "124.82",
          "127.28",
          "123.89",
          "122.18",
          "112.64",
          "115.49",
          "117.08",
          "118.44",
          "117.65",
          "118.37",
          "114.35",
          "115.92",
          "116.59",
          "112.07",
          "111.36",
          "109.10",
          "111.52",
          "111.51",
          "113.01",
          "112.18",
          "114.43",
          "113.63",
          "116.66",
          "116.81",
          "119.34",
          "116.22",
          "118.66",
          "120.90",
          "119.43",
          "118.65",
          "117.79",
          "118.62",
          "119.03",
          "118.12",
          "117.61",
          "116.31",
          "116.18",
          "115.61",
          "115.66",
          "116.94",
          "116.35",
          "117.29",
          "127.61",
          "130.82",
          "129.93",
          "128.79",
          "130.49",
          "131.79",
          "130.96",
          "130.53",
          "129.37",
          "128.92",
          "127.77",
          "128.12",
          "127.44",
          "130.69",
          "129.79",
          "132.18",
          "133.73",
          "135.54",
          "131.87",
          "133.55",
          "135.39",
          "133.24",
          "131.99",
          "134.20",
          "133.36",
          "133.22",
          "131.75",
          "131.25",
          "131.24",
          "132.09",
          "130.22",
          "128.63",
          "125.41",
          "127.21",
          "123.28",
          "122.49",
          "124.00",
          "125.99",
          "125.40",
          "124.08",
          "123.31",
          "122.55",
          "123.37",
          "120.93",
          "122.91",
          "123.09",
          "124.98",
          "124.97",
          "128.96",
          "126.59",
          "127.36",
          "126.81",
          "124.23",
          "124.95",
          "126.63",
          "127.56",
          "128.35",
          "124.06",
          "123.31",
          "118.47",
          "121.54",
          "121.25",
          "120.13",
          "124.02",
          "125.07",
          "126.96",
          "127.46",
          "142.59",
          "142.11",
          "137.82",
          "135.52",
          "138.36",
          "144.67",
          "144.50",
          "143.90",
          "141.72",
          "141.07",
          "145.98",
          "151.49",
          "149.09",
          "147.13",
          "148.01",
          "149.44",
          "153.61",
          "153.24",
          "154.14",
          "153.68",
          "153.72",
          "154.43",
          "154.69",
          "175.72",
          "169.30",
          "173.94",
          "173.12",
          "173.55",
          "172.89",
          "170.69",
          "170.45",
          "173.55",
          "173.73",
          "178.86",
          "177.30",
          "181.17",
          "181.18",
          "177.68",
          "178.44",
          "179.12",
          "178.58",
          "178.69",
          "179.09",
          "175.99",
          "176.12",
          "173.43",
          "171.44",
          "172.26",
          "173.64",
          "171.28",
          "172.78",
          "171.89",
          "169.56",
          "163.03",
          "171.88",
          "168.17",
          "170.97",
          "176.96",
          "176.43",
          "180.23",
          "181.16",
          "190.00",
          "188.21",
          "189.63",
          "190.91",
          "187.67",
          "186.35",
          "186.44",
          "183.00",
          "183.65",
          "191.76",
          "197.09",
          "197.51",
          "190.98",
          "189.04",
          "194.98",
          "193.94",
          "192.26",
          "188.03",
          "189.99",
          "201.91",
          "194.51",
          "195.06",
          "196.75",
          "197.16",
          "196.76",
          "194.24",
          "195.24",
          "192.28",
          "191.14",
          "192.86",
          "188.73",
          "184.72",
          "186.91",
          "185.92",
          "184.85",
          "185.53",
          "184.52",
          "188.97",
          "188.50",
          "189.73",
          "187.56",
          "187.32",
          "187.89",
          "186.49",
          "185.49",
          "187.46",
          "185.93",
          "187.26",
          "187.43",
          "182.79",
          "183.11",
          "182.76",
          "183.02",
          "184.27",
          "184.64",
          "183.39",
          "185.33",
          "186.02",
          "185.51",
          "184.25",
          "181.51",
          "181.79",
          "184.84",
          "184.30",
          "181.67",
          "177.85",
          "178.34",
          "173.70",
          "170.08",
          "169.68",
          "169.27",
          "171.36",
          "172.40",
          "174.31",
          "176.17",
          "176.47",
          "179.04",
          "178.65",
          "178.84",
          "177.00",
          "176.24",
          "177.18",
          "176.99",
          "176.33",
          "176.04",
          "176.57",
          "177.38",
          "178.18",
          "175.86",
          "174.66",
          "174.65",
          "172.42",
          "173.97",
          "173.50",
          "175.33",
          "177.93",
          "178.35",
          "176.57",
          "173.93",
          "175.77",
          "177.26",
          "177.11",
          "173.69",
          "172.82",
          "172.80",
          "177.04",
          "184.38",
          "183.65",
          "183.42",
          "184.15",
          "179.31",
          "172.95",
          "176.75",
          "176.89",
          "175.13",
          "176.14",
          "178.74",
          "179.50",
          "179.10",
          "178.35",
          "176.02",
          "175.55",
          "172.99",
          "172.58",
          "176.71",
          "177.13",
          "176.72",
          "177.07",
          "178.09",
          "179.29",
          "181.08",
          "179.09",
          "175.83",
          "174.74",
          "173.25",
          "175.12",
          "177.66",
          "178.47",
          "178.31",
          "176.56",
          "180.14",
          "179.98",
          "181.30",
          "183.48",
          "181.86",
          "181.00",
          "184.34",
          "185.15",
          "185.91",
          "184.12",
          "184.98",
          "182.40",
          "184.41",
          "183.34",
          "183.47",
          "178.61",
          "171.17",
          "173.65",
          "176.25",
          "176.00",
          "178.26",
          "174.52",
          "172.68",
          "169.17",
          "176.01",
          "173.46",
          "174.61",
          "175.48",
          "177.71",
          "176.74",
          "173.52",
          "173.13",
          "172.96",
          "174.41",
          "176.46",
          "171.14",
          "171.18",
          "170.55",
          "171.34",
          "169.42",
          "172.01",
          "172.04",
          "169.55",
          "169.68",
          "169.07",
          "170.19",
          "169.83",
          "170.08",
          "170.28",
          "175.63",
          "176.87",
          "175.11",
          "174.45",
          "162.11",
          "159.63",
          "158.43",
          "158.83",
          "157.33",
          "155.58",
          "154.00",
          "154.16",
          "151.03",
          "151.34",
          "148.11",
          "147.81",
          "144.90",
          "142.15",
          "147.20",
          "146.22",
          "150.37",
          "150.81",
          "153.34",
          "152.94",
          "152.71",
          "150.43",
          "149.10",
          "150.40",
          "148.75",
          "148.76",
          "146.47",
          "151.05",
          "151.88",
          "153.63",
          "152.80",
          "155.20",
          "154.87",
          "155.93",
          "154.89",
          "156.76",
          "155.73",
          "155.19",
          "156.90",
          "157.83",
          "156.60",
          "157.89",
          "157.80",
          "155.44",
          "151.94",
          "152.27",
          "150.11",
          "147.62",
          "137.38",
          "137.46",
          "136.51",
          "133.60",
          "135.42",
          "138.63",
          "142.97",
          "144.49",
          "142.62",
          "140.03",
          "142.02",
          "142.51",
          "142.48",
          "147.23",
          "152.16",
          "149.47",
          "150.85",
          "154.72",
          "156.35",
          "152.95",
          "151.36",
          "148.09",
          "145.87",
          "149.40",
          "149.53",
          "148.46",
          "145.70",
          "147.34",
          "145.57",
          "140.72",
          "133.50",
          "131.75",
          "133.65",
          "133.64",
          "131.75",
          "129.03",
          "134.20",
          "138.14",
          "139.47",
          "140.30",
          "138.62",
          "140.11",
          "137.64",
          "138.96",
          "139.14",
          "138.72",
          "142.38",
          "140.96",
          "137.16",
          "137.00",
          "138.58",
          "135.62",
          "132.57",
          "131.87",],
          color: "rgba(255,255,255,1)",
        },
        // {
        //   name: "Gewinn",
        //   data: [920, -158, 1236, 1267, 2345, 2533, 3374, 4687, 4427, 3307, 3963, 4807, 5682, 6136, 7501, 8382, 9391, 8980, 12598, 11054, -2864, 1995]
        // }
      ]
    };
  }

  render() {
    return (
          <div className="">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="area"
              width="100%"
              height="200%"
            />
      </div>
    );
  }
}

export default Aktienkurs;