import React from "react";


function BranchenReportsHeader() {


    return (
<>

<div className="BRHeader">

<h1 className="BRTopline">
Branchenanalyse
</h1>

<h2 className="BRSubLine">
    Müll und Abwassermanagement
</h2>

</div>

</>
  
    );
  }
  
  export default BranchenReportsHeader;