import BranchenReportsFinancialsChart3 from "./BranchenReportsFinancialsChart3";
import React from "react";


function BranchenReportsFinancialsApp3() {


    return (
<>

<span style={{fontSize:"20px", marginLeft:"5%", fontWeight:"900"}}>Profitabilität</span>

<div className="BRFundamentalsChartContainer" style={{marginTop:"5px", alignItems:"center"}}>
<BranchenReportsFinancialsChart3/>
</div>


</>
  
    );
  }
  
  export default BranchenReportsFinancialsApp3;