import BranchenReportsFinancialsChart2 from "./BranchenReportsFinancialsChart2";
import React from "react";


function BranchenReportsFinancialsApp2() {


    return (
<>

<span style={{fontSize:"20px", marginLeft:"5%", fontWeight:"900"}}>Margen</span>

<div className="BRFundamentalsChartContainer" style={{marginTop:"5px", alignItems:"center"}}>
<BranchenReportsFinancialsChart2/>
</div>


</>
  
    );
  }
  
  export default BranchenReportsFinancialsApp2;