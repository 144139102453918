import "./earnings.css"
import React from "react";

export default class FetchEarnings extends React.Component{



    state = {
        loading: true,
        date: null,
    }

    async componentDidMount() {
        const url = "https://financialmodelingprep.com/api/v3/earning_calendar?&to=2022-12-31&apikey=3c8a64de9342c1f496c9780b2e3fdba1"
        const response = await fetch(url);
        const data = await response.json()
        this.setState({date: data[0].date, loading: false})
        this.setState({symbol: data[0].symbol, loading: false})
        this.setState({eps: data[0].eps, loading: false})
        this.setState({revenue: data[0].revenue, loading: false})
        this.setState({time: data[0].time, loading: false})

        
    }

render() {


    return (

<>

<div className="earningsBody">

 <table className="earningsTable">
 <tr style={{height:"60px", borderBottom:"1px solid rgba(255,255,255,0.4)"}}>
 <td style={{fontSize:"calc(8px + 0.4vw)", fontWeight:"900"}}>
        Datum
        </td>
        <td style={{fontSize:"calc(8px + 0.4vw)", fontWeight:"900", textAlign:"right"}}>
        Ticker
        </td>
        <td style={{fontSize:"calc(8px + 0.4vw)", fontWeight:"900", textAlign:"right"}}>
            Unternehmen
        </td>
        <td style={{fontSize:"calc(8px + 0.4vw)", fontWeight:"900", textAlign:"right"}}>
            Umsatz (erwartet)
        </td>
        <td style={{fontSize:"calc(8px + 0.4vw)", fontWeight:"900", textAlign:"right"}}>
            EPS (erwartet)
        </td>
        <td style={{fontSize:"calc(8px + 0.4vw)", fontWeight:"900", textAlign:"right"}}>
            Zeit (US)
        </td>
    </tr>
    <tr style={{height:"60px", borderBottom:"1px solid rgba(255,255,255,0.4)"}}>
        <td style={{ fontSize:"calc(8px + 0.4vw)", textAlign:"left"}}>
        {this.state.loading || !this.state.date ? (
    <div>loading...</div> 
    ) : (
    <div>{this.state.date}</div>
    )}
        </td>
        <td style={{ fontSize:"calc(8px + 0.4vw)", textAlign:"right"}}>
        {this.state.loading || !this.state.symbol ? (
    <div>loading...</div> 
    ) : (
    <div>{this.state.symbol}</div>
    )}
        </td>
        <td style={{ fontSize:"calc(8px + 0.4vw)", textAlign:"right"}}>
            Apple Inc.
        </td>
        <td style={{ fontSize:"calc(8px + 0.4vw)", textAlign:"right"}}>
        {this.state.loading || !this.state.revenue ? (
    <div>loading...</div> 
    ) : (
    <div>{this.state.revenue}</div>
    )}
        </td>
        <td style={{ fontSize:"calc(8px + 0.4vw)", textAlign:"right"}}>
        {this.state.loading || !this.state.eps ? (
    <div>loading...</div> 
    ) : (
    <div>{this.state.eps}</div>
    )}
        </td>
        <td style={{ fontSize:"calc(8px + 0.4vw)", textAlign:"right"}}>
        {this.state.loading || !this.state.time ? (
    <div>loading...</div> 
    ) : (
    <div>{this.state.time}</div>
    )}
        </td>
    </tr>
 </table>

</div>



</>
  
    );
  }

}
